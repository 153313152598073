import React, {useState,useEffect} from "react";
import "../styles/ring.css"


const ColorRing = props => {

    const [percent,setpercent] = useState()
    const [leftTransformerDegree,setLeftTransformerDegree] = useState("0deg")
    const [rightTransformerDegree,setRightTransformerDegree] = useState("0deg")
    const borderWidth = 2
    
    useEffect(()=>{
        setpercent(props.percent)
        if (percent >= 50) {
          setRightTransformerDegree('180deg')
          setLeftTransformerDegree ((percent - 50) * 3.6 + 'deg')
        } else {
          setRightTransformerDegree(percent * 3.6 + 'deg')
          setLeftTransformerDegree ('0deg')
        }
    },[props.percent, percent])
    return (
        <div
          className="circle"
          style={{
            width: props.radius ?  props.radius * 2:20 *2,
            height: props.radius ?  props.radius * 2:20 *2,
            borderRadius: props.radius ?  props.radius:20,
            backgroundColor: props.bgcolor? props.bgcolor:"#e3e3e3",
          }}
        >
          <div
            className="left-wrap"
            style={{
              width: props.radius ?  props.radius:20,
              height: props.radius ?  props.radius * 2:20,
              left: 0,
            }}
          >
            <div
              className="loader"
              id="id1"
              style={{
                left: props.radius ?  props.radius:20,
                width: props.radius ?  props.radius:20,
                height: props.radius ?  props.radius * 2:20 *2,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                backgroundColor: props.color? props.color:'#000',
                transform: 'rotate(' + leftTransformerDegree + ')',
              }}
            />
          </div>
          <div
            className="right-wrap"
            style={{
              width: props.radius ?  props.radius:20,
              height: props.radius ?  props.radius * 2:20 *2,
              left: props.radius ?  props.radius:20,
            }}
          >
            <div
              className="loader2"
              id="id2"
              style={{
                left: props.radius ?  -props.radius:20,
                width: props.radius ?  props.radius:20,
                height: props.radius ?  props.radius * 2:20 *2,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: props.color? props.color:'#000',
                transform: 'rotate(' + rightTransformerDegree + ')',
              }}
            />
          </div>
          <div
            className="inner-circle"
            style={{
              left: borderWidth,
              top: borderWidth,
              width:  props.radius ? (props.radius - borderWidth) * 2:(20 - borderWidth) * 2,
              height:  props.radius ? (props.radius - borderWidth) * 2:(20 - borderWidth) * 2,
              borderRadius: props.radius - borderWidth,
              backgroundColor: props.innerColor ? props.innerColor :'#fff',
            }}
          >

          </div>
        </div>
      );
};

export default ColorRing;
