import React from "react";

import Modal from "react-responsive-modal";

const MainModal =(props) =>{
    
    const style = {
        modal: {
            
            ...props.style
        },
        modalAlert: {
            borderRadius: 4,
            width: "40%",
            ...props.style
        },
        broker: {
            borderRadius: 4,
            alignSelf: "center",
            width: "1500px",
            height: "860px",
            maxWidth: "1000000px",
            ...props.style
        },
        modalMed: {
            borderRadius: 4,
            width: "40%",
            ...props.style
        },
        modalBig: {
            borderRadius: 4,
            width: "50%",
            ...props.style
        },
        modalGigant: {
            borderRadius: 4,
            width: "66%",
            ...props.style
        },
        modalSuper: {
            borderRadius: 30,
            height: "800px", 
            width: "72%", 
            maxWidth:"1400px",
            padding:"2.2rem",
            ...props.style
        },
        headerView: {
            display: "flex",
            flexDirection: "column"
        },
        title: {
            fontSize: 18,
            fontWeight: 700,
            marginTop: 10
        },
        headerViewCenter: {
            display:"flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "15px"
        }

        
    };

    return (
        <Modal
            styles={{ modal: props.broker ? style.broker : props.med? style.modalMed: props.big ? style.big : props.gigant ? style.modalGigant: props.super ? style.modalSuper:props.alert ? style.modalAlert:style.modal }}
            classNames={props.classNamesModal ? props.classNamesModal:{} }
            open={props.isVisible}
            onClose={props.onClose}
            center
        >
                <div className="lalal" style={props.center ? style.headerView:style.headerViewCenter}>
                    {props.banner && (<img className="banner" style={props.bannerStyle} src={props.banner} />)}
                    <label className="modalTitle" style={style.title}>{props.title}</label>
                    <label className="modalSubtitle">{props.subtitle}</label>
                    {props.powered && (<div style={{display: "flex", alignItems: "center"}}>
                        <span>Powered by </span> <img style={props.poweredStyle} src={props.powered} id="logo" alt="logo"/>
                    </div>)}
                </div>
                {props.children}
        </Modal>
    );
};

export default MainModal;
