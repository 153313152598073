import React, {useState, useEffect} from "react";
import Jobin from 'jobin-client'

import { Loading } from 'jobin-ui-components'
import NavBar from "../../components/NavBar";
import BillingCreator from '../../components/billing/BillingCreator'
import ButtonImage from '../../components/button/ButtonImage'

export default function Create(props) {

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState();
    const [cif, setCif] = useState();
    const [address1, setAddress1] = useState();
    const [address2, setAddress2] = useState();

    const [rowArray, setRowArray] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);

    const style = {
        loadingContainer: {
            display: "flex",
            flex: 1,
            backgroundColor: "var(--soft-grey)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
        },
        container:{
            display: 'flex',
            backgroundColor: "var(--soft-grey)",
            height: 'calc(100vh - 72px)',
            justifyContent: 'center'
        }
    }

    useEffect(() => {
        const totalPrice = rowArray.reduce((total, item) => {
            return total + item.amount * item.pricePerUnit;
        }, 0);

        setTotalPrice(totalPrice);
    }, [rowArray]);

    const saveBill = async () =>{
        setLoading(true);
        const result = await Jobin.PDF.createCustomBill(rowArray, name, cif, address1, address2);
        if(result.status === 'ok'){
            setLoading(false);
            setName(undefined);
            setCif(undefined);
            setAddress1(undefined);
            setAddress2(undefined);

            var win = window.open(result.result, '_blank');
            win.focus();
        }
        else{
            setLoading(false);
            alert(result.result);
        }
    }

    const addRow = ({ description, amount, pricePerUnit }) => {
        const newRow = {
            description: description,
            amount: amount,
            pricePerUnit: pricePerUnit
        };
        const newRowArray = [...rowArray, newRow];
        setRowArray(newRowArray);
    };

    const editRow = (index, { description, amount, pricePerUnit }) => {
        const newRow = {
            description: description,
            amount: amount,
            pricePerUnit: pricePerUnit
        };
        rowArray[index] = newRow;
        setRowArray([...rowArray]);
    };

    const enableEdit = index => {
        rowArray[index].editable = true;
        setRowArray([...rowArray]);
    };

    const deleteRow = index => {
        rowArray.splice(index, 1);
        setRowArray([...rowArray]);
    };

    const renderNavBarButtons = (
        <section style={style.buttonContainer}>
            <div className={`filter custom publish-no-hide`}>
                <ButtonImage
                    src={require("../../assets/available.svg")}
                    buttonText="Guardar"
                    onClick={saveBill}
                    disabled={! (name && cif && address1 && address2 && rowArray.length > 0)}
                />
            </div>
        </section>
    );

    if (loading) {
        return (
            <section style={style.loadingContainer}>
                <Loading />
            </section>
        );
    } else {
        return (
            <React.Fragment>
                <NavBar
                    history={props.history}
                    location={props.location}
                    title={"Nueva factura"}
                    buttonView={renderNavBarButtons}
                />
                <div style={style.container}>
                    <BillingCreator
                    changeName={setName}
                    changeIdentityNumber={setCif}
                    changeAddress1={setAddress1}
                    changeAddress2={setAddress2}

                    rowArray={rowArray}
                    totalPrice={totalPrice}

                    addRow={addRow}
                    editRow={editRow}
                    deleteRow={deleteRow}
                    enableEdit={enableEdit}
                    />
                </div>
            </React.Fragment>
        );
    }
}
