import React, { useEffect, useState } from "react";
import MainModal from "./MainModal";
import Lottie from 'react-lottie'
import * as cardSpinnerData from "../../assets/animations/button-loading.json";
import FilterSingleSelection from "../filter/FilterSingleSelection";
import fetchBusinesses from "../../constants/businesses";
import fetchUsers  from "../../constants/user/fetchUsers";
import fetchDepartments  from "../../constants/crm/departments";

const NewCrm = props => {   
    const [Businesses, setBusinesses] = useState(false);
    const [Users, setUsers] = useState(false);
    const [Departments, setDepartments] = useState(false);
    const [Name, setName] = useState("");
    const [Lastname, setLastname] = useState("");
    const [Username, setUsername] = useState("");
    const [Password, setPassword] = useState("");
    const [UserAsociated, setUserAsociated] = useState(null);
    const [DepartmentSelected, setDepartmentSelected] = useState(false);
    const [BusinessAsociated, setBusinessAsociated] = useState(false);
    const [Makinguser, setMakinguser] = useState(false);
    const [loading,setLoading] = useState(true);
    const [readyToSave,setReadyToSave] = useState(false);

    useEffect(()=>{
        async function  fetchAsync(){
            let Businesses_ = await fetchBusinesses()
            let Users_ = await fetchUsers()
            let Departments_ = await fetchDepartments()
            setBusinesses(Businesses_.filter((element)=>element.id!="all"))
            setUsers(Users_.filter((element)=>element.id!="all"))
            setDepartments(Departments_.filter((element)=>element.id!="all"))
            setLoading(false)
        }
        fetchAsync()
    },[])
    useEffect(()=>{
        checkConditions(Name, Lastname, Username, Password, DepartmentSelected) ? setReadyToSave(true):setReadyToSave(false)
    },[Name,
        Lastname,
        Username,
        Password,
        DepartmentSelected])

   const styles = {
       select: {
            width: "200px",
            height: "35px",
            padding: "5px",
            borderRadius: "5px",
            border: "transparent",
            boxShadow: "rgb(0 0 0 / 30%) 0px 0px 10px 3px",
            margin: "15px",
        },
        
        subTitle:{
            fontWeight: "700", 
        },
        buttonFilter:{
            background:"white",
            padding:"10px",
            width:"120px",
            border: "#0000",
            boxShadow: "rgb(0 0 0 / 30%) 0px 0px 10px 3px",
            color:"#000",
            marginBottom:"20px"
        },
        rulesContainer:{
            padding:" 10px 0",
            overflowY: "auto",
            maxHeight: "550px",
            marginTop: "10px",
            minHeight:"550px"
        },
        input:{
            width: '100%',
            padding: '5px',
            border: '1px solid #4a4a4a',
            borderRadius: '4px',
            marginRight:"auto",
            height:"44px"
        },
        filterContainer:{
            marginBottom:"10px",
            display:"flex",
            width:"100%",
            justifyContent:"space-around"
        }

    }
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: cardSpinnerData.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    const checkConditions = (Name_=null,Lastname_=null,Username_=null,Password_=null,DepartmentSelected_=null)=>{

       
        var name              = Name_ != null && Name_ != "" ? true:false
        var lastname          = Lastname_ != null && Lastname_ != "" ? true:false
        var username          = Username_ != null && Username_ != "" ? true:false
        var password          = Password_ != null && Password_ != "" ? true:false
        var departmentSelected = DepartmentSelected_ != null && DepartmentSelected_ != false && DepartmentSelected_ != []    ? true:false
        

        return name && lastname && username && password && departmentSelected ? true:false
    }
    const makeUser = ()=>{
        setMakinguser(true)
        props.createAgent(Name, Lastname, Username, Password, UserAsociated, DepartmentSelected.id, BusinessAsociated)
    }
   

    return (
        <MainModal
            isVisible={props.isVisible}
            onClose={props.onClose}
            modalGigant={true}
            title="Crear nuevo agente"
            subtitle="Introduce los datos del nuevo agente"
        >    
            <div style={styles.rulesContainer}>
                <div style={{marginBottom:"10px"}} >
                    <label style={{color: '#4a4a4a', fontSize: '14px', marginTop: '8px', width:"100%"}}>Nombre      </label>
                    <input
                        className="textInput"
                        type="text" 
                        style={styles.input} 
                        value={Name}
                        onChange={(e)=>setName(e.target.value)}
                        name="Name"
                    ></input>
                    
                </div>
                <div style={{marginBottom:"10px"}} >
                    <label style={{color: '#4a4a4a', fontSize: '14px', marginTop: '8px', width:"100%"}}>Apellido        </label>
                    <input
                        className="textInput"
                        type="text" 
                        style={styles.input} 
                        value={Lastname}
                        onChange={(e)=>setLastname(e.target.value)}
                        name="LastName"
                    ></input>
                    
                </div>
                <div style={{marginBottom:"10px"}} >
                    <label style={{color: '#4a4a4a', fontSize: '14px', marginTop: '8px', width:"100%"}}>Usuario / Correo</label>
                    <input
                        className="textInput"
                        type="text" 
                        style={styles.input} 
                        value={Username}
                        onChange={(e)=>setUsername(e.target.value)}
                        name="UserName"
                    ></input>
                    
                </div>
                <div style={{marginBottom:"10px"}} >
                    <label style={{color: '#4a4a4a', fontSize: '14px', marginTop: '8px', width:"100%"}}>Contraseña      </label>
                    <input
                        className="textInput"
                        type="password" 
                        style={styles.input} 
                        value={Password}
                        onChange={(e)=>setPassword(e.target.value)}
                        name="Password"
                    ></input>
                    
                </div>
                
                {!loading && (<div style={styles.filterContainer} >
                    <FilterSingleSelection
                        style={styles.singleSelector}
                        styleMenu={{ maxHeight:"180px"}}
                        type="Department"
                        title={"Departamento"}
                        items={Departments}
                        selectionChange={(e)=>setDepartmentSelected(e)}
                        styleButton={styles.buttonFilter}
                    />  
                    <FilterSingleSelection
                        disabled={(UserAsociated != null || UserAsociated != [] ) && BusinessAsociated == null  ? true:false}
                        style={styles.singleSelector}
                        styleMenu={{ maxHeight:"180px"}}
                        type="Business"
                        title={"Negocio"}
                        items={Businesses}
                        selectionChange={(e)=>setBusinessAsociated(e)}
                        styleButton={styles.buttonFilter}
                    />  
                    <FilterSingleSelection
                        disabled={(BusinessAsociated != null || BusinessAsociated != [] ) && UserAsociated == null ? true:false}
                        style={styles.singleSelector}
                        styleMenu={{ maxHeight:"180px"}}
                        type="User"
                        title={"Usuario"}
                        items={Users}
                        selectionChange={(e)=>setUserAsociated(e)}
                        styleButton={styles.buttonFilter}
                        right={"right"}
                    />  
                </div>)}                  
            </div>
            <div style={{marginBottom:"10px",display:"flex",width:"100%",justifyContent: "center"}}>
                <button disabled={!readyToSave} className="activateButton"
                onClick={()=>{makeUser()}}>
                            { Makinguser ?  
                                (<Lottie style={{height: 30, width: 80}} options={defaultOptions}/>):
                                "Crear"
                            }
                </button>
                            </div>
        </MainModal>
    );
};

export default NewCrm;