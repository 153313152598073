import React, { useState, useEffect, useRef } from "react";
import ButtonImage from "../button/ButtonImage";
import DispatModal from "../modal/DispatModal";
import NewCrm from "../modal/NewCrm";
import SearchBarID from "./SearchBarID"
import {createAgent} from "../../utils/ParserUtilities"


import { useStateValue } from "../../state/context";

import "../../styles/search/search-nav.css";

export default function SearchNav(props) {
    const [{ agentsSearch}, dispatch] = useStateValue();
    const [dispatModalShow, setDispatModalShow] = useState(false);
    const [newCRMShow, setNewCRMShow] = useState(false);
    const [idOptionArr, setIdOptionArr] = useState([]);

    const createAgent_ = async (Name_, Lastname_, Username_, Password_, UserAsociated_, DepartmentSelected_, BusinessAsociated_)=>{
       let result=  await createAgent(  Lastname_, Password_, Name_, DepartmentSelected_, Username_, BusinessAsociated_,  UserAsociated_)
        setNewCRMShow(false)
       if(result){
        dispatch({
            type: "SEARCH_AGENT_REFRESH",
            refresh: true
        });
       }else{
           alert("La creacion del nuevo agente ha fallado")
       }
    }

    return (
        <section className="search-nav">
            
            {dispatModalShow && (
                <DispatModal
                    isVisible={dispatModalShow}
                    onClose={() => setDispatModalShow(false)}
                />
            )}
            {newCRMShow && (
                <NewCrm
                    isVisible={newCRMShow}
                    onClose={() => setNewCRMShow(false)}
                    createAgent={createAgent_}
                />
            )}
            <div className="above">
                <div className="container-fluid">
                    
                   
                        <div className="col-md-6 text-left">
                                <ButtonImage
                                    src={require("../../assets/rayo.svg")}
                                    buttonText={
                                        'Dispat'
                                    }
                                    onClick={() => setDispatModalShow(true)}
                                    style={{ display: "inline-block" }}
                                />
                            <ButtonImage
                                src={require("../../assets/add.svg")}
                                buttonText="Nuevo"
                                onClick={() => setNewCRMShow(true)}
                                style={{ display: "inline-block" }}
                            />
                        </div>
                   
                </div>
            </div>
        </section>
    );
}
