import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { useStateValue } from "../../state/context";
import CategoryList from "./generic/CategoryList";
import StepControlls from "./generic/StepControlls";

import "../../styles/job/service-category.css";

export default function ServiceCategory(props) {
    const [{ job }, dispatch] = useStateValue();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [title, setTitle] = useState("");
    const [items, setItems] = useState([]);

    const childHandler = dataFromChild => {
        setTitle(dataFromChild);
    };

    useEffect(() => {
        dispatch({
            type: "JOB_ROUTER",
            history: props.history,
            location: props.location,
            match: props.match
        });

        let didCancel = false;

        async function getChildren() {
            try {
                const {result: parent} = await Jobin.Guild.get(props.match.params.subCategoryId);
                const result = await Jobin.Guild.getFatherChilds(parent, !job.store.exists);
                if (result.status === "ok") {
                    if (!didCancel) {
                        if (result.result.length > 0) {
                            setTitle(parent.get("ViewTitle"));
                            setItems(result.result);
                            setIsLoaded(true);
                        } else {
                            dispatch({
                                type: "JOB_CATEGORY_GUILD_SUBCATEGORY",
                                subCategoryId: props.match.params.subCategoryId,
                            });
                            job.history.replace({
                                pathname: "/job/new/location",
                                search: job.location.search,
                                state: {
                                    inner: true
                                }
                            });
                        }
                    }
                } else {
                    if (!didCancel) {
                        setIsLoaded(true);
                        setError(result.result);
                    }
                }
            } catch (error) {
                if (!didCancel) {
                    setIsLoaded(false);
                    setError(error);
                }
            }
        }

        getChildren();

        // unmount
        return () => {
            didCancel = true;
        };
    }, []);

    return error ? (
        <div>Error: {error.message}</div>
    ) : (
        <div id="service-category" className="section">
            <div className="container">
                <div className="row">
                    {isLoaded && (
                        <div className="col-md-6">
                            <h4 className="title">{title}</h4>
                            <p>
                                Selecciona una de las opciones para aportar más
                                información.
                            </p>
                            <div className="content">
                                <ul className="subvert-list">
                                    <CategoryList
                                        service={items}
                                        parent={
                                            props.match.params.subCategoryId
                                        }
                                        parentTitle={childHandler}
                                    />
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <StepControlls page={0} nextHidden={true} prev="root"/>
        </div>
    );
}
