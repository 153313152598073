export const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        width: 780,
        backgroundColor: "#fff",
        padding: 70
    },
    row: {
        display: "flex",
        flexDirection: "row",
        height: 120
    },
    column: {
        display: "flex",
        flex: 1,
        flexDirection: "column"
    },
    logo: {
        height: 120,
        width: 120
    },
    billContainer: {
        display: "flex",
        flex: 1,
        marginTop: 48,
        flexDirection: "column"
    },
    billTitles: {
        display: "flex",
        height: 35,
        borderBottom: "2px solid var(--soft-grey)"
    },
    billColumn: {
        padding: "5px",
        display: "flex",
        width: 105,
        alignItems: "center",
        justifyContent: "flex-end"
    },
    dotsColumn: {
        padding: "5px",
        display: "flex",
        width: 46,
        alignItems: "center",
        justifyContent: "flex-end"
    },
    descriptionColumn: {
        padding: "5px",
        display: "flex",
        flex: 1,
        alignItems: "center"
    },
    billRow: {
        display: "flex",
        height: 46
    },
    resume: {
        display: "flex",
        alignItems: "flex-end",
    },
    resumeContainer: {
        display: "flex",
        flexDirection: "column",
        borderTop: "2px solid var(--soft-grey)"
    }
};
