import React from 'react';

const JobsMapsWollerMarker = (params) => {

    const handleOnClick = () => {
        params.setWollerCard(params.woller);
        params.setShowWollerCard(true);
    }

    return <img onClick={handleOnClick} className={`jobs-map-woller-icon ${params.woller.get("nJobs") > 0 ? "b-c-blue" : "b-c-red"}`} 
                src={params.woller.get("UserPic") ? params.woller.get("UserPic").url() : require("../../assets/user-default-pic.svg")} />
    
}

export default JobsMapsWollerMarker;