import React from "react";

import Button from "../../../button/Button";

export default function AgentCell(props) {
    const style = {
        cellContainer: {
            display: "flex",
            flexDirection: "row",
            position: "relative",
            alignItems: "center",
            marginTop: 8,
            paddingTop: 8,
            paddingBottom: 8,
            borderBottom: "1px solid var(--soft-grey)"
        },
        nameLabel: {
            fontWeight: "bold",
            lineHeight: "1.8em",
            maxHeight: "1.8em",
            textOverflow: "elipsis",
            wordWrap: "break-word",
            overflow: "hidden"
        },
        imageView: {
            position: "relative",
            height: 56,
            width: 56,
            marginRight: 12
        },
        image: {
            height: 56,
            width: 56,
            borderRadius: 28,
            overflow: "hidden",
            objectFit: "cover"
        },
        medalImage: {
            position: "absolute",
            height: 20,
            width: 20,
            bottom: -3,
            right: -3
        },
        dataView: {
            display: "flex",
            flex: 1,
            flexDirection: "column"
        },
        pointsLabel: {
            color: "var(--coral)",
            marginLeft: 8,
            marginRight: 3,
            fontWeight: "bold"
        },
        newLabel: {
            color: "#fff",
            marginLeft: 8,
            fontWeight: "bold",
            backgroundColor: "var(--coral)",
            fontSize: 10,
            padding: 4,
            height: 22,
            alignItems: "center",
            borderRadius: 4
        },
        button: {
            marginLeft: 8
        }
    };

    return (
        <section style={style.cellContainer}>
            <div style={style.imageView}>
            <img style={style.image} src={require("../../../../assets/usuario.svg")} alt="usuario" />
            </div>
            <div style={style.dataView}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                    }}
                >
                    <label style={style.nameLabel}>
                        {props.agent.has("Lastname")
                            ? props.agent.get("Name") + " " + props.agent.get("Lastname")
                            : props.agent.get("Name")}
                    </label>
                </div>
                <label>{props.agent.get("email")}</label>
            </div>
            <Button style={style.button} buttonText={props.buttonText || "Ver"} onClick={props.onClick} />
        </section>
    );
}
