import React, { useState, useEffect,Fragment } from "react";
import Jobin from "jobin-client";

import MainModal from "./MainModal";
import {editExtras} from  "../../utils/ParserUtilities.js";
import { useStateValue } from "../../state/context";




import { Input } from "jobin-ui-components";


const EditExtrasModal = props => {
    const style = {
        addContainer: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 16,
            borderTop: "1px solid #e8e8e8",
            marginTop: 24,
        },
        input: {
            borderRadius: 0,
            width: 160,
            border: "1px solid var(--greyish)",
        },
    };
    const [extrasArray, setExtrasArray] = useState(props.extrasArray);
    

    const [priceOk, setPriceOk] = useState(true);


   
    useEffect(() => {


        //Remove urgency extra if needed
        const tempArray = extrasArray.filter(item => item.type !== "urgency");
        setExtrasArray(tempArray);
    }, []);


    const onClose = async() => {
        props.onClose();
    };
    const handleChange = async (event,indexG)=>{
        let arrayTem = [...extrasArray]

        arrayTem[indexG][event.target.name]=event.target.value
       
        setExtrasArray(arrayTem)

    }
    const renderExtraView = () => {
        return (
            <Fragment>
                {extrasArray.map((extra,index)=>{
                    
                    return(
                    <div style={style.addContainer} className="flex-justify-evenly" key={"extraEdit"+index}>
                <div className="display-flex flex-column">
                    <label style={{color: '#B0B0B0', fontSize: '12px', marginTop: '8px', width:"100%"}}>Descipcion</label>
                    <Input
                        style={{
                            display: "flex",
                            flex: 1,
                            borderRadius: 0,
                            border: "1px solid var(--greyish)",
                        }}
                       
                    
                        placeholder="Descripción"
                        value={extra.name}
                        onChange={(e)=>handleChange(e,index)}
                        name={"name"}
                    />
                </div>
                <div className="display-flex flex-column">
                    <label style={{color: '#B0B0B0', fontSize: '12px', marginTop: '8px', width:"100%"}}>€/unidad</label>
                    <Input
                        style={style.input}
                        badInput={!priceOk}
                        placeholder="€/unidad"
                        value={extra.pricePerUnit ? extra.pricePerUnit : undefined}
                        onChange={(e)=>handleChange(e,index)}
                        name={"pricePerUnit"}

                    />
                </div>
                <div className="display-flex flex-column">

                    <label style={{color: '#B0B0B0', fontSize: '12px', marginTop: '8px', width:"100%"}}>Cantidad</label>
                    <input 
                        style={{
                        borderRadius: 0,
                        border: "1px solid var(--greyish)",
                        ...style.input,
                    }}
                        type="number" 
                            className="bwbEPc"
                        
                        min={1} 
                        max={1000}
                        value={extra.units}
                        onChange={(e)=>handleChange(e,index)}
                        name={"units"}

                    />
                </div>
                        
                    </div>)
                })}
            </Fragment>
               
        );
    };

    return (
        <MainModal
            title={"Extras del servicio"}
            subtitle={
                "Edita los extras del servicio básico contratado por el cliente."
            }
            isVisible={props.isVisible}
            onClose={onClose}
            big
        >
            {renderExtraView()}
        </MainModal>
    );
};

export default EditExtrasModal;
