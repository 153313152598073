import React, { useEffect, useState } from "react";

import "../../styles/multiSelect.css";

export default function MultiSelect({title,values,preSelected=[],handleSelected}) {
   const [valuesSelected,setValuesSelected] = useState([...preSelected])
   const [showList,setShowList] = useState(false)
   

    const handleValueSelected = (selectedValue)=>{
        if(valuesSelected.length > 0 && valuesSelected.some((element)=>element.id == selectedValue.id)){
            let filterValues = valuesSelected.filter((item)=> item.id!=selectedValue.id) 
            setValuesSelected(filterValues)
            handleSelected(filterValues)
        }else{
            setValuesSelected([...valuesSelected,selectedValue])
            handleSelected([...valuesSelected,selectedValue])
        }
    }

    return (
        <div className="multiSelectContainer">
            <p className="titleMultiSelect">{title}</p>
            <div   className="multiSelect" onClick={()=>{setShowList(!showList)}} >
                <p className="selectedOptions">{ valuesSelected.length > 0 ? valuesSelected?.map((value)=>{
                     if(value.id != "all"){
                        return value.name+" "
                     }
                                                            })
                :"Selecciona una opcion"}</p>
                <span className={showList ? "multiSelectIcon active":"multiSelectIcon"}></span>
            </div>
            <div className={showList ? "optionList active":"optionList"}>
                {values?.map((value)=>{
                    if(value.id != "all"){
                        return (
                            <div key={value.id} className={valuesSelected.some((element)=>element.id == value.id) ? "selected option":"option"} onClick={()=>handleValueSelected(value)}> {value.name} </div>
                        )
                    }
                })}
            </div>
        </div>
    );
}
