import React, { useState } from "react";
import MainModal from "./MainModal";
import Jobs from "../user/detail/Jobs"

const OpsNotificationDetailModal = props => {

    return (
        <MainModal
            style={{ height: "calc(100vh - 143px);", width: "700px" }}
            title={"Trabajos comprados"}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        >
            <Jobs biPro={true} history={props.history}/>
        </MainModal>
    )
}

export default OpsNotificationDetailModal;