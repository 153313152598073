import React from "react";
import PurchasedJobs from "../../components/purchased-jobs/PurchasedJobs";

export default function Search(props) {

    return (
        <React.Fragment>
            <PurchasedJobs history={props.history} location={props.location}/>
        </React.Fragment>
    );
}