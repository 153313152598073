import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { useStateValue } from "../../../../state/context";

import HistoryCell from "./HistoryCell";

import "../../../../styles/search/detail/job-detail-history.css";

export default function HistoryContainer() {
    const [ {user,jobDetails}, dispatch] = useStateValue();

    useEffect(() => {
        dispatch({
            type: "JOB_DETAILS_JOB",
            getHistoryOfJob: getHistoryOfJob
        });
        getHistoryOfJob(jobDetails.job);
    }, []);

    const getHistoryOfJob = async job => {
        const result = await Jobin.History.getHistoryOfJob(job);
        if (result.status === "ok") {
            dispatch({
                type: "JOB_DETAILS_JOB",
                historyArray: result.result
            });
        }
    };

    return (
        <section className="historyContainer">
            <div className="listContainer">
                {!user?.isGoldWoller && jobDetails.historyArray &&
                jobDetails.historyArray.length > 0 ? (
                    jobDetails.historyArray.map((item, index) => {
                        return (
                            <HistoryCell
                                key={"history" + index}
                                history={item}
                            />
                        );
                    })
                ) : (
                    <label>No hay histórico</label>
                )}
            </div>
        </section>
    );
}
