import React, { useState } from "react";
import MainModal from "./MainModal";
import IncidentDetailModal from "./IncidentDetailModal"

import '../../styles/job/incidents.css';

const AllIncidentModal = props => {

    const [showIncidentDetailModal, setShowIncidentDetailModal] = useState(false);
    const [count, setCount] = useState(8);
    const [incidentId, setIncidentId] = useState();

    let pathname = window.location.href.split('/');

    const calculateCount = ( n ) => {
        let newCount = count + n;
        if(newCount>0) setCount(newCount);
    }

    const showDetail = (id) => {
        if(!props.mediator){
            setIncidentId(id);
            setShowIncidentDetailModal(true);
        }
    }

    return (
        <MainModal
            style={{ height: "700px", width: "1000px" }}
            title={"Todas las Incidendias"}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        >
            {
                props.incidents.slice((count-8) , count).map( (incident, i) => (
                    <button className='all-incident-wrapper' onClick={()=> showDetail(incident.id)}>
                        <div className='space-between'>
                            <p className='incident-typology'>{incident.get('ExtraInfo').typology}</p>
                            <p className={props.stateClassname(incident.get('ExtraInfo').state)}>{incident.get('ExtraInfo').state}</p>
                        </div>
                        <p className='incident-description'>{incident.get('ExtraInfo').description}</p>
                        <div className='space-between'>
                            <p className='incident-createdAt'>{props.formatDate(incident.get('createdAt').toString())}</p>
                            {
                                incident.get('User') && (
                                    <p className='incident-woller'>{incident.get('User').get('Name')}</p>
                                )
                            }
                        </div>
                    </button>
                ))
            }
            <div style={{display: "flex", justifyContent: "space-between", width: "250px", margin: "30px 0px 0px 15px"}}>
                <button className='back-next-incidence-button' onClick={() => calculateCount(-8)}>Anterior</button>
                <p className='incident-count-page'>{`${count-7} - ${count}`}</p>
                <button className='back-next-incidence-button' onClick={() => calculateCount(8)}>Siguiente</button>
            </div>

            <IncidentDetailModal
                isVisible={showIncidentDetailModal}
                incidents={props.incidents}
                incidentId={incidentId}
                onClose={() => setShowIncidentDetailModal(false)}
                jobId= {pathname[pathname.length - 1]}
                formatDate={props.formatDate}
                fetchIncidents={props.fetchIncidents}
            />
        </MainModal>
    )
}

export default AllIncidentModal;