import React, { useEffect } from "react";
import { useStateValue } from "../../state/context";
import { initialState } from "../../state/initialState";
import { HashRouter as Router, Route } from "react-router-dom";

import ServiceType from "../../components/job/ServiceType";
import ServiceCategory from "../../components/job/ServiceCategory";
import ServiceLocation from "../../components/job/ServiceLocation";
import ServiceWeight from "../../components/job/ServiceWeight";
import ServicePrice from "../../components/job/ServicePrice";
import ServiceDate from "../../components/job/ServiceDate";
import ServiceDescription from "../../components/job/ServiceDescription";
import ServicePicture from "../../components/job/ServicePicture";
import ServiceUrgency from "../../components/job/ServiceUrgency";
import ServiceSummary from "../../components/job/ServiceSummary";
import ServiceCompleted from "../../components/job/ServiceCompleted";
import ServiceAvailability from "../../components/job/ServiceAvailability";

export default function Job() {
    const [{ job }, dispatch] = useStateValue();

    useEffect(() => {
        // unmount
        return () => {
            dispatch({
                type: "JOB_RESET",
                job: initialState.job
            });
        };
    }, []);

    return (
        <Router>
            <Route path="/job/new" exact={true} component={ServiceType} />
            <Route
                path="/job/new/category/:subCategoryId"
                component={ServiceCategory}
            />
            <Route
                path="/job/new/location"
                render={({ history, location }) => (
                    <ServiceLocation
                        {...job.locate}
                        history={history}
                        location={location}
                    />
                )}
            />
            <Route path="/job/new/weight" component={ServiceWeight} />
            <Route path="/job/new/price" component={ServicePrice} />
            <Route
                path="/job/new/availability"
                component={ServiceAvailability}
            />
            <Route path="/job/new/date" component={ServiceDate} />
            <Route
                path="/job/new/description"
                render={({ history, location }) => (
                    <ServiceDescription
                        {...job.description}
                        history={history}
                        location={location}
                    />
                )}
            />
            <Route path="/job/new/picture" component={ServicePicture} />
            <Route path="/job/new/urgency" component={ServiceUrgency} />
            <Route path="/job/new/summary" component={ServiceSummary} />
            <Route path="/job/new/completed" component={ServiceCompleted} />
        </Router>
    );
}
