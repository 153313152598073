import React, { useState, useEffect, useCallback } from "react";
import { useStateValue } from "../../state/context";
import { createNewRefuseNotification, createOpsNotification, resetAJob} from "../../utils/ParserUtilities";
import { Modal } from 'jobin-ui-components';

const RefuseServiceModal = (props) => {
    const [{ user }] = useStateValue();

    const [loading, setLoading] = useState(false);
    const [showFalse, setShowFalse] = useState(false);
    const [textAnulationReason, setTextAnulationReason] = useState("");
    const [measureSelected, setMeasureSelected] = useState(true);

    const jobType = props.job.jobType;
    const [title, setTitle] = useState("Rechazo del servicio");
    const [subtitle, setSubtitle] = useState(null);

    useEffect(() => {
        setLoading(false);
    }, [props.job, measureSelected, jobType]);

    const onClose = useCallback((rejected) => {
        props.onClose(rejected);
    }, [props]);

    const cancelAction = useCallback(async (action) => {
        setLoading(true);
        try {

            if (textAnulationReason) {
                await createOpsNotification(props.job.id, textAnulationReason, "Motivo anulación");
            }
        } catch (error) {
            console.error("Error canceling job:", error);
        } finally {
            setLoading(false);
        }
    }, [measureSelected, textAnulationReason, props]);

    const createRefuseNotification = useCallback(async () => {
        await createNewRefuseNotification(props.job.id, user, textAnulationReason);
    }, [textAnulationReason, props.job]);

    const resetJob = useCallback(async () => {
        await resetAJob(props.job.id);
    }, [props.job]);

    const onClick = useCallback(async () => {
        try {
            await createRefuseNotification();
            await resetJob();
            onClose(true);
        } catch (error) {
            console.error("Error in onClick handler:", error);
        }
    }, [createRefuseNotification, resetJob, onClose]);

    const handleChangeDescription = (event) => {
        setTextAnulationReason(event.target.value);
    };

    return (
        <Modal
            title={title}
            subtitle={subtitle}
            onClose={onClose}
            buttonText={showFalse ? "Cancelación" : ("Rechazar servicio")}
            secondButtonText={showFalse ? "Falso" : null}
            loading={loading}
            onClick={onClick}
            secondOnClick={() => cancelAction("falso")}
            goBack={( showFalse) ? onClose : null}
        >
            {user?.isConstructionCompany && (
                <div>
                    <label style={{ color: '#B0B0B0', fontSize: '12px', marginTop: '8px' }}>
                        Motivo del rechazo
                    </label>
                    <textarea
                        style={{
                            width: '100%',
                            padding: '5px',
                            border: '1px solid #E3E3E3',
                            borderRadius: '4px',
                            minHeight: '80px',
                            resize: 'none'
                        }}
                        value={textAnulationReason}
                        onChange={handleChangeDescription}
                    ></textarea>
                </div>
            )}
        </Modal>
    );
}

export default RefuseServiceModal;
