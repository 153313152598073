import React, { useState } from "react";
import Jobin from "jobin-client"
import Parse from "parse";

import NavBar from "../../components/NavBar";
import SignUpData from "../../components/user/signup/SignUpData";
import SignUpLocation from "../../components/user/signup/SignUpLocation";
import SignUpVerticales from "../../components/user/signup/SignUpVerticales";
import {createJober} from "../../utils/ParserUtilities"

import { apiId, jsKey, urlServer } from '../../constants/credentials';

import "../../styles/search/detail/job-details.css";

export default function SignUp(props) {
    const [currentView, setCurrentView] = useState("data");
    const [user, setUser] = useState(undefined);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [loading, setLoading] = useState(false);

    const nextView = async (currentView, collectedData) => {
        setLoading(true);
        if (currentView === "data") {
            let {name, phone, email, type, level, leadsFree, selectedFile, description, nif} = collectedData;
            let isNegative = false;
            if((level && level < 0) || (leadsFree && leadsFree < 0)) isNegative = true;
            if (type === "jober") {
                if (!isNegative) {
                let {status, result} = await createJober({name:name, phone:phone, email:email, nif:nif});
                    if (status == 'ok') {
                        Parse.initialize(apiId, jsKey);
                        Parse.serverURL = urlServer;
                        Parse.User.enableUnsafeCurrentUser();
                        Parse.Cloud.run("updateLevelDescriptionJober", { level: parseInt(level), leadsFree: parseInt(leadsFree), description: description, id: result.id, selectedFile: selectedFile });
                        setUser(result);
                        setCurrentView("location");
                    } else {
                        alert('El usuario ya existe');
                    }
                } else{
                    alert('El campo Nivel y Leads gratuitos no pueden ser numeros negativos');
                }
            } else {
                let {status, result} = await Jobin.Client.createClient(name, phone, email);
                if (status == 'ok') {
                    setUser(result);
                    props.history.push({
                        pathname: "/user/" + result.id,
                        state: {
                            inner: true
                        }
                    });
                } else {
                    alert(result);
                }
            }
        } else if (currentView === "location") {
            let {lat, lng, address} = collectedData;
            setLat(lat);
            setLng(lng);
            setCurrentView("verticales");
        } else if (currentView === "verticales") {
            let {verticalIds, isDefaultJober} = collectedData;
            let {status, result} = await Jobin.Jober.updateJoberLocationAndTasks(
                user,
                lat,
                lng,
                verticalIds,
                isDefaultJober
            );
            if (status == 'ok') {
                props.history.push({
                    pathname: "/user/" + user.id,
                    state: {
                        inner: true
                    }
                });
            } else {
                alert(result);
            }
        }
        setLoading(false);
    };
    return (
        <section>
            <NavBar history={props.history} location={props.location} title={"Registro"} />
            <div className="detailContainer">
                {currentView === "data" ? (
                    <SignUpData nextView={nextView} loading={loading} />
                ) : currentView === "location" ? (
                    <SignUpLocation nextView={nextView} loading={loading} />
                ) : currentView === "verticales" ? (
                    <SignUpVerticales nextView={nextView} loading={loading} />
                ) : null}
            </div>
        </section>
    );
}
