import React, { useState } from "react";
import MainModal from "./MainModal";

const AddCarPlate = (props) => {

    const saveCarPlate = () => {
    }

    return (
        <MainModal
            style={{ height: "350px", width: "1000px" }}
            title={"Añadir Matricula"}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        >  
            <p style={{marginTop: 20, fontWeight: "bold"}}>Escribe el nuevo saldo del woller:</p>
            <input style={{marginTop: 40, padding: 8}}/>
            <button onClick={() => saveCarPlate()} 
                    style={{height: "37px", padding: 0, width: 110, backgroundColor: "black", border: "2px solid black", color: "var(--coral)", marginLeft: 75}}
            >
                Guardar
            </button>

        </MainModal>
    )
}

export default AddCarPlate;