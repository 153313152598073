import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { levelArr as levels } from "../../constants/levels";
import { optionsArray } from "../../constants/moreOptionsJober";

import { SearchBar } from "jobin-ui-components";
import MainModal from "./MainModal";
import JoberCell from "../search/detail/infoColumn/JoberCell";
import FilterMultipleSelection from "../filter/FilterMultipleSelection";
import FilterDate from "../filter/FilterDate";
import FilterSlider from "../filter/FilterSlider";
import FilterSingleSelection from "../filter/FilterSingleSelection";
import { useStateValue } from "../../state/context";


const SelectJoberModal = props => {
    const style = {
        searchContainer: {
            paddingBottom: 8,
            marginBottom: 16,
            borderBottom: "1px solid var(--soft-grey)",
        },
        searchBar: {
            marginTop: 16,
            marginBottom: 8,
        },
    };
    const [{user,currentSession}] = useStateValue()
    const [jobersArray, setJobersArray] = useState([]);
    const [jobersPredefinedArray, setJobersPredefinedArray] = useState([]);
    const [leadPrice, setLeadPrice] = useState(null);
    const [searching, setSearching] = useState(false);
    const [levelArr, setLevelArr] = useState([]);
    const [levelArray, setLevelArray] = useState([]);
    const [distance, setDistance] = useState(75);
    const [points, setPoints] = useState(0);
    const [endDate, setEndDate] = useState(null);
    const [typeJob, setTypeJob] = useState(null);
    const [leads, setLeads] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [option, setOption] = useState(() => {
        if (props.job) {
            const jobType = props.job.get("JobType");
            return {
                id: jobType === "lead" ? "search" : "predefined",
                name: jobType === "lead" ? "Busqueda" : "Predefinidos",
                selected: true,
            };
        } else {
            return {
                id: "search",
                name: "Busqueda",
                selected: true,
            };
        }
    });
    const [searchText, setSearchText] = useState(null);

    useEffect(() => { 
        async function getLeadPrice() {

            const result = await Jobin.Job.getPrice(props.job);
            if (result.status === "ok") {
                searchJober(
                    [1, 2, 3, 4],
                    !user?.isGoldWoller ? distance:100,
                    !user?.isGoldWoller ? points:0,
                    !user?.isGoldWoller ? result.result:0,
                    !user?.isGoldWoller ? startDate:null,
                    !user?.isGoldWoller ? endDate:null,
                    !user?.isGoldWoller?  searchText: currentSession?.wollerData?.get("Phone")
                );
                setLeadPrice(result.result);
                setLeads(0);
            }
        }

        if (props.job) {

            const jobType = props.job.get("JobType");
            if (jobType === "lead") {
                getLeadPrice();
            }
        }
        const jobLevel = props.job.has("Level") ? props.job.get("Level") : 1;

        const levelArr = levels.map(item => (
            {
                ...item, 
                selected: item.id !== 'all' && item.id !== 'none' >= jobLevel
            }
        ));
        setLevelArr(levelArr);
        setLevelArray(levelArr.map(item => Number(item.id)))
    }, []);

    const searchJober = async (
        levelArray,
        distance,
        points,
        leads,
        startDate,
        endDate,
        text
    ) => {
        setSearching(true);
        var typeJob = await props.job.get("Type")

        const result = await Jobin.Jober.search(
            levelArray,
            distance < 75 ? distance : undefined,
            props.job ? props.job.get("Location") : null,
            points,
            leads,
            startDate ? new Date(startDate) : null,
            endDate ? new Date(endDate) : null,
            [typeJob],
            text,
            6
        );
   
        if (result.status === "ok") {
            setJobersArray(result.result);
        }
        setSearching(false);
    };

    const getPredefinedJobersForJob = async () => {
        setSearching(true);
        const result = await Jobin.Job.getPredefinedJobersForJob(props.job);

        if (result.status === "ok" && result.result) {
            const tempArray = [];
            for (let i = 0; i < result.result.length; i++) {
                const joberId = result.result[i];
                const joberResult = await Jobin.Jober.getJoberById(joberId);
                if (joberResult.status === "ok" && joberResult.result) {
                    tempArray.push(joberResult.result);
                }
            }
            setJobersArray(tempArray);
            setJobersPredefinedArray(tempArray);
        }
        setSearching(false);
    };

    const selectJober = async jober => {
        const jobType = props.job.get("JobType");
        if (jobType === "lead") {
            const joberLeads = jober.get("nJobs");
            const subscription = jober.get("UserSubscription");
            if (joberLeads >= leadPrice || subscription) {
                props.asignJober(jober);
            } else {
                alert(
                    "Este Jober no tiene suscripción activo o suficientes créditos para aceptar este trabajo."
                );
            }
        } else {
            setJobersArray(jobersPredefinedArray);
            props.asignJober(jober);
        }
    };

    const renderSearchBar = () => {
        return (
            !user?.isGoldWoller && (<div style={style.searchContainer}>
                <SearchBar
                    placeholder={"Buscar por número"}
                    style={style.searchBar}
                    onChange={e => {
                        setSearchText(e.target.value);
                        if (e.target.value.length > 0)
                            searchJober(
                                levelArray,
                                distance,
                                points,
                                leads,
                                startDate,
                                endDate,
                                e.target.value
                            );
                        else setJobersArray(jobersPredefinedArray);
                    }}
                />
                <div>
                    <FilterMultipleSelection
                        type="level"
                        title="Nivel"
                        items={levelArr}
                        selectionChange={selection => {
                            const tempArray = selection
                            .filter(item => item.id !== 'all' && item.id !== 'none' && item.selected)
                            .map(item => Number(item.id));
                            
                            setLevelArray(tempArray);
                            searchJober(
                                tempArray,
                                distance,
                                points,
                                leads,
                                startDate,
                                endDate,
                                searchText
                            );
                        }}
                    />
                    <FilterSlider
                        style={{ marginLeft: 4 }}
                        type="distance"
                        title="Dist"
                        minValue={5}
                        maxValue={75}
                        value={distance}
                        unit="Km"
                        onBlur={distance => {
                            setDistance(distance);
                            searchJober(
                                levelArray,
                                distance,
                                points,
                                leads,
                                startDate,
                                endDate,
                                searchText
                            );
                        }}
                    />
                    <FilterSlider
                        style={{ marginLeft: 4 }}
                        type="points"
                        title="Pun"
                        minValue={0}
                        maxValue={5}
                        value={points}
                        onBlur={points => {
                            setPoints(Number(points));
                            searchJober(
                                levelArray,
                                distance,
                                Number(points),
                                leads,
                                startDate,
                                endDate,
                                searchText
                            );
                        }}
                    />
                    {props.job &&
                        (props.job.get("JobType") === "lead" ||
                            props.job.get("JobType") === "bid") && (
                            <FilterSlider
                                style={{ marginLeft: 4 }}
                                type="leads"
                                title="Leads"
                                minValue={0}
                                maxValue={50}
                                unit="€"
                                value={leads}
                                onBlur={leads => {
                                    setLeads(Number(leads));
                                    searchJober(
                                        levelArray,
                                        distance,
                                        points,
                                        Number(leads),
                                        startDate,
                                        endDate,
                                        searchText
                                    );
                                }}
                            />
                        )}
                    <FilterDate
                        style={{ marginLeft: 4 }}
                        type="date"
                        title="Últ. conex."
                        selectionChange={date => {
                            setStartDate(date.startDate);
                            setEndDate(date.endDate);
                            searchJober(
                                levelArray,
                                distance,
                                points,
                                leads,
                                date.startDate,
                                date.endDate,
                                searchText
                            );
                        }}
                    />
                    <FilterSingleSelection
                        style={{ marginLeft: 4 }}
                        type="more"
                        title="Más"
                        prevSelection={option}
                        items={optionsArray}
                        selectionChange={option => {
                            setOption(option);
                            if (option.id === "search") {
                                searchJober(
                                    levelArray,
                                    distance,
                                    points,
                                    leads,
                                    startDate,
                                    endDate,
                                    searchText
                                );
                            } else {
                                getPredefinedJobersForJob();
                            }
                        }}
                    />
                </div>
            </div>)
        );
    };

    return (
        <MainModal
            style={{ height: "calc(100vh - 2.4rem)" }}
            title={"Selección de Wollers"}
            subtitle={"Escoge al Woller que realizará esta solicitud"}
            isVisible={props.isVisible}
            onClose={props.onClose}
            gigant

        >
            <div style={{ height: "calc(100vh - 136px)", overflow: "auto" }}>
                {renderSearchBar()}
                {searching ? (
                    <label>Buscando...</label>
                ) : jobersArray.length > 0 ? (
                    jobersArray.map((item, index) => {
                        const subscription = item.get("UserSubscription");
                        const balance = item.get("nJobs")
                        return (
                            <JoberCell
                                key={"jober" + index}
                                jober={item}
                                onClick={() => selectJober(item)}
                                balance={balance > leadPrice ? "balance":"noBalance"}
                                buttonText={
                                    leadPrice
                                        ? "Precio " +
                                          (subscription ? 0 : leadPrice) +
                                          " €"
                                        : "Seleccionar"
                                }
                            />
                        );
                    })
                ) : (
                    <div>
                        {option.id === "search" ? (
                            <label>
                                No hay ningún Woller que coincida con tu búsqueda
                            </label>
                        ) : (
                            <label>No hay ningún Woller predeterminado</label>
                        )}
                    </div>
                )}
            </div>
        </MainModal>
    );
};

export default SelectJoberModal;
