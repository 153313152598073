import React from "react";
import SearchNav from "../../components/business/SearchNav";
import ResultTable from '../../components/business/ResultTable';



export default function Search(props) {
    return (
        <React.Fragment>
            <SearchNav/>
            <ResultTable history={props.history} location={props.location} />
        </React.Fragment>
    );
}
