import React from "react";

import { useStateValue } from "../../../../state/context";
import Button from "../../../button/Button";

export default function InfoCell(props) {
 
    const joberImage = props.jober.get("UserPic");
    const level = props.jober.get("Level");
    const points = props.jober.get("Points");
    const nReviews = props.jober.get("Reviews");
    const [data] = useStateValue();
    const [{ user }, dispatch] = useStateValue();

    const style = {
        cellContainer: {
            display: "flex",
            flexDirection: "row",
            position: "relative",
            alignItems: "center",
            marginTop: 8,
            paddingTop: 8,
            paddingBottom: 8,
            borderBottom: "1px solid var(--soft-grey)"
        },
        nameLabel: {
            fontWeight: "bold",
            lineHeight: "1.8em",
            maxHeight: "1.8em",
            textOverflow: "elipsis",
            wordWrap: "break-word",
            overflow: "hidden"
        },
        imageView: {
            position: "relative",
            height: 56,
            width: 56,
            marginRight: 12
        },
        image: {
            height: 56,
            width: 56,
            borderRadius: 28,
            overflow: "hidden",
            objectFit: "cover"
        },
        medalImage: {
            position: "absolute",
            height: 20,
            width: 20,
            bottom: -3,
            right: -3
        },
        dataView: {
            display: "flex",
            flex: 1,
            flexDirection: "column"
        },
        pointsLabel: {
            color: "var(--coral)",
            marginLeft: 8,
            marginRight: 3,
            fontWeight: "bold"
        },
        newLabel: {
            color: "#fff",
            marginLeft: 8,
            fontWeight: "bold",
            backgroundColor: "var(--coral)",
            fontSize: 10,
            padding: 4,
            height: 22,
            alignItems: "center",
            borderRadius: 4
        },
        button: {
            marginLeft: 8
        },
        noBalance:{
            backgroundColor:"#FF3569",
            fontWeight:"Bold"
        },
         balance:{
            backgroundColor:"#35FFC8",
            fontWeight:"Bold"
        }
    };

    return (
        <section style={style.cellContainer}>
            <div style={style.imageView}>
                <img style={style.image} src={joberImage ? joberImage.url() : require("../../../../assets/jober-icon.svg")} alt="joberIcon"/>
                {level > 1 && (
                    <img
                        style={style.medalImage}
                        src={
                            level === 2
                                ? require("../../../../assets/badge-silver.svg")
                                : level === 3
                                ? require("../../../../assets/badge-gold.svg")
                                : level === 4
                                ? require("../../../../assets/badge-platinum.svg")
                                : null
                        }
                        alt="medal"
                    />
                )}
            </div>
            <div style={style.dataView}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                    }}
                >
                    <label style={style.nameLabel}>{props.jober.get("Name")}</label>
                    <label style={points > 0 && nReviews > 0 ? style.pointsLabel : style.newLabel}>
                        {points > 0 && nReviews > 0 ? points.toFixed(1) : "NUEVO"}
                    </label>
                    {points > 0 && nReviews > 0 && (
                        <img style={{ height: 16, width: 16 }} src={require("../../../../assets/star-fill.svg")} alt="star"/>
                    )}
                </div>
                <label>{data.user.permissions && data.user.permissions.job.edit ? props.jober.get("Phone"):""}</label>
            </div>
            {data.user.permissions && data.user.permissions.job.edit && (
                <Button style={style.button,style[props.balance]} buttonText={props.buttonText ? props.buttonText : "Ver"} onClick={props.onClick} />
            )}
            {props.onDelete && data.user.permissions && data.user.permissions.job.edit && !user.isGoldWoller && (
                <Button style={style.button,style[props.balance],{marginLeft: 2}} buttonText={'Eliminar'} onClick={props.onDelete} />
            )}
        </section>
    );
}
