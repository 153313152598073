import React, { useState } from "react";
import MainModal from "./MainModal";
import {deleteOpsNotification, changeOpsNotificationDescription} from "../../utils/ParserUtilities";

const OpsNotificationDetailModal = props => {

    const [description, setDescription] = useState(null);

    const { notification } = props;
    const handleDescriptionChange = e => setDescription(e.target.value);

    const deleteNotification = async () => {
        await deleteOpsNotification(notification.id);
        window.location.reload(false);
        props.onClose();
    }

    const changeDescription = async () => {
        await changeOpsNotificationDescription(notification.id, description);
        props.onClose();
    }

    return (
        <MainModal
            style={{ height: "400px", width: "700px" }}
            title={"Detalle de la Notificación"}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        >
            <div style={{display:"flex", justifyContent: "space-between"}}>
                <p className='incident-detail-typology'>{notification?.get('ExtraInfo').typology}</p>
                <button onClick={() => deleteNotification()} className='delete-incident-button'>Eliminar</button>
            </div>

            <p style={{ marginTop: 20, marginBottom: 20 }}>{notification.get("ExtraInfo").description}</p>

            <p style={{ fontWeight: "bold", marginTop: 20, marginBottom: 20 }}>DESCRIPCIÓN:</p>
            <input 
                type="textarea" 
                name="textValue"
                value={description}
                style={{outline: "none", width: 634, resize: "none", height: 50, padding: 10}}
                placeholder= 'Edita la descripción...'
                onChange={handleDescriptionChange}
            />

            <div style={{display: "flex", justifyContent: "center", marginTop: 40}}>
                <button onClick={changeDescription}
                    style={{color: "white", backgroundColor: "black", borderRadius: 10, fontWeight: "bold", padding: 15}}>
                        Editar Descripción
                </button>
            </div>
            
        </MainModal>
    )
}

export default OpsNotificationDetailModal;