import React, { useState } from "react"
import DropZone from "../DropZone"
import XLSX from "xlsx";
import Lottie from 'react-lottie'
import Button from "../button/Button";
import {processECIExcel} from "../../utils/ParserUtilities"
import GenericTable from "../GenericTable";

import * as cardSpinnerData from "../../assets/animations/button-loading.json";
export default function ImportServices({onClose,location}) {
  const [dataParsed, setDataParsed] = useState([])
  const [isReadyToSave, setIsReadyToSave] = useState(false)
  const [cols, setCols] = useState([])
  const [saving, setSaving] = useState(false)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: cardSpinnerData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
  const SheetJSFT = ["xlsx","xlsb","xlsm","xls","xml","csv"].map((x)=> "." + x).join(",");
  
  const handleFile = (file)=> {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = e => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let data = XLSX.utils.sheet_to_json(ws, { header: 1 }).filter(x=>x.length>0);
      data.forEach((data,index)=>{
        if(index ===0){
          data.unshift("status")
        }else{
          data.unshift("ready")
        }
      })

      let headers = data[0].map(head=>{
        if(head == "status"){
          return {Header: "",id: head.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "_"),}
        }else{
          return {Header: head,id: head.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "_"),}
        }
      })
      
      setCols(headers);
      data = data.splice(1);
      data = data.map(job=>{
        let newData={}
        headers.map((head,index)=>{
          if(head.id== "Fecha_ECI"){
            newData[head.id]= new Date(Math.round((job[index] - 25569)*86400*1000));
          }else if(head.id== "FEcha_Ok"){
            newData[head.id]= new Date(Math.round((job[index] - 25569)*86400*1000));
          }else{
            newData[head.id]= job[index]
          }
        })
        return newData
      })
      setDataParsed(data)
      setIsReadyToSave(true)
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  }

  
  const processECIExcel_ =  async()=> {
    setSaving(true)
      try {
        let dataInTable = dataParsed
        dataInTable.forEach(async (data_,indexJobs)=>{
          setTimeout(() => {
            
          }, 1000);
          try {
            processECIExcel(data_).then(saveJob=>{
              let result
             if(saveJob.status=="uploaded"){
               //modify status of service in table
               result = dataParsed.map(data=>{
                if(data.Id_ECI==data_.Id_ECI){
                  data.status =  saveJob.status
                }
                return data
               }) 
             }else if(saveJob.status=="error"){
                //modify status of service in table
                result = dataParsed.map(data=>{
                  if(data.Id_ECI==data_.Id_ECI){
                    data.status = saveJob.status 
                  }
                  return data
                }) 
             }
              setDataParsed(result)
              
            })
            
          } catch (error) {
            console.error(error)
            //modify status of service in table 
            let result = dataParsed.map(data=>{
              if(data.Id_ECI==data_.Id_ECI){
                data.status = "error"
              }
              return data
            })
            setDataParsed(result)
          }
          if(indexJobs+1 == dataInTable.length){
            setSaving(false)
            setIsReadyToSave(false)
          }
        })
      } catch (error) {
        console.error(error)
        alert("ha ocurrido un error cargando los servicios")
        setSaving(false)
      }
  };

  const OutTable = ()=>{
    let rows = dataParsed.map((job,index)=>{
      return(<tr key={job.Id_ECI+index}>
              {cols.map(col=>{
                let row = col.id =="status" ? 
                  <td key={col.Header+index+"job"}>{
                    job[col.id]=="uploaded"?(<img style={{width: 18, height: 18}} src={ require("../../assets/available.svg")} alt="Negocio" />)
                    :job[col.id]=="error"?(<img style={{width: 18, height: 18}} src={ require("../../assets/delete-image.svg")} alt="Negocio" />)
                    :(<img style={{width: 18, height: 18}} src={ require("../../assets/inactive.svg")} alt="Negocio" />)}</td>
                    
                  :col.id =="Fecha_ECI" || col.id =="FEcha_Ok"?<td key={col.Header+index+"job"}>{`${job[col.id].getUTCDate()}/${job[col.id].getMonth()+1}/${job[col.id].getUTCFullYear()}` }</td>
                  :<td key={col.Header+index+"job"}>{job[col.id]}</td>     
                  return row           
              })}
            </tr>)
        })
    return(
        <GenericTable className="tableSearch" heads={cols} rows={rows}/>
    )
}

  return (
    <div className="ImportServicesExcel" style={{paddingTop: "25px"}}>
      <div style={{paddingBottom: "25px"}} className="row">
        <div className="col-xs-12">
        <DropZone prompText={"Arrastra el archivo hasta acá, o haz click para seleccionar uno."} handleFile={handleFile} acceptedFormats={SheetJSFT} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12" style={{ maxHeight:250, overflow:"auto" }}>
          <OutTable />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <div style={{ display: "flex", justifyContent: "center", width: "100%", marginBottom:'25px' }}>
          { !saving ? (
              <Button 
                disabled={!isReadyToSave}
                buttonText="Guardar Servicios"
                onClick={()=>processECIExcel_()}
              />
              ):(
                <button style={{ backgroundColor: 'black', borderRadius: 5,width:320}}>
                    <Lottie
                        style={{height: 33, width: 65}}
                        options={defaultOptions}
                    />
                </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );

}
