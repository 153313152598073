import React, { useState ,useEffect} from "react";
import Jobin from "jobin-client";
import {Loading} from 'jobin-ui-components'
import { useStateValue } from "../../../../state/context";

import PaymentCell from "./PaymentCell";
import Alert from "../../../modal/Alert";
import PaymentMethodModal from "../../../modal/PaymentMethodModal";
import FiscalModal from "../../../modal/FiscalModal";
import EditFixedPriceModal from "../../../modal/EditFixedPriceModal";
import PostPayModal from "../../../modal/PostPayModal";

import "../../../../styles/search/detail/job-detail-info.css";

export default function PaymentsContainer({fetchData}) {
    const [{user,jobDetails}, dispatch] = useStateValue();

    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showFiscalModal, setShowFiscalModal] = useState(false);
    const [showPaymentMethod, setShowPaymentMethod] = useState(false);
    const [showEditFixedPrice, setShowEditFixedPrice] = useState(false);
    const [showPostPay, setShowPostPay] = useState(false);
    const [payment, setPayment] = useState(null);
    const [currentPaymentState,setCurrentPaymentState] =  useState(null);
    const style = {
        total: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            textAlign: "right",
            marginTop: 16
        },
        totalPriceLabel:{
            fontSize: 16,
            fontWeight: "bold"
        },
        priceLabel:{
            fontSize: 12,
        }
    };

    useEffect(()=>{
        paymentState()
    },[])

    const paymentOptionSelected = (payment, option) => {
        setPayment(payment);
        if (option.id === "modify-payment-method") {
            setShowPaymentMethod(true);
        } else if (option.id === "mark-as-paid") {
            setShowAlert(true);
        }else if (option.id === "receipt"){
            const win = window.open(payment.get("Receipt").url(), "_blank");
            win.focus();
        }
        else if (option.id === "modify-receipt"){
            setShowFiscalModal(true);
        }
    };
    const paymentState = ()=>{
        var state 
        jobDetails.paymentsArray.forEach(element => {
            const action = element.get("Action");
            const paidDay = element.get("PaidDay");
            if(!action && paidDay ){
                state = "Done";
            }else{
                state = action;
            }
        });
        setCurrentPaymentState(state)
    }

    const markPaymentAsPaid = async () => {
        setLoading(true);
        const result = await Jobin.Payment.markAsPaid(payment);
        if (result.status === "ok") {
            //Update the current payments array
            const tempArray = jobDetails.paymentsArray;
            for (let i = 0; i < tempArray.length; i++) {
                if (tempArray[i].id === payment.id) {
                    tempArray[i].set("Action", undefined);
                    break;
                }
            }
            await jobDetails.getHistoryOfJob(jobDetails.job);
            dispatch({
                type: "JOB_DETAILS_JOB",
                paymentsArray: tempArray
            });
        } else {
            alert(result.result);
        }
        setLoading(false);
    };

    const changePaymentMethod = async paymentMethod => {
        setLoading(true);
        const result = await Jobin.Payment.changePaymentMethod(
            payment,
            paymentMethod
        );
        if (result.status === "ok") {
            await jobDetails.getPayments(jobDetails.job);
            await jobDetails.getHistoryOfJob(jobDetails.job);
        } else {
            alert(result.result);
        }
        setLoading(false);
    };

    const remakeBillSuccess = async (billingInfo) =>{
        setLoading(true);
        setShowFiscalModal(false);
        const result = await Jobin.PDF.remakeBill(payment, billingInfo);
        if(result.status === 'ok'){
            setLoading(false);
            var win = window.open(result.result, '_blank');
            win.focus();
        }
        else{
            setLoading(false);
            alert(result.result);
        }
    }

    return (
        <section className="infoContainer">
            {showAlert && (
                <Alert
                    isVisible={showAlert}
                    onClose={() => setShowAlert(false)}
                    title={"¿Seguro que quieres continuar?"}
                    subtitle={
                        "Si marcas este pago como pagado significa que se ha realizado con éxito en el mismo método de pago que el indicado. Si no es así, cancela el proceso y modifica el método de pago."
                    }
                    buttonText={"Marcar como pagado"}
                    onClick={() => {
                        setShowAlert(false);
                        markPaymentAsPaid();
                    }}
                />
            )}
            {showPaymentMethod && (
                <PaymentMethodModal
                    isVisible={showPaymentMethod}
                    onClose={() => setShowPaymentMethod(false)}
                    job={jobDetails.job}
                    initialView={"change"}
                    savePaymentMethod={paymentMethod => {
                        setShowPaymentMethod(false);
                        changePaymentMethod(paymentMethod);
                    }}
                />
            )}
            {showFiscalModal && 
                <FiscalModal
                    isVisible={showFiscalModal}
                    onClose={() => setShowFiscalModal(false)}
                    user={jobDetails.job.get("CreatedBy")}
                    success={(billingInfo) => remakeBillSuccess(billingInfo)}
                />
            }
            {showEditFixedPrice && 
                <EditFixedPriceModal
                    isVisible={showEditFixedPrice}
                    onClose={() => setShowEditFixedPrice(false)}
                    jobDetails={jobDetails}
                />
            }
            {showPostPay && 
                <PostPayModal
                    isVisible={showPostPay}
                    onClose={() => setShowPostPay(false)}
                    jobDetails={jobDetails}
                    fetchData={fetchData}

                />
            }
            <label className="title">Pagos</label>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <div style={{ marginTop: 12,display:"flex", flexDirection:"column" }}>
                        
                        {jobDetails.paymentsArray.map((item, index) => {
                            return (
                                <PaymentCell
                                    key={"payment" + index}
                                    buttonHidden={user.permissions && !user.permissions.job.edit }
                                    payment={item}
                                    buttonItemSelected={ option =>{
                                        paymentOptionSelected(item, option)}
                                    }
                                    
                                />
                            );
                        })}
                        {currentPaymentState}
                        {currentPaymentState != "Done" && <button hidden={jobDetails.readMode || !user.permissions.job.edit} onClick={() => setShowEditFixedPrice(true)} className="edit-fixed-price-button">Editar Precio</button>}
                        {jobDetails.job.get("Business") && (jobDetails.job.get("Business").id == "M7t1A74luq" || jobDetails.job.get("Business").id == "ynn52ewgCE")  && <button hidden={!user.permissions.job.edit} disabled={!jobDetails.paymentClientData } onClick={() => setShowPostPay(true)} className="edit-fixed-price-button">Ejecutar PostPago</button>}
                    </div>
                    <div style={style.total}>
                        <label style={style.totalPriceLabel}>
                            {"Total: " + (jobDetails.job.get("PriceMax")).toFixed(2) + "€"}
                        </label>
                        <label style={style.priceLabel}>
                            {(jobDetails.job.get("PriceMax") * 1.21).toFixed(2) + "€ con IVA"}
                        </label>
                    </div>
                </div>
            )}
        </section>
    );
}
