import React, { useState } from "react";

import "../../styles/slider.css";

export default function Slider({minValue,maxValue,handleChange,unit,initialValue}) {
   const [value,setValue] = useState(initialValue ? initialValue :0)


    return (
        <div className="sliderContainer">
            <input
                className="slider"
                type="range"
                min={minValue ? minValue.toString() : 1}
                max={maxValue ? maxValue.toString() : 100}
                value={value}
                onChange={e => {
                    setValue(e.target.value);
                    handleChange(e);
                }}
                onBlur={() => setValue(value)}
            />
            <p className="sliderValue">{value + " " + unit}</p>
        </div >
    );
}
