import React, { useState } from "react";
import MainModal from "./MainModal";
import {formatCurrency} from "../../utils/formatter" 
import { processPostPay} from "../../utils/ParserUtilities" 
import { useStateValue } from "../../state/context";
import Alert from "./Alert";
import Lottie from 'react-lottie'
import * as cardSpinnerData from "../../assets/animations/button-loading.json";

import "../../styles/modal/edit-fixed-price-modal.css";


const PostPayModal = ({isVisible,onClose,jobDetails,fetchData}) => {
    const [{agent}] = useStateValue();
    const [jobPrice] = useState(jobDetails.fixedInfo.get("FixedPrice"));
    const [jobDescription] = useState(jobDetails.job.get("Description"));
    const [client] = useState(jobDetails.job.get("CreatedBy"));
    const [paymentMethods] = useState(jobDetails.paymentClientData);
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: cardSpinnerData.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    const handleProcessPostPay = async ()=>{
        setIsLoading(true)
        try {
            await processPostPay({ammount:jobPrice, userId:client.id, jobId: jobDetails.job.id,sessionToken:agent.token});
            fetchData()
            onClose()
        } catch (error) {
            setIsLoading(false)
            alert(error)
        }

    }
   

    return (
        <MainModal
            classNamesModal={{modal:"basicModalSmall"}}
            subtitle={"Ejecutar Postpago"}
            title={"Valida el precio y la descripcion de la factura"}
            isVisible={isVisible}
            onClose={onClose}
        >
            {paymentMethods.get("CardId") ? 
            (<>
                { showAlert && (
                    <Alert
                        isVisible={showAlert}
                        onClose={() => setShowAlert(false)}
                        title={"¿Seguro que quieres continuar?"}
                        subtitle={
                            "Al ejecutar el pago se realizara el cobro al cliente con los datos de su tarjeta de credito."
                        }
                        buttonText={"Ejecutar pago"}
                        onClick={() => {
                            setShowAlert(false);
                        handleProcessPostPay()
                        }}
                    />)
                }
            
                <div className="edit-fixed-price-modal-wrapper">
                    <div>
                        <p>
                            <span><b>Cliente: </b></span>
                            <span>{client.get("Name")}</span>
                        </p>
                        <p>
                       
                            <span><b>Telefono: </b></span>
                            <span>{client.get("Phone")}</span>
                        </p>
                    </div>
                    <p>
                        <b>Titulo del servicio: </b>{jobDescription}
                    </p>
                    <p>
                        <b>Precio total: </b>{ formatCurrency(jobPrice*1.21)} ({formatCurrency(jobPrice)} Sin IVA)
                    </p>

                        {/* <textarea className="w-50 margin-top-48px" onChange={(event)=>{setJobDescription(event.target.value)}} value={jobDescription}>

                        </textarea>
                        <input
                            type="text"
                            name="name"
                            onChange={(event)=>setJobPrice(event.target.value)}
                            value={formatCurrency(jobPrice)}
                            className="edit-fixed-price-modal-input"
                        /> */}

                    <button className="edit-fixed-price-modal-button" onClick={()=>{setShowAlert(true)}} >{isLoading?(<Lottie
                                                    style={{height: 30, width: 80}}
                                                    options={defaultOptions}
                                                />):"Ejecutar pago"}</button>
                </div>
            </>)
            :(<div className="edit-fixed-price-modal-wrapper">
                    <p>
                       Este cliente no ha proporcionado ningun metodo de pago
                    </p>
                </div>)}

        </MainModal>
    )
}

export default PostPayModal;