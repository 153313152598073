import React from "react";
import { useStateValue } from "../../../state/context";

export default function JobinProModal(props) {
    const [{ job }] = useStateValue();

    return (
        <div className="modal jobinPro">
            <h4 className="title">{props.title}</h4>
            <p className="excerpt">{props.excerpt}</p>
            <div className="one-row-button">
                <button
                    type="button"
                    onClick={() => {
                        job.history.push({
                            pathname: "/job"
                        });
                    }}
                >
                    {props.button}
                </button>
            </div>
        </div>
    );
}
