import React,  { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/es"  

import "../../../styles/Users/wollers/description.css";

export default function Reviews({reviews}) {
    const [reducedReview ,setReducedReview] =useState([])
    const [isAllReviews ,setIsAllReviews] =useState(false)

     
    useEffect(()=>{
        setReducedReview(reviews?.slice(0,2))
    },[reviews])

    const showAllReviews = ()=>{
        if(!isAllReviews){
            setReducedReview(reviews)
            setIsAllReviews(true)
    
        }else{
            setReducedReview(reviews?.slice(0,2))
            setIsAllReviews(false)
        }
    }
    return (
        <>
            <div className="reviewsContainer userDetails">
                {reducedReview?.map((review)=>{
                    return(<div className="individualReviewContainer" key={review.id}>
                        <p>“{review.get("Text")}”</p>
                        {review.get("User") ? 
                        <div className="clientProfile">
                            <div className="clientProfilePic" 
                                style={{backgroundImage:review.get("User")?.get("UserPic")?.url()?
                                        `url(${ review.get("User")?.get("UserPic")?.url()})`:
                                        `url(${require(  "../../../assets/user-default-pic.svg")})`} }>
                            </div>
                            <div className="userProfileData">
                                <p>{review.get("User").get("Name")}</p>
                                <p>{review.createdAt ? `${review.createdAt.getMonth()+1}/${review.createdAt.getUTCFullYear()}`:null }</p>
                            </div>
                        </div>
                        :(<div className="clientProfile">
                            <div className="clientProfilePic" style={{backgroundImage:`url(${require(  "../../../assets/user-default-pic.svg")})`} }></div>
                            <div>
                                <p>Anonimo</p>
                                <p>{review.createdAt ? moment(review.createdAt).format("MMMM [ de ] YYYY"):null}</p>
                            </div>
                        </div>)}
                    </div>)
                })}
            </div>
            {reviews?.length >= 2 ? <button className="showMoreReviews" onClick={showAllReviews}>{isAllReviews?"Mostrar menos":`Mostrar ${reviews?.length > 4 ? reviews?.length -2:""} mas`}</button>:null}
        </>
    )
}