import React, {useState} from 'react';

import MainModal from './MainModal'
import Button from '../button/Button'

import {Input} from 'jobin-ui-components'

const JoberPriceModal = (props) => {

	const style = {
        bodyView:{
            display: 'flex',
            flex: 1,
            marginTop: 24
        },
        inputViewRight:{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            marginLeft: 12
        },
        inputViewLeft:{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            marginRight: 12
        }
    }
    
    const [loading, setLoading] = useState(props.selectedDate);
    const [price, setPrice] = useState(0);
    const [priceOk, setPriceOk] = useState(true);

    const onChangeWith = (text) =>{
        if(text.length === 0){
            setPrice(0);
            setPriceOk(true);
        } else if(/^\d+\.\d{2}$/.test(text)){
            setPrice(Number(text)/1.21);
            setPriceOk(true);
        } else{
            setPrice(0);
            setPriceOk(false);
        }
    }

    const onChangeWithout= (text) =>{
        if(text.length === 0){
            setPrice(0);
            setPriceOk(true);
        } else if(/^\d+\.\d{2}$/.test(text)){
            setPrice(Number(text));
            setPriceOk(true);
        } else{
            setPrice(0);
            setPriceOk(false);
        }
    }

    const savePrice = () =>{
        if(price > props.priceMax){
            setPriceOk(false);
            alert("No es posible pagar al Woller más de lo que el cliente ha pagado por el servicio");
        }
        else{
            setLoading(true);
            props.savePrice(price);
        }
    }

    return (
        <MainModal
			title={"Modifica el precio del Woller"}
			subtitle={"Puedes modificar el precio que se le pagará al Woller asignado por este trabajo."}
			isVisible={props.isVisible}
            onClose={props.onClose}
            big
		>
            <div style={{marginTop: 15}}>Formato de ejemplo: 17.00 (dos decimales obligatorios). </div>

            <div style={style.bodyView}>
                <div style={style.inputViewLeft}>
                    <label style={{marginBottom: 8}}>Precio con IVA</label>
                    <Input
                        placeholder={"Max. "+(props.priceMax * 1.21).toFixed(2)+" €"}
                        onChange={(e) => onChangeWith(e.target.value)}
                        badInput={!priceOk}
                        value={price ? (price*1.21).toFixed(2) : undefined}
                    />
                </div>
                <div style={style.inputViewRight}>
                    <label style={{marginBottom: 8}}>Precio sin IVA</label>
                    <Input
                        placeholder={"Max. "+props.priceMax.toFixed(2)+" €"}
                        onChange={(e) => onChangeWithout(e.target.value)}
                        badInput={!priceOk}
                        value={price ? price.toFixed(2) : undefined}
                    />
                </div>
            </div>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent:'flex-end', marginTop: 24}}>
                <Button
                    style={{width: 85, height: 44}}
                    buttonText = "Guardar"
                    loading={loading}
                    disabled={(price > 0 && priceOk) ? false : true}
                    onClick={savePrice}
                />
            </div>
        </MainModal>
    )
}

export default JoberPriceModal;