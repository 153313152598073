import React from "react";
import JobsMaps from "../../components/JobsMaps/JobsMaps";




export default function Search(props) {
    return (
        <React.Fragment>
            <JobsMaps history={props.history} location={props.location} />
        </React.Fragment>
    );
}
