import React, { useState, useEffect } from "react";
import fetchServices from "../../../constants/services";

import { CheckBox, Button } from 'jobin-ui-components'

export default function SignUpLocation(props) {

    const style = {
        section:{
            display: 'flex',
            flex: 1,
        },
        halfSection:{
            display: 'flex',
            position: 'relative',
            flex: 1,
            flexDirection: 'column',
            height: 'calc(100vh - 72px)'
        },
        leftView:{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '10%',
            marginRight: '10%',
            marginTop: 20,
            height: 'calc(100% - 100px)'
        },
        rightView:{
            display: 'flex',
            flexDirection: 'column',
        },
        inputSection:{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            marginTop: 24,
            overflow: 'auto',
        },
        inputView:{
            display: 'flex',
            flex: 1,
            marginBottom: 12
        },
        radioButtonSection:{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            marginTop: 24
        },
        bottomBar:{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            position: 'absolute',
            bottom: 0,
            paddingBottom: 12,
            paddingTop: 12,
            width: '100%',
            borderTop: '1px solid var(--light-grey)',
            background: '#fff'
        }
    }

    const [verticals, setVerticals] = useState([]);
    const [selectedArr, setSelectedArr] = useState([]);
    const [isDefaultJober, setDefaultJober] = useState(false);

    useEffect(() => {
        fetchServices().then(verticales => {
            let final = verticales.filter( vertical => vertical.id !== "all" && vertical.id !== "none")
            setVerticals(final);
        })
    }, []);

    const verticalSelected = (vertical, selected) =>{
        vertical.selected = selected;
        setSelectedArr( verticals.filter( vertical => vertical.selected));
    }

    return (
        <section style={style.section}>
            <div style={style.halfSection}>
                <div style={style.leftView}>
                    <h4>Selecciona los servicios que ofrece</h4>
                    <label>El Woller recibirá avisos por cada nuevo servicio de las categorías que selecciones</label>
                    <div style={style.inputSection}>
                        {verticals.map((item) =>{
                                return(
                                    <CheckBox
                                        key={item.id}
                                        style={{margin: '10px 0px'}}
                                        title={item.name}
                                        onChange={selected => verticalSelected(item, selected)}
                                    />
                                );
                        })}
                    </div>
                </div>
                <div style={style.bottomBar}>
                    <Button
                        buttonText="Siguiente"
                        disabled={props.loading || selectedArr.length === 0}
                        onClick={() => props.nextView("verticales", { verticalIds: selectedArr.map(vertical => vertical.id), isDefaultJober: isDefaultJober })}
                        loading={props.loading}
                    />
                </div>
            </div>
            <div style={style.halfSection}>
            <CheckBox
                style={{margin: '10px 0px'}}
                title='Asignar como jober por defecto para precios fijos'
                onChange={setDefaultJober}
            />
                </div>
        </section>
    );
}
