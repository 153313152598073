import React from "react";
import MainModal from "./MainModal";

import "../../styles/search/detail/job-details.css";
import "../../"

const ShowPhotosModal = props => {

    return (
        <MainModal
            style={{ height: "750px", width: "1000px" }}
            title={props.title}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        > 
            <div className="show-photos-modal-wrapper">
            {
                props.photos.map( photo => (
                    <img src={photo.base64 ? photo.base64 : photo} className="fixedPhotos-photo"/>
                ))
            }
            </div>
        </MainModal>
    )
}

export default ShowPhotosModal;