import React, { useState } from "react";
import { Input, CallToAction } from "jobin-ui-components";
import { styles } from "./styles";

const JobinBillingInfo = () => {
    return (
        <div style={styles.column}>
            <label>Jobin App S.L.</label>
            <label>B87444782</label>
            <label>Calle Ebro 58, 28250</label>
            <label>Torrelodones</label>
            <label>Madrid, España</label>
        </div>
    );
};

const DisplayBillingInfo = props => {
    if (props.billingInfo)
        return (
            <div style={styles.column}>
                <label>{props.billingInfo.get("FiscalName")}</label>
                <label>{props.billingInfo.get("IdentityNumber")}</label>
                <label>{props.billingInfo.get("FiscalAddress")}</label>
                <label>
                    {props.billingInfo.get("FiscalPostalCode") +
                        " " +
                        props.billingInfo.get("FiscalCity")}
                </label>

                <CallToAction
                    style={{ padding: 16 }}
                    buttonText="Editar datos fiscales"
                    onClick={props.onEdit}
                />
            </div>
        );
    else
        return (
            <div style={styles.column}>
                <label>. . . . . . . .</label>
                <label>. . . . . . . .</label>
                <label>. . . . . . . .</label>
                <label>. . . . . . . .</label>

                <CallToAction
                    style={{ padding: 16 }}
                    buttonText="Añadir datos fiscales"
                    onClick={props.onEdit}
                />
            </div>
        );
};

const StandardBillingInfo = props => {
    console.info("StandardBillingInfo", props);
    const [name, setName] = useState(
        props.billingInfo ? props.billingInfo.get("FiscalName") : undefined
    );
    const [cif, setCif] = useState(
        props.billingInfo ? props.billingInfo.get("IdentityNumber") : undefined
    );
    const [address, setAddress] = useState(
        props.billingInfo ? props.billingInfo.get("FiscalAddress") : undefined
    );
    const [postalCode, setPostalCode] = useState(
        props.billingInfo
            ? props.billingInfo.get("FiscalPostalCode")
            : undefined
    );
    const [city, setCity] = useState(
        props.billingInfo ? props.billingInfo.get("FiscalCity") : undefined
    );
    const [allowEmpty, setAllowEmpty] = useState(true);

    const onSave = () => {
        if (
            name &&
            cif &&
            address &&
            postalCode &&
            city &&
            /^\d{5}$/.test(postalCode)
        )
            props.onSave(name, cif, address, postalCode, city);
        else setAllowEmpty(false);
    };

    return (
        <div style={styles.column}>
            <Input
                style={{ marginBottom: 4 }}
                placeholder="Nombre"
                value={name}
                badInput={!allowEmpty && !name}
                onChange={e => setName(e.target.value)}
            />
            <Input
                style={{ marginBottom: 4 }}
                placeholder="CIF o NIF"
                value={cif}
                badInput={!allowEmpty && !cif}
                onChange={e => setCif(e.target.value)}
            />
            <Input
                style={{ marginBottom: 4 }}
                placeholder="Dirección"
                value={address}
                badInput={!allowEmpty && !address}
                onChange={e => setAddress(e.target.value)}
            />
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    marginBottom: 4
                }}
            >
                <Input
                    placeholder="Código postal"
                    style={{ marginRight: "4px" }}
                    value={postalCode}
                    badInput={
                        postalCode ? !/^\d{5}$/.test(postalCode) : !allowEmpty
                    }
                    onChange={e => setPostalCode(e.target.value)}
                />
                <Input
                    placeholder="Localidad"
                    value={city}
                    badInput={!allowEmpty && !city}
                    onChange={e => setCity(e.target.value)}
                />
            </div>
            <CallToAction
                style={{ padding: 16 }}
                buttonText="Guardar datos fiscales"
                onClick={onSave}
            />
        </div>
    );
};

const CustomBillingInfo = props => {
    return (
        <div style={styles.column}>
            <Input
                style={{ marginBottom: 4 }}
                placeholder="Nombre"
                onChange={e => props.changeName(e.target.value)}
            />
            <Input
                style={{ marginBottom: 4 }}
                placeholder="CIF o NIF"
                onChange={e => props.changeIdentityNumber(e.target.value)}
            />
            <Input
                style={{ marginBottom: 4 }}
                placeholder="Dirección 1"
                onChange={e => props.changeAddress1(e.target.value)}
            />
            <Input
                style={{ marginBottom: 4 }}
                placeholder="Dirección 2"
                onChange={e => props.changeAddress2(e.target.value)}
            />
        </div>
    );
};

const BillingInfo = props => {
    const inputBillingInfo = props.custom ? (
        <CustomBillingInfo {...props} />
    ) : props.editable ? (
        <StandardBillingInfo {...props} />
    ) : (
        <DisplayBillingInfo {...props} />
    );
    return props.reverse ? (
        <div style={styles.row}>
            {inputBillingInfo}
            <JobinBillingInfo />
        </div>
    ) : (
        <div style={styles.row}>
            <JobinBillingInfo />
            {inputBillingInfo}
        </div>
    );
};

export default BillingInfo;
