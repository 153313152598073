import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { Link } from "react-router-dom";
import { useStateValue } from "../../../state/context";
import { initialState } from "../../../state/initialState";
import { getCookie } from "../../../utils/cookie";

import "../../../styles/job/child-service-list.css";

export default function ChildServiceList(props) {
    const [{ job }, dispatch] = useStateValue();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [child, setChild] = useState([]);

    useEffect(() => {
        let didCancel = false;

        async function getChildren() {

            try {
                const result = await Jobin.Guild.getFatherChilds(
                    props.parent.id,
                    true
                );

                if (result.status === "ok") {
                    if (!didCancel) {
                        setIsLoaded(true);
                        setChild(result.result);

                        if (result.result.length > 0) props.exist(true);
                        else props.exist(false);
                    }
                } else {
                    if (!didCancel) {
                        setIsLoaded(true);
                        setIsEmpty(true);

                        props.exist(false);
                    }
                }
            } catch (error) {
                if (!didCancel) {
                    setIsLoaded(false);
                    setError(error);
                }
            }
        }

        getChildren();

        // unmount
        return () => {
            didCancel = true;
        };
    }, []);

    const clickHandler = async (e, id) => {
        dispatch({
            type: "JOB_RESET",
            job: initialState.job
        });

        const agent = JSON.parse(
            getCookie("agent") ? decodeURIComponent(getCookie("agent")) : null
        );

        if (agent && agent.id) {
            dispatch({
                type: "JOB_STORE",
                exists: true
            });
        } else {
            dispatch({
                type: "JOB_STORE",
                exists: false
            });
        }
        dispatch({
            type: "JOB_CATEGORY_GUILD_ID_TYPE",
            categoryId: e.target.getAttribute("dataid"),
            categoryType: e.target.getAttribute("datatype")
        });

        const {status, result: config} = await Jobin.getConfig();
        if (status === "ok") {
            if (id === config.get("guildTransportId")) {
                dispatch({
                    type: "JOB_TRANSPORT",
                    transport: true
                });
                if (agent.id) {
                    const {result: storeGuild} = await Jobin.Guild.get(
                        agent.id
                    );

                    dispatch({
                        type: "JOB_GUILD",
                        guild: storeGuild
                    });
                    dispatch({
                        type: "JOB_CATEGORY_GUILD_SUBCATEGORY",
                        subCategoryId: agent.id
                    });
                }
            } else {
                dispatch({
                    type: "JOB_TRANSPORT",
                    transport: false
                });
                const {result: parent} = await Jobin.Guild.get(id);
                dispatch({
                    type: "JOB_GUILD",
                    guild: parent
                });
            }
        }
    };

    return error ? (
        <div>Error: {error.message}</div>
    ) : isEmpty ? (
        <React.Fragment />
    ) : !isLoaded ? (
        <div>Cargando...</div>
    ) : (
        <ul className="child-service-list">
            {child.map(childService => {
                return (
                    <li key={childService.id}>
                        <Link
                            to={{
                                pathname: `/job/new/category/${childService.id}`,
                                search: job.location.search,
                                state: {
                                    inner: true
                                }
                            }}
                            dataid={props.parent.id}
                            datatype={props.parent.get("Type")}
                            onClick={e => clickHandler(e, childService.id)}
                        >
                            {childService.get("Type")}
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
}
