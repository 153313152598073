import React, { useState } from "react";
import MainModal from "./MainModal";
import {opsNotificationOptions} from "../../constants/opsNotificationOptions";
import Select from 'react-select';
import { createOpsNotification } from "../../utils/ParserUtilities";

const NewOpsNotificationModal = props => {

    const [option, setOption] = useState(null);
    const [description, setDescription] = useState(null);

    const handleDescriptionChange = e => setDescription(e.target.value);
    const handleOptionChange = e => setOption(e);

    const saveNotification = async () => {
        let pathname = window.location.href.split('/');
        if(option){
            await createOpsNotification(pathname[pathname.length - 1], description, option.value);
            props.fetchNotification();
            props.onClose();
        } else {
            alert("Elige una de las opciones.");
        }
    }

    return (
        <MainModal
            style={{ height: "400px", width: "700px" }}
            title={"Nueva Notificación"}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        >
            <p style={{ fontWeight: "bold", marginTop: 20, marginBottom: 20 }}>TIPOLOGÍA</p>
            <Select
                options={opsNotificationOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleOptionChange} 
            />
            <p style={{ fontWeight: "bold", marginTop: 20, marginBottom: 20 }}>DESCRIPCIÓN</p>
            <input 
                type="textarea" 
                name="textValue"
                value={description}
                style={{outline: "none", width: 634, resize: "none", height: 50, padding: 10}}
                placeholder= 'Añade una descripción...'
                onChange={handleDescriptionChange}
            />

            <div style={{display: "flex", justifyContent: "center", marginTop: 40}}>
                <button onClick={saveNotification}
                    style={{color: "white", backgroundColor: "black", borderRadius: 10, fontWeight: "bold", padding: 15}}>
                        Nueva Notificación
                </button>
            </div>

        </MainModal>
    )
}

export default NewOpsNotificationModal;