import React, {useEffect} from 'react';
import "../../styles/JobsMaps/jobs-maps-card.css";
import {getNearJobsByWollerId} from "../../utils/ParserUtilities";
import { useStateValue } from "../../state/context";

const JobsMapsWollerCard = (params) => {

    const woller = params.woller;

    const [{ user }] = useStateValue();

    useEffect(()=> {
        getNearJobs();
    },[]);

    const itemDetail = id => {
        window.open(`${window.location.origin}/#/user/${id}`, "_blank")
    };

    const getNearJobs = async () => {
        const res = await getNearJobsByWollerId(woller.id); // añadir verticales
    }

    const getMedal = (level) => {
        if(level === 2) {
            return <img
                        className='jobs-maps-woller-details-medal'
                        src={require("../../assets/badge-silver.svg")}
                        alt="Plata"
                    />
        } else if(level === 3){
            return <img
                        className='jobs-maps-woller-details-medal'
                        src={require("../../assets/badge-gold.svg")}
                        alt="Oro"
                    />
        } else if(level === 4){
            return <img
                        className='jobs-maps-woller-details-medal'
                        src={require("../../assets/badge-platinum.svg")}
                        alt="Platino"
                    />
        }
    }

    return (
        <div className='jobs-maps-woller-card'>
            <button className='job-card-woller-cancel-button' onClick={() => params.setShowWollerCard()}>X</button>
            <div className='jobs-maps-woller-details'>
                <img className='jobs-map-woller-icon' src={woller.get("UserPic") ? woller.get("UserPic").url() : require("../../assets/user-default-pic.svg")} />
                <div>
                    <p className='jobs-maps-woller-details-name'>{woller.get("Name")}</p>
                    <p className='jobs-maps-woller-details-phone'>{woller.get("Phone")}</p>
                    <p className='jobs-maps-woller-details-nJobs'>Nivel: {getMedal(woller.get("Level"))}</p>
                    <p className='jobs-maps-woller-details-nJobs'>Saldo Disponible: <b>{woller.get("nJobs")}</b></p>
                </div>
                {/* <button className='job-card-rechargue-button'>Recargar</button> */}
            </div>
            <button className='job-card-details-button-todelete' onClick={()=>{itemDetail(woller.id)}}>
                Mas Información
            </button>

        </div>
    )
}

export default JobsMapsWollerCard;
