import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyDYwng9doYyVkIIyzYLX6crFuJLii07Cus");
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

export const getAddressByLatLong = (lat, long, func) => {
    return Promise.all([Geocode.fromLatLng(lat, long)]).then(
        ([response]) => {
            if(func == "linker"){
                return response.results[0].formatted_address;
            } else{
                func(response.results[0].formatted_address);
            }
        },
        (error) => {
            console.error(error);
        }
    );
}

