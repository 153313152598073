import React, { useState, useEffect } from "react";

import { useStateValue } from "../../state/context";

import { useDebounce } from "../../utils/hooks";
import { provinceArr } from "../../constants/provinces";
import FilterDate from "../filter/FilterDate";
import FilterMultipleSelection from "../filter/FilterMultipleSelection";
import "../../styles/search/search-nav.css";

export default function SearchNav({history,location,fetchMatchData}) {
    const [{matchDetails, general},dispatch] = useStateValue();
    const [date_, setDate_] = useState()
    const [serviceDate_, setServiceDate_] = useState()
    const [business_, setBusiness_] = useState()
    const [province_, setProvince_] = useState()
    const [agent_, setAgent_] = useState()
    const [clearFilters_, setClearFilters_] = useState()
   
    const debounceDate = useDebounce(date_, 500);
    const debounceServiceDate = useDebounce(serviceDate_, 500);
    const debounceBusinesses = useDebounce(business_, 500);
    const debounceAgent = useDebounce(agent_, 500);
    const debounceProvince = useDebounce(province_, 500);
    
  
    
    useEffect(() => {
        if(matchDetails.services.length <1){
            fetchMatchData({})
        }
        
    }, []);

    useEffect(()=>{
        if(debounceDate!== undefined){
            dispatch({
            type: "MATCH_FILTER_DATE",
                startDate: debounceDate.startDate ? new Date(debounceDate.startDate):debounceDate.startDate,
                endDate: debounceDate.endDate ? new Date(debounceDate.endDate):debounceDate.endDate,
                page:1,
            });
           
            fetchMatchData({startDate:debounceDate.startDate,endDate:debounceDate.endDate,page:1});
        }
    },[debounceDate])
    useEffect(()=>{
        if(debounceServiceDate!== undefined){
            dispatch({
            type: "MATCH_FILTER_SERVICE_DATE",
                startDate: debounceServiceDate.startDate ? new Date(debounceServiceDate.startDate):debounceServiceDate.startDate,
                endDate: debounceServiceDate.endDate ? new Date(debounceServiceDate.endDate):debounceServiceDate.endDate,
                page:1,
            });
           
            fetchMatchData({startServiceDate:debounceServiceDate.startDate,endServiceDate:debounceServiceDate.endDate,page:1});
        }
    },[debounceServiceDate])
    useEffect(()=>{
        if(debounceBusinesses !== undefined){
            dispatch({
                type: "MATCH_FILTER_BUSINESS",
                business:debounceBusinesses,
                page:1,
            });
            
            fetchMatchData({business:debounceBusinesses,page:1});
        }
    },[ debounceBusinesses])
    useEffect(()=>{
        if(debounceAgent !== undefined){
            dispatch({
                type: "MATCH_FILTER_AGENTS",
                agents:debounceAgent,
                page:1,
            });
            
            fetchMatchData({agents:debounceAgent,page:1});
        }
    },[ debounceAgent])
    useEffect(()=>{
        if(debounceProvince !== undefined){
            dispatch({
                type: "MATCH_FILTER_PROVINCES",
                provinces:debounceProvince,
                page:1,
            });
            
            fetchMatchData({provinces:debounceProvince,page:1});
        }
    },[ debounceProvince])

  
    
    //clear filters
    const clearFilters = async ()=> {
        dispatch({
            type: "MATCH_CLEAN",
           
        });
        dispatch({
            type:"MATCH_CHANGE_PAGE",
            currentPage:  1
        })
  
        setClearFilters_(true)
        setDate_(undefined)
        setServiceDate_(undefined)
        setBusiness_(undefined)
        setAgent_(undefined)
       
        fetchMatchData({
            startDate:null,
            endDate:null,
            startServiceDate:null,
            endServiceDate:null,
            business:[],
            agents:[],
            provinces:[],
            page:1,
        })
    }  


   //filters acctions
    const fetchMatchsByDate = async date => {
            
        setDate_({startDate:date.startDate,endDate:date.endDate})
    }
    const fetchMatchsByServiceDate = async date => {
        setServiceDate_({startDate:date.startDate,endDate:date.endDate})
    }
    const fetchMatchByBusinesses = async selection => {
            
        selection = selection.map(selected=>{
            return selected.id
        })
    
        setBusiness_(selection)
    }
    const fetchMatchByAgent = async selection => {
            
        selection = selection.map(selected=>{
            return selected.id
        })
    
        setAgent_(selection)
    }
    const fetchMatchByProvince = async selection => {
            
        selection = selection.map(selected=>{
            return selected.id
        })
    
        setProvince_(selection)
    }

    return (
        <section className="search-nav Matchs" >
            
            <div >
                <div >
                    <FilterDate
                        style={{ marginRight: 15 }}
                        type="date"
                        title="Fecha de match"
                        startDate={null}
                        endDate={null}
                        selectionChange={fetchMatchsByDate}
                        isClearFilters={clearFilters_}
                        settingClearFilters={setClearFilters_}
                    />
                    <FilterDate
                        style={{ marginRight: 15 }}
                        type="date"
                        title="Fecha del servicio"
                        startDate={null}
                        endDate={null}
                        selectionChange={fetchMatchsByServiceDate}
                        isClearFilters={clearFilters_}
                        settingClearFilters={setClearFilters_}
                    />
                    <FilterMultipleSelection
                        style={{ marginRight: 15 }}
                        type="businesses"
                        title="Negocios"
                        items={general.businesses}
                        selectionChange={fetchMatchByBusinesses}
                        isClearFilters={clearFilters_}
                        settingClearFilters={setClearFilters_}
                    />
                    <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="agent"
                        title="Agente"
                        items={general.agents}
                        selectionChange={fetchMatchByAgent}
                        isClearFilters={clearFilters_}
                        settingClearFilters={setClearFilters_}
                    />
                    <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="province"
                        title="Provincias"
                        items={provinceArr}
                        selectionChange={fetchMatchByProvince}
                        isClearFilters={clearFilters_}
                        settingClearFilters={setClearFilters_}
                    />
                    <button 
                        className="onlyIncidencesTrue"
                        onClick={()=>clearFilters()}>Limpiar Filtros</button>
                    
                </div>
            </div>
        </section>
    );
}
