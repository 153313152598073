export const paymentPriceSignArr = [
    {
        id: "all",
        name: "Todos",
        selected: false
    },
    {
        id: "positive",
        name: "Venta",
        selected: false
    },
    {
        id: "negative",
        name: "Devolución",
        selected: false
    }
];
