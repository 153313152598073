function getSafe(fn, defaultVal) {
    try {
        return fn();
    } catch (e) {
        return defaultVal;
    }
}

function hasClass(element, className) {
    return !(
        !className ||
        !element ||
        !element.className ||
        !getSafe(() => element.className.match(new RegExp("(\\s|^)" + className + "(\\s|$)")), false)
    );
}

function parentByClass(childElement, className) {
    if (!childElement || childElement === document) return null;
    else if (hasClass(childElement, className)) return childElement;
    else return parentByClass(childElement.parentNode, className);
}

export function hasClassInTree(element, className) {
    return hasClass(element, className) || parentByClass(element, className);
}
