import React, { useState } from "react";
import MainModal from "./MainModal";
import Parse from "parse";
import Button from "../button/Button";
import { Input } from "jobin-ui-components";

import { apiId, jsKey, urlServer  } from '../../constants/credentials';
import '../../styles/modal/edit-vertical-woller-modal.css';

const EditPostalCodesModal = props => {

    const [page, setPage] = useState(12);
    const [newPostalCode, setNewPostalCode] = useState();
    const [duplicateError, setDuplicateError] = useState(false);

    const calculatePage = ( n ) => {
        let newPage = page + n;
        if(newPage>0) setPage(newPage);
    }

    const EditPostalCode = async (postalCode, add) => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();

        let postalCodes = props.postalCodes ? props.postalCodes : [];
        if(!add){
            postalCodes = postalCodes.filter(function(ele){ 
                return ele != postalCode; 
            });
            let res = await Parse.Cloud.run("editPostalCode", { postalCodes: postalCodes, id: props.joberId });
            setDuplicateError(false);
        }
        else if(!postalCodes.includes(postalCode)){
            postalCodes.push(postalCode);
            let res = await Parse.Cloud.run("editPostalCode", { postalCodes: postalCodes, id: props.joberId });
            setDuplicateError(false);
        }
        else setDuplicateError(true);

        props.setPostalCodes([...postalCodes]);
    }

    const handleNewPostalCodeChange = (e) => {
        setNewPostalCode(e.target.value)
    }

    return (
        <MainModal
            style={{ height: "700px", width: "900px" }}
            title={"Editar Códigos Postales"}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        >
            <div style={{marginTop: '15px', marginBottom: '25px'}}>
                {
                    props.postalCodes && (
                        props.postalCodes.slice((page-12) , page).map( postalCode => (
                            <div style={{display: 'flex', flexDirection:'row', justifyContent:"center"}}>
                                <p className='jobType'>{postalCode}</p>
                                <button className='deleteJobType' onClick={()=> EditPostalCode(postalCode)}>Eliminar</button>
                            </div>
                        ))
                    )
                }
            </div>

            <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", width: "270px", margin: "10px 0px 10px 0px"}}>
                <button className='back-next-vertical-button' onClick={() => calculatePage(-12)}>Anterior</button>
                <p className='vertical-page'>{`${page-11} - ${page}`}</p>
                <button className='back-next-vertical-button' onClick={() => calculatePage(12)}>Siguiente</button>
            </div>

            <p style={{ marginBottom: "15px", fontWeight: "bold", marginTop: "40px"}}>NUEVO CÓDIGO POSTAL:</p>
            <Input
                style={{
                    display: "flex",
                    flex: 1,
                    borderRadius: 0,
                    border: "1px solid var(--greyish)",
                }}
                inputStyle={{ border: "1px solid #e8e8e8" }}
                placeholder="Nuevo código postal"
                onChange={handleNewPostalCodeChange}
            />
            {
                duplicateError && (
                    <p style={{ marginBottom: "15px", fontWeight: "bold", marginTop: "10px", color: "red"}}>El código postal ya existe</p>
                )
            }
            <Button
                style={{marginTop: 10}}
                disabled={false}
                buttonText="Guardar cambios"
                onClick={ () => {
                    if(newPostalCode.trim() != "")
                        EditPostalCode(newPostalCode.trim(), true);
                }}
            />
        </MainModal>
    )
}

export default EditPostalCodesModal;
