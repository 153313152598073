import { setCampainClick } from "../utils/ParserUtilities";
import { urlServer  } from '../constants/credentials';

export const clickSamsumgCampain = async ( userId ) => {

    await setCampainClick(
        process.env.REACT_APP_CAMPAINS_KEY,
        userId ? userId : undefined
    );

    window.open('https://www.samsung.com/es/business/how-register/?cid=es_pd_display_wolly_leadsempresa_nophase_leads-empresa-octubre-23_banner_bannerbanner-20231019_inapp_hot_pfm&dclid=CJKPoLvChIIDFQRJHQkds4cNSQ', '_blank');
}

export const clickBiproSession = async ( userId ) => {

    await setCampainClick(
        process.env.REACT_APP_CAMPAINS_KEY,
        userId ? userId : undefined
    );
}