import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import moment from "moment";
import { provinceArr } from "../../../constants/provinces";
import fetchServices from "../../../constants/services";

import { SearchBar, Modal, Loading } from "jobin-ui-components";
import FilterMultipleSelection from "../../filter/FilterMultipleSelection";
import FilterDate from "../../filter/FilterDate";
import { useStateValue } from "../../../state/context";

import JobCell from "../../JobCell";
import { finishedFixedStateArr } from "../../../constants/jobState";

export default function JobSearchModal(props) {
    const [guildArr, setGuildArr] = useState([]);
    const [data] = useStateValue();
    const [jobId, setJobId] = useState("PC-");
    const [loading, setLoading] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [filters, setFilters] = useState({
        id: "",
        startDate: moment()
            .utc()
            .startOf("month"),
        endDate: moment()
            .utc()
            .endOf("day"),
        provinces: [],
        services: [],
        states: finishedFixedStateArr.filter(state => state.selected)
    });

    const style = {
        searchNav: {
            paddingRight: 24,
            paddingLeft: 24,
            paddingTop: 24,
            borderBottom: "2px solid var(--soft-grey)"
        },
        filterView: {
            paddingTop: 8,
            paddingBottom: 12
        }
    };

    const fetch = async () => {
        setLoading(true);

        const {result} = await Jobin.Job.getJobs(
            null,
            100,
            filters.startDate ? filters.startDate.format() : undefined,
            filters.endDate ? filters.endDate.format() : undefined,
            filters.provinces.length > 0
                ? filters.provinces.map(province => province.id)
                : undefined,
            filters.services.length > 0 ? filters.services : undefined,
            undefined,
            undefined,
            filters.states.length > 0
                ? filters.states.map(state => state.id)
                : undefined,
            jobId !== "" ? jobId : "PC-",
            undefined,
            undefined,
            undefined,
            undefined
        );
        setLoading(false);
        setJobs(result.jobs);
    }

    useEffect(() => {
        fetchServices(filters.services).then(setGuildArr);
        fetch();
    }, []);

    useEffect(() => {
        fetch()
    }, [filters, jobId]);


    const fetchJobsByDate = async date => {
        
        filters.startDate =
            date && date.startDate ? moment(date.startDate) : undefined;
        filters.endDate =
            date && date.endDate ? moment(date.endDate) : undefined;
        setFilters({ ...filters });
    };

    const fetchJobsByProvince = async selection => {
        filters.provinces = selection;
        setFilters({ ...filters });
    };

    const fetchJobsByGuild = async selection => {
        filters.services = selection;
        setFilters({ ...filters });
    };

    const fetchJobsByJobState = async selection => {
        filters.states = selection;
        setFilters({ ...filters });
    };

    const fetchJobsById = async e => {
        const keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            setJobId(jobId !== "" ? jobId : "PC-");
        }
    };
    const selectJob = async id => {
        setLoading(true);
        let { status, result } = await Jobin.Job.getJobById(id);
        setLoading(false);
        if (status === "ok") props.onJobSelected(result);
    };

    return (
        <Modal
            title={"Añadir servicio a factura"}
            onClose={props.onClose}
            loading={props.loading}
        >
            <SearchBar
                style={{ display: "flex", flex: 1 }}
                placeholder="Buscar por ID"
                onKeyPress={fetchJobsById}
                value={jobId}
                onChange={e => setJobId(e.target.value)}
            />
            <div style={style.filterView}>
                <FilterDate
                    style={{ marginRight: 4 }}
                    type="date"
                    title="Fecha de creación"
                    startDate={filters.startDate}
                    endDate={filters.endDate}
                    selectionChange={fetchJobsByDate}
                />
                <FilterMultipleSelection
                    style={{ marginRight: 4 }}
                    type="province"
                    title="Provincias"
                    items={provinceArr}
                    selectionChange={fetchJobsByProvince}
                />
                <FilterMultipleSelection
                    style={{ marginRight: 4 }}
                    type="service"
                    title="Gremios"
                    items={guildArr}
                    selectionChange={fetchJobsByGuild}
                />
                <FilterMultipleSelection
                    style={{ marginRight: 4 }}
                    type="custom"
                    title="Estado del trabajo"
                    items={finishedFixedStateArr}
                    selectionChange={fetchJobsByJobState}
                />
            </div>
            <div style={{ height: "65vh", overflowY: "auto" }}>
                {loading ? (
                    <Loading />
                ) : jobs.length <= 0 ? (
                    <div>Nada que mostrar</div>
                ) : (
                    jobs.map((item, index) => {
                        return (
                            <JobCell
                                key={"job" + index}
                                permissions={data.user.permissions}
                                job={item}
                                onClick={() => selectJob(item.id)}
                                style={{
                                    marginTop: 24,
                                    marginRight: 24,
                                    marginLeft: 24
                                }}
                                buttonText="Seleccionar"
                            />
                        );
                    })
                )}
            </div>
        </Modal>
    );
}
