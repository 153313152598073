import React, { useState, useEffect } from "react";
import ButtonImage from "../../../button/ButtonImage";
import NewOpsNotificationModal from "../../../modal/NewOpsNotificationModal";
import { fetchOpsNotification } from "../../../../utils/ParserUtilities";

import "../../../../styles/job/ops-notification-container.css";
import OpsNotificationDetailModal from "../../../modal/OpsNotificationDetailModal";
import AllNotificationsModal from "../../../modal/AllNotificationsModal";

const OpsNotificationContainer = (props) => {

    const [showNewOpsNotificationModal, setShowNewOpsNotificationModal] = useState(false);
    const [showOpsNotificationDetailModal, setShowOpsNotificationDetailModal] = useState(false);
    const [showAllNotificationModal, setShowAllNotificationModal] = useState(false);
    const [notification, setNotification] = useState(undefined);

    useEffect(() => {
        fetchNotification();
    }, []);

    const fetchNotification = async () => {
        let pathname = window.location.href.split('/');
        let res = await fetchOpsNotification(pathname[pathname.length - 1]);
        setNotification(res);
    }

    return(
        <section className="infoContainer">
            <div style={{ display: "flex", flex: 1 }}>
                <div style={{ display: "flex", flex: 1 }}>
                    <label className="title">Notificaciones</label>
                </div>
                <ButtonImage
                    buttonText=""
                    src={require("../../../../assets/add.svg")}
                    onClick={() =>{
                        setShowNewOpsNotificationModal(true);
                    }}
                    disabled={false}
                />
            </div>
            {
                notification && notification.length >= 1 && (
                    <>
                        <button onClick={() => setShowOpsNotificationDetailModal(true)} className="notification-wrapper">
                            <div className='space-between'>
                                <p className='incident-typology'>{notification[0].get('ExtraInfo').typology}</p>
                            </div>
                            <p className='incident-description'>{notification[0].get('ExtraInfo').description ? notification[0].get('ExtraInfo').description.length < 33 ? notification[0].get('ExtraInfo').description : `${notification[0].get('ExtraInfo').description.substring(0,33)}...` : ""}</p>
                        </button>
                        <button className='incident-show-more' onClick={() => setShowAllNotificationModal(true)}>Mostrar Todos</button>
                    </>
            )}
            { showNewOpsNotificationModal && (
                <NewOpsNotificationModal 
                    isVisible={showNewOpsNotificationModal}
                    onClose={() => setShowNewOpsNotificationModal(false)}
                    fetchNotification={fetchNotification}
                    setNotification={setNotification}
                />
            )}
            { showOpsNotificationDetailModal && (
                <OpsNotificationDetailModal
                    isVisible={showOpsNotificationDetailModal}
                    onClose={() => setShowOpsNotificationDetailModal(false)}
                    notification={notification[0]}
                    setNotification={setNotification}
                />
            )}
             { showAllNotificationModal && (
                <AllNotificationsModal
                    isVisible={showAllNotificationModal}
                    onClose={() => setShowAllNotificationModal(false)}
                    notifications={notification}
                    setNotification={setNotification}
                />
            )}
        </section>
    )
}

export default OpsNotificationContainer;
