import React from "react";

export default function DataTable(props) {

    if(props.thead, props.data){
        return (
            <div className="kpis-table-container display-flex flex-column" >
                <span className="singleContextTitles text-center">{props.globalContext}</span>
                <table className="kpis-table-borders">
                    <thead>
                        {props.thead === "Woller" ? (
    
                            <tr className="diplay-flex flex-justify-center">
                                <th></th>
                                <th>woller</th>
                                <th>leads</th>
                                
                            </tr>
                        ):props.thead !== "Ops" ?(
                            
                            <tr className="diplay-flex flex-justify-center">
                                <th></th>
                                <th>{props.thead}</th>
                                <th>N° Jobs</th>
                                <th>%</th>
                                <th>% Resp.</th>
                                <th>T.O</th>
                            </tr>
                        ):(
                            
                            <tr className="diplay-flex flex-justify-center">
                                <th></th>
                                <th>Agente</th>
                                <th>Jobs</th>
                                <th>Pend</th>
                                <th>Match</th>
                                <th>%</th>
                            </tr>
                        ) }
                    </thead>
                    <tbody>
                        {props.thead === "Woller" ? props.data.map((data,index)=>{
                            return( <tr className="diplay-flex flex-justify-center">
                                <td>{index+1}</td>
                                <td>{data.name}</td>
                                <td>{data.leads}</td>
                                
                            </tr>)
                        }):props.thead !== "Ops" ? props.data.map((data,index)=>{
                           return ( <tr className="diplay-flex flex-justify-center">
                                <td>{index+1}</td>
                                <td>{data.type}</td>
                                <td>{data.count}</td>
                                <td>{data.percentage}%</td>
                                <td>{data.perResp}%</td>
                                <td>{data.to}</td>
                            </tr>)
                        }):props.data.map((data,index)=>{
                            return ( <tr className="diplay-flex flex-justify-center">
                                 <td>{index+1}</td>
                                 <td>{data.fullName}</td>
                                 <td>{data.jobs}</td>
                                 <td>{data.pend}</td>
                                 <td>{data.match}</td>
                                 <td>{data.percentage}%</td>
                             </tr>)
                         }) }
                    </tbody>
                </table>
            </div>
        );
    }else{
        return null
    }
}
