import React from "react";

import SearchNav from "../../components/match/SearchNav";
import ResultDashboard from "../../components/match/ResultDashboard";
import ResultCount from "../../components/search/ResultCount";

import { useStateValue } from "../../state/context";
import { fetchMatchs as fetchMatchs_} from "../../utils/ParserUtilities";




export default function Search(props) {
    const [{matchDetails,agent},dispatch] = useStateValue();

    const fetchMatchData = async ({startDate,endDate,business,startServiceDate,endServiceDate,agents,page,provinces}) => {
    
        dispatch({
            type: "MATCH_DETAILS_LOADING",
            loading:true
        })
        let  matchsPromise = fetchMatchs_(
            {
                startDate:startDate ? new Date(startDate): startDate === null? startDate:matchDetails.startDate,
                endDate:endDate ? new Date(endDate): endDate === null? endDate:matchDetails.endDate,
                startServiceDate:startServiceDate ? new Date(startServiceDate): startServiceDate === null? startServiceDate:matchDetails.startServiceDate,
                endServiceDate:endServiceDate ? new Date(endServiceDate): endServiceDate === null? endServiceDate:matchDetails.endServiceDate,
                business:business ? business:matchDetails.business?matchDetails.business:null,
                agents:agents ? agents:matchDetails.agents?matchDetails.agents:null,
                provinces:provinces ? provinces:matchDetails.provinces?matchDetails.provinces:null,
                sesionToken:agent.token,
                page:page?page:matchDetails.currentPage,
                limit:1000
            }
        )
        
        Promise.all([matchsPromise]).then(([results]) => {
            dispatch({
                type: "SET_MATCH_ITEMS",
                count:results.count,
                items:results.items
            })
            dispatch({
                type: "MATCH_DETAILS_LOADING",
                loading:false
            })
        })
        .catch((error) => {
            console.error(error)
            dispatch({
                type: "MATCH_DETAILS_LOADING",
                loading:false
            })
            
        })
        

    };
   
    return (
        <React.Fragment>
            <SearchNav history={props.history} location={props.location} fetchMatchData={fetchMatchData} />
            <ResultDashboard history={props.history} location={props.location}  />
             <ResultCount history={props.history} location={props.location} controllerState={matchDetails} findFunction={fetchMatchData} reducerFunction={"MATCH_CHANGE_PAGE"}/>
        </React.Fragment>
    );
}
