import React, { useState } from "react";
import MainModal from "./MainModal";
import Parse from "parse";

import { apiId, jsKey, urlServer  } from '../../constants/credentials';
import '../../styles/modal/edit-vertical-woller-modal.css';

const EditVerticalWollerModal = props => {


    const [page, setPage] = useState(12);
    const [verticalId, setVerticalId] = useState(undefined);

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const calculatePage = ( n ) => {
        let newPage = page + n;
        if(newPage>0) setPage(newPage);
    }

    const handleChange = async (e) => {
        let repeated = false;

        props.jobsType.map( (job) => {
            if(job.get('Type') == e.target.value) repeated = true;
        })

        if(repeated == false){
            let pathname = window.location.href.split('/');
            Parse.initialize(apiId, jsKey);
            Parse.serverURL = urlServer;
            Parse.User.enableUnsafeCurrentUser();
    
            let res = await Parse.Cloud.run("createJoberTask", { joberId: pathname[pathname.length - 1], type: e.target.value, verticalId: verticalId });
            
            let tasks = await Parse.Cloud.run("getTasks",{ joberId: pathname[pathname.length - 1] });
            props.setJobsType(tasks)
        }
    }

    const deleteJoberTask = async (type) => {
        let pathname = window.location.href.split('/');
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let res = await Parse.Cloud.run("deleteJoberTask", { joberId: pathname[pathname.length - 1], type: type });

        await delay(200);
        let tasks = await Parse.Cloud.run("getTasks",{ joberId: pathname[pathname.length - 1] });
        props.setJobsType(tasks)
    } 

    return(
        <MainModal
            style={{ height: "650px", width: "700px" }}
            title={"Editar verticales del woller"}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        >
            <div style={{marginTop: '15px', marginBottom: '25px'}}>
                {
                    props.jobsType.slice((page-12) , page).map( job => (
                        <div style={{display: 'flex', flexDirection:'row', justifyContent:"center"}}>
                            <p className='jobType'>{job.get('Type')}</p>
                            <button className='deleteJobType' onClick={()=> deleteJoberTask(job.get('Type'))}>Eliminar</button>
                        </div>
                    ))
                }
            </div>

            <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", width: "270px", margin: "30px 0px 30px 0px"}}>
                <button className='back-next-vertical-button' onClick={() => calculatePage(-12)}>Anterior</button>
                <p className='vertical-page'>{`${page-11} - ${page}`}</p>
                <button className='back-next-vertical-button' onClick={() => calculatePage(12)}>Siguiente</button>
            </div>
           
            <p style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '15px'}}>Selecciona para añadir nuevo trabajo:</p>
            <select name="select" onChange={handleChange}>
                {
                    props.optionsSelectVerticals.map( option => (
                            <option onClick={option.id ? () => setVerticalId(option.id) : null} value={option.label}>{option.label}</option>
                    ))
                }
            </select>
        </MainModal>
    )
}

export default EditVerticalWollerModal;