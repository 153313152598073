export const matchTitles = [

    {
        Header: "ID",
        id: "id", 
    },
    {
        Header: "Negocio",
        id: "business", 
    },
    {
        Header: "Servicio",
        id: "takenSlots", 
    },
    {
        Header: "Provincia",
        id: "province", 
    },
    {
        Header: "Agente",
        id: "agent", 
    },
    {
        Header: "Fecha de match",
        id: "takenSlots", 
    },
    {
        Header: "Fecha del servicio",
        id: "jobDate", 
    },
    {
        Header: "Tipo de servicio",
        id: "name", 
    },
    {
        Header: "Origen",
        id: "origin", 
    },
    
];
