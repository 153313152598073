import React from "react";
import { useStateValue } from "../../../state/context";

export default function Footer(props) {
    const [{ businessDetails }] = useStateValue();

    const styles = {
        container: {
            position: "absolute",
            display: "flex",
            flex: 1,
            flexDirection: 'column',
            bottom: 0,
            right: 0,
            left: 64,
            borderTop: "1px solid var(--soft-grey)",
            boxShadow: "0 -2px 4px 0 rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
            alignItems: "flex-end",
            justifyContent: "center",
            height: 71,
            paddingRight: 24,
            ...props.style
        },
        priceLabel:{
            fontSize: 28,
            fontWeight: "bold",
            lineHeight: '30px'
        },
        priceIvaLabel:{
            fontSize: 16,
            lineHeight: '18px'
        }
    };

    return (
        <section style={styles.container}>
            <label style={styles.priceLabel}>{(businessDetails.billsTotal).toFixed(2)} €</label>
            <label style={styles.priceIvaLabel}>{(businessDetails.billsTotal * 1.21).toFixed(2)} € con IVA</label>
        </section>
    );
}
