import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import Parse from "parse";
import { useStateValue } from "../../state/context";
import { getCookie } from "../../utils/cookie";
import StepControlls from "./generic/StepControlls";
import BreadCrumb from "./generic/BreadCrumb";

import {Input} from 'jobin-ui-components'

import "../../styles/job/service-weight.css";

export default function ServiceWeight(props) {
    const [{ job }, dispatch] = useStateValue();

    const [weight, setWeight] = useState(job.weight.amount);
    const [error, setError] = useState(job.weight.error);
    const [disabledBtn, setDisabledBtn] = useState(job.weight.disabledBtn);
    const [loading, setLoading] = useState(false);
    const [nextTitle, setNextTitle] = useState("Siguiente");

    const validateWeight = e => {
        const value = e.target.value;
        if (value.length > 0) {
            const reg = new RegExp("^\\d+$");
            if (reg.test(value)) {
                setError(false);
                setDisabledBtn(false);
            } else {
                setError(true);
                setDisabledBtn(true);
            }
        } else {
            setError(true);
            setDisabledBtn(true);
        }

        setWeight(value);
    };

    const update = async () => {
        setLoading(true);
        setDisabledBtn(true);
        const location = new Parse.GeoPoint({
            latitude: job.locate.center.lat,
            longitude: job.locate.center.lng
        });
        const agent = JSON.parse(
            getCookie("agent") ? decodeURIComponent(getCookie("agent")) : null
        );
        const emptyStoreGuild = Parse.Object.extend(
            "Verticales"
        ).createWithoutData(agent && agent.id ? agent.id : job.category.subCategoryId);

        const Jobs = Parse.Object.extend("Jobs");
        const tempJob = new Jobs();
        tempJob.set("JobType", "transport");
        tempJob.set("Location", location);
        tempJob.set("Subvertical", emptyStoreGuild);

        try {
            const result = await Jobin.Job.getPrice(tempJob, weight);
            if (result.status === "ok") {
                dispatch({
                    type: "JOB_CATEGORY_GUILD_SUBCATEGORY",
                    subCategoryId: agent.id
                        ? agent.id
                        : job.category.subCategoryId
                });
                dispatch({
                    type: "JOB_PRICE",
                    amount: result.result.price,
                    error: null
                });
                setLoading(false);
                setDisabledBtn(false);
                job.history.push({
                    pathname: "/job/new/price",
                    search: job.location.search,
                    state: {
                        inner: true
                    }
                });
            } else {
                alert(result.result);
                setLoading(false);
                setDisabledBtn(false);
                setNextTitle("Intentar de nuevo");
                dispatch({
                    type: "JOB_PRICE",
                    amount: "",
                    error: true
                });
            }
        } catch (error) {
            setLoading(false);
            setDisabledBtn(false);
            setNextTitle("Intentar de nuevo");
            dispatch({
                type: "JOB_PRICE",
                amount: "",
                error: error
            });
        }
        dispatch({
            type: "JOB_WEIGHT",
            amount: Number(weight),
            disabledBtn: disabledBtn,
            error: error
        });
    };

    useEffect(() => {
        if (!(props.location.state && "inner" in props.location.state))
            props.history.replace("/job/new");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="service-weight" className="section">
            <BreadCrumb history={props.history} location={props.location} />
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="title">Indica el peso</h4>
                        <p>
                            Asegúrate de indicar el peso correcto para este
                            transporte. El precio variará según lo que indiques.
                        </p>
                        <div className="content">
                            <div className="input-suffix-container">
                                <Input
                                    style={{ marginRight: 8 }}
                                    badInput={error}
                                    onChange={validateWeight}
                                    value={weight}
                                    autoFocus
                                />
                                <span>Kg</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <StepControlls
                page={props.step}
                disabled={disabledBtn}
                nextTrigger={error}
                loading={loading}
                prev="job/new/location"
                nextTitle={nextTitle}
                update={update}
            />
        </div>
    );
}
