import React from "react";
import XLSX from "xlsx";

import Parse from "parse";
import Button from "../../button/Button";

import { apiId, jsKey, urlServer  } from '../../../constants/credentials';

export default class ImportContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      cols: [] 
    };

    this.handleFile = this.handleFile.bind(this);
    this.saveWollers = this.saveWollers.bind(this)
  }

  handleFile(file) {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = e => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.setState({ data: data, cols: make_cols(ws["!ref"]) });
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  }

   async saveWollers() {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let res = await Parse.Cloud.run("saveWollers", { data: this.state.data });
        this.props.onClose();
    };

  render() {
    return (
      <div style={{paddingTop: "25px"}}>
        <div style={{paddingBottom: "25px"}} className="row">
          <div className="col-xs-12">
            <DataInput handleFile={this.handleFile} />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <div style={{ display: "flex", justifyContent: "center", width: "100%", marginBottom:'25px' }}>
                <Button 
                    disabled={false}
                    buttonText="Guardar Wollers"
                    onClick={this.saveWollers}
                />
            </div>
          </div>
        </div>
        
        <div className="row">
          <div className="col-xs-12">
            <OutTable data={this.state.data} cols={this.state.cols} />
          </div>
        </div>
      </div>
    );
  }
}

class DataInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  
  render() {
    return (
      <form className="form-inline">
        <div className="form-group">
          <input
            type="file"
            className="form-control"
            id="file"
            accept={SheetJSFT}
            onChange={this.handleChange}
          />
        </div>
      </form>
    );
  }
}

class OutTable extends React.Component {
  render() {
    return (
      <div className="table-responsive">
        <p style={{paddingBottom: "5px"}}>Previsualizacion cortada para evitar errores:</p>
        <p style={{paddingBottom: "25px"}}>Formato: Id, Nombre, Ciudad, Trabajo, Telefono, Email (minimo)</p>
        <table className="table table-striped">
          <thead>
            <tr>
              {this.props.cols.map(c => (
                <th key={c.key}>{c.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((r, i) => (
              <tr key={i}>
                {this.props.cols.map(c => (
                  <td key={c.key}>{r[c.key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm"
]
  .map(function(x) {
    return "." + x;
  })
  .join(",");

const make_cols = refstr => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};