import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { Modal, Loading } from "jobin-ui-components";
import NavBar from "../../components/NavBar";
import JoberPayoffEditor from "../../components/billing/JoberPayoffEditor";
import SelectJoberModal from "../../components/modal/SelectJoberModal";
import JobSearchModal from "../../components/billing/receipt/JobSearchModal";
import { elementOf } from "../../utils/formatter";

export function JoberPayoffEdit(props) {
    const initFromJobs = (fixedInfos, transportations) => {
        let array = [];
        if (fixedInfos)
            for (let i = 0; i < fixedInfos.length; i++) {
                const fixedInfo = fixedInfos[i];
                array.push({
                    fixedInfo: fixedInfo,
                    job: fixedInfo.get("Job"),
                    description: fixedInfo.get("ID"),
                    amount: 1,
                    pricePerUnit: fixedInfo.get("JoberPrice")
                });
            }
        if (transportations)
            for (let i = 0; i < transportations.length; i++) {
                const transportation = transportations[i];
                array.push({
                    transportation: transportation,
                    job: transportation.get("Job"),
                    description: transportation.get("ID"),
                    amount: 1,
                    pricePerUnit: transportation.get("JoberPrice")
                });
            }
        return array;
    };
    const style = {
        loadingContainer: {
            display: "flex",
            flex: 1,
            backgroundColor: "var(--soft-grey)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
        }
    };

    const [payment, setPayment] = useState();
    const [billingInfo, setBillingInfo] = useState();
    const [totalPrice, setTotalPrice] = useState(() =>
        props.payment ? props.payment.get("Price") : 0
    );
    const [rowArray, setRowArray] = useState([]);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [index, setIndex] = useState();
    const [modalLoading, setModalLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const [joberModalVisible, setJoberModalVisible] = useState(false);
    const [addModalVisible, setAddModalVisible] = useState(false);

    useEffect(() => {
        setPayment(props.payment);
        setTotalPrice(props.payment.get("Price"));
        setBillingInfo(props.billingInfo);
        setRowArray(initFromJobs(props.fixedInfos, props.transportations));
    }, [
        props.payment && props.payment.id,
        props.billingInfo && props.billingInfo.id,
        props.jobs,
        props.fixedInfos,
        props.transportations
    ]);

    useEffect(() => {
        const totalPrice = rowArray.reduce((total, item) => {
            return total + item.amount * item.pricePerUnit;
        }, 0);
        setTotalPrice(totalPrice);
    }, [rowArray]);

    const addRow = async ({ job, fixedInfo, transportation }) => {
        setLoading(true);
        setAddModalVisible(false);
        if (!elementOf(job.get("State"), ["finalizado", "review"]))
            alert("Este trabajo no está finalizado y firmado");
        else if (fixedInfo) {
            if (
                !fixedInfo.has("Jober") ||
                fixedInfo.get("Jober").id !== payment.get("User").id
            ) {
                let oldJober = fixedInfo.get("Jober");
                await deleteFromOtherBill(oldJober, fixedInfo);
                await Jobin.Jober.asignToJob(payment.get("User"), job);
            }
            rowArray.push({
                fixedInfo: fixedInfo,
                job: job,
                description: fixedInfo.get("ID"),
                amount: 1,
                pricePerUnit: fixedInfo.get("JoberPrice")
            });
        } else if (transportation) {
            if (
                !transportation.has("Delivery") ||
                transportation.get("Delivery").get("Jober").id !==
                    payment.get("User").id
            ) {
                let oldJober = transportation.get("Delivery").get("Jober");
                await deleteFromOtherBill(oldJober, undefined, transportation);
                await Jobin.Jober.sendToTransit(
                    [transportation.get("ID")],
                    payment.get("User")
                );
            }
            rowArray.push({
                transportation: transportation,
                job: transportation.get("Job"),
                description: transportation.get("ID"),
                amount: 1,
                pricePerUnit: transportation.get("JoberPrice")
            });
        }
        const newArray = [...rowArray];
        setRowArray(newArray);
        await saveBill(newArray);
        setLoading(false);
    };

    const deleteFromOtherBill = async (oldJober, fixedInfo, transportation) => {
        const deleteId = fixedInfo
            ? fixedInfo.get("ID")
            : transportation.get("ID");
        let { status, result } = await Jobin.Payment.getPayments({
            typeArray: ["joberPayoff"],
            stateArray: ["pending"],
            user: oldJober
        });
        if (status === "ok" && result.length > 0) {
            let payment = result[0];
            let { status } = await Jobin.PDF.remakePayOff(
                payment,
                payment.get("FixedIds").filter(id => id !== deleteId)
            );
            if (status !== "ok") alert("Error al actualizar factura asociada");
        }
    };

    const addToOtherBill = async (newJober, fixedInfo, transportation) => {
        const newId = fixedInfo
            ? fixedInfo.get("ID")
            : transportation.get("ID");
        let { status, result } = await Jobin.Payment.getPayments({
            typeArray: ["joberPayoff"],
            stateArray: ["pending"],
            user: newJober
        });
        if (status === "ok" && result.length > 0) {
            let payment = result[0];
            let ids = payment.get("FixedIds");
            ids.push(newId);
            let { status } = await Jobin.PDF.remakePayOff(payment, ids);
            if (status !== "ok") alert("Error al actualizar factura asociada");
        } else if (status === "ok") {
            alert("Es necesario volver a generar la facturación");
        }
    };

    const editRow = async (index, { pricePerUnit }) => {
        setLoading(true);
        let row = rowArray[index];
        let job = row.fixedInfo
            ? row.fixedInfo.get("Job")
            : row.transportation.get("Job");
        let { status, result } = await Jobin.Job.changeJoberPrice(
            job,
            pricePerUnit
        );
        if (status === "ok") {
            row.pricePerUnit = pricePerUnit;
            row.editable = false;
            if (row.fixedInfo) row.fixedInfo = await row.fixedInfo.fetch();
            if (row.transportation)
                row.transportation = await row.transportation.fetch();
            const newArray = [...rowArray];
            setRowArray(newArray);
            await saveBill(newArray);
        } else {
            alert(result);
        }
        setLoading(false);
    };

    const enableEdit = index => {
        rowArray[index].editable = true;
        setRowArray([...rowArray]);
    };

    const saveBill = async newArray => {
        setLoading(true);
        let generatedIds = newArray.map(row =>
            row.fixedInfo
                ? row.fixedInfo.get("ID")
                : row.transportation.get("ID")
        );
        let { status, result } = await Jobin.PDF.remakePayOff(
            payment,
            generatedIds
        );
        if (status !== "ok") alert(result);
        setLoading(false);
    };

    const reopenRow = async index => {
        setModalLoading(true);
        await Jobin.Job.reopenJob(rowArray[index].job);
        rowArray.splice(index, 1);
        const newArray = [...rowArray];
        setRowArray(newArray);
        setModalLoading(false);
        setDeleteModalVisible(false);
        await saveBill(newArray);
    };

    const asignJober = async (index, jober) => {
        setJoberModalVisible(false);
        setLoading(true);
        let result;
        const job = rowArray[index].job;
        if (job.get("JobType") === "transport") {
            result = await Jobin.Job.sendToTransit(
                [rowArray[index].transportation.get("ID")],
                jober
            );
        } else if (job.get("JobType") === "fixed") {
            result = await Jobin.Jober.asignToJob(jober, rowArray[index].job);
        } else {
            result = await Jobin.Jober.asignToJob(jober, rowArray[index].job);
        }
        await addToOtherBill(
            jober,
            rowArray[index].fixedInfo,
            rowArray[index].transportation
        );
        if (result.status === "ok") {
            const newArray = rowArray.filter((_, i) => i !== index);
            setRowArray(newArray);
            await saveBill(newArray);
        } else {
            alert(result.result);
        }
        setLoading(false);
    };

    return (
        <React.Fragment>
            {loading && (
                <section style={style.loadingContainer}>
                    <Loading />
                </section>
            )}
            {deleteModalVisible && (
                <Modal
                    title={"Eliminar servicio de factura"}
                    subtitle={
                        "Si no hay que pagar este servicio al woller, es posible que no lo haya finalizado todavía" +
                        " o que haya sido otro woller el que lo ha realizado. Qué quieres hacer?"
                    }
                    onClose={() => setDeleteModalVisible(false)}
                    loading={modalLoading}
                    buttonText={"Reabrir servicio"}
                    onClick={() => reopenRow(index)}
                    secondButtonText={"Cambiar woller"}
                    secondOnClick={() => {
                        setDeleteModalVisible(false);
                        setJoberModalVisible(true);
                    }}
                ></Modal>
            )}
            {joberModalVisible && (
                <SelectJoberModal
                    isVisible={joberModalVisible}
                    onClose={() => setJoberModalVisible(false)}
                    loading={modalLoading}
                    asignJober={jober => asignJober(index, jober)}
                    job={rowArray[index].job}
                />
            )}
            {addModalVisible && (
                <JobSearchModal
                    onClose={() => setDeleteModalVisible(false)}
                    loading={modalLoading}
                    onJobSelected={({ job, fixedInfo, transportation }) =>
                        addRow({ job, fixedInfo, transportation })
                    }
                />
            )}
            <NavBar
                history={props.history}
                location={props.location}
                title={"Editar factura"}
                //buttonView={renderNavBarButtons}
            />
            <div style={props.style.container}>
                <JoberPayoffEditor
                    payment={payment}
                    billingInfo={billingInfo}
                    rowArray={rowArray}
                    totalPrice={totalPrice}
                    onBillingInfoChanged={setBillingInfo}
                    showAdd={() => setAddModalVisible(true)}
                    addRow={addRow}
                    editRow={editRow}
                    deleteRow={index => {
                        setIndex(index);
                        setDeleteModalVisible(true);
                    }}
                    enableEdit={enableEdit}
                />
            </div>
        </React.Fragment>
    );
}
