import React from "react";
import SearchNav from "../../components/verticals/SearchNav";
import ResultDashboard from '../../components/verticals/ResultDashboard';



export default function Search(props) {
    return (
        <React.Fragment>
            <SearchNav history={props.history} location={props.location} />
            <ResultDashboard history={props.history} location={props.location}  />
        </React.Fragment>
    );
}
