import React, {useState, useEffect} from 'react';
import GenericPdfDownloader from "./GenericPdfDownloader";
import "../../styles/pdfLM.css"
import { getLMPDFData } from "../../utils/ParserUtilities";
import {formatDateWhitoutHour} from '../../utils/formatter';

const PdfLM = () => {
    const [jobData, setJobData] = useState({});

    useEffect(()=> {
        getData();
    },[]);

    const getData = async () => {
        let url = window.location.href.split('pdflm/');
        let newJobData = await getLMPDFData(url[1]);
        setJobData(newJobData);
    }

    return(
        <div className='pdflm-wrapper'>
            <GenericPdfDownloader 
                downloadFileName="CustomPdf" 
                rootElementId="testId" 
            />
            <div id="testId" className='pdf-lm'> 
                <div className='lm-images-wrapper'>
                    <img className='lm-logo' src={require("../../assets/pdfLM/LM-Logo.jpeg")}/>
                    <img className='lm-logo' src={require("../../assets/wolly-logo-billing.png")}/>
                </div>

                <div className='lm-box-wrapper'>
                    <p className='lm-green-title'>Número de Servicio</p>
                    <p className='lm-white-title mr-20'>{jobData.order ? jobData.order : ""}</p>
                    <p className='lm-parte'>PARTE DE FINALIZACIÓN DEL SERVICIO</p>
                </div>
                <div className='lm-box-wrapper'>
                    <p className='lm-green-title'>Orden Trabajo</p>
                    <p className='lm-white-title mr-20'>{jobData.jobDescription ? jobData.jobDescription.substring(0,11) : ""}</p>
                    <p className='lm-green-title'>Fecha de emisión</p>
                    <p className='lm-white-title'>{jobData.imagesNotification ? formatDateWhitoutHour(jobData.imagesNotification.date.toString()) : ""}</p>
                </div>

                <p className='lm-big-green-title'>Datos del cliente</p>
                <div className='lm-box-wrapper'>
                    <p className='lm-green-title'>Nombre del cliente</p>
                    <p className='lm-white-title mr-20'>{jobData.clientName ? jobData.clientName : ""}</p>
                    <p className='lm-green-title'>Telf del cliente</p>
                    <p className='lm-white-title'>{jobData.clientPhone ? jobData.clientPhone : ""}</p>
                </div>
                <div className='lm-box-wrapper'>
                    <p className='lm-green-title'>Dirección</p>
                    <p className='lm-white-big-title'>{jobData.jobAddress ? jobData.jobAddress : ""}</p>
                </div>

                <p className='lm-big-green-title'>Descripción de los trabajos</p>
                <p className='lm-white-ultra-title'>{jobData.jobDescription ? jobData.jobDescription : ""}</p>

                <p className='lm-big-green-title'>Conforme cliente</p>
                <div className='lm-client-wrapper'>
                    <p className='lm-little-p'>El objeto de la firma de este parte es dejar constancia de la conformidad
                        del Cliente a la ejecución de los servicios contratados con Leroy Merlin
                        reflejados en este documento en las fechas asimismo indicadas, sin
                        perjuicio de las posibles observaciones que se puedan realizar en el
                        momento de su firma. En ningún caso la firma de este documento
                        sustituye ni limita los derechos reconocidos al Cliente en virtud de la
                        normativa de consumidores y usuarios vigente (Real Decreto Ley /2021,
                        de 27 de abril y otras leyes complementarias o norma que lo sustituya).</p>
                        <img src={jobData.imagesNotification ? jobData.imagesNotification.signature ? jobData.imagesNotification.signature : "" : ""} className="lm-photo-signature"/>
                </div>

                <p className='lm-big-green-title'>Conforme Profesional </p>
                <div className='lm-woller-wrapper'>
                    <p className='lm-woller-p'>Fecha de firma: {jobData.imagesNotification ? formatDateWhitoutHour(jobData.imagesNotification.date.toString()) : ""}</p>
                    <img src={jobData.imagesNotification ? jobData.imagesNotification.signaturePro ? jobData.imagesNotification.signaturePro : "" : ""} className="lm-photo-signature"/>
                </div>
                <p className='lm-footer-bold-p'>Protección de datos de caracter personal</p>
                <p className='lm-footer-p'>
                    RESPONSABLE DE TRATAMIENTO: Leroy Merlin España, S.L.U. ("Leroy Merlin") / FINALIDAD: Verificar, evidenciar y documentar la completa
                    ejecución de los servicios contratados por él Cliente en Leroy Merlin / LEGITIMACIÓN: Ejecución de un contrato solicitado por el Cliente /
                    DESTINATARIOS: No se comunicarán datos a terceros, salvo a prestadores de servicios de LEROY MERLIN autorizados. (Encargados del
                    Tratamiento ) o en el supuesto de una obligación legal | / DERECHOS: Acceso, rectificación, supresión y oposición, entre otros, en la
                    Dirección: privacidad@leroymerlin.es / INFORMACIÓN ADICIONAL: Puede consultar nuestra Politica de Privacidad en: https://wwww.leroymerlin.es/politica-de-privacidad
                </p>

            </div>
        </div>
    )
}

export default PdfLM;

