const monthName = [
    {
        id: "01",
        name: "Enero"
    },
    {
        id: "02",
        name: "Febrero"
    },
    {
        id: "03",
        name: "Marzo"
    },
    {
        id: "04",
        name: "Abril"
    },
    {
        id: "05",
        name: "Mayo"
    },
    {
        id: "06",
        name: "Junio"
    },
    {
        id: "07",
        name: "Julio"
    },
    {
        id: "08",
        name: "Agosto"
    },
    {
        id: "09",
        name: "Septiembre"
    },
    {
        id: "10",
        name: "Octubre"
    },
    {
        id: "11",
        name: "Noviembre"
    },
    {
        id: "12",
        name: "Diciembre"
    }
];
const d = new Date();
d.setDate(1);
let tempArr = [];
for (let i = 0; i <= 11; i++) {
    tempArr.push({
        id: `${monthName[d.getMonth()].id}-${d.getFullYear()}`,
        name: `${monthName[d.getMonth()].name} ${d.getFullYear()}`,
        selected: i === 0
    });
    d.setMonth(d.getMonth() - 1);
}

export const monthArr = tempArr;

