import React, { useState, useEffect } from 'react'
import Jobin from "jobin-client";
import ServiceItem from "../job/generic/ServiceItem";
import { Modal } from 'jobin-ui-components'

const VerticalModal = (props) => {
    let { title } = props
    let [ items, setItems ] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const [childrenLoaded, setChildrenLoaded] = useState([]);

    const loaded = dataFromChild => {
        setChildrenLoaded(childrenLoaded =>
            childrenLoaded.concat(dataFromChild)
        );
    };

    useEffect(() => {

        let didCancel = false;

        async function getGuilds() {
            try {
                const result = await Jobin.Guild.getFathers();

                if (result.status === "ok") {
                    if (!didCancel) {
                        setItems(result.result);
                        setIsLoaded(true);
                    }
                } else {
                    if (!didCancel) {
                        setIsLoaded(false);
                    }
                }
            } catch (error) {
                if (!didCancel) {
                    setIsLoaded(false);
                }
            }
        }

        if (childrenLoaded.length === items.length - 1)
            console.log('isChildrenLoaded true')
        else getGuilds();

        // unmount
        return () => {
            didCancel = true;
        };

    },  [childrenLoaded])
    return (
        <Modal
			title={title}
			subtitle={props.subtitle}
            onClose={props.onClose}
		>
            <section>
                <div style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent:'flex-end', marginTop: 8}}>
                    {isLoaded && [
                        items.map(service => {
                            return service.id === "8y4kFU8eiA" ||
                                service.id ===
                                    "Z7HBY4VaNi" ? null : (
                                <ServiceItem
                                    key={service.id}
                                    service={service}
                                    loaded={loaded}
                                />
                            );
                        })
                    ]}
                </div>
            </section>
        </Modal>
    )
}

export default VerticalModal;