import React, { useEffect, useState } from "react";

import "../../styles/multiSelect.css";

export default function SingleSelect({title,values,preSelected,handleSelected}) {
   const [valuesSelected,setValuesSelected] = useState(null)
   const [showList,setShowList] = useState(false)
   
    useEffect(()=>{
        if(preSelected){
            handleValueSelected()
        }
    },[])
    const handleValueSelected = (selectedValue) => {
        if(preSelected && !selectedValue){
            setValuesSelected(preSelected)
        }else if(selectedValue){
            setValuesSelected(selectedValue)
            handleSelected(selectedValue.id)
        }else{
            setValuesSelected(null)
            handleSelected(null)
        }
    }

    return (
        <div className="multiSelectContainer">
            <p className="titleMultiSelect">{title}</p>
            <div   className="multiSelect" onClick={()=>{setShowList(!showList)}} >
                <p className="selectedOptions">{ valuesSelected ? valuesSelected.name+" ":"Selecciona una opcion"}</p>
                <span className={showList ? "multiSelectIcon active":"multiSelectIcon"}></span>
            </div>
            <div className={showList ? "optionList active":"optionList"}>
                {values?.map((value)=>{
                    if(value.id != "all"){
                        return (
                            <div key={value.id} className={valuesSelected && valuesSelected.id == value.id ? "selected option":"option"} onClick={()=>handleValueSelected(value)}> {value.name} </div>
                        )
                    }
                })}
            </div>
        </div>
    );
}
