import React from "react";
import { useStateValue } from "../../../state/context";

import { Loading } from "jobin-ui-components";
import JobCell from "../../JobCell";
import JobSearchNav from "./JobSearchNav";

import "../../../styles/search/detail/job-detail-history.css";

export default function Jobs(props) {
    
    const [{ businessDetails }] = useStateValue();
    const [data] = useStateValue();
    const goToJob = id => {
        props.history.push({
            pathname: "/job/" + id,
            state: {
                inner: true
            }
        });
    };

    return (
        <div className="businessDetailColumn">
            <JobSearchNav />
            {businessDetails.jobsLoading ? (
                <Loading />
            ) : businessDetails.jobs.length <= 0 ? (
                <div>Nada que mostrar</div>
            ) : (
                businessDetails.jobs.map((item, index) => {
                    return (
                        <JobCell
                            key={"job" + index}
                            job={item}
                            permissions={data.user.permissions}
                            onClick={() => goToJob(item.id)}
                            style={{marginTop: 24, marginRight: 24, marginLeft: 24}}
                        />
                    );
                })
            )}
        </div>
    );
}
