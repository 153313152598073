
import React from 'react';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import "../../styles/pdfLM.css";

const GenericPdfDownloader = ({rootElementId , downloadFileName}) => {

    const downloadPdfDocument = () => {
        const input = document.getElementById(rootElementId);
        html2canvas(input)
            .then((canvas) => {
                const imgWidth = 210;
                const pageHeight = 295;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;
                heightLeft -= pageHeight;
                const doc = new jsPDF('p', 'mm');
                doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                    heightLeft -= pageHeight;
                }
                doc.save('Downld.pdf');
            })
    }

    return <button className='pdf-button' onClick={downloadPdfDocument}>Descargar</button>

}

export default GenericPdfDownloader;