import React from "react";

export default function ExtraInfoCell(props) {
    const style = {
        container: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            marginTop: 8,
            marginBottom: 8,
            alignItems: "center"
        },
        contentContainer: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            marginRight: 16
        },
        nameLabel: {
            display: "flex",
            flex: 1
        },
        priceLabel: {
            textAlign: "right",
            fontSize: 12
        },
        totalPriceLabel: {
            textAlign: "right",
            fontWeight: "bold"
        }
    };

    return (
        <section style={style.container}>
            <div style={style.contentContainer}>
                <label style={style.nameLabel}>
                    {"x" + props.extra.units + " " + props.extra.name}
                </label>
            </div>
            <div style={style.contentContainer}>
                <label style={style.totalPriceLabel}>
                    {(props.extra.pricePerUnit * props.extra.units).toFixed(2) + "€"}
                </label>
                <label style={style.priceLabel}>
                    {(props.extra.pricePerUnit * props.extra.units*1.21).toFixed(2) + "€ con IVA"}
                </label>
            </div>
        </section>
    );
}
