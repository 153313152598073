import React, { useState } from "react";
import { useStateValue } from "../../state/context";

import Jobin from "jobin-client";

import MainModal from "./MainModal";
import Button from "../button/Button";

import { Input } from "jobin-ui-components";

const FiscalModal = props => {
    const style = {
        container: {
            display: "flex",
            flex: 1,
            marginTop: 12
        },
        inputView: {
            display: "flex",
            flex: 1,
            flexDirection: "column"
        }
    };

    const [{ job }, dispatch] = useStateValue();

    const [fiscalName, setFiscalName] = useState(
        (props.billingInfo && props.billingInfo.fiscalName) ||
            (props.billingInfo && props.billingInfo.get
                ? props.billingInfo.get("FiscalName")
                : null)
    );
    const [fiscalNameError, setFiscalNameError] = useState(false);
    const [identityNumber, setIdentityNumber] = useState(
        (props.billingInfo && props.billingInfo.identityNumber) ||
            (props.billingInfo && props.billingInfo.get
                ? props.billingInfo.get("IdentityNumber")
                : null)
    );
    const [identityNumberError, setIdentityNumberError] = useState(false);
    const [fiscalAddress, setFiscalAddress] = useState(
        (props.billingInfo && props.billingInfo.fiscalAddress) ||
            (props.billingInfo && props.billingInfo.get
                ? props.billingInfo.get("FiscalAddress")
                : null)
    );
    const [fiscalAddressError, setFiscalAddressError] = useState(false);
    const [fiscalCity, setFiscalCity] = useState(
        (props.billingInfo && props.billingInfo.fiscalCity) ||
            (props.billingInfo && props.billingInfo.get
                ? props.billingInfo.get("FiscalCity")
                : null)
    );
    const [fiscalCityError, setFiscalCityError] = useState(false);
    const [fiscalPostalCode, setFiscalPostalCode] = useState(
        (props.billingInfo && props.billingInfo.fiscalPostalCode) ||
            (props.billingInfo && props.billingInfo.get
                ? props.billingInfo.get("FiscalPostalCode")
                : null)
    );
    const [fiscalPostalCodeError, setFiscalPostalCodeError] = useState(false);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    const checkFields = async () => {
        if (
            fiscalName &&
            identityNumber &&
            fiscalAddress &&
            fiscalCity &&
            fiscalPostalCode
        ) {
            setSaveBtnDisabled(false);
        }
    };

    const saveBillingInfo = async () => {
        setLoading(true);
        if (props.business) {
            let { status, result } = await Jobin.Business.setBillingInfo(
                props.business,
                fiscalName,
                identityNumber,
                fiscalAddress,
                fiscalPostalCode,
                fiscalCity
            );
            if (status === "ok") {
                dispatch({
                    type: "BUSINESS_DETAIL",
                    billing: result
                });

                setLoading(false);
                props.success(result);
            } else {
                alert(result);
                setLoading(false);
            }
        } else {
            let { status, result } = await Jobin.Client.createBillingInfo(
                fiscalName,
                identityNumber,
                fiscalAddress,
                fiscalPostalCode,
                fiscalCity,
                props.user ? props.user.id : job.currentUser.id
            );
            if (status === "ok") {
                dispatch({
                    type: "SET_FISCAL_INFO",
                    id: result.id,
                    fiscalName: result.get("FiscalName"),
                    identityNumber: result.get("IdentityNumber"),
                    fiscalAddress: result.get("FiscalAddress"),
                    fiscalPostalCode: result.get("FiscalPostalCode"),
                    fiscalCity: result.get("FiscalCity")
                });
                setLoading(false);
                props.success(result);
            } else {
                alert(result);
                setLoading(false);
            }
        }
    };

    const validateFiscalName = input => {
        if (input !== "") {
            setFiscalName(input);
            setFiscalNameError(false);
            checkFields();
        } else {
            setFiscalName(null);
            setFiscalNameError(true);
            setSaveBtnDisabled(true);
        }
    };

    const validateIdentityNumber = input => {
        if (input !== "") {
            setIdentityNumber(input);
            setIdentityNumberError(false);
            checkFields();
        } else {
            setIdentityNumber(null);
            setIdentityNumberError(true);
            setSaveBtnDisabled(true);
        }
    };

    const validateFiscalAddress = input => {
        if (input !== "") {
            setFiscalAddress(input);
            setFiscalAddressError(false);
            checkFields();
        } else {
            setFiscalAddress(null);
            setFiscalAddressError(true);
            setSaveBtnDisabled(true);
        }
    };

    const validateFiscalCity = input => {
        if (input !== "") {
            setFiscalCity(input);
            setFiscalCityError(false);
            checkFields();
        } else {
            setFiscalCity(null);
            setFiscalCityError(true);
            setSaveBtnDisabled(true);
        }
    };

    const validateFiscalPostalCode = input => {
        if (/^\d{5}$/.test(input)) {
            setFiscalPostalCode(input);
            setFiscalPostalCodeError(false);
            checkFields();
        } else {
            setFiscalPostalCode(null);
            setFiscalPostalCodeError(true);
            setSaveBtnDisabled(true);
        }
    };

    return (
        <MainModal
            title={"Información de facturación"}
            subtitle={
                "Introduce la información fiscal que es necesaria que aparezca en la factura."
            }
            isVisible={props.isVisible}
            onClose={props.onClose}
            style={{ width: "500px" }}
        >
            <div style={style.container}>
                <div style={style.inputView}>
                    <Input
                        style={{ display: "flex", marginRight: 8 }}
                        badInput={fiscalNameError}
                        placeholder="Nombre"
                        onChange={e => validateFiscalName(e.target.value)}
                        defaultValue={fiscalName}
                    />
                    <span className={fiscalNameError ? "error" : "hidden"}>
                        Nombre fiscal requerido
                    </span>
                </div>
                <div style={style.inputView}>
                    <Input
                        style={{ display: "flex" }}
                        badInput={identityNumberError}
                        placeholder="NIF o CIF"
                        onChange={e => validateIdentityNumber(e.target.value)}
                        defaultValue={identityNumber}
                    />
                    <span className={identityNumberError ? "error" : "hidden"}>
                        Número de identidad no válido
                    </span>
                </div>
            </div>
            <div style={style.container}>
                <div style={style.inputView}>
                    <Input
                        style={{ display: "flex" }}
                        badInput={fiscalAddressError}
                        placeholder="Dirección de facturación"
                        onChange={e => validateFiscalAddress(e.target.value)}
                        defaultValue={fiscalAddress}
                    />
                    <span className={fiscalAddressError ? "error" : "hidden"}>
                        Dirección de facturación no válida
                    </span>
                </div>
            </div>
            <div style={style.container}>
                <div style={style.inputView}>
                    <Input
                        style={{ display: "flex", marginRight: 8 }}
                        badInput={fiscalPostalCodeError}
                        placeholder="Código postal"
                        onChange={e => validateFiscalPostalCode(e.target.value)}
                        defaultValue={fiscalPostalCode}
                    />
                    <span
                        className={fiscalPostalCodeError ? "error" : "hidden"}
                    >
                        Código postal no válido
                    </span>
                </div>
                <div style={style.inputView}>
                    <Input
                        style={{ display: "flex" }}
                        badInput={fiscalCityError}
                        placeholder="Ciudad"
                        onChange={e => validateFiscalCity(e.target.value)}
                        defaultValue={fiscalCity}
                    />
                    <span className={fiscalCityError ? "error" : "hidden"}>
                        Ciudad no válida
                    </span>
                </div>
            </div>
            <Button
                style={{ marginTop: 16 }}
                disabled={saveBtnDisabled}
                buttonText="Guardar cambios"
                onClick={saveBillingInfo}
                loading={loading}
            />
        </MainModal>
    );
};

export default FiscalModal;
