import React,  { useState, useEffect } from "react";
import {addJobsImages,removeJobsImages} from "../../../utils/ParserUtilities"
import DropZone from "../../DropZone"
import { useStateValue } from "../../../state/context";

import "../../../styles/Users/wollers/description.css";

export default function Photos({wollerDetails}) {
    const [{user}, dispatch] = useStateValue();
    const [images,setImages] = useState()
    useEffect(()=>{
        setImages(wollerDetails?.get("JobsImages"))
    },[wollerDetails])

    const  getBase64 = file => {
        return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve({fileName:file.name,base64:baseURL});
            };
        });
    };

    const  handleFileInputChange = (file)=> {
        
        getBase64(file).then(async (result)=> {
            let base64= result.base64.split("base64,")[1]
            let name= result.fileName.replace(/\s/g,"_")
           let results = await addJobsImages({wollerId:user.goldId,photos:{name:name,base64:base64}})
           dispatch({
            type:"USER_DETAILS_SET_INFO",
            wollerData:results
           })
        })
        .catch(err => {
          console.error(err);
        });
    };
    const removeImage = async(file)=>{
        let results = await removeJobsImages({wollerId:user.goldId,file:file})
           dispatch({
            type:"USER_DETAILS_SET_INFO",
            wollerData:results
           })
    }
    return (
        <div className="photosContainer userDetails">
                
            {images && images.length > 0 && images.map(image=>{
                return(
                    <div className="photos-wrapper">
                        <img className="individualPhoto" src={image.url()} onClick={()=>removeImage(image)} alt={image.name()}/>
                    </div>
                    )
                })}

                <DropZone promtImage={require("../../../assets/plus-solid.svg")}  className="individualPhoto" handleFile={handleFileInputChange} acceptedFormats={"image/*"}/>
        </div>
    )
}