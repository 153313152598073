import React from 'react';

import  MainModal  from './MainModal'
import { Button } from 'jobin-ui-components'

const AlertModal = (props) => {
    const style = {
       
        button: {
            padding: "12px 30px",
            borderRadius: "8px",
            backgroundColor: "var(--aqua-jobin-darker)",
            color: "var(--white)",
            boxShadow: "-1px 2px 7px -1px var(--aqua-jobin)",
            border: "none",
            fontFamily: "HurmeBold",
            letterSpacing: "1px",
            fontSize:"16px",
        },
        
    };

    return (
        <MainModal
            isVisible={props.isVisible}
			title={props.title}
			subtitle={props.subtitle}
            onClose={props.onClose}
            alert={props.classNamesModal ?false:true}
            center={false}
            classNamesModal={props.classNamesModal}
		>
            <section>
                <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent:'flex-end', marginTop: 20}}>
                    {props.secondButtonText &&
                        <Button
                            style={{marginRight: 8}}
                            buttonText = {props.secondButtonText}
                            onClick={props.secondOnClick}
                        />
                    }
                    <button className='button'
                     style={style.button}
                        onClick={props.onClick}
                    >{props.buttonText}</button>
                </div>
            </section>
        </MainModal>
    )
}

export default AlertModal;