import React , {useState,useEffect}from "react";
import MainModal from "./MainModal";
import '../../styles/modal/asukas-list.css';

const AsukasList = props => {

    const [optionSelected, setOptionSelected] = useState();


    const openpage =()=>{
        window.open(props.baseUrl + optionSelected, "_blank")
    }

    return (
        <MainModal
            med ={true}
            title={"Selecciona la promocion para crear el servicio"}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        >
            <div className="list-container">
                <select 
                value={optionSelected}
                    onChange={(event)=> setOptionSelected(event.target.value)}
                    style={{
                        width: '100%',
                        padding: '5px',
                        border: '1px solid #E3E3E3',
                        borderRadius: '4px'
                    }}
                >  
                    {props.promotion?.map((promo)=>{
                            return (<option key={promo.business} value={promo.business}>{promo.name}</option>)
                        })
                    }
                        
                </select>
                <button onClick={() => openpage()} className="button-go">
                    Ir
                </button>
                </div>
        </MainModal>
    );
};

export default AsukasList;