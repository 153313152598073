import React, {useState} from 'react';
import Jobin from 'jobin-client'

import { Input, Modal } from 'jobin-ui-components'

const AddweightModal = (props) => {

	const style = {
        inputContainer:{
            display: 'flex',
            flex: 1,
            marginTop: 16,
            marginBottom: 16
        },
        newPriceView:{
            display: 'flex',
            flex: 1,
            marginTop: 16,
            marginBottom: 16,
            justifyContent: 'center',
            fontSize: 20
        }
    }
    
    const [loading, setLoading] = useState(false);
    const [showNewPrice, setShowNewPrice] = useState(false);
    const [title, setTitle] = useState("Añadir peso");
    const [subtitle, setSubtitle] = useState("Añade más peso al transporte");
    const [value, setValue] = useState(null);
    const [newPrice, setNewPrice] = useState(null);
    const [valueOk, setValueOk] = useState(true);

    const onNumberChange = input => {
        if (input === "") {
            setValue(null);
            setValueOk(true);
        } else if (/^-?\d+$/.test(input) && props.currentWeight + Number(input) > 0) {
            setValue(Number(input));
            setValueOk(true);
        } else {
            setValue(null);
            setValueOk(false);
        }
    };

    const calculateNewPrice = async () =>{
        setLoading(true);
        const result = await Jobin.Job.getPrice(props.job, (Number(props.currentWeight) + Number(value)));
        if(result.status === 'ok'){
            const currentPrice = props.job.get("PriceMax");
            setNewPrice((result.result.price-currentPrice)*1.21);
            setTitle("Aumento de precio");
            setSubtitle("Al añadirse más peso el precio del transporte aumentará en:")
            setShowNewPrice(true);
        }
        else{
            alert(result.result);
        }
        setLoading(false);
    }

    const renderNewPriceView = () =>{
        return(
            <div style={style.newPriceView}>
                <label style={{ fontWeight: 600 }}>
                    {newPrice === 0
                        ? `  0.00 €`
                        :newPrice > 0
                        ? `+ ${newPrice.toFixed(2)} €`
                        : `- ${(-newPrice).toFixed(2)} €`}
                </label>
            </div>
        )
    }

    const goBack = () =>{
        setValue(null);
        setTitle("Añadir peso");
        setSubtitle("Añade más peso al transporte");
        setShowNewPrice(false);
    }

    const addWeight = () =>{
        props.addWeight(value, newPrice);
    }

    return (
        <Modal
			title={title}
			subtitle={subtitle}
            onClose={props.onClose}
            buttonText={"Siguiente"}
            onClick={showNewPrice ? addWeight : calculateNewPrice}
            disabled={!value}
            loading={loading}
            goBack={showNewPrice ? goBack : null}
		>
            {showNewPrice ? 
                renderNewPriceView()
            :
                <div style={style.inputContainer}>
                    <Input
                        style={{display: 'flex', flex: 1}}
                        placeholder={"0 Kg"}
                        onChange={(e) => onNumberChange(e.target.value)}
                        badInput={!valueOk}

                    />
                </div>
            }
        </Modal>
    )
}

export default AddweightModal;