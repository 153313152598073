import React from "react";

import number1 from "../../../assets/step-item-1.svg";
import number2 from "../../../assets/step-item-2.svg";
import number3 from "../../../assets/step-item-3.svg";

import "../../../styles/job/service-steps.css";

export default function SearchSteps() {
    return (
        <div id="service-steps" className="hidden-md hidden-sm hidden-xs">
            <div className="step-item">
                <img src={number1} alt="1" />
                <p className="title">Información básica</p>
                <p>Con unos pocos datos seremos capaces de presupuestar el servicio.</p>
            </div>
            <div className="step-item">
                <img src={number2} alt="2" />
                <p className="title">El cliente escoge</p>
                <p>
                    El cliente podrá escoger entre el precio
                    cerrado o recibir 3 presupuestos de nuestros Wollers.
                </p>
            </div>
            <div className="step-item">
                <img src={number3} alt="3" />
                <p className="title">Completa la solicitud</p>
                <p>
                    Por último solo tendrás que seguir los pasos para completar toda la solicitud.
                </p>
            </div>
        </div>
    );
}
