import moment from "moment";
import { getCookie } from "../utils/cookie";
import { businessTitles } from "../constants/businessTitles";
import { billingTitles } from "../constants/billing/billingTitles";
import { monthArr } from "../constants/months";
import { jobTypeArr } from "../constants/user/jobTypes";

const agent = JSON.parse(
    getCookie("agent") ? decodeURIComponent(getCookie("agent")) : null
);

export const initialState = {
    token: agent ? agent.token : undefined,
    general:{
        holdStandBy:false,
        agents:[],
        businesses:[],
        guilds:[],
        allGuilds:[],
        loaded:false,
        packs:[]
    },
    agent: agent,
    user: {
        isGoldWoller:false,
        isUserSubscription:false,
        isB2b:false,
        permissions: undefined,
        goldId:undefined,
        goldPhone:undefined,
        goldGuilds:[],
        goldBalance:0,
        business: undefined,
        isMediator:undefined,
        isConstructionCompany:undefined,
        promotionData:undefined
    },
    search: {
        currentPage:1,
        filters:[],
        actions:[],
        loading: false,
        limit: 100,
        idTypes: [],
        currentIdType:"lead",
        startDate: null,
        endDate: null,
        appointmentStart: null,
        appointmentEnd: null,
        provinces: [],
        guilds: [],
        levels: [],
        slots: [],
        jobStates: [],
        agents: [],
        businesses: [],
        budget:[],
        onlyIncidences:undefined,
        prevIDSearch:"",
        prevClientNameSearch:"",
        prevClientPhoneSearch:"",
        prevServiceNameSearch:"",
        count: 0,
        navCollapsed: false,
        pipedrive:true,
        services: [], // where the results goes
        findJobs:null
    },
    job: {
        history: null,
        location: null,
        match: null,
        guild: null,
        budget:null,
        store: {
            latitude: null,
            longitude: null,
            exists: false
        },
        transport: false,
        currentUser: "",
        fromLeadToFixed: false,
        leadToFixedId: "",
        breadCrumb: [
            {
                name: "Tipo de servicio",
                pathname: "/job"
            }
        ],
        category: {
            step: 1,
            categoryId: "",
            categoryType: "",
            subCategoryId: ""
        },
        locate: {
            step: 4,
            mapsKey: "AIzaSyDYwng9doYyVkIIyzYLX6crFuJLii07Cus",
            map: null,
            maps: null,
            area: null,
            center: {
                lat: 40.416782,
                lng: -3.703391
            },
            zoom: 12,
            address: "",
            address2: "",
            addressSelected: null,
            error: null,
            disabledBtn: true,
            postalCode: null
        },
        directionsService: null,
        directionsDisplay: null,
        weight: {
            amount: "",
            error: null,
            disabledBtn: true
        },
        price: {
            amount: null,
            error: null,
            disabledBtn: false,
            extraAmount: null,
            extrasArray: [],
            selectedOption: "fixed",
            buttonTitle: "Aceptar y continuar"
        },
        availability: {
            dateTo: null,
            error: null,
            disabledBtn: false,
            buttonTitle: "Continuar sin fecha"
        },
        date: {
            dateTransport: null,
            error: null,
            disabledBtn: false,
            buttonTitle: "Continuar sin fecha"
        },
        description: {
            step: 2,
            message: "",
            minChar: 40,
            maxChar: 1000,
            tooltip: "Faltan 40 caracteres",
            styleType: "primary",
            error: null,
            disabledBtn: true
        },
        picture: {
            step: 3,
            files: [],
            textBtn: "Continuar sin fotos"
        },
        urgency: {
            step: 4,
            priority: null,
            selectedOption: null,
            error: null,
            disabledBtn: true
        },
        signIn: {
            visible: false,
            step: 1,
            phoneModal: {
                areaCode: "+34",
                phone: "",
                contactMode: "chat",
                disabledBtn: false
            },
            nameModal: {
                name: "",
                email: "",
                disabledBtn: false
            }
        },
        billingInfo: {
            id: "",
            fiscalName: "",
            identityNumber: "",
            fiscalAddress: "",
            fiscalPostalCode: "",
            fiscalCity: "",
            visible: false
        },
        results: {
            leadId: null,
            transportId: null,
            fixedId: null,
            fixedDraftId: null,
            userPDF: null,
            jobinPDF: null,
            joberPDF: null,
            warehousePDF: null
        }
    },
    jobDetails: {
        job: null,
        fixedInfo: null,
        transportInfo: null,
        paymentsArray: [],
        historyArray: [],
        extrasArray: [],
        jobersArray: [],
        getPayments: null,
        getHistoryOfJob: null,
        paymentClientData: null,
        readMode: null
    },
    businessSearch: {
        itemsTitle: businessTitles,
        items: [],
        loading: false,
        levels: []
    },
    businessDetails: {
        business: null,
        billing: null,
        jobs: [],
        jobsLoading: false,
        bills: [],
        payoffs: [],
        billsLoading: false,
        billsTotal: 0,
        promotion:null,
        filters: {
            id: "",
            startDate: moment()
                .utc()
                .startOf("month"),
            endDate: moment()
                .utc()
                .endOf("day"),
            provinces: [],
            services: [],
            month: monthArr.find(month => month.selected)
        }
    },
    billingSearch: {
        itemsTitle: billingTitles,
        items: [],
        loading: false,
        startDate: moment()
            .utc()
            .startOf("month")
            .toDate(),
        endDate: moment()
            .utc()
            .endOf("day")
            .toDate(),
        businessIdArray: [],
        provinces: [],
        signArray: [],
        methodArray: [],
        typeArray: [],
        stateArray: [undefined]
    },
    userSearch: {
        idType: "",
        typeArray: [],
        itemsTitle: [],
        items: [],
        selected:{},
        loading: false,
        startDate:null,
        endDate: null,
        levels: null,
        distance: null,
        location: null,
        points: 0,
        leads: 0,
        guilds: [],
        isReaded:false,
        business: []
    },
    userDetails: {
        loading: true,
        reviews:null,
        wollerData:null,
        jobsLoading: false,
        wollerLocation:null,
        user: null,
        tasks: [],
        info: null,
        billingInfo: null,
        paymentMethods: [],
        payments: [],
        jobs: [],
        filters: {
            id: jobTypeArr.find(jobType => jobType.selected).id,
            startDate:undefined,
            endDate: undefined,
            provinces: [],
            services: [],
            serviceType:null
        },
        chat:[]
    },
   
    kpisDetail: {
        loading: false,
        filters: {
            startDate: moment()
                .utc()
                .startOf("day"),
            endDate: moment()
                .utc()
                .endOf("day"),
            provinces: [],
            guilds: [],
            business:[],
            jobTypes:[],
        },
        results:{
            dailyServices:null
            ,
            global:null,
            leads:null,
            fixed:null,
            dailyProfits:null,
            wolers:null,
            guilds:null,
            regions:null
        }
    },
    matchDetails: {
        loading: false,
        startDate:null,
        endDate: null,
        startServiceDate:null,
        endServiceDate: null,
        matchTitles:[],
        business:[],
        agents:[],
        provinces:[],
        services:[],
        currentPage:1,
        limit: 100,
        count: 0,
    },
    agentsSearch:{
        refresh:false,
        searchKeyword:""
    },
    crmNotifications: {
        showMenu: false,
        notifications: []
    },
    currentSession:{
        loaded:false,
        wollerData:null,
        wollerGuilds:null,
        allowedFilters:{},
        allowedActions:{},
        allowedData:{},
        showMoneyModal:undefined,
        certificationState:undefined,
        contractor:undefined,
        marketplaceBaseUrl:undefined
    },
    explore: {
        distance:50,
        loading: true,
        startDate: null,
        endDate: null,
        provinces: [],
        guilds: [],
        count: 0,
        services: [], // where the results goes
    },
};
