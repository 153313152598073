import React, { useEffect, useState } from "react";
import MainModal from "./MainModal";
import logo from "../../assets/icons/isotipo-negro.png"
import dispat from "../../assets/dispatchingBanner.png"
import {Loading} from 'jobin-ui-components'
import Lottie from 'react-lottie'
import * as cardSpinnerData from "../../assets/animations/button-loading.json";
import FilterMultipleSelection from "../filter/FilterMultipleSelection";
import FilterSingleSelection from "../filter/FilterSingleSelection";

import { getProvinceArrEsp, provinceArrEsp } from "../../constants/provinces";
import fetchBusinesses from "../../constants/businesses";
import fetchServices from "../../constants/services";
import { elementOf } from "../../utils/formatter";


import {getAgentsByParams,getAssignRules,createAssignRules,setAssignRules,deleteAssignRules,updatePriorities} from "../../utils/ParserUtilities"

const DispatModal = props => {
   
    const [agents, setAgents] = useState([]);
    const [rules, setRules] = useState([]);
    const [agentName, setAgentName] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingRule, setLoadingRule] = useState([]);
    const [services, setServices] = useState([]);
    const [businesses, setBusinesses] = useState([]);
    

    useEffect(()=>{
        async function  fetchAsync(){
         const Services_ = await fetchServices()
         const Businesses_ = await fetchBusinesses()
         
           let agents_ = await getAgentsByParams("Department","Ops")
           agents_ = agents_.map((agent)=>{
                return {
                    id:agent.id,
                    name: `${agent.get("Name")} ${agent.get("Lastname")}`,
                    selected:false,
                   
                }
            });
           let rules_ = await getAssignRules();
           let rulesPromise_ = rules_.map(async (rule)=>{
               let agent_ = rule.agent
              
               return {
                id:rule.id,
                agent:agent_ ? {id:agent_?.id,name:`${agent_?.get("Name")} ${agent_?.get("Lastname")}`}:null,
                provinces:rule.provinces,
                guilds:rule.guilds,
                business:rule.business,
                isActive:rule.isActive,
                selectableProvinces:preProcessData(rule.provinces,getProvinceArrEsp()),
                selectableServices: preProcessData(rule.guilds ,Services_),
                SelectableBusiness: preProcessData(rule.business ,Businesses_)
                
               }
           })
           
            rules_ =  await Promise.all([...rulesPromise_]).then((rule)=>{
                return rule
            })
            
            setRules(rules_)
                       
           let loadingsRules = Array(rules_.length).fill(false)
           let AgentNames = rules_.map((rule)=>{
               if(rule.agent != null){
                   let agent = rule.agent
                   return agent.name
               }else{
                   return "Agentes"
               }
           })
           setServices(Services_)
            setBusinesses(Businesses_)
           setAgentName(AgentNames)
           setAgents(agents_)
           setRules(rules_)
           setLoadingRule(loadingsRules)
           setLoading(false)
        }
        fetchAsync()
    },[])

    useEffect(()=>{
        parseAgentName()

    },[rules])
 
    

   const styles = {
       select: {
            width: "200px",
            height: "35px",
            padding: "5px",
            borderRadius: "5px",
            border: "transparent",
            boxShadow: "rgb(0 0 0 / 30%) 0px 0px 10px 3px",
            margin: "15px",
        },
        indexList:{
            borderRadius: "50px",
            background: "black",
            padding: "3px 9px",
            color: "var(--coral)",
            marginRight:"20px"
        },
        indexListTwoDigits:{
            borderRadius: "50px",
            background: "black",
            padding: "3px 4.5px",
            color: "var(--coral)",
            marginRight:"20px",
            width: "26px"
        },
        subTitle:{
            fontWeight: "700", 
        },
        noRecordsDiv:{
            margin:"50px 0"
        },
        noRecordsTitle:{
            fontWeight: "700", 
            fontSize: "18px",
        },
        activateButton:{
            height: "35px ",
            width: "150px ",
            backgroundColor:" black ",
            border: "2px solid black  ",
            color:"var(--coral) ",
            borderRadius: "8px",
            transition: "all 0.5s",
            cursor:"pointer"

        },
        activatedButton:{
            padding: "0px",
            width: "150px",
            backgroundColor:"#1536ff",
            color:"white",
            height: "35px",
            borderRadius: "5px",
            border: "transparent",
            boxShadow: "rgb(0 0 0 / 30%) 0px 0px 10px 3px",
            transition: "all 0.5s",
            cursor:"pointer"

        },
        addRule:{
            color:"#3543FF",
            cursor:"pointer"
        },
        addRuleSimbol:{
            color: "white",
            background: "black",
            padding: "3px 6px",
            borderRadius: "50px",
            fontWeight: 100,
            fontSize: "11px",
            cursor:"pointer"
        },
        deleteRuleSimbol:{
            color: "#fff",
            background: "red",
            padding: "2px 8px",
            borderRadius: "50px",
            fontWeight: "bold",
            fontSize: "14px",
            cursor:"pointer",
            marginLeft: "15px",
            
        },
        buttonFilter:{
            background:"white",
            padding:"10px",
            width:"175px",
            border: "#0000",
            boxShadow: "rgb(0 0 0 / 30%) 0px 0px 10px 3px",
            color:"#000"
        },
        rulesContainer:{
            padding:" 10px 0",
            overflowY: "auto",
            maxHeight: "570px",
            marginTop: "10px",
            minHeight:"580px"
        }

    }
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: cardSpinnerData.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    const preProcessData= (selectedData_,allData_)=>{
        
        if(selectedData_?.length >0 && allData_.length >0 ){
            const arr = allData_.map(data => {
                if(data.id != "all"){
                    return {
                        id: data.id,
                        name: data.name,
                        selected:
                            elementOf(data.id, selectedData_)
                    };
                }
            }).filter(data=>data);
            return arr // array con los datos segun filtro si estan seleccionados o no  
        }else{
            let arr = allData_.map(data => {
                if(data.id != "all"){
                    return data
                }
            }).filter(data=>data);
           
            return arr // array con los datos segun filtro si estan seleccionados o no 
        }
        
    }
    const parseAgentName =()=>{
        let rules_ = rules
        let AgentNames = rules_.map((rule)=>{
            if(rule.agent != null){
                let agent = rule.agent
                return agent.name
            }else{
                return "Agentes"
            }
        })
        setAgentName([...AgentNames])
    }
    const preProcessAgent= (agentId, allData_)=>{
        if(agentId && allData_.length >0 ){
            const arr = allData_.map(data => {
                return {
                    id: data.id,
                    name: data.name,
                    selected:
                        elementOf(data.id, [agentId.id])
                };
            });
            return arr // array con los datos segun filtro si estan seleccionados o no 
        }else{
            const arr = [...allData_]
            return arr // array con los datos segun filtro si estan seleccionados o no 
        }
    }
   
    const changeRule = async (dataSelected,itemIndex,type)=>{
        let isLoadingRule = loadingRule 
        isLoadingRule[itemIndex] = true
        setLoadingRule([...isLoadingRule]) 

        let newData = Array.isArray(dataSelected) && dataSelected.length > 1 ? dataSelected.map((data)=> data.id):  Array.isArray(dataSelected) && dataSelected.length == 1  ?[ dataSelected[0].id]:dataSelected

        let currentData = rules[itemIndex]
        
        switch (type) {
            case "province":
                    currentData.provinces= newData
                break;
            case "service":
                    currentData.guilds= newData
                break;
            case "businesses":
                    currentData.business= newData
                break;
            case "agent":
                    currentData.agent= {id:newData.id,name:newData.name}
                    let AgentNames = agentName
                    AgentNames[itemIndex] =dataSelected.name
                    setAgentName([...AgentNames]) 

                break;
        
            default:
                break;
        }
        let allRules = rules
        allRules[itemIndex]= currentData
        setRules(allRules)
        let result = await setAssignRules(currentData.id,currentData.agent?.id,currentData.provinces, currentData.guilds, currentData.business)
        if(result){
            isLoadingRule[itemIndex] = false
            setLoadingRule([...isLoadingRule]) 
        }
        
    }
    const deleteRule = async (ruleID,index)=>{
        let isLoadingRule = loadingRule 
        isLoadingRule[index] = true
        setLoadingRule([...isLoadingRule]) 
        let confirmation = window.confirm("¿Estas seguro que deseas eleminar esta regla?")
        if(confirmation){
            let result = await deleteAssignRules(ruleID)
            var rules_ = rules
            let isLoadingRule = loadingRule 
           
            rules_ = rules_.filter((rule_,index_)=> index !== index_)
            isLoadingRule = isLoadingRule.filter((loadingRule,index_)=> index !== index_)
            setLoadingRule([...isLoadingRule]) 
            setRules( rules_)
            updatePriorities(rules_.map((rule)=> rule.id))
           
        }
        
    }
    const createRule = async ()=>{
        setLoading(true)
        let newRule = await createAssignRules(rules.length + 1)
       if(newRule){
           newRule =   {
               id:newRule.id,
               agent:null,
               provinces:newRule.provinces,
               guilds:newRule.guilds,
               business:newRule.business,
               isActive:newRule.isActive,
               selectableProvinces:provinceArrEsp,
               selectableServices: [...services],
               SelectableBusiness: [...businesses],
               
            }
            setRules([...rules,newRule])
           
            setLoading(false)
        }else{
            console.error(newRule)
            setLoading(false)
            alert("Hubo un problema al crear la nueva regla")

       }
    }
    const activate = async (ruleIndex)=>{
        let rules_ = rules
        let isLoadingRule = loadingRule 
        isLoadingRule[ruleIndex] = true
        rules_[ruleIndex].isActive = !rules_[ruleIndex].isActive
        setLoadingRule([...isLoadingRule]) 
        let currentRule = rules_[ruleIndex]
        let ruleEdited = await setAssignRules(currentRule.id, null,null ,null,null,currentRule.isActive)
        setRules([...rules_])
        isLoadingRule[ruleIndex] = false
        setLoadingRule([...isLoadingRule]) 
        if(!ruleEdited){
      
            setLoading(false)
            console.error(ruleEdited)
            alert("Hubo un problema al activar esta regla")
        }
    }
    

    return (
        <MainModal
            isVisible={props.isVisible}
            powered={logo}
            banner={dispat}
            onClose={props.onClose}
            bannerStyle={{width: "300px",height: "40px"}}
            poweredStyle={{width:40,height:40}}
            super={true}
        >
            <span style={styles.subTitle}>Asigna a un agente que concuerde con las opciones seleccionadas:</span>
            
       {   loading ?  (
            <section className=" display-flex flex-align-center flex-justify-center">
                <Loading />
            </section>
        ):(<>
                <div style={styles.rulesContainer}>
                {rules.length > 0 ? rules.map((rule,index)=>{
                    return(
                        <div  style={{ marginTop: "20px"}} className=" display-flex flex-align-center "  key={"rule"+index}>
                            <span style={index >= 9 ? styles.indexListTwoDigits: styles.indexList}>{index+1}</span>

                            <FilterSingleSelection
                                style={{ marginRight: 30}}
                                styleMenu={{ maxHeight:"500px"}}
                                type="agent"
                                title={agentName[index]}
                                items={ preProcessAgent(rule.agent,agents)}
                                selectionChange={changeRule}
                                styleButton={styles.buttonFilter}
                                itemData={index}
                                idElement={rule.id}
                            />
                            <FilterMultipleSelection
                                style={{ marginRight: 30}}
                                styleMenu={{ maxHeight:"500px"}}
                                type="province"
                                title="Provincias"
                                items={rule.selectableProvinces}
                                selectionChange={changeRule}
                                styleButton={styles.buttonFilter}
                                itemData={index}
                                idElement={rule.id}
                                

                            />
                            <FilterMultipleSelection
                                style={{ marginRight: 30}}
                                styleMenu={{ maxHeight:"500px"}}
                                type="service"
                                title="Gremios"
                                items={rule.selectableServices}
                                selectionChange={changeRule}
                                styleButton={styles.buttonFilter}
                                itemData={index}
                                idElement={rule.id}
                                

                            />
                            <FilterMultipleSelection
                                style={{ marginRight: 40}}
                                styleMenu={{ maxHeight:"500px"}}
                                type="businesses"
                                title="Negocios"
                                items={rule.SelectableBusiness}
                                selectionChange={changeRule}
                                styleButton={styles.buttonFilter}
                                itemData={index}
                                idElement={rule.id}
                                
                            />
                            <button
                                style={loadingRule[index] ? styles.activateButton : rule.isActive ? styles.activatedButton:styles.activateButton}
                                onClick={()=>{activate(index)}}
                                >
                                    { loadingRule[index] ?  (<Lottie
                                            style={{height: 30, width: 80}}
                                            options={defaultOptions}
                                        />):rule.isActive ? "Activada":"Activar"}
                                    
                            </button>
                            <span onClick={()=>deleteRule(rule.id,index)} style={styles.deleteRuleSimbol}>&times;</span>
                                               
                        </div>
                    )
                }):
                    (<div style={styles.noRecordsDiv}>
                        <p  style={styles.noRecordsTitle}>No hay reglas definidas</p>
                    </div>)
                }

            
                </div>
                <div style={{ marginTop: "20px"}}>
                    <span onClick={createRule} style={styles.addRuleSimbol}>✚</span> <span onClick={createRule} style={styles.addRule}>añadir regla</span>
                </div>
            </>)}
        </MainModal>
    );
};

export default DispatModal;