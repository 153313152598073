import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { Link } from "react-router-dom";
import { useStateValue } from "../../state/context";
import { initialState } from "../../state/initialState";
import { getCookie } from "../../utils/cookie";
import StepControlls from "./generic/StepControlls";
import BreadCrumb from "./generic/BreadCrumb";
import PaymentMethodModal from "../../components/modal/PaymentMethodModal";
import FiscalModal from "../../components/modal/FiscalModal";
import Button from "../button/Button";
import ButtonEmpty from "../button/ButtonEmpty";
import ExtraInfoCell from "../search/detail/detailColumn/ExtraInfoCell";
import BusinessModal from "../modal/SelectBusinessModal";

import "../../styles/job/service-summary.css";
import { Modal } from "jobin-ui-components";

export default function ServiceCompleted(props) {
    let didCancel = false;

    const [data, dispatch] = useStateValue();
    const [business, setBusiness] = useState(null);
    const [categoryArray, setCategoryArray] = useState([]);
    const [draftLoading, setDraftLoading] = useState(false);
    const [publishLoading, setPublishLoading] = useState(false);
    const [buttonTitle, setButtonTitle] = useState("Publicar solicitud");
    const [showConfirmationReparationModal, setShowConfirmationReparationModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showBusinessModal, setShowBusinessModal] = useState(false);
    const [showFiscalModal, setShowFiscalModal] = useState(false);

    const savePaymentMethod = async (paymentMethod, input) => {
        if (!didCancel) {
            setShowPaymentModal(false);
            setShowConfirmationReparationModal(false);

            publishJob(
                paymentMethod,
                data.job.transport ? "transport" : data.job.fromLeadToFixed ? "leadToFixed" : "fixed",
                input
            );
        }
    };

    const publishJob = async (paymentMethod, jobType, emailForInvoice) => {

        if (!didCancel) {
            setPublishLoading(true);
            switch (jobType) {
                case "lead":
                    try {
                        const lead = await Jobin.Job.createLeadJob(
                            data.job.currentUser.id,
                            data.job.category.categoryId,
                            data.job.category.categoryType,
                            data.job.category.subCategoryId,
                            data.job.locate.center.lat,
                            data.job.locate.center.lng,
                            data.job.description.message,
                            data.job.picture.files.length > 0
                                ? data.job.picture.files
                                : undefined,
                            data.job.urgency.selectedOption,
                            "bi-web",
                            business ? business.id : undefined
                        );
                        if (lead.status === "ok") {
                            setPublishLoading(false);

                            dispatch({
                                type: "JOB_RESET",
                                job: initialState.job
                            });

                            dispatch({
                                type: "JOB_RESULTS_LEAD",
                                leadId: lead.result
                            });

                            data.job.history.push({
                                pathname: "/job/new/completed",
                                search: data.job.location.search,
                                state: {
                                    inner: true
                                }
                            });
                        } else {
                            alert(lead.result);
                            setPublishLoading(false);
                            setButtonTitle("Intentar de nuevo");
                        }
                    } catch (error) {
                        console.error(error);
                        alert("Error, intentar de nuevo.");
                        setPublishLoading(false);
                        setButtonTitle("Intentar de nuevo");
                    }
                    break;
                case "transport":
                    try {
                        const transport = await Jobin.Job.createTransportJob(
                            data.job.currentUser.id,
                            data.job.category.categoryId,
                            data.job.category.categoryType,
                            data.job.category.subCategoryId,
                            data.job.locate.center.lat,
                            data.job.locate.center.lng,
                            data.job.weight.amount,
                            data.job.price.amount,
                            data.job.description.message,
                            data.job.picture.files.length > 0
                                ? data.job.picture.files
                                : undefined,
                            paymentMethod,
                            "bi-web",
                            true,
                            3,
                            data.job.locate.address2 !== ""
                                ? `${data.job.locate.address} (${data.job.locate.address2})`
                                : data.job.locate.address,
                            business ? business.id : undefined,
                            data.job.date.dateTransport
                                ? new Date(data.job.date.dateTransport)
                                : undefined
                        );
                        if (transport.status === "ok") {
                            setPublishLoading(false);

                            dispatch({
                                type: "JOB_RESET",
                                job: initialState.job
                            });

                            dispatch({
                                type: "JOB_RESULTS_TRANSPORT",
                                transportId: transport.result.job,
                                userPDF: transport.result.userPDF,
                                jobinPDF: transport.result.jobinPDF,
                                joberPDF: transport.result.joberPDF,
                                warehousePDF: transport.result.warehousePDF
                            });

                            data.job.history.push({
                                pathname: "/job/new/completed",
                                search: data.job.location.search,
                                state: {
                                    inner: true
                                }
                            });
                        } else {
                            alert(transport.result);
                            setPublishLoading(false);
                            setButtonTitle("Intentar de nuevo");
                        }
                    } catch (error) {
                        console.error(error);
                        alert("Error, intentar de nuevo.");
                        setPublishLoading(false);
                        setButtonTitle("Intentar de nuevo");
                    }
                    break;
                case "fixed":
                    try {
                        const fixed = await Jobin.Job.createFixedJob(
                            data.job.currentUser.id,
                            data.job.category.categoryId,
                            data.job.category.categoryType,
                            data.job.category.subCategoryId,
                            data.job.locate.center.lat,
                            data.job.locate.center.lng,
                            data.job.price.extrasArray,
                            data.job.description.message,
                            data.job.picture.files.length > 0
                                ? data.job.picture.files
                                : undefined,
                            data.job.urgency.selectedOption,
                            paymentMethod,
                            "bi-web",
                            true,
                            3,
                            data.job.locate.address2 !== ""
                                ? `${data.job.locate.address} (${data.job.locate.address2})`
                                : data.job.locate.address,
                            emailForInvoice,
                            undefined,
                            business ? business.id : undefined,
                            data.job.availability.dateTo
                                ? new Date(data.job.availability.dateTo)
                                : undefined
                        );
                  
                        if (fixed.status === "ok") {
                            setPublishLoading(false);

                            dispatch({
                                type: "JOB_RESET",
                                job: initialState.job
                            });

                            dispatch({
                                type: "JOB_RESULTS_FIXED",
                                fixedId: fixed.result.job,
                                userPDF: fixed.result.userPDF,
                                jobinPDF: fixed.result.jobinPDF,
                                joberPDF: fixed.result.joberPDF
                            });

                            data.job.history.push({
                                pathname: "/job/new/completed",
                                search: data.job.location.search,
                                state: {
                                    inner: true
                                }
                            });
                        } else {
                            alert(fixed.result);
                            setPublishLoading(false);
                            setButtonTitle("Intentar de nuevo");
                        }
                    } catch (error) {
                        console.error(error);
                        alert("Error, intentar de nuevo.");
                        setPublishLoading(false);
                        setButtonTitle("Intentar de nuevo");
                    }
                    break;
                case "leadToFixed":
                    try {
                        const leadToFixed = await Jobin.Job.transformLeadToFixed(
                            data.job.locate.center.lat,
                            data.job.locate.center.lng,
                            data.job.locate.address2 !== ""
                                ? `${data.job.locate.address} (${data.job.locate.address2})`
                                : data.job.locate.address,
                            data.job.leadToFixedId,
                            paymentMethod,
                            undefined,
                            emailForInvoice,
                            data.job.price.extrasArray,
                            undefined,
                            data.job.availability.dateTo
                                ? new Date(data.job.availability.dateTo)
                                : undefined,
                            business ? business.id : undefined
                        );
                        if (leadToFixed.status === "ok") {
                            setPublishLoading(false);

                            dispatch({
                                type: "JOB_RESET",
                                job: initialState.job
                            });

                            dispatch({
                                type: "JOB_RESULTS_FIXED",
                                fixedId: leadToFixed.result.job,
                                userPDF: leadToFixed.result.userPDF,
                                jobinPDF: leadToFixed.result.jobinPDF,
                                joberPDF: leadToFixed.result.joberPDF
                            });

                            data.job.history.push({
                                pathname: "/job/new/completed",
                                search: data.job.location.search,
                                state: {
                                    inner: true
                                }
                            });
                        } else {
                            alert("Error, intentar de nuevo.");
                            setPublishLoading(false);
                            setButtonTitle("Intentar de nuevo");
                        }
                    } catch (error) {
                        console.error(error);
                        alert("Error, intentar de nuevo.");
                        setPublishLoading(false);
                        setButtonTitle("Intentar de nuevo");
                    }
                    break;
                default:
            }
        }
    };
    
    const onClosePaymentMethodModal = () => {
        if (!didCancel) {
            setShowPaymentModal(false);
            setShowConfirmationReparationModal(false);
            dispatch({
                type: "JOB_RESULTS_FIXED",
                fixedId: null
            });
            dispatch({
                type: "JOB_RESULTS_TRANSPORT",
                transportId: null
            });
            dispatch({
                type: "JOB_RESULTS_LEAD",
                leadId: null
            });
        }
    };

    const update = () => {
        if ( data?.user.isConstructionCompany && !data?.user.isGoldWoller ) {
            setShowConfirmationReparationModal(true);
        }
        else if (data.job.price.selectedOption !== "lead") {
            setShowPaymentModal(true);
        } 
        else publishJob(undefined, "lead");
    };

    const saveAsDraft = async () => {
        setDraftLoading(true);
        if (data.job.fromLeadToFixed) {
            try {
                const leadToFixedDraft = await Jobin.Job.transformLeadToFixed(
                    data.job.locate.center.lat,
                    data.job.locate.center.lng,
                    data.job.locate.address2 !== ""
                        ? `${data.job.locate.address} (${data.job.locate.address2})`
                        : data.job.locate.address,
                    data.job.leadToFixedId,
                    undefined,
                    true,
                    undefined,
                    data.job.price.extrasArray,
                    undefined,
                    data.job.availability.dateTo
                        ? new Date(data.job.availability.dateTo)
                        : undefined,
                    business ? business.id : undefined
                );
                if (leadToFixedDraft.status === "ok") {
                    dispatch({
                        type: "JOB_RESET",
                        job: initialState.job
                    });

                    dispatch({
                        type: "JOB_RESULTS_FIXED_DRAFT",
                        fixedDraftId: leadToFixedDraft.result.job,
                        userPDF: leadToFixedDraft.result.userPDF,
                        jobinPDF: leadToFixedDraft.result.jobinPDF,
                        joberPDF: leadToFixedDraft.result.joberPDF
                    });
                } else {
                    setDraftLoading(false);
                }
                setDraftLoading(false);
            } catch (error) {
                setDraftLoading(false);
            }
        } else {
            try {
                const result = await Jobin.Job.createFixedJob(
                    data.job.currentUser.id,
                    data.job.category.categoryId,
                    data.job.category.categoryType,
                    data.job.category.subCategoryId,
                    data.job.locate.center.lat,
                    data.job.locate.center.lng,
                    data.job.price.extrasArray,
                    data.job.description.message,
                    data.job.picture.files.length > 0
                        ? data.job.picture.files
                        : undefined,
                    data.job.urgency.selectedOption,
                    undefined,
                    "bi-web",
                    true,
                    3,
                    data.job.locate.address2 !== ""
                        ? `${data.job.locate.address} (${data.job.locate.address2})`
                        : data.job.locate.address,
                    undefined,
                    true,
                    business ? business.id : undefined,
                    data.job.availability.dateTo
                        ? new Date(data.job.availability.dateTo)
                        : undefined
                );
                if (result.status === "ok") {
                    dispatch({
                        type: "JOB_RESET",
                        job: initialState.job
                    });

                    dispatch({
                        type: "JOB_RESULTS_FIXED_DRAFT",
                        fixedDraftId: result.result.job,
                        userPDF: result.result.userPDF,
                        jobinPDF: result.result.jobinPDF,
                        joberPDF: result.result.joberPDF
                    });
                }
                setDraftLoading(false);
            } catch (error) {
                setDraftLoading(false);
            }
        }
    };

    useEffect(() => {

        if (
            !(props.location.state && "inner" in props.location.state) &&
            !data.job.results.fixedDraftId
        )
            props.history.replace("/job/new");

        const getBusiness = async () => {
            const result = await Jobin.Business.getById(data.agent.business.id);
            if (result.status === "ok") setBusiness(result.result);
        };

        const getSubCategory = async (id) => {
            const result = await Jobin.Guild.getFromId(
                id
            );
            if (result.status === "ok") {
                let child = result.result;
                const tempArray = [child.get("Type")];
                while (true) {
                    const father = child.get("Father");
                    if (father) {
                        const result2 = await Jobin.Guild.getChildsFather(
                            child
                        );
                        if (result2.status === "ok") {
                            child = result2.result;
                            tempArray.unshift(result2.result.get("Type"));
                        }
                    } else {
                        break;
                    }
                }
                setCategoryArray(tempArray);
            }
        };

        if (data.job.category.subCategoryId !== "" && !didCancel){
            getSubCategory(data.job.category.subCategoryId);
        }else if(data.job.category.categoryId !== "" && !didCancel){
            getSubCategory(data.job.category.categoryId);
        }

        if(data.agent.business){
            getBusiness();
        }

        // unmount
        return () => {
            didCancel = true;
            dispatch({
                type: "SET_FISCAL_INFO",
                ...initialState.job.billingInfo
            });
        };
    }, []);

    const billingInfo = () =>
        data.job.billingInfo.id ? (
            <div>
                <h5 className="title">Datos de facturación</h5>
                <div style={{ marginBottom: 4 }}>
                    <label style={{ fontWeight: 600 }}>Nombre fiscal: </label>
                    <label>{data.job.billingInfo.fiscalName}</label>
                </div>
                <div style={{ marginBottom: 4 }}>
                    <label style={{ fontWeight: 600 }}>
                        Número de identidad:{" "}
                    </label>
                    <label>{data.job.billingInfo.identityNumber}</label>
                </div>
                <div style={{ marginBottom: 4 }}>
                    <label style={{ fontWeight: 600 }}>
                        Dirección de facturación:{" "}
                    </label>
                    <label>{data.job.billingInfo.fiscalAddress}</label>
                </div>
                <div style={{ marginBottom: 4 }}>
                    <label style={{ fontWeight: 600 }}>Ciudad: </label>
                    <label>{data.job.billingInfo.fiscalCity}</label>
                </div>
                <div style={{ marginBottom: 4 }}>
                    <label style={{ fontWeight: 600 }}>Código postal: </label>
                    <label>{data.job.billingInfo.fiscalPostalCode}</label>
                </div>
                <ButtonEmpty
                    style={{ marginTop: 8 }}
                    buttonText="Cambiar datos fiscales"
                    onClick={() => setShowFiscalModal(true)}
                    loading={false}
                />
            </div>
        ) : (
            <div>
                <h5 className="title">Datos de facturación</h5>
                <ButtonEmpty
                    buttonText="Añadir datos fiscales"
                    onClick={() => setShowFiscalModal(true)}
                    loading={false}
                />
            </div>
        );


    return (
        <div id="service-summary" className="section">
            <BreadCrumb history={props.history} location={props.location} />
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="title">Resumen del servicio</h4>
                        <div className="content">
                            <div className="guild">
                                <h5 className="title">Servicio</h5>
                                <div className="guild-data">
                                    {categoryArray.map((category, index) => {
                                        return [
                                            index === 0 ? (
                                                <div
                                                    key={"category" + index}
                                                    className="guild-cell"
                                                >
                                                    <label>{category}</label>
                                                </div>
                                            ) : (
                                                <div
                                                    key={"category" + index}
                                                    className="guild-cell"
                                                >
                                                    <img
                                                        className="icon"
                                                        src={require("../../assets/next-step.svg")}
                                                        alt="icon"
                                                    />
                                                    <label>{category}</label>
                                                </div>
                                            )
                                        ];
                                    })}
                                </div>
                            </div>
                            <div className="address">
                                <h5 className="title">Ubicación</h5>
                                <p>
                                    {data.job.locate.address2 !== ""
                                        ? `${data.job.locate.address} (${data.job.locate.address2})`
                                        : data.job.locate.address}
                                </p>
                            </div>
                            <div className="description">
                                <h5 className="title">Descripción</h5>
                                <p>{data.job.description.message}</p>
                            </div>
                            {data.job.price.selectedOption === "fixed" &&
                                data.job.price.extrasArray.length > 0 && (
                                    <div className="extras-list">
                                        <h5 className="title">Extras</h5>
                                        <table style={{ width: "100%" }}>
                                            <tbody>
                                                <tr>
                                                    {data.job.price.extrasArray.map(
                                                        (extra, index) => {
                                                            return (
                                                                <td
                                                                    key={`${extra}-${index}`}
                                                                >
                                                                    <ExtraInfoCell
                                                                        extra={
                                                                            extra
                                                                        }
                                                                    />
                                                                </td>
                                                            );
                                                        }
                                                    )}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            {data.job.weight.amount !== "" && (
                                <div className="weight">
                                    <h5 className="title">Peso</h5>
                                    <p>{data.job.weight.amount} Kg</p>
                                </div>
                            )}
                            {(data.job.price.selectedOption === "fixed" ||
                                data.job.price.selectedOption === "urgent") &&
                                data.job.price.amount +
                                    data.job.price.extraAmount >
                                    0 && (
                                    <div className="price">
                                        <h5 className="title">Total a pagar</h5>
                                        <p>
                                            {(
                                                (data.job.price.amount +
                                                    data.job.price
                                                        .extraAmount) *
                                                1.21
                                            ).toFixed(2)}
                                            {" €"}
                                        </p>
                                    </div>
                                )}
                            {data.job.availability.dateTo && (
                                <div className="date">
                                    <h5 className="title">Fecha</h5>
                                    <p>
                                        {data.job.availability.dateTo.format(
                                            "DD/MM/YYYY"
                                        )}
                                    </p>
                                </div>
                            )}
                            {data.job.urgency.selectedOption && (
                                <div className="urgency">
                                    <h5 className="title">Nivel de urgencia</h5>
                                    <p>{data.job.urgency.selectedOption}</p>
                                </div>
                            )}
                            <div className="business-data">
                                <div className="business-title">
                                    <h5 className="title">Negocio asignado</h5>
                                    {business && !data.agent.business && (
                                        <ButtonEmpty
                                            style={{
                                                marginTop: 28,
                                                marginLeft: 12
                                            }}
                                            buttonText="Editar"
                                            onClick={() =>
                                                setShowBusinessModal(true)
                                            }
                                        />
                                    )}
                                </div>
                                {business &&
                                business.get("Logo") &&
                                business.get("Logo").url() ? (
                                    <div className="business-cell">
                                        <img src={business.get("Logo").url()} alt="business-logo" />
                                        <label>{business.get("Name")}</label>
                                    </div>
                                ) : (
                                    <ButtonEmpty
                                        buttonText="Asignar negocio"
                                        onClick={() =>
                                            setShowBusinessModal(true)
                                        }
                                    />
                                )}
                            </div>
                            {data.job.results.fixedDraftId && (
                                <Link
                                    to={{
                                        pathname: `/job/${data.job.results.fixedDraftId}`
                                    }}
                                >
                                    Ir al detalle del trabajo borrador
                                </Link>
                            )}
                        </div>
                    </div>
                    <div className="col-md-offset-1 col-md-4">
                        <div className="client-data">
                            <h5 className="title">Datos del cliente</h5>
                            <div style={{ marginBottom: 4 }}>
                                <label style={{ fontWeight: 600 }}>
                                    Teléfono:{" "}
                                </label>
                                <label>
                                    {data.job.signIn.phoneModal.areaCode +
                                        data.job.signIn.phoneModal.phone}
                                </label>
                            </div>
                            <div style={{ marginBottom: 4 }}>
                                <label style={{ fontWeight: 600 }}>
                                    Nombre:{" "}
                                </label>
                                <label>{data.job.signIn.nameModal.name}</label>
                            </div>
                            {data.job.signIn.nameModal.email &&
                                data.job.signIn.nameModal.email !== "" && (
                                    <div>
                                        <label style={{ fontWeight: 600 }}>
                                            Email:{" "}
                                        </label>
                                        <label>
                                            {data.job.signIn.nameModal.email}
                                        </label>
                                    </div>
                                )}
                            {billingInfo()}
                        </div>
                        {(data.job.price.selectedOption === "fixed" ||
                            data.job.price.selectedOption === "urgent") &&
                            !data.job.transport && (
                                <Button
                                    style={{ marginTop: 16 }}
                                    buttonText="Guardar como borrador"
                                    onClick={saveAsDraft}
                                    loading={draftLoading || publishLoading}
                                />
                            )}
                    </div>
                </div>
            </div>
            {showFiscalModal && (
                <FiscalModal
                    isVisible={showFiscalModal}
                    onClose={() => setShowFiscalModal(false)}
                    billingInfo={data.job.billingInfo}
                    success={() => setShowFiscalModal(false)}
                />
            )}
            {showPaymentModal && (
                <PaymentMethodModal
                    isVisible={showPaymentModal}
                    initialView="main"
                    price={(
                        (data.job.price.amount + data.job.price.extraAmount) *
                        1.21
                    ).toFixed(2)}
                    savePaymentMethod={savePaymentMethod}
                    onClose={onClosePaymentMethodModal}
                    jobType={data.job.transport ? "transport" : "fixed"}
                    business={business}
                />
            )}
             {showConfirmationReparationModal && (
                <Modal
                    title="¿Estás seguro que esta reparación procede?"
                    subtitle="Confirma que esta reparación procede para crearla."
                    /*onClose={}*/
                >           
                    <div style={{width:"100%",display:"flex", gap: 6}}>
                        <Button
                            style={{ width: 85, height: 44 }}
                            buttonText="Si"
                            onClick={() => {savePaymentMethod('business')}}
                        />
                        <Button
                            style={{ width: 85, height: 44 }}
                            buttonText="No"
                            onClick={() => {setShowConfirmationReparationModal(false)}}
                        />
                    </div>
                </Modal>
            )}
            {showBusinessModal && (
                <BusinessModal
                    isVisible={showBusinessModal}
                    onClose={() => setShowBusinessModal(false)}
                    selectBusiness={item => {
                        setShowBusinessModal(false);
                        setBusiness(item);
                    }}
                />
            )}
            <StepControlls
                isLastPage={true}
                page={props.step}
                disabled={false}
                nextTitle={buttonTitle}
                next="job/new/completed"
                prev={
                    data.job.price.selectedOption === "lead"
                        ? "job/new/urgency"
                        : "job/new/picture"
                }
                loading={publishLoading || draftLoading}
                update={update}
            />
        </div>
    );
}
