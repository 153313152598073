import React, {useState,useEffect} from "react";
import "../../styles/silgleComponents/switch.css"


const Switch = props => {

    const [isChecked,setIsCheked] = useState(false)

    const color = props.color ? props.color : "";

    useEffect(()=> {
        setIsCheked(props.value);

        return function cleanup() {
            setIsCheked(undefined);
        };
    },[props]);

    const handleChange = (event)=>{
        setIsCheked(event.target.checked)
        props.getChangeValue(props.jobId, event.target.checked, props.type ? props.type : undefined)
    }
    return (
        <>
            <label className="switch">
                <input onClick={(e)=>handleChange(e)} checked={isChecked}  type="checkbox"/>
                <span className={`switch-slider${color} round`}></span>
            </label>
        </>
      );
};

export default Switch;
