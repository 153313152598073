import Jobin from "jobin-client";
import { apiId, jsKey, urlServer } from "./credentials";
import { getCookie } from "../utils/cookie";
import { elementOf } from "../utils/formatter";

const fetchBusinesses = async (prevSelection, isProDispat=false) => {
    Jobin.initServer(apiId, jsKey, urlServer);
    const agent = JSON.parse(
        getCookie("agent") ? decodeURIComponent(getCookie("agent")) : null
    );

    Jobin.initToken(agent ? agent.token : undefined);
        
    if(isProDispat){
        var selectedIds =
        prevSelection && Array.isArray(prevSelection)
            ? prevSelection.map(item => item)
            : [];

    }else{
        var selectedIds =
        prevSelection && Array.isArray(prevSelection)
            ? prevSelection.filter(item => item.selected).map(item => item.id)
            : [];

    }
    try {
        const result = await Jobin.Business.getMainBusinesses();
        if (result.status !== "error") {
            const arr = result.result.map(business => {
                return {
                    id: business.id,
                    name: business.get("Name"),
                    selected:
                    agent.business ?  business.id === agent.business.id : elementOf(business.id, selectedIds)
                };
            });
            return [
                {
                    id: "all",
                    name: "Todos",
                    selected: false
                }
            ].concat(arr);
        }
    } catch (error) {
        console.error(error);
    }
};

export default fetchBusinesses;
