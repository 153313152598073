import React, { useEffect,useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import AlertModal from "../modal/Alert";
import { buyJobForWoller } from "../../utils/ParserUtilities";

import { useStateValue } from "../../state/context";
import { formatCurrency } from "../../utils/formatter";
import { Loading } from "jobin-ui-components";

import moment from "moment";
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import "../../styles/explore/jobCard.css";

import "moment/locale/es"  
import { User } from "parse";

export default function JobCard({buySucesfull,lowFounds,lowLevel,handleSucsesfullBuy,ownedService,serviceDetails,setIsForOwn}) {
    const [{explore,currentSession, user}, dispatch] = useStateValue();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [jobSelected, setJobSelected] = useState(false);

    moment.locale('es')

    const calculateSlots = (slots,takenSlots,serviceId)=>{
        let takenSlots_ = takenSlots 
        let slotsCalc = []
        for (let index = 0; index < parseInt(slots); index++) {
            if(takenSlots_ > 0){
                takenSlots_ = takenSlots_ - 1
                slotsCalc.push(<div key={"fullHelmet"+index+serviceId} className="helmet"><img src={require("../../assets/icons/Casco.png")} alt=""  /></div>)
            }else{
                slotsCalc.push(<div key={"emptyHelmet"+index+serviceId} className="empty"></div>)
            }
        }
        return slotsCalc
    }
   
    const style = {
        loadingContainer: {
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
        }
    };
   
    const handleBuy = async (service)=>{
        if(parseInt(currentSession.wollerData.get("Level")) < parseInt(service.level)){
            lowLevel(true)
        }else if(currentSession.wollerData.get("nJobs")<parseInt(service.jobLeadPrice)){
            lowFounds(true)
        }else{
            setJobSelected(service)
            setShowConfirmModal(true)
        }
    }
    const asignWoller = async (serviceId) => {
        let buy = await  buyJobForWoller(serviceId,currentSession.wollerData.id)
            if (buy){
                ownedService(jobSelected)
                setShowConfirmModal(false)
                handleSucsesfullBuy()
            } else  {
                alert(buy.error)
                setShowConfirmModal(false)
            }
        
    };
    const parseJobSelected =(service)=>{
       
        setJobSelected(service); 
        ownedService(service);
        serviceDetails(true)
    }
    return (<div className="jobCardContainer ">
                {showConfirmModal && (<AlertModal
                        isVisible={showConfirmModal}
                        onClose={() => setShowConfirmModal(false)}
                        title={jobSelected?.title}
                        subtitle="¿Seguro que deseas comprar este trabajo?"
                        buttonText="Comprar"
                        onClick = {()=>{asignWoller(jobSelected.id)}}
                        classNamesModal={{modal:"outFoundsModal"}}
                    />
                )}
                {explore.loading ?( <section style={style.loadingContainer}>
                    <Loading />
                </section>
                ):explore?.services.length > 0 ? explore?.services?.map((service)=>{
                    return (
                        
                        <div className={"card jobContainer"} key={service.id}>
                            {service.level === 2 ? (<img
                                                    src={require("../../assets/badgeSilver.png")}
                                                    alt="Plata"
                                                    className="badge"
                                                />
                                            ) : service.level === 3 ? (
                                                <img
                                                    src={require("../../assets/badgeGold.png")}
                                                    alt="Oro"
                                                    className="badge"
                                                />
                                            ) : service.level === 4 ? (
                                                <img
                                                    src={require("../../assets/badgePlatinum.png")}
                                                    alt="Oro"
                                                    className="badge"
                                                />
                                            ) :  <img
                                                    src={require("../../assets/badgeBronze.png")}
                                                    alt="Oro"
                                                    className="badge"
                                                />}
                         {service.images ?(
                         <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                            {service.images.map((image,imageIndex)=>{
                                return(
                                    <SwiperSlide key={service.id+""+imageIndex} className="jobSlideImage" ><div style={{backgroundImage:"url("+image.url()+")"}}></div></SwiperSlide>
                                )
                            })}
                            
                        </Swiper>):
                        (
                           <div className="noPics" style={{backgroundImage:service.vertical?.get("DefaultPicture")?.url()? "url("+ service.vertical?.get("DefaultPicture")?.url()+")":"url("+require("../../assets/noPics.png")+")"}}></div> 
                        )}
                            <div className="dataContainer">
                                <div className="textContainer">
                                    <div className="textImage descriptionContainer">
                                        <img className="verticalImg" src={service?.vertical?.get("Icon")?.url()} alt="" />
                                        <div className="levelAndDescriptionContainer">
                                            <p className={service?.level  === 4 ? "Platino" : service?.level === 3 ? "Oro" : service?.level === 2 ? "Plata" : "Bronce"}>{service?.level  === 4 ? "Platino" : service?.level === 3 ? "Oro" : service?.level === 2 ? "Plata" : "Bronce"} - {service.type}</p>
                                            <p className="description">{service?.title}</p>
                                        </div>
                                        {service.business && service.business.get("Logo") && (
                                            <img className="businessImg"  src={service?.business?.get("Logo")?.url()}></img>
                                        )}

                                    </div>
                                    <div className="jobInfoContainer">
                                        <div className="textImage">
                                            <img  src={require("../../assets/icons/User.svg")} alt="" />
                                            <p>{service?.createdBy?.get("Name")}</p>
                                        </div>
                                        <div className="textImage">
                                            <img  src={require("../../assets/icons/MapPinLine.svg")} alt="" />
                                            <p>{service?.address} <b>{service?.distanceFormWoller > 1 ? ` a ${service?.distanceFormWoller.toFixed(1)}Km de ti`:` a Menos de 1Km de ti`}</b></p>
                                        </div>
                                        <div className="textImage">
                                            <img  src={require("../../assets/icons/Clock.svg")} alt="" />
                                            <p>Subido {moment(service?.createdAt).fromNow()}</p>
                                        </div>
                                        <div className="textImage more" onClick={()=>{ setIsForOwn(true);parseJobSelected(service) }}>
                                            <img  src={require("../../assets/add.svg")} alt="" />
                                            <p><u>Ver Mas</u></p>
                                        </div>
                                    </div>
                                    {service.images ?(
                                        <Swiper navigation={true} modules={[Navigation]} className="mySwiper mobileSwiper">
                                            {service.images.map((image,imageIndex)=>{
                                                return(
                                                    <SwiperSlide key={service.id+""+imageIndex} className="jobSlideImage" ><div style={{backgroundImage:"url("+image.url()+")"}}></div></SwiperSlide>
                                                )
                                            })}
                                        </Swiper>):
                                    (<div className="noPics mobileNoPic" style={{backgroundImage:service.vertical.get("DefaultPicture") ? "url("+ service.vertical.get("DefaultPicture")?.url()+")":"url("+ require("../../assets/noPics.png")+")"}}></div>)}
                                </div>
                                <div className="priceAndSlots">
                                    <div className="slotsContainer">
                                    {service?.totalSlots == 1 ? 
                                        (
                                            <>
                                                <img className="oneSlot" src={require("../../assets/n1.png")} alt="" />
                                                <p>SERVICIO SIN COPMPETENCIA</p>
                                            </>
                                        ) :(
                                            <>
                                                {calculateSlots(service?.totalSlots,service?.takenSlots,service.id)}
                                            </>
                                    )}
                                    </div>
                                    <div className={service.type == "Fontanero" ? "buttons-wrapper mt-20" : "buttons-wrapper"}>
                                        <button onClick={()=>{handleBuy(service);ownedService(service)}} className="priceButton">CONTACTAR {user.isUserSubscription ? "Contactar":`POR ${formatCurrency(service?.jobLeadPrice)} `}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }):null}
        </div>)
 }