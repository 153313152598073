export const selectService = [
    {
        id: "fixed",
        name: "Servicio a precio cerrado",
        selected: true
    },
    {
        id: "urgent",
        name: "Servicio a precio cerrado urgente",
        selected: false
    },
    {
        id: "lead",
        name: "Pedir 3 presupuestos",
        selected: false
    }
];
