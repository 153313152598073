export const options = [
    {
        id: "manual",
        title: "Facturación manual"
    },
    {
        id: "service",
        title: "Por servicio finalizado"
    },
    {
        id: "monthly",
        title: "A final de cada mes"
    }
];