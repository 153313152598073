import React, { useState, useEffect } from "react";
import { useStateValue } from "../../../state/context";
import { Link } from "react-router-dom";

import "../../../styles/job/breadcrumb.css";

export default function BreadCrumb(props) {
    const [{ job }, dispatch] = useStateValue();

    const [items, setItems] = useState(job.breadCrumb);

    useEffect(() => {
        let didCancel = false;

        const pathname = props.history.location.pathname;

        let name = "";
        switch (pathname) {
            case "/job/new/location":
                name = "Ubicación";
                break;
            case "/job/new/weight":
                name = "Peso";
                break;
            case "/job/new/price":
                name = "Precio";
                break;
            case "/job/new/availability":
                name = "Disponibilidad";
                break;
            case "/job/new/date":
                name = "Fecha";
                break;
            case "/job/new/description":
                name = "Detalles";
                break;
            case "/job/new/picture":
                name = "Fotos";
                break;
            case "/job/new/urgency":
                name = "Urgencia";
                break;
            case "/job/new/summary":
                name = "Resumen";
                break;
            default:
                name = "";
        }
        if (name !== "") {
            let occurrence = false;
            let yndex = 0;
            let itemTemp = items.map((item, index) => {
                // checks if the element already exist inside the array
                if (item.pathname === pathname) {
                    occurrence = true;
                    yndex = index;
                }
                return item;
            });
            if (!occurrence) {
                itemTemp.push({
                    name: name,
                    pathname: pathname
                });
            } else itemTemp = itemTemp.slice(0, yndex + 1);
            if (!didCancel) {
                setItems(itemTemp);
                dispatch({
                    type: "JOB_UPDATE_BREADCRUMB",
                    breadCrumb: itemTemp
                });
            }
        }

        // unmount
        return () => {
            didCancel = true;
            dispatch({
                type: "JOB_UPDATE_BREADCRUMB",
                breadCrumb: [
                    {
                        name: "Tipo de servicio",
                        pathname: "/job/new"
                    }
                ]
            });
        };
    }, []);

    return (
        <div className="breadcrumb hidden-xs">
            <ul>
                {items.map((item, index) => {
                    return (
                        <li
                            key={`${item.name}-${index}`}
                            className={
                                items.length - 1 === index
                                    ? "breadcrumb-item active"
                                    : "breadcrumb-item"
                            }
                        >
                            <Link
                                replace
                                to={{
                                    pathname: item.pathname,
                                    search: props.location.search,
                                    state: {
                                        inner: true
                                    }
                                }}
                            >
                                {item.name}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
