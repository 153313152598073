export const provinceArr = [
    {
        id: "01",
        name: "Alava",
        selected: false,
        location:{latitude: 42.846462, longitude: -2.672510}
    },
    {
        id: "02",
        name: "Albacete",
        selected: false,
        location:{latitude: 38.994347, longitude: -1.858446}
    },
    {
        id: "03",
        name: "Alicante",
        selected: false,
        location:{latitude: 38.378427, longitude: -0.487912}
    },
    {
        id: "04",
        name: "Almería",
        selected: false,
        location:{latitude: 36.846883, longitude: -2.451879}
    },
    {
        id: "05",
        name: "Ávila",
        selected: false,
        location:{latitude: 40.655452, longitude: -4.691035}
    },
    {
        id: "06",
        name: "Badajoz",
        selected: false,
        location:{latitude: 38.878238, longitude: -6.973174}
    },
    {
        id: "07",
        name: "Islas Baleares",
        selected: false,
        location:{latitude: 39.594428, longitude: 2.686488}
    },
    {
        id: "08",
        name: "Barcelona",
        selected: false,
        location:{latitude: 41.398349, longitude: 2.154952}
    },
    {
        id: "09",
        name: "Burgos",
        selected: false,
        location:{latitude: 42.342594, longitude: -3.700530}
    },
    {
        id: "10",
        name: "Cáceres",
        selected: false,
        location:{latitude: 39.472956, longitude: -6.376213}
    },
    {
        id: "11",
        name: "Cádiz",
        selected: false,
        location:{latitude: 36.687948, longitude: -6.126590}
    },
    {
        id: "12",
        name: "Castellón",
        selected: false,
        location:{latitude: 40.226234, longitude: -0.151899}
    },
    {
        id: "13",
        name: "Ciudad Real",
        selected: false,
        location:{latitude: 38.984363, longitude: -3.927417}
    },
    {
        id: "14",
        name: "Córdoba",
        selected: false,
        location:{latitude: 37.889734, longitude: -4.780118}
    },
    {
        id: "15",
        name: "La Coruña",
        selected: false,
        location:{latitude: 43.332146, longitude: -8.284971}
    },
    {
        id: "16",
        name: "Cuenca",
        selected: false,
        location:{latitude: 40.070280, longitude: -2.136191}
    },
    {
        id: "17",
        name: "Gerona",
        selected: false,
        location:{latitude: 41.982667, longitude: 2.821859}
    },
    {
        id: "18",
        name: "Granada",
        selected: false,
        location:{latitude: 37.173855, longitude: -3.599474}
    },
    {
        id: "19",
        name: "Guadalajara",
        selected: false,
        location:{latitude: 40.632873, longitude: -3.169911}
    },
    {
        id: "20",
        name: "Guipuzcoa",
        selected: false,
        location:{latitude: 43.136013, longitude: -2.229231}
    },
    {
        id: "21",
        name: "Huelva",
        selected: false,
        location:{latitude: 37.260717, longitude: -6.944921}
    },
    {
        id: "22",
        name: "Huesca",
        selected: false,
        location:{latitude: 42.136956, longitude: -0.408252}
    },
    {
        id: "23",
        name: "Jaen",
        selected: false,
        location:{latitude: 37.772628, longitude: -3.789572}
    },
    {
        id: "24",
        name: "León",
        selected: false,
        location:{latitude: 42.597853, longitude: -5.570012}
    },
    {
        id: "25",
        name: "Lérida",
        selected: false,
        location:{latitude: 41.616543, longitude: 0.620364}
    },
    {
        id: "26",
        name: "La Rioja",
        selected: false,
        location:{latitude: 42.470303, longitude: -2.667379}
    },
    {
        id: "27",
        name: "Lugo",
        selected: false,
        location:{latitude: 43.009771, longitude: -7.558904}
    },
    {
        id: "28",
        name: "Madrid",
        selected: false,
        location: {latitude: 40.416780, longitude: -3.703756}
    },
    {
        id: "29",
        name: "Málaga",
        selected: false,
        location:{latitude: 36.725696, longitude: -4.461571}
    },
    {
        id: "30",
        name: "Murcia",
        selected: false,
        location:{latitude: 37.990266, longitude: -1.132665}
    },
    {
        id: "31",
        name: "Navarra",
        selected: false,
        location:{latitude: 42.785660, longitude: -1.643483}
    },
    {
        id: "32",
        name: "Orense",
        selected: false,
        location:{latitude: 42.334744, longitude: -7.863858}
    },
    {
        id: "33",
        name: "Asturias",
        selected: false,
        location:{latitude: 43.453570, longitude: -5.795225}
    },
    {
        id: "34",
        name: "Palencia",
        selected: false,
        location:{latitude: 42.008686, longitude: -4.528499}
    },
    {
        id: "35",
        name: "Las Palmas",
        selected: false,
        location:{latitude: 28.004976, longitude: -15.614712}
    },
    {
        id: "36",
        name: "Pontevedra",
        selected: false,
        location:{latitude: 42.431011, longitude: -8.645017}
    },
    {
        id: "37",
        name: "Salamanca",
        selected: false,
        location:{latitude: 40.969623, longitude: -5.663437}
    },
    {
        id: "38",
        name: "Santa Cruz de Tenerife",
        selected: false,
        location:{latitude: 28.306887, longitude: -16.509848}
    },
    {
        id: "39",
        name: "Cantabria",
        selected: false,
        location:{latitude: 43.332730, longitude: -4.039750}
    },
    {
        id: "40",
        name: "Segovia",
        selected: false,
        location:{latitude: 40.942685, longitude: -4.113613}
    },
    {
        id: "41",
        name: "Sevilla",
        selected: false,
        location:{latitude: 37.388122, longitude: -5.985127}
    },
    {
        id: "42",
        name: "Soria",
        selected: false,
        location:{latitude: 41.766028, longitude: -2.477558}
    },
    {
        id: "43",
        name: "Tarragona",
        selected: false,
        location:{latitude: 41.132271, longitude: 1.172517}
    },
    {
        id: "44",
        name: "Teruel",
        selected: false,
        location:{latitude: 40.344746, longitude: -1.108175}
    },
    {
        id: "45",
        name: "Toledo",
        selected: false,
        location:{latitude: 39.862346, longitude: -4.027612}
    },
    {
        id: "46",
        name: "Valencia",
        selected: false,
        location:{latitude: 39.481097, longitude: -0.454578}
    },
    {
        id: "47",
        name: "Valladolid",
        selected: false,
        location:{latitude: 41.648786, longitude: -4.728240}
    },
    {
        id: "48",
        name: "Vizcaya",
        selected: false,
        location:{latitude: 43.259050, longitude: -2.935326}
    },
    {
        id: "49",
        name: "Zamora",
        selected: false,
        location:{latitude: 41.503178, longitude: -5.746688}
    },
    {
        id: "50",
        name: "Zaragoza",
        selected: false,
        location:{latitude: 41.649502, longitude: -0.888081}
    },
    {
        id: "51",
        name: "Ceuta",
        selected: false,
        location:{latitude: 35.889472, longitude: -5.324877}
    },
    {
        id: "52",
        name: "Melilla",
        selected: false,
        location:{latitude: 35.291647, longitude: -2.939307}
    }
];
