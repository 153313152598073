import React, { useEffect, useState } from "react";
import {Loading} from 'jobin-ui-components'
import { useStateValue } from "../../state/context";

import "../../styles/search/result-table.css";

export default function ResultTable(props) {

    const [{ businessSearch }, dispatch] = useStateValue();

    const style = {
        loadingContainer: {
            display: "flex",
            flex: 1,
            backgroundColor: "var(--soft-grey)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
        },
        tableContainer: {
        },
        logoImage:{
            height: 36,
            width: 36,
            backgroundColor: "var(--soft-grey)",
            borderRadius: 18,
            overflow: 'hidden',
            objectFit: 'cover'
        },
        starImage:{
            height: 18,
            width: 18,
            overflow: 'hidden',
            objectFit: 'cover'
        }
    };

    const itemDetail = id => {
        props.history.push({
            pathname: "/business/" + id,
            state: {
                inner: true
            }
        });
    };

    const BusinessTable = key => {
        return (
            <table>
                <thead>
                    <tr>
                        {businessSearch.itemsTitle.map((title, index) => {
                            return (
                                <th key={`${key}-${title}-${index}`}>
                                    {title}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {businessSearch.items.map((item, index) => {
                        return (
                            <tr key={`${key}-${item.id}-${index}`}>
                                <td onClick={() => itemDetail(item.id)}>
                                    {item.get("Level") === 2 ? (
                                        <img
                                            src={require("../../assets/badge-silver.svg")}
                                            alt="Plata"
                                        />
                                    ) : item.get("Level") === 3 ? (
                                        <img
                                            src={require("../../assets/badge-gold.svg")}
                                            alt="Oro"
                                        />
                                    ) : item.get("Level") === 4 ? (
                                        <img
                                            src={require("../../assets/badge-platinum.svg")}
                                            alt="Platino"
                                        />
                                    ) : (
                                        <img
                                            src={require("../../assets/badge-bronce.svg")}
                                            alt="Bronce"
                                        />
                                    )}
                                </td>
                                <td>
                                    <img
                                        style={style.logoImage}
                                        src={item.get("Logo") ? item.get("Logo").url() : null}
                                        alt="Logo"
                                    />
                                </td>
                                <td onClick={() => itemDetail(item.id)}>
                                    {item.get("Name")}
                                </td>
                                <td onClick={() => itemDetail(item.id)}>
                                    {item.get("Address")}
                                </td>
                                <td onClick={() => itemDetail(item.id)}>
                                    {item.get("Url")}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    if (businessSearch.loading) {
        return (
            <section style={style.loadingContainer}>
                <Loading />
            </section>
        );
    } else {
        return (
            <div
                className="result-table-container"
                style={style.tableContainer}
            >
                {businessSearch.items.length > 0 ? (
                    <BusinessTable key="lead" />    
                ) : (
                    <section style={{ padding: 8 }}>
                        <h3
                            style={{
                                fontSize: 25,
                                marginTop: 0,
                                marginBottom: 8
                            }}
                        >
                            No se han encontrado resultados
                        </h3>
                        <p>
                            Modifica los filtros de tu búsqueda para ampliar el
                            rango.
                        </p>
                    </section>
                )}
            </div>
        );
    }
}
