import React from "react";
import "../../styles/broker.css";
import Parse from "parse";

import { apiId, jsKey, urlServer  } from '../../constants/credentials';

const BrokerComponent = props => {

    const { title, data } = props;

    const changeAmountBroker = async (id, amount) => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        await Parse.Cloud.run("changeAmountBroker", {brokerId: id, amount: amount});
    }

    const changeVerticalAmount = async (id, amount) => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        await Parse.Cloud.run("changeVerticalAmount", {brokerId: id, amount: amount});
    }

    const changeAmount = (id, index, amount ) => {
        if(title == "Provincia"){
            changeAmountBroker(id, amount);
            data[index].set("Amount", amount);
            props.updateAmount();
        } else if(title == "Dia"){
            changeAmountBroker(id, amount);
            data[index].set("Amount", amount);
            props.updateAmount();
        } else if(title == "Gremio"){
            changeVerticalAmount(id, amount);
            data[index].set("BrokerAmount", amount);
            props.updateAmount();
        }
    }

    return(
        <div style={{display: "flex", width: "30%", flexDirection: "column", alignItems: "center"}}>
            <p className="broker-title">{title}</p>
            <div className="broker-card">
                {
                    data.map( (element, i) => (
                        <div className="broker-item">                            
                            {title != "Gremio" && (
                                <p className="broker-name">{element.get("Name")}</p>
                            )}
                            {title == "Gremio" && (
                                <p className="broker-name">{element.get("Type")}</p>
                            )}
                            <div className="broker-amount">
                                <button className="broker-item-button" onClick={() => changeAmount(element.id, i, typeof element.get("Amount") == 'number' ? (element.get("Amount") - 1) : (element.get("BrokerAmount") - 1) )}>-</button>
                                {title != "Gremio" && (
                                    <div className={element.get("Amount")>0 ? "broker-item-number amount-green": element.get("Amount")<0 ? "broker-item-number amount-red": "broker-item-number"}>{element.get("Amount")}</div>
                                )}
                                {title == "Gremio" && (
                                    <div className={element.get("BrokerAmount")>0 ? "broker-item-number amount-green": element.get("BrokerAmount")<0 ? "broker-item-number amount-red": "broker-item-number"}>{element.get("BrokerAmount")}</div>
                                )}
                                <button className="broker-item-button" onClick={() => changeAmount(element.id, i, typeof element.get("Amount") == 'number' ? (element.get("Amount") + 1) : (element.get("BrokerAmount") + 1)  )}>+</button>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default BrokerComponent;