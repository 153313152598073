import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import GoogleMapReact from "google-map-react";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from "react-places-autocomplete";
import { useStateValue } from "../../state/context";
import { SearchBar } from "jobin-ui-components";
import StepControlls from "./generic/StepControlls";
import BreadCrumb from "./generic/BreadCrumb";
import { getCookie } from "../../utils/cookie";

import "../../styles/job/service-location.css";

import mapsMarker from "../../assets/maps-marker.svg";

export default function ServiceLocation(props) {
    const [{ job, currentSession }, dispatch] = useStateValue();

    const [center, setCenter] = useState(job.locate.center);
    const [zoom, setZoom] = useState(job.locate.zoom);
    const [address, setAddress] = useState(job.locate.address);
    const [address2, setAddress2] = useState(job.locate.address2);
    const [distance, setDistance] = useState(null);
    const [addressSelected, setAddressSelected] = useState(
        job.locate.addressSelected
    );
    const [storeLocation, setStoreLocation] = useState(() => {
        if (currentSession?.wollerData?.get("Location")) {
            return {
                lat: currentSession?.wollerData?.get("Location").latitude,
                lng: currentSession?.wollerData?.get("Location").longitude
            };
        } else {
            return null;
        }
    });
    const [postalCode, setPostalCode] = useState(job.locate.postalCode);
    const [disabledBtn, setDisabledBtn] = useState(job.locate.disabledBtn);
    const [error, setError] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    let didCancel = false;

    const handleChange = address => {
        if (!didCancel) {
            setAddress(address);
            setAddressSelected(false);
            setDisabledBtn(true);
        }

        if (job.locate.area) job.locate.area.setMap(null);
    };

    const handleChange2 = e => {
        if (!didCancel) {
            setAddress2(e.target.value);
        }
    };

    const handleSelect = async address => {
        try {
            const results = await geocodeByAddress(address);

            const addressMeta = results[0].address_components.filter(
                comp => comp.types[0] === "postal_code"
            );
            if (addressMeta.length > 0) {
                if (!didCancel) {
                    setPostalCode(addressMeta[0].short_name);
                    setDisabledBtn(false);
                    setErrorMsg(null);

                    setAddress(results[0].formatted_address);
                    setAddressSelected(true);

                    const latLng = await getLatLng(results[0]);

                    setCenter({
                        lat: latLng.lat,
                        lng: latLng.lng
                    });
                    setZoom(16);
                }
            } else {
                if (!didCancel) {
                    setDisabledBtn(true);
                    setErrorMsg("Debes escribir una dirección más precisa.");

                    setAddress(results[0].formatted_address);
                    setAddressSelected(false);

                    const latLng = await getLatLng(results[0]);

                    setCenter({
                        lat: latLng.lat,
                        lng: latLng.lng
                    });
                    setZoom(16);
                }
            }

            //Show directions if needed
            if (job.transport && job.locate.maps) {
                const latLng = await getLatLng(results[0]);

                job.directionsService.route(
                    {
                        origin: {
                            lat: storeLocation.lat,
                            lng: storeLocation.lng
                        },
                        destination: {
                            lat: latLng.lat,
                            lng: latLng.lng
                        },
                        travelMode: "DRIVING"
                    },
                    (response, status) => {
                        if (status === "OK") {
                            setDistance(
                                response.routes[0].legs[0].distance.text
                            );
                            job.directionsDisplay.setDirections(response);
                            job.directionsDisplay.setMap(job.locate.map);
                        } else {
                            window.alert(
                                "Directions request failed due to " + status
                            );
                        }
                    }
                );
            }
        } catch (error) {
            setError(error);
        }
    };

    const apiIsLoaded = (map, maps) => {
        dispatch({
            type: "JOB_LOCATE_GMAPS",
            map: map,
            maps: maps,
            directionsService: new maps.DirectionsService(),
            directionsDisplay: new maps.DirectionsRenderer({
                suppressMarkers: true
            })
        });
    };

    const update = async () => {
        const { status, result: guild } = await Jobin.Guild.get(
            job.category.subCategoryId || job.category.categoryId
        );
        if (status === "ok" && guild.get("Price")) {
            let priceAmount = null;
            // fixed price
            const fixedPrice = guild.get("Price").fixed;
            if (fixedPrice) {
                const subPostalCode = postalCode.substring(0, 2);
                if (fixedPrice[subPostalCode]) {
                    dispatch({
                        type: "JOB_PRICE",
                        amount: fixedPrice[subPostalCode].user
                    });
                    priceAmount = fixedPrice[subPostalCode].user;
                } else if (fixedPrice.default) {
                    dispatch({
                        type: "JOB_PRICE",
                        amount: fixedPrice.default.user
                    });
                    priceAmount = fixedPrice.default.user;
                } else {
                    dispatch({
                        type: "JOB_PRICE",
                        amount: null
                    });
                    priceAmount = null;
                }
            }
            const next = job.transport
                ? "/job/new/weight"
                : priceAmount !== null
                ? "/job/new/price"
                : "/job/new/description";
            job.history.push({
                pathname: next,
                search: props.location.search,
                state: {
                    inner: true
                }
            });
        } else {
            job.history.push({
                pathname: job.transport
                    ? "/job/new/weight"
                    : job.fromLeadToFixed
                    ? "/job/new/price"
                    : "/job/new/description",
                search: props.location.search,
                state: {
                    inner: true
                }
            });
        }

        dispatch({
            type: "JOB_LOCATE",
            address: address,
            address2: address2,
            addressSelected: addressSelected,
            center: center,
            disabledBtn: disabledBtn,
            error: error,
            postalCode: postalCode
        });
    };

    const searchOptions = {
        types: ["address"],
        componentRestrictions: { country: ["es", "pt", "fr"] }
    };

    useEffect(() => {
        if (
            !(props.location.state && "inner" in props.location.state) &&
            !props.location.search.includes("from=details")
        )
            props.history.replace("/job/new");

        dispatch({
            type: "JOB_ROUTER",
            history: props.history,
            location: props.location,
            match: props.match
        });

        //Search store location if needed
        if (job.transport && !storeLocation) {
            setStoreLocation({
                lat: job.store.latitude,
                lng: job.store.longitude
            });
        }

        if (
            props.location.search.includes("from=details") &&
            job.locate.address &&
            job.locate.address !== ""
        )
            handleSelect(job.locate.address);

        // unmount
        return () => {
            didCancel = true;
        };
    }, []);

    const Marker = () => (
        <div className="maps-marker">
            <img src={mapsMarker} alt="marker" />
        </div>
    );

    return (
        <div id="service-location" className="section">
            <BreadCrumb history={props.history} location={props.location} />
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="title">
                            ¿Donde se necesita el servicio?
                        </h4>
                        <p className="hidden-md hidden-sm hidden-xs">
                            Indica la dirección exacta donde se realizará el
                            servicio. Es importante que sea con la mayor
                            precisión posible.
                        </p>
                        <p className="hidden-lg">
                            Indica la dirección exacta donde se realizará el
                            servicio. Es importante que sea con la mayor
                            precisión posible.
                        </p>
                        <div className="content">
                            <PlacesAutocomplete
                                value={address}
                                onChange={handleChange}
                                onSelect={handleSelect}
                                searchOptions={searchOptions}
                            >
                                {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading
                                }) => (
                                    <div>
                                        <SearchBar
                                            style={{ marginTop: 24 }}
                                            {...getInputProps({
                                                placeholder:
                                                    "Escribe la dirección exacta"
                                            })}
                                        />
                                        <div
                                            className={
                                                suggestions.length > 0
                                                    ? "autocomplete-dropdown-container ready"
                                                    : "autocomplete-dropdown-container"
                                            }
                                        >
                                            {loading && <div>Cargando...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? "suggestion-item active"
                                                    : "suggestion-item";
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(
                                                            suggestion,
                                                            {
                                                                className
                                                            }
                                                        )}
                                                    >
                                                        <span>
                                                            {
                                                                suggestion.description
                                                            }
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            {errorMsg && (
                                <em className="color-coral">{errorMsg}</em>
                            )}
                            <SearchBar
                                style={{ marginTop: 8 }}
                                placeholder="Piso, escalera, puerta"
                                styleinput={{ paddingLeft: 16 }}
                                defaultValue={job.locate.address2}
                                onChange={handleChange2}
                                hideicon={true}
                            />
                            {distance && (
                                <div className="distance">
                                    <label>{distance}</label>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-offset-1 col-md-4">
                        <div className="maps">
                            <GoogleMapReact
                                bootstrapURLKeys={{
                                    key: props.mapsKey
                                }}
                                center={center}
                                zoom={zoom}
                                yesIWantToUseGoogleMapApiInternals
                                onGoogleApiLoaded={({ map, maps }) =>
                                    apiIsLoaded(map, maps)
                                }
                            >
                                {addressSelected && (
                                    <Marker lat={center.lat} lng={center.lng} />
                                )}
                                {storeLocation && (
                                    <Marker
                                        lat={storeLocation.lat}
                                        lng={storeLocation.lng}
                                    />
                                )}
                            </GoogleMapReact>
                        </div>
                    </div>
                </div>
            </div>
            <StepControlls
                page={props.step}
                disabled={disabledBtn}
                nextTrigger={!addressSelected}
                nextTitle="Siguiente"
                prev={
                    props.location.state && props.location.state.param
                        ? undefined
                        : props.location.search.includes("from=details")
                        ? undefined
                        : "root"
                }
                update={update}
            />
        </div>
    );
}
