import React, { useState, useEffect } from "react";


const WollerState = ({wollers,budget,totalSlots}) => {

   const styes={
    
    joberIcon:{
        height: 36,
        width: 36,
        borderRadius: 18,
        marginRight: 6
    }
   }
  
   const getHelmetType= ()=>{
    let helmets = []
    for (let index = 0; index < totalSlots; index++) {
        if(wollers && wollers[index]){
            helmets.push(<FullPlace budget_={budget && budget[index]?budget[index]:null}/>)
        }else{
            helmets.push(<VoidPlace />)
        }
        
    }
    
    return helmets
   }
   const VoidPlace = ()=>{
        return (
            <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <defs>
                <rect id="path-1" x="0" y="0" width="40" height="40" rx="20"></rect>
                <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="40" height="40" fill="white">
                    <rect id="path-1" x="0" y="0" width="40" height="40" rx="20"></rect>
                </mask>
            </defs>
            <g id="Icono-/-Background-/-Pro-/-Empty" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="40" height="40" fill="white">
                    <rect id="path-1" x="0" y="0" width="40" height="40" rx="20"></rect>
                </mask>
                <g id="Icono-/-Pro-/-Empty-/-Outline" transform="translate(10.000000, 10.000000)">
                    <mask id="mask-4" fill="white">
                        <path d="M4.16666667,11.9189167 L4.16666667,11.6664167 C4.16666667,9.94558333 4.7825,8.01891667 6.36833333,7.14641667 L5.9975,11.4089167 L7.67083333,11.4089167 L8.16333333,5.74141667 C8.21083333,5.31891667 8.56666667,4.99975 8.99166667,4.99975 L11.0083333,4.99975 C11.4333333,4.99975 11.7891667,5.31891667 11.835,5.72141667 L12.3291667,11.4089167 L14.0025,11.4089167 L13.6308333,7.14641667 C15.2175,8.01891667 15.8333333,9.94558333 15.8333333,11.6664167 L15.8333333,11.9189167 L16.7758333,13.3330833 L3.22416667,13.3330833 L4.16666667,11.9189167 Z M18.3333333,14.99975 L19.0266667,13.70475 L17.4966667,11.4089167 C17.4133333,8.33058333 15.8283333,6.03058333 13.435,5.27558333 C13.1775,4.15308333 12.1858333,3.33308333 11.0083333,3.33308333 L8.99166667,3.33308333 C7.82,3.33308333 6.83166667,4.14475 6.56666667,5.27475 C4.1725,6.02975 2.58666667,8.32975 2.50333333,11.4089167 L0.973333333,13.70475 L1.66666667,14.99975 L18.3333333,14.99975 Z" id="path-3"></path>
                    </mask>
                    <path d="M4.16666667,11.9189167 L4.16666667,11.6664167 C4.16666667,9.94558333 4.7825,8.01891667 6.36833333,7.14641667 L5.9975,11.4089167 L7.67083333,11.4089167 L8.16333333,5.74141667 C8.21083333,5.31891667 8.56666667,4.99975 8.99166667,4.99975 L11.0083333,4.99975 C11.4333333,4.99975 11.7891667,5.31891667 11.835,5.72141667 L12.3291667,11.4089167 L14.0025,11.4089167 L13.6308333,7.14641667 C15.2175,8.01891667 15.8333333,9.94558333 15.8333333,11.6664167 L15.8333333,11.9189167 L16.7758333,13.3330833 L3.22416667,13.3330833 L4.16666667,11.9189167 Z M18.3333333,14.99975 L19.0266667,13.70475 L17.4966667,11.4089167 C17.4133333,8.33058333 15.8283333,6.03058333 13.435,5.27558333 C13.1775,4.15308333 12.1858333,3.33308333 11.0083333,3.33308333 L8.99166667,3.33308333 C7.82,3.33308333 6.83166667,4.14475 6.56666667,5.27475 C4.1725,6.02975 2.58666667,8.32975 2.50333333,11.4089167 L0.973333333,13.70475 L1.66666667,14.99975 L18.3333333,14.99975 Z" id="path-3"></path>

                    <g id="✱-/-Color-/-Black-/-400" mask="url(#mask-4)" fill="#B0B0B0" fillRule="evenodd">
                        <rect id="Rectangle-13" x="0" y="0" width="20" height="20"></rect>
                    </g>
                </g>
            </g>
        </svg>
        )
    }
   const FullPlace = ({budget_})=>{
       return (
        <svg width="38" height="38" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <title>Layer 1</title>
            <g id="Mask">
                <path stroke="#000000" fill={
                    !budget_?"#d2d2d2":
                    budget_?.state == "estimated" ? "#35FFC8": "#FF3569"
                    } opacity="undefined" d="m19,37.83183c-10.40777,0 -18.83806,-8.4275 -18.83806,-18.83183c0,-10.40433 8.43029,-18.83183 18.83806,-18.83183c10.40777,0 18.83806,8.4275 18.83806,18.83183c0,10.40433 -8.43029,18.83183 -18.83806,18.83183z" id="svg_3" stroke-opacity="0"/>
                <path strokeWidth="0" stroke="null" fill="#444444" d="m19.96074,9.56174c1.12193,0 2.06679,0.77316 2.31214,1.83338c2.28038,0.71259 3.79057,2.88339 3.86997,5.7888l0,0l1.45779,2.16608l-0.66061,1.22305l-0.72096,0l0,0.78652c0,3.90271 -3.20618,7.07869 -7.14602,7.07869c-3.93985,0 -7.14603,-3.17598 -7.14603,-7.07869l0,0l0,-0.78652l-0.86705,0l-0.66061,-1.22305l1.45858,-2.16608c0.07939,-2.9062 1.58959,-5.07779 3.87156,-5.78958c0.2517,-1.06653 1.19338,-1.8326 2.30976,-1.8326l0,0l1.92149,0l-0.00001,0zm4.67032,11.01131l-11.11604,0l0,0.78652c0,3.03597 2.49316,5.50564 5.55802,5.50564c3.06486,0 5.55802,-2.46967 5.55802,-5.50564l0,0l0,-0.78652zm-8.40993,0.78652c0.65744,0 1.19101,0.52854 1.19101,1.17978c0,0.65124 -0.53357,1.17979 -1.19101,1.17979c-0.65743,0 -1.191,-0.52855 -1.191,-1.17979c0,-0.65124 0.53357,-1.17978 1.191,-1.17978zm5.55803,0c0.65743,0 1.19101,0.52854 1.19101,1.17978c0,0.65124 -0.53358,1.17979 -1.19101,1.17979c-0.65743,0 -1.191,-0.52855 -1.191,-1.17979c0,-0.65124 0.53357,-1.17978 1.191,-1.17978zm-1.81842,-10.22478l-1.9215,0c-0.40494,0 -0.74398,0.30123 -0.78923,0.7l0,0l-0.46926,5.34913l-1.59436,0l0.35334,-4.02305c-1.51099,0.82348 -2.09776,2.64193 -2.09776,4.26609l0,0l0,0.23832l-0.89802,1.33473l12.91288,0l-0.8988,-1.33473l0,-0.23832c0,-1.62416 -0.58678,-3.4426 -2.09776,-4.26609l0,0l0.35332,4.02305l-1.59356,0l-0.47164,-5.36801c-0.04288,-0.37989 -0.38271,-0.68111 -0.78764,-0.68111l0,0l-0.00001,-0.00001z" id="svg_1"/>
            </g>
        </svg>
        )
    }
    
   return(
       <div style={styes.joberIcon}>
            {getHelmetType()}
       </div>
   )
};

export default WollerState;
