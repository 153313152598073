import React, {useEffect, useState} from "react";
import { formatNotificationMessage } from "../../utils/formatter"
import { useStateValue } from "../../state/context";
import { Button } from "jobin-ui-components";
import ChatModal from '../modal/ChatModal';

export default function NotificationMessageCell(props) {

    const [{ userDetails },dispatch] = useStateValue();
    const [showMessagesModal, setShowMessagesModal] = useState(false);


    const style = {
        container:{
            borderBottom: '1px solid var(--soft-grey)',
            paddingRight: 24,
            paddingLeft: 24,
            paddingTop: 16,
            paddingBottom: 16
        },
        content:{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
        },
        title:{
            fontSize: 16,
            fontWeight: 700,
            marginBottom: 8,
            lineHeight: '18px'
        },
        description:{
            fontSize: 13,
            marginBottom: 8,
            color: 'var(--greyish)'
        },
        date:{
            fontSize: 13,
            fontWeight: 700,
            color: 'var(--greyish)'
        },
        container_logo:{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        containerLeft:{
            borderRight: '1px solid var(--soft-grey)',
            paddingTop: 16,
            paddingBottom: 16
        },
    }

    const goToUser = id => {
        props.history.push({
            pathname: "/user/" + id,
            state: {
                inner: true
            }
        });
    };

    const showModal = (_chats)=>{
        setShowMessagesModal(true)
    }

    const closeChatModal= (data)=>{
        dispatch({
            type: "USER_DETAILS",
            chat: data.chat
        });
        setShowMessagesModal(false)
    }

    const checkMessages = (_chats) =>{
        var newMessages = 0
        _chats.map((message)=>{
            if(!message.read && message.user){
                newMessages+=1
            }
        })
        return newMessages
    }
    
    return (
        <div style={style.container} onClick={() => goToUser(props.notification.get("User").id)}>
            {showMessagesModal && (
                    <ChatModal 
                        isVisible={showMessagesModal} 
                        chat={userDetails.chat} 
                        user={userDetails.user} 
                        onClose={(chat)=>closeChatModal(chat)} 
                    />
                )}
            <div style={style.container_logo}>
                <div>
                    <h5 style={style.title}>Has recibido un mensaje de un Woller</h5>  
                    <p style={style.containerLeft}>Mensaje: {props.notification.get("Note")}</p>
                    <p style={style.description}>Nombre: {props.notification.get("User")?.get("Name")}</p>
                    <p style={style.description}>Fecha: { formatNotificationMessage( props.notification?.get("createdAt")?.toString() )}</p>
                    <Button
                        style={{marginLeft:"auto",marginTop:"5px", height: 36, padding: 0, width: 110, backgroundColor: "#000000", borderColor: "#000000", color: "#FFE235" }}
                        buttonText={checkMessages(userDetails.chat) < 1 ?  "Chat":"+"+checkMessages(userDetails.chat)}
                        onClick={ ()=>{showModal(); }}
                    />
                </div>
            </div>
        </div>
    )
}   
