import React, {useState, useEffect } from "react";

import GenericTable from "./resultTable/GenericTable";
import { Loading } from "jobin-ui-components"

import { useStateValue } from "../../state/context";

import "../../styles/search/result-table.css";

export default function ResultDashboard({history,location}) {

    const [{ matchDetails}, dispatch] = useStateValue();
    
    const itemDetail = id => {
        window.open(`#/job/${id}`, "_blank")
    };

    const matchsTable = (matchs)=>{
        let rows = matchs.map(match=>{
            return(
                <tr key={match.id}>
                    <td className="mediumText" onClick={()=>{itemDetail(match.servicio.id)}}>{match.servicio?.id}</td>
                    <td className="mediumText">{match.servicio && match.servicio.get("Business")?.get("Logo") ? 
                        <img style={{width: 60, height: 60}} src={ match.servicio.get("Business")?.get("Logo").url()} alt="Negocio" /> : null }
                    </td>
                    <td className="mediumText">{match.servicio? match.servicio.get("Title"):null}</td>
                    <td className="mediumText">{match.province? match.province:null}</td>
                    <td className="mediumText">{match.agente?`${match.agente.get("Name")} ${match.agente.get("Lastname")}`:"Automatico"}</td>
                    <td className="mediumText">{`${match.date?.toLocaleDateString("es-ES")} - ${match.date?.toLocaleTimeString()}`}</td>
                    <td className="mediumText">{match.servicio?.get("createdAt")? `${match.servicio.get("createdAt")?.toLocaleDateString("es-ES")} - ${match.servicio.get("createdAt")?.toLocaleTimeString()}`:null}</td>
                    <td className="mediumText">{match.servicio && match.servicio.get("JobType") == "fixed"?"Precio cerrado": match.servicio && match.servicio.get("JobType") == "lead"?"Lead":null}</td>
                    <td className="mediumText">{match.servicio? match.servicio.get("From"):null}</td>
                </tr>)
            })
        return(
            <GenericTable className="tableSearch-match" heads={matchDetails.matchTitles} rows={rows}/>
        )
    }
 
    if (matchDetails.loading) {
        return (
            <section className="loading-container match">
                <Loading />
            </section>
        );
    } else {
        return (
            <>
              <div className="table-wrapper-match">
                  {matchDetails && matchDetails.services?matchsTable(matchDetails.services):null}
              </div>
            </>
        )
    }
}
