import React, { useState } from "react";
import Jobin from "jobin-client";

import ReceiptHeader from "./receipt/ReceiptHeader";
import BillingInfo from "./receipt/BillingInfo";
import {
    BillHeaders,
    DisplayRow,
    InputRow,
    ButtonRow,
    TotalFooter
} from "./receipt/BillRows";
import { styles } from "./receipt/styles";

export default function BillingEditor(props) {
    const receiptName =
        props.payment && props.payment.has("ReceiptName")
            ? props.payment.get("ReceiptName")
            : undefined;
    const receiptType =
        props.payment && props.payment.has("ReceiptInfo")
            ? props.payment.get("ReceiptInfo").type
            : undefined;
    const receiptDate = props.payment
        ? props.payment.get("createdAt")
        : undefined;

    const [showNewRow, setShowNewRow] = useState(false);

    const [editable, setEditable] = useState(false);
    const [menuItems] = useState([
        {
            id: "edit",
            title: "Editar"
        },
        {
            id: "delete",
            title: "Borrar"
        }
    ]);

    const onSaveBillingInfo = async (
        fiscalName,
        identityNumber,
        fiscalAddress,
        fiscalPostalCode,
        fiscalCity
    ) => {
        const business = props.billingInfo
            ? props.billingInfo.get("Business")
            : props.payment.get("Business");
        const user = props.billingInfo
            ? props.billingInfo.get("User")
            : props.payment.get("User");
        if (business) {
            let { status, result } = await Jobin.Business.setBillingInfo(
                business.id,
                fiscalName,
                identityNumber,
                fiscalAddress,
                fiscalPostalCode,
                fiscalCity
            );
            if (status == "ok") {
                setEditable(false);
                props.onBillingInfoChanged(result);
            }
        } else if (user) {
            let { status, result } = await Jobin.Client.createBillingInfo(
                fiscalName,
                identityNumber,
                fiscalAddress,
                fiscalPostalCode,
                fiscalCity,
                user.id
            );
            if (status == "ok") {
                setEditable(false);
                props.onBillingInfoChanged(result);
            }
        }
    };

    return (
        <section style={styles.container}>
            <ReceiptHeader receiptName={receiptName} date={receiptDate} />
            <BillingInfo
                billingInfo={props.billingInfo}
                reverse={receiptType === "reverse"}
                editable={editable}
                onEdit={() => setEditable(true)}
                onSave={onSaveBillingInfo}
            />
            <div style={styles.billContainer}>
                <table>
                    <BillHeaders />
                    <tbody>
                        {props.rowArray.map((item, index) =>
                            item.editable ? (
                                <InputRow
                                    index={index}
                                    item={item}
                                    saveRow={data => {
                                        props.editRow(index, data);
                                    }}
                                />
                            ) : (
                                <DisplayRow
                                    index={index}
                                    item={item}
                                    menuItems={menuItems}
                                    buttonItemSelected={option => {
                                        if (option.id === "edit")
                                            props.enableEdit(index);
                                        else if (option.id === "delete")
                                            props.deleteRow(index);
                                    }}
                                />
                            )
                        )}
                        {showNewRow && (
                            <InputRow
                                index={props.rowArray.length}
                                saveRow={data => {
                                    props.addRow(data);
                                    setShowNewRow(false);
                                }}
                            />
                        )}
                        <ButtonRow
                            buttonText="Añadir nueva partida"
                            onClick={() => setShowNewRow(true)}
                            disabled={showNewRow}
                        />
                    </tbody>
                    <TotalFooter totalPrice={props.totalPrice} />
                </table>
            </div>
        </section>
    );
}
