import React from "react";

import "../../../styles/barDiagram/barDiagram.css"


export default function BarDiagram(props) {

    const Charts = (props)=>{         
            let data = props.data;
            let max = 0;
        
            for (let i = data.length; i--; ) {
                if (data[i].value > max) {
                    max = data[i].value;
                }
            }
        
            const randKey = Math.floor(Math.random() * (100 - 1)) + 1;
            return (
                <div className={ 'Charts' + (props.horizontal ? ' horizontal' : '' ) }>
                    { data.map(function (serie, serieIndex) {
                        
                        let color = props.colors;
                        let size = serie.value / (max) * 100;
                        
                        var style = {
                            backgroundColor: color,
                            opacity:  1 ,
                            zIndex: serie.value,
                        };
                    
                        if (props.horizontal) {
                            style['width'] = size + '%';
                        } else {								
                            style['height'] = size + '%';						
                        }
                            
                            
                        return (
                            <div key={'Charts'+serie.value+randKey} className={ 'Charts--serie ' + (props.grouping) }
                                key={ serieIndex }
                                style={{ height: props.height ? props.height: 'auto' }}
                            >
                                <div
                                    className={ 'Charts--item ' + (props.grouping) }
                                    style={ style }
                                    key={ serieIndex }
                                >
                                    <b style={{ color: color }}>{ serie.value }</b>
                                </div>
                    
                            <label>{ serie.day }</label>
                            </div>
                        );
                    }) }
                </div>
            );
       
    }

    return (
        <div className="display-flex  flex-justify-between diagramBox flex-column" >
                <span className="singleContextTitles ">{props.globalContext}</span>
                {props.data && props.color &&(
				<Charts
					data={ props.data !== null ? props.data:null } //valores de cada barra
					colors={ props.color !== null ? props.color:null }// --coral
					height={ 300 } // for review
				/>
			)}
        </div>
    );
}


