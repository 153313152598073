import React, { useState, useEffect } from "react";
import { CallToAction, Input, MenuList } from "jobin-ui-components";
import { styles } from "./styles";

export const BillHeaders = () => {
    return (
        <thead>
            <tr key={"headers"} style={styles.billTitles}>
                <th style={styles.descriptionColumn}>
                    <label style={{ color: "var(--coral)" }}>Descripción</label>
                </th>
                <th style={styles.billColumn}>
                    <label style={{ color: "var(--coral)" }}>Cantidad</label>
                </th>
                <th style={styles.billColumn}>
                    <label style={{ color: "var(--coral)" }}>
                        Precio unid.
                    </label>
                </th>
                <th style={styles.billColumn}>
                    <label style={{ color: "var(--coral)" }}>Importe</label>
                </th>
                <th style={styles.dotsColumn} />
            </tr>
        </thead>
    );
};

export const ButtonRow = props => {
    return (
        <tr key={"button"}>
            <td>
                <CallToAction
                    style={styles.descriptionColumn}
                    buttonText={props.buttonText}
                    onClick={props.onClick}
                    disabled={props.disabled}
                />
            </td>
        </tr>
    );
};

export const DisplayRow = props => {
    const [showMenuList, setShowMenuList] = useState(false);
    return (
        <tr key={"row-" + props.index} style={styles.billRow}>
            <td style={styles.descriptionColumn}>
                <label>{props.item.description}</label>
            </td>
            <td style={styles.billColumn}>
                <label>{props.item.amount}</label>
            </td>
            <td style={styles.billColumn}>
                <label>{props.item.pricePerUnit + " €"}</label>
            </td>
            <td style={styles.billColumn}>
                <label>
                    {(props.item.pricePerUnit * props.item.amount).toFixed(2) +
                        " €"}
                </label>
            </td>
            {props.menuItems && props.menuItems.length > 0 ? (
                <td
                    style={{
                        ...styles.dotsColumn,
                        cursor: "pointer",
                        position: "relative"
                    }}
                    onClick={() => setShowMenuList(show => !show)}
                >
                    <img
                        src={require("../../../assets/option-dots.svg")}
                        alt="option"
                    />
                    {showMenuList && (
                        <MenuList
                            style={{ left: "12px", top: "36" }}
                            items={props.menuItems}
                            buttonItemSelected={props.buttonItemSelected}
                            onClickOutside={() => setShowMenuList(false)}
                        />
                    )}
                </td>
            ) : (
                <td style={styles.dotsColumn}></td>
            )}
        </tr>
    );
};

export const TotalFooter = props => {
    return (
        <tfoot style={styles.resumeContainer}>
            <tr key={"footer-1"} style={styles.resume}>
                <td style={styles.descriptionColumn} />
                <td style={styles.billColumn} />
                <td style={styles.billColumn}>Subtotal: </td>
                <td style={styles.billColumn}>
                    {props.totalPrice.toFixed(2) + " €"}
                </td>
                <td style={styles.dotsColumn} />
            </tr>
            <tr key={"footer-2"} style={styles.resume}>
                <td style={styles.descriptionColumn} />
                <td style={styles.billColumn} />
                <td style={styles.billColumn}>IVA (21%): </td>
                <td style={styles.billColumn}>
                    {(props.totalPrice * 0.21).toFixed(2) + " €"}
                </td>
                <td style={styles.dotsColumn} />
            </tr>
            <tr key={"footer-3"} style={styles.resume}>
                <td style={styles.descriptionColumn} />
                <td style={styles.billColumn} />
                <td style={styles.billColumn}>Total: </td>
                <td style={styles.billColumn}>
                    {(props.totalPrice * 1.21).toFixed(2) + " €"}
                </td>
                <td style={styles.dotsColumn} />
            </tr>
        </tfoot>
    );
};

export const InputRow = props => {
    const [description, setDescription] = useState(
        props.item ? props.item.description : undefined
    );
    const [amount, setAmount] = useState(
        props.item ? String(props.item.amount) : undefined
    );
    const [pricePerUnit, setPricePerUnit] = useState(
        props.item ? String(props.item.pricePerUnit) : undefined
    );
    const [priceTotal, setPriceTotal] = useState(
        props.item ? props.item.pricePerUnit : "0.00"
    );
    const [allowEmpty, setAllowEmpty] = useState(true);

    const priceOK = input =>  /^\d+(\.\d{2})?$/.test(input);
    const amountOK = input => /^\d+(\.\d{2})?$/.test(input);

    useEffect(() => {
        if (amount && amountOK(amount) && pricePerUnit && priceOK(pricePerUnit))
            setPriceTotal((Number(amount) * Number(pricePerUnit)).toFixed(2));
    }, [amount, pricePerUnit]);

    const save = () => {
        if (
            description &&
            amount &&
            amountOK(amount) &&
            pricePerUnit &&
            priceOK(pricePerUnit)
        )
            props.saveRow({
                description,
                amount,
                pricePerUnit
            });
        else setAllowEmpty(false);
    };

    return (
        <tr key={"row-" + props.index} style={styles.billRow}>
            <td style={styles.descriptionColumn}>
                <Input
                    style={{ width: "100%" }}
                    placeholder="Descripción"
                    value={description}
                    badInput={!description && !allowEmpty}
                    onChange={e => setDescription(e.target.value)}
                />
            </td>
            <td style={styles.billColumn}>
                <Input
                    style={{ width: "100%" }}
                    placeholder="Cant."
                    value={amount}
                    badInput={amount ? !amountOK(amount) : !allowEmpty}
                    onChange={e => setAmount(e.target.value)}
                />
            </td>
            <td style={styles.billColumn}>
                <Input
                    style={{ width: "100%" }}
                    placeholder="Precio"
                    value={pricePerUnit}
                    badInput={
                        pricePerUnit ? !priceOK(pricePerUnit) : !allowEmpty
                    }
                    onChange={e => setPricePerUnit(e.target.value)}
                />
            </td>
            <td style={styles.billColumn}>
                <label>{priceTotal} €</label>
            </td>
            <td style={styles.dotsColumn} onClick={save}>
                <img src={require("../../../assets/tick.svg")} alt="Guardar" />
            </td>
        </tr>
    );
};


export const PriceInputRow = props => {
    const [pricePerUnit, setPricePerUnit] = useState(
        props.item ? String(props.item.pricePerUnit) : undefined
    );
    const [priceTotal, setPriceTotal] = useState(
        props.item ? props.item.pricePerUnit : "0.00"
    );
    const [allowEmpty, setAllowEmpty] = useState(true);

    const priceOK = input => /^-?\d+\.\d{2}$/.test(input);

    useEffect(() => {
        if (pricePerUnit && priceOK(pricePerUnit))
            setPriceTotal((props.item.amount * Number(pricePerUnit)).toFixed(2));
    }, [pricePerUnit]);

    const save = () => {
        if (
            pricePerUnit &&
            priceOK(pricePerUnit)
        )
            props.saveRow({
                description : props.item.description,
                amount: props.item.amount,
                pricePerUnit
            });
        else setAllowEmpty(false);
    };

    return (
        <tr key={"row-" + props.index} style={styles.billRow}>
            <td style={styles.descriptionColumn}>
                <label>{props.item.description}</label>
            </td>
            <td style={styles.billColumn}>
                <label>{props.item.amount}</label>
            </td>
            <td style={styles.billColumn}>
                <Input
                    style={{ width: "100%" }}
                    placeholder="Precio"
                    value={pricePerUnit}
                    badInput={
                        pricePerUnit ? !priceOK(pricePerUnit) : !allowEmpty
                    }
                    onChange={e => setPricePerUnit(e.target.value)}
                />
            </td>
            <td style={styles.billColumn}>
                <label>{priceTotal} €</label>
            </td>
            <td style={styles.dotsColumn} onClick={save}>
                <img src={require("../../../assets/tick.svg")} alt="Guardar" />
            </td>
        </tr>
    );
};
