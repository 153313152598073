import React, { useEffect,useState } from "react";
import Slider from "../singleComponents/Slider";

import { useDebounce } from "../../utils/hooks";
import { useStateValue } from "../../state/context";


import "../../styles/explore/searchNav.css"

export default function SearchNav({findJobsforBiPro}) {
    const [{explore}, dispatch] = useStateValue();

    const [filterBy_,setFilterBy_] = useState("recent")
    const [distance_, setDistance] = useState()

    const debounceDistance = useDebounce(distance_, 1000);
    useEffect(()=>{
        if(debounceDistance!= undefined){
            dispatch({
                    type:"EXPLORE_FILTER_DISTANCE",
                    distance:debounceDistance
                })
            findJobsforBiPro({distance:debounceDistance}) 
        }
    },[debounceDistance])

    const filterBy =(criterya)=>{
        setFilterBy_(criterya)
        let sortedServices  
        if(criterya == "recent"){
            sortedServices = explore.services.sort((aServ, bServ)=>{
                return aServ.createdAt.getTime() - bServ.createdAt.getTime() 
            })
            sortedServices.reverse()
        }else if(criterya == "closer"){
            sortedServices = explore.services.sort((aServ, bServ)=>{
                return aServ.distanceFormWoller - bServ.distanceFormWoller 
            })
        }else if(criterya == "cheap"){
            sortedServices = explore.services.sort((aServ, bServ)=>{
                return parseInt(aServ.jobLeadPrice) - parseInt(bServ.jobLeadPrice) 
            })
        }

        dispatch({
            type:"EXPLORE_RESULTS",
            services:sortedServices
        })
    }
    const findByDistance = async (distance)=>{
        setDistance(distance.target.value)
    }
   return(
    <div className="searchNavContainer"> 
        <Slider
            type="distance"
            title="Dist"
            initialValue={explore.distance}
            unit="Km"
            handleChange={findByDistance}
            minValue={1}
            maxValue={100}
        />
        <p className="title">ordenar por:</p>
        <div onClick={()=>filterBy("recent")} className={filterBy_ == "recent" ? "buttonFilter active":"buttonFilter"}>Reciente</div>
        <div onClick={()=>filterBy("closer")} className={filterBy_ == "closer" ? "buttonFilter center active":"buttonFilter center"}>Cercanos</div>
        <div onClick={()=>filterBy("cheap")} className={filterBy_ == "cheap" ? "buttonFilter active":"buttonFilter"}>Baratos</div>
    </div>
   )
}