import React, {  useEffect, useState } from "react";
import {Loading} from 'jobin-ui-components'
import { getVerticalsWithFaters } from "../../utils/ParserUtilities";
import VerticalCard from "./components/VerticalCard"
import {setVerticalPrices} from "../../utils/ParserUtilities"


import "../../styles/search/result-table.css";

export default function ResultDashboard(props) {
   
  const [ verticals ,  setVerticals] = useState(false);
  const [ loading ,  setLoading] = useState(true);

  useEffect(()=>{
    async function fetchAsync(){
      setVerticals(await getVerticalsWithFaters())
      setLoading(false)
    }
    fetchAsync()
  },[])

  const saveVerticalPrices = async (id,wollerPrice,userPrice) => {
      await setVerticalPrices(id,wollerPrice,userPrice,)
  }

  if (loading) {
      return (
          <section className="loadingContainer display-flex flex-align-center flex-justify-center">
              <Loading />
          </section>
      );
  } else {
      return (
          <div className="result-cards-container">
            {verticals.map(vertical=>{
              return vertical.sons.length> 0 ? (
                <div className="display-flex flex-column " key={vertical.id}>
                  <p className="sectionTitle">{vertical.name}</p>
                  <div className="display-flex flex-row  flex-wrap-wrap">
                    { vertical.sons.map(son=>{
                      return(
                        <VerticalCard key={son.id} saveVerticalPrices={saveVerticalPrices} verticalSon={son} wollerPrice={son.get("Price")?.fixed.default.jober} userPrice={son.get("Price")?.fixed.default.user}/>
                      )
                    })}
                  </div>
                </div>
              ):null
            })}
            
          </div>
      );
  }
}
