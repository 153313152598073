import React, {useState} from 'react';

import MainModal from './MainModal'
import Button from '../button/Button'

const GuaranteeModal = (props) => {

    const style = {
        textContent: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            marginTop: 12
        },
        subtitle:{
            marginBottom: 4,
            fontWeight: "bold"
        },
        textarea:{
            padding: 4,
            outline: 'none',
        }
    }

    const [description, setDescription] = useState(null);

    return (
        <MainModal
			title={"Aplicar garantía"}
			subtitle={"Si continuas se entiende que hubo un problema con servicio que debe ser revisado."}
			isVisible={props.isVisible}
            onClose={props.onClose}
		>
            <section>
                <div style={style.textContent}>
                    <label style={style.subtitle}>Indica el motivo</label>
                    <textarea
                        style={style.textarea}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent:'flex-end', marginTop: 8}}>
                    <Button
                        buttonText = {"Aplicar garantía"}
                        onClick={() => props.onClick(description)}
                        disabled={description ? false : true}
                    />
                </div>
            </section>
        </MainModal>
    )
}

export default GuaranteeModal;