import React, { useState, useEffect } from "react";
import { useStateValue } from "../../state/context";
import { urgencyStates } from "../../constants/urgencyStates";
import StepTip from "./generic/StepTip";
import StepControlls from "./generic/StepControlls";
import BreadCrumb from "./generic/BreadCrumb";
import SignIn from "../../components/job/SignIn";

import {RadioButton} from 'jobin-ui-components'

import "../../styles/job/service-urgency.css";

import stepIconLeft from "../../assets/step-icon-left.svg";

export default function ServiceUrgency(props) {
    const [{ job }, dispatch] = useStateValue();

    const [selectedOption, setSelectedOption] = useState(
        job.urgency.selectedOption
    );
    const [disabledBtn, setDisabledBtn] = useState(job.urgency.disabledBtn);
    const [loading, setLoading] = useState(false);

    let didCancel = false;

    const handleChange = urgency => {
        if (!didCancel) {
            setSelectedOption(urgency);
            setDisabledBtn(false);
        }
    };

    const update = () => {
        dispatch({
            type: "JOB_URGENCY",
            selectedOption: selectedOption
        });

        showSignInModal();
    };

    const showSignInModal = () => {
        dispatch({
            type: "JOB_SIGNIN_SHOW_HIDE",
            visible: true
        });
        dispatch({
            type: "JOB_SIGNIN_STEP",
            step: 1
        });
    };

    useEffect(() => {
        if (!(props.location.state && "inner" in props.location.state))
            props.history.replace("/job/new");
    }, []);

    return (
        <div id="service-urgency" className="section">
            <BreadCrumb history={props.history} location={props.location} />
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="title">¿Para cuando lo necesita?</h4>
                        <p>Avisaremos a los Jobers para que sean conscientes de cuándo el cliente quiere realizar el servicio.</p>
                        <div className="content">
                            <div className="urgency-list">
                                {urgencyStates.map((urgency, index) => {
                                    return (
                                        <RadioButton
                                            key={"radio"+index}
                                            customOnImage={
                                                index === 0 ?
                                                    <img style={{marginRight: 12}} src={require('../../assets/urgent-selected.svg')} alt="urgent-logo"/>
                                                    : null
                                            }
                                            customOffImage={
                                                index === 0 ?
                                                    <img style={{marginRight: 12}} src={require("../../assets/urgent-unselected.svg")} alt="unurgent-logo"/>
                                                    : null
                                            }
                                            item={{
                                                title: urgency.name,
                                                selected: selectedOption === urgency.id ? true : false
                                            }}
                                            onClick={() => handleChange(urgency.id)}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-offset-1 col-md-4">
                        <StepTip
                            iconLeft={stepIconLeft}
                            title="Intenta ser lo más exacto posible"
                            excerpt="Para mejorar la experiencia del cliente y de nuestros Jobers es importante ser siempre lo más precisos posibles."
                        />
                    </div>
                </div>
            </div>
            <StepControlls
                page={props.step}
                disabled={disabledBtn}
                nextTitle="Siguiente"
                nextTrigger={selectedOption === null}
                loadTrigger={loading}
                prev="job/new/picture"
                update={update}
            />
            {job.signIn.visible && <SignIn step={job.signIn.step} />}
        </div>
    );
}
