import React, { useEffect, useState } from 'react';

import DatePicker from 'react-datepicker';
import Select from 'react-select';

import { getAllCampains } from "../../../utils/ParserUtilities"

export default function Filters({selectedCampain, setSelectedCampain, dateRange, setDateRange}) {

    const [allCampains, setAllcampains] = useState();

    const [startDate, endDate] = dateRange;

    const handleCampainChange = e => setSelectedCampain(e);

    useEffect(()=>{
        getAllCampainsRequest()
    },[])

    const getAllCampainsRequest = async () => {
        let campains = await getAllCampains();

        const formatedCampains = [];
        campains.map( province => {
            let formatedVertical = {
                value: province.id,
                label: province.get("Name")
            }
            formatedCampains.push(formatedVertical);
        })
        setAllcampains(formatedCampains);
    }

    return (
        <div style={{width:"50%", marginLeft: "22px"}}>
            <p className='campains-filter-title'>Filtros</p>
            <p className='campains-filter'>Selecciona el tipo:</p>
            <Select
                options={allCampains}
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={selectedCampain}
                onChange={handleCampainChange} 
            />
            <p className='campains-filter'>Selecciona una fecha:</p>
            <DatePicker
                className="maps-filter-datepicker"
                dateFormat={" dd/MM/yyyy "}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                    let endDate;
                    let startDate;
                    if(update[1] != null){
                        endDate = new Date(update[1].setHours(update[1].getHours()+23));
                        startDate = new Date(update[0].setHours(update[0].getHours()+2));
                        setDateRange([new Date(startDate),new Date(endDate)]);
                    } else {
                        setDateRange(update)
                    }
                }}
                withPortal
            />
        </div>
    )
}
