import React from "react";
import SearchNav from "../../components/user/SearchNav";
import ResultTable from "../../components/user/ResultTable";
import { useStateValue } from "../../state/context";




export default function Search(props) {
    const [{userSearch}] = useStateValue();

    return (
        <React.Fragment>
            <SearchNav history={props.history} typeArray={userSearch.typeArray} location={props.location}/>
            <ResultTable history={props.history} location={props.location}/>
        </React.Fragment>
    );
}
