import React from "react";
import { useStateValue } from "../../state/context";
import {  getNotificationsByJob } from "../../utils/ParserUtilities";
import Jobin from "jobin-client";
import { getParameterByName } from "../../utils/formatter";
import { Loading } from 'jobin-ui-components'
import JobCell from '../JobCell'

import "../../styles/search/pipedrive.css";

export default function Pipedrive(props) {

    const [{user, search }] = useStateValue();

    const style = {
        loadingContainer: {
            display: "flex",
            flex: 1,
            backgroundColor: "var(--soft-grey)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
        },
        tableContainer: {
            paddingTop: 140,
            paddingRight: 0,
            paddingLeft: 24,
          
            display: "inline-flex"
        },
        columnContainer:{
            display: "inline-block",
            height: 'calc(100vh - 160px)',
            width: 365,
            marginRight: 12
        },
        titleView:{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            padding: '8px 12px',
            backgroundColor: 'var(--soft-grey)',
            borderRadius: 4,
            marginBottom: 8,
            fontSize: 14,
            fontWeight: 600
        },
        jobsView:{
            height: '77vh',
            backgroundColor: 'var(--soft-grey)',
            borderRadius: 4,
            overflowY: 'scroll',
        }
    };

    const columnContainerTypes = [
        {title:"EN ESPERA" ,state:"standby",type:["lead"]},
        {title:"PRESUPUESTO PENDIENTE" ,state:"draft",type:["fixed","transport"]},
        {title:"PENDIENTE DE CITA" ,state:"pending",type:["fixed","transport"]},
        {title:"PENDIENTE ASIGNACIÓN PROFESIONAL" ,state:"abierto",type:["fixed","transport","lead"]},
        {title:"EN PROCESO" ,state:"abierto",type:["fixed","transport","lead"]},
        {title:"PENDIENTE DE FIRMA" ,state:"signaturePending",type:["fixed","transport"]},
        {title:"FIRMADO",titleLead:"ESTIMADO" ,state:"finalizado",type:["fixed","transport","lead"],optional:true},
        {title:"VALORADO" ,state:"review",type:["fixed","transport","lead"],optional:true},
        {title:"ELIMINADO" ,state:"eliminado",type:["fixed","transport","lead"],optional:true},
        {title:"FALSO" ,state:"falso",type:["fixed","transport","lead"],optional:true},
    ]

    
    const itemDetail = id => {
        props.history.push({
            pathname: "/job/" + id,
            state: {
                inner: true
            }
        });
    };



    const Column = props =>{
        let elements = props.items.map((item) => {
            if(search.onlyIncidences){
                if(item.incidence == undefined){
                    return <></>
                }
            }
            return(
                <JobCell
                    key={item.id}
                    job={item}
                    permissions={user?.permissions}
                    style={{marginTop: 8, marginRight: 8, marginLeft: 8, boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.3)'}}
                    onClick={() =>itemDetail(item.id) }
                    refresh={search.findJobs}
                />
            )
        })
        return elements
    }

    const ColumnContainer = props => {
        const filteredItems = search.services.filter(item =>  {
            if(search.currentIdType == "lead" && props.title === "EN PROCESO" && item.state !== "finalizado" && item.state !== "review" && item.state !== "standby"&& item.state !== "falso" ){
               if(item.totalSlots != item.nJober){
                    return true;
               } 
            } else if(search.currentIdType == "lead" && props.title === "PENDIENTE ASIGNACIÓN PROFESIONAL" && item.state !== "finalizado" && item.state !== "review" && item.state !== "standby" && item.state !== "falso"){
                
                if(item.totalSlots == item.nJober){
                    return true;
               } else return false;
               
            } else if(search.currentIdType == "fixed" && props.title === "EN PROCESO"){
                return item.state === "full";
            } else {
                return item.state === props.state 
            }
        });
        if (props.optional && filteredItems.length == 0)
            return null;
        else
            return (
                <div className="pipedrive-item">
                    <div className="pipedrive-head">{props.title}</div>
                    
                    <div className="pipedrive-column"><Column items={filteredItems}/></div>
                </div>
            );
    }
    
    if (search.loading) {
        return (
            <section className="loading-container">
                <Loading />
            </section>
        );
    } else {
        return (
            <div className="pipedrive-wrapper">
               {columnContainerTypes.map((type, i)=>{
                  return type.type.includes(search.currentIdType)&&(
                  <ColumnContainer
                   key={i}
                   title={search.currentIdType == "lead" && type.title== "FIRMADO" ? type.titleLead:type.title}
                   state={type.state}
                   optional={type.type.includes(search.currentIdType) ?false: type.optional}
               />)
               })

               }
            </div>
        );
    }
}
