import React, { useState } from "react";
import MainModal from "./MainModal";
import OpsNotificationDetailModal from "./OpsNotificationDetailModal";

const AllNotificationsModal = props => {
    const [count, setCount] = useState(8);
    const [showOpsNotificationDetailModal, setShowOpsNotificationDetailModal] = useState(false);
    const [notificationId, setNotificationId] = useState();

    const calculateCount = ( n ) => {
        let newCount = count + n;
        if(newCount>0) setCount(newCount);
    }

    const showDetail = (id) => {
        setNotificationId(id)
        setShowOpsNotificationDetailModal(true);
    }

    return(
        <MainModal
            style={{ height: "700px", width: "1000px" }}
            title={"Todas las Incidendias"}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        >
            {
                props.notifications.slice((count-8) , count).map( (notification, i) => (
                    <button className='all-incident-wrapper' onClick={()=> showDetail(notification)}>
                        <div className='space-between'>
                            <p className='incident-typology'>{notification.get('ExtraInfo').typology}</p>
                        </div>
                        <p className='incident-description'>{notification.get('ExtraInfo').description}</p>
                    </button>
                ))
            }
            <div style={{display: "flex", justifyContent: "space-between", width: "250px", margin: "30px 0px 0px 15px"}}>
                <button className='back-next-incidence-button' onClick={() => calculateCount(-8)}>Anterior</button>
                <p className='incident-count-page'>{`${count-7} - ${count}`}</p>
                <button className='back-next-incidence-button' onClick={() => calculateCount(8)}>Siguiente</button>
            </div>
            { showOpsNotificationDetailModal && (
                <OpsNotificationDetailModal
                    isVisible={showOpsNotificationDetailModal}
                    onClose={() => setShowOpsNotificationDetailModal(false)}
                    notification={notificationId}
                    setNotification={props.setNotification}
                />
            )}
        </MainModal>
    )
}

export default AllNotificationsModal;