import React, {useState, useEffect} from 'react';
import { useStateValue } from "../../state/context";

import MainModal from './MainModal'
import Button from '../button/Button'
import { saveMessage} from '../../utils/ParserUtilities'

const ChatModal = (props) => {
    const [{agent}] = useStateValue();
    const [currentUser,setCurrentUser] = useState("")
    const [newMessage,setNewMessage] = useState("")
    const [chatSala,setChatSala] = useState("")
    const [agentId,setAgentId] = useState("")
    const [Messages,setMessages] = useState([])
    const style = {
        userMessage:{
            width:"fit-content",
            maxWidth:"60%",
            background:"#fff",
            marginTop:"10px",
            marginRight:"13px",
            marginBottom:"10px",
            marginLeft:"auto",
            padding:"10px",
            color:"black",
            background:"#FFE23590",
            borderRadius:"10px"
            
        },
        messageView:{
            display: 'flex',
            flex: 1,
            flexDirection: 'column'
        },
        agentMessage:{
           width:"fit-content",
           maxWidth:"60%",
           background:"#fff",
           marginTop:"10px",
           marginRight:"auto",
           marginBottom:"10px",
           marginLeft:"3px",
           color:"black",
            padding:"10px",
            background:"#FFE23590",
           borderRadius:"10px"
           
        },messageView:{
            height: "65vh",
            overflowY: "auto",
            margin: "15px 0px 10px",
            paddingBottom: "5px",
        },
        hr:{

            height: "1px",
            marginTop: "12px",
            marginBottom: "12px",
        },
        noMessage:{
            margin:"43% 0 0",
            color:"#c3c3c3",
            textAlign:"center"
        }
    }
    const onInputChange = (_text)=>{
        setNewMessage(_text)
    }
    const sendMessage = async ()=>{
       let newMessageData={
            text:newMessage,
            
            chatSalaId:chatSala,
            agentId:agentId,
            user:null
       }
       setMessages([...Messages,newMessageData])
       setNewMessage("")
       var saveData ={...newMessageData}
       await saveMessage(saveData,currentUser)
    }
    useEffect(()=>{
        setMessages(props.chat)
        setCurrentUser(props.user ? {name:props.user.get("Name"),id:props.user.id}:{name:"",id:null})
        setAgentId(agent.agentId)
       
        
    },[props])
    useEffect(()=>{
        Messages.map((message)=>{
           
            setChatSala(message.chatId)
        })
          var view =document.querySelector("#messageView")
          if(view){
              view.scrollTop = view.scrollHeight;
          }
    },[Messages])
    
    return (
   
        <MainModal
            gigant = {true}
			isVisible={props.isVisible}
			onClose={()=>props.onClose({chat:Messages,chatSala:chatSala,userId:currentUser.id})}
            title={currentUser.name}
		>
            <section id="messageView" style={style.messageView}>
                {Messages.length >0 ? Messages.map((message)=>{ 
                    if(message.user){
                       return ( <h6 key={message.id} className="user" style={style.userMessage}>{message.text}</h6>)
                    }else{
                       return ( <h6 key={message.id} className="agent" style={style.agentMessage}>{message.text}</h6>)
                    }
                }):
                    (<h4 style={style.noMessage}>No existen mensajes con este usuario</h4>)
                }

            </section>
            <hr style={style.hr}></hr>
            <section className="noteContainer">
                <input
                    value={newMessage}
                    className="textInput"
                    type="text"
                    placeholder="Escribe aquí"
                    onChange={e => onInputChange(e.target.value)}
                />
                <div className="buttonContainer">
                    <Button
                        buttonText="Enviar"
                        style={{ width: 85, color: "#ffe235" }}
                        disabled={newMessage.length > 0 ? false : true}
                        onClick={sendMessage}
                    />
                </div> 
            </section>
        </MainModal>
    )
}

export default ChatModal;