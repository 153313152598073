import React, { Component } from 'react';
import { getNearGarages } from '../../utils/ParserUtilities'
import { getAddressByLatLong } from "../../utils/reactGeocodeUtils"

import "../../styles/search-garages/search-garage-autocomplete.css";

class AutoComplete extends Component {
    constructor(props) {
        super(props);
        this.clearSearchBox = this.clearSearchBox.bind(this);
    }

    componentDidMount({ map, mapApi } = this.props) {
        const options = {
            types: ['address'],
        };
        this.autoComplete = new mapApi.places.Autocomplete(
            this.searchInput,
            options,
        );
        this.autoComplete.addListener('place_changed', this.onPlaceChanged);
        this.autoComplete.bindTo('bounds', map);
    }

    componentWillUnmount({ mapApi } = this.props) {
        mapApi.event.clearInstanceListeners(this.searchInput);
    }

    onSearchAddresse(newAddresse){
        this.props.setAddresses(addresses => [...addresses, newAddresse])
    }

    onPlaceChanged = async ({ map } = this.props) => {

        const place = this.autoComplete.getPlace();
        let res = await getNearGarages(Object.values(place.geometry.viewport)[0].lo, Object.values(place.geometry.viewport)[1].lo);

        res.map( async (item, i) => {
            let newAddresse = await getAddressByLatLong(item.get("Location")?.latitude, item.get("Location")?.longitude, "linker")
            this.props.setAddresses(addresses => [...addresses, {addresse: newAddresse, position: i}])
        })

        this.props.setGarages(res);

        if (!place.geometry) return;
        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
            map.setZoom(14);
        } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
        }

        this.searchInput.blur();
    };

    clearSearchBox() {
        this.searchInput.value = '';
    }

    render() {
        return (
                <input
                    className="garage-search-input"
                    ref={(ref) => {
                        this.searchInput = ref;
                    }}
                    type="text"
                    onFocus={this.clearSearchBox}
                    placeholder="Busca una ubicación..."
                />
        );
    }
}

export default AutoComplete;