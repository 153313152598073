import React from "react";
import MainModal from "./MainModal";
import ImportServices from '../search/ImportServices'

const ImpotServicesModal = props => {
    return (
        <MainModal
            style={{ height: "800px", width: "1300px", maxWidth:"1300px" }}
            title={"Importar Servicios"}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        >
            <ImportServices
                onClose={props.onClose}
            />
        </MainModal>
    );
};

export default ImpotServicesModal;