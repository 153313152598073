import React, { useEffect, useState } from "react";
import { useStateValue } from "../../../state/context";
import { initialState } from "../../../state/initialState";
import { Link } from "react-router-dom";
import ChildServiceList from "./ChildServiceList";

import "../../../styles/job/service-item.css";

export default function ServiceItem(props) {
    const [{ job }, dispatch] = useStateValue();

    const [childExist, setChildExist] = useState(false);

    const childHandler = dataFromChild => {
        setChildExist(dataFromChild);
        props.loaded(dataFromChild);
    };

    const clickHandler = e => {
        dispatch({
            type: "JOB_RESET",
            job: initialState.job
        });
        dispatch({
            type: "JOB_CATEGORY_GUILD_ID_TYPE",
            categoryId: e.target.getAttribute("dataid"),
            categoryType: e.target.getAttribute("datatype")
        });
    };

    return (
        <div className="service-item">
            <div className="img-container">
                <img
                    src={props.service.get("Icon").url()}
                    alt={props.service.get("Type")}
                />
            </div>
            <div className="excerpt">
                {childExist ? (
                    <h4 className="title">{props.service.get("Type")}</h4>
                ) : (
                    <h4 className="title">
                        <Link
                            to={{
                                pathname: "/job/new/location",
                                search: job.location.search,
                                state: {
                                    inner: true
                                }
                            }}
                            dataid={props.service.id}
                            datatype={props.service.get("Type")}
                            onClick={clickHandler}
                        >
                            {props.service.get("Type")}
                        </Link>
                    </h4>
                )}
                <ChildServiceList
                    parent={props.service}
                    exist={childHandler}
                />
            </div>
        </div>
    );
}
