import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { useStateValue } from "../../../state/context";

import { RadioButton } from "jobin-ui-components";

export default function CategoryList(props) {
    const [{ job }, dispatch] = useStateValue();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [items, setItems] = useState(props.service);
    const [selectedOption, setSelectedOption] = useState(null);

    let didCancel = false;

    async function loadAsync(newId) {
        if (!didCancel) setIsLoaded(false);

        try {
            const { result: parent } = await Jobin.Guild.get(newId);
            const result = await Jobin.Guild.getFatherChilds(
                parent,
                !job.store.exists
            );
            if (result.status === "ok") {
                if (result.result.length > 0) {
                    if (!didCancel) {
                        setIsLoaded(true);
                        setItems(result.result);

                        props.parentTitle(parent.get("ViewTitle"));
                    }
                } else {
                    if (!didCancel) {
                        setIsLoaded(true);
                        setIsEmpty(true);

                        const location = parent.get("Location");
                    
                        dispatch({
                            type: "JOB_CATEGORY_GUILD_SUBCATEGORY",
                            subCategoryId: parent.id,
                            latitude: location ? location.latitude : null,
                            longitude: location ? location.longitude : null
                        });

                        job.history.push({
                            pathname: "/job/new/location",
                            search: job.location.search,
                            state: {
                                inner: true,
                                param: true
                            }
                        });
                    }
                }
            } else {
                if (!didCancel) {
                    setIsLoaded(false);
                    setError(result.result);
                }
            }
        } catch (error) {
            if (!didCancel) {
                setIsLoaded(false);
                setError(error);
            }
        }
    }

    const handleChange = service => {
        setSelectedOption(service);
        loadAsync(service);
    };

    useEffect(() => {
        // unmount
        return () => {
            didCancel = true;
        };
    }, []);

    return error ? (
        <div>Error: {error.message}</div>
    ) : isEmpty ? (
        <React.Fragment />
    ) : !isLoaded ? (
        <div>Cargando...</div>
    ) : (
        items.map((service, index) => {
            return (
                <RadioButton
                    key={"radio" + index}
                    item={{
                        title: service.get("Type"),
                        selected: selectedOption === service.id
                    }}
                    onClick={() => handleChange(service.id)}
                />
            );
        })
    );
}
