import React from "react";
import { AxisOptions, Chart } from "react-charts";

export default function DyanmicContainer(props: any) {

  const data = props.campainData;

  const primaryAxis = React.useMemo<
    AxisOptions<typeof data[number]["data"][number]>
  >(
    () => ({
      getValue: (datum) => datum.primary as unknown as Date,
    }),
    []
  );

  const secondaryAxes = React.useMemo<
    AxisOptions<typeof data[number]["data"][number]>[]
  >(
    () => [
      {
        getValue: (datum) => datum.secondary,
      },
    ],
    []
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "12px",
          height: "400px",
        }}
      >
        <div
          style={{
            flex: "0 0 auto",
            padding: "10px",
            fontSize: "30px",
            fontWeight: "bold"
          }}
        >
          Estadísticas
        </div>
        <div
          style={{
            flex: 2,
            maxHeight: "500px",
            overflow: "hidden",
            padding: "0px 10px"
          }}
        >
          <Chart
            options={{
              data,
              primaryAxis,
              secondaryAxes,
            }}
          />
        </div>
      </div>
    </>
  );
}
