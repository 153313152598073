import React, {useState } from "react";
import CurrencyInput from 'react-currency-input-field'; 
import Button from "../../button/Button";
import Lottie from 'react-lottie'
import * as cardSpinnerData from "../../../assets/animations/button-loading.json";

export default function VerticalCard(props) {
    const [verticalSon,setverticalSon] = useState(props.verticalSon)   
    const [ wollerPrice ,  setWollerPrice] = useState(props.wollerPrice);
    const [ userPrice ,  setUserPrice] = useState(props.userPrice);
    const [ currentVerticalEdit ,  setCurrentVerticalEdit] = useState(true);
    const [ savingVertical ,  setSavingVertical] = useState(false);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: cardSpinnerData.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    const handleChangeWollerPrice = async (value,id) => {
        setWollerPrice(value)
        setCurrentVerticalEdit(id)
    }
    const handleChangeUserPrice = async (value,id) => {
        setUserPrice(value)
        setCurrentVerticalEdit(id)
    }
    const onSave= async()=>{
        setSavingVertical(true)
        await props.saveVerticalPrices(verticalSon.id,wollerPrice,userPrice)
        setCurrentVerticalEdit(null)
        setSavingVertical(false)
    }

    return (
        <div className="card">
            <p><b>{verticalSon.get("Type")}</b></p>
            {verticalSon.get("Price") != null && <div className="display-flex flex-column">
                <label style={{color: '#B0B0B0', fontSize: '15px', marginTop: '8px', width:"100%"}}>precio para Woller :</label>
                <CurrencyInput
                    className="textInput"
                    id="input-example"
                    name="input-name"
                    placeholder="Please enter a number"
                    intlConfig={{ currency: 'EUR' }}
                    groupSeparator="."
                    decimalsLimit={2}
                    value={wollerPrice}
                    onValueChange={(value)=>handleChangeWollerPrice(value,verticalSon.id)}
                    style={{
                    width: '100%',
                    padding: '5px',
                    border: '1px solid #E3E3E3',
                    borderRadius: '4px',
                    marginRight:"auto"
                    }} 
                />
            </div>}
            {verticalSon.get("Price") != null && <div className="display-flex flex-column">
                <label style={{color: '#B0B0B0', fontSize: '15px', marginTop: '8px', width:"100%"}}>precio para User :</label>
                <CurrencyInput
                    className="textInput"
                    id="input-example"
                    name="input-name"
                    placeholder="Please enter a number"
                    intlConfig={{ currency: 'EUR' }}
                    decimalsLimit={2}
                    groupSeparator="."
                    value={userPrice}
                    onValueChange={(value)=>handleChangeUserPrice(value,verticalSon.id)}
                    style={{
                    width: '100%',
                    padding: '5px',
                    border: '1px solid #E3E3E3',
                    borderRadius: '4px',
                    marginRight:"auto"
                    }} 
                />
            </div>}
            <Button
                buttonText={ savingVertical ? (
                    <Lottie style={{height: 16, width: 55}} options={defaultOptions} />)
                    :"Guardar"}
                style={{ width: 85, color: "var(--coral)", marginTop:"10px" }}
                disabled={currentVerticalEdit != verticalSon.id}
                onClick={onSave}
            />
        </div>
    );
}

