import React from "react";
import Jobin from "jobin-client";
import { styles } from "./styles";

const ReceiptHeader = props => {
    let date = props.date || new Date();
    let receiptName = props.receiptName
        ? props.receiptName
        : props.custom
        ? `ES-OT-${date.getFullYear()}-XXX-XXX-XXX`
        : "ES-XX-XXXX-XXX-XXX-XXX";
    return (
        <div style={styles.row}>
            <div style={styles.column}>
                <img
                    style={styles.logo}
                    src={require("../../../assets/wolly-logo-billing.png")}
                    alt="wolly-logo"
                />
            </div>
            <div style={styles.column}>
                <label
                    style={{
                        fontSize: 22,
                        color: "var(--coral)",
                        fontWeight: 600
                    }}
                >
                    {receiptName}
                </label>
                <label>Factura</label>
                <label>{Jobin.Date.toSortFormat(date).result}</label>
            </div>
        </div>
    );
};

export default ReceiptHeader;
