import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import fetchDepartments from "../../constants/crm/departments";

import MainModal from "./MainModal";
import AgentCell from "../search/detail/detailColumn/AgentCell";
import FilterMultipleSelection from "../filter/FilterMultipleSelection";

const SelectAgentModal = props => {
    const style = {
        searchContainer: {
            paddingBottom: 8,
            marginBottom: 16,
            borderBottom: "1px solid var(--soft-grey)"
        },
        searchBar: {
            marginTop: 16,
            marginBottom: 8
        }
    };

    const [departments, setDepartments] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [agentArray, setAgentArray] = useState([]);

    useEffect(() => {
        fetchDepartments().then(departments => {
            //console.info(departments)
            //departments.find(dep => dep.id == "Ops").selected = true;
            setDepartments(departments);
        });
    }, []);

    useEffect(() => {
        let selectedNames = selectedDepartments
            .filter(dep => dep.id != "all" && dep.id != "none" && dep.selected)
            .map(dep => dep.id);
        Jobin.CRMUser.search({
            departments: selectedNames
        }).then(({ status, result }) => {
            if (status === "ok") setAgentArray(result);
        });
    }, [selectedDepartments]);

    return (
        <MainModal
            title={"Nuevo agente"}
            subtitle={
                "Selecciona el nuevo agente al que quieres que pertenezca esta solicitud."
            }
            isVisible={props.isVisible}
            onClose={props.onClose}
        >
            <section>
                <div style={style.searchContainer}>
                    {/*<SearchBar
                        style={style.searchBar}
                        placeholder={"Buscar por email"}
                        //onChange={(e) => searchJober(e.target.value)}
                    />*/}
                    <div>
                        <FilterMultipleSelection
                            type="department"
                            title="Departamento"
                            items={departments}
                            selectionChange={selection => {
                                if (
                                    selection.length > 0 &&
                                    selection[0].id == "all"
                                )
                                    setSelectedDepartments([]);
                                else setSelectedDepartments(selection);
                            }}
                        />
                    </div>
                </div>
                {agentArray &&
                    agentArray.length > 0 &&
                    agentArray.map(agent => {
                        return (
                            <AgentCell
                                key={agent.id}
                                agent={agent}
                                buttonText={"Seleccionar"}
                                onClick={() => props.selectAgent(agent)}
                            />
                        );
                    })}
            </section>
        </MainModal>
    );
};

export default SelectAgentModal;
