export const budgetState = [
    {
        id: "all",
        name: "Todos",
        selected: false
    },
    {
        id: "desestimated",
        name: "Desestimado",
        selected: false
    },
    {
        id: "estimated",
        name: "Estimado",
        selected: false
    },
           
];