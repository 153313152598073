import Jobin from "jobin-client";
import { apiId, jsKey, urlServer } from "./credentials";
import { getCookie } from "../utils/cookie";
import { elementOf } from "../utils/formatter";
import {findGuildByBusiness as findGuildByBusiness_} from "../utils/ParserUtilities";

const fetchServices = async (prevSelection,isGoldSession_=false,isProDispat_=false) => {
    let arr = [];
    let selectedIds = [];
    let result = "error";
    Jobin.initServer(apiId, jsKey, urlServer);
    const agent = JSON.parse(
        getCookie("agent") ? decodeURIComponent(getCookie("agent")) : null
    );

    if (isGoldSession_) {
        selectedIds =
            prevSelection && Array.isArray(prevSelection)
                ? prevSelection.map(item => item.id)
                : [];
    } else if (isProDispat_) {
        selectedIds =
            prevSelection && Array.isArray(prevSelection)
                ? prevSelection.map(item => item)
                : [];
    } else {
        selectedIds =
            prevSelection && Array.isArray(prevSelection)
                ? prevSelection.filter(item => item.selected).map(item => item.id)
                : [];
    }

    Jobin.initToken(agent ? agent.token : undefined);

    try {
        if (agent?.dept === "ConstructionCompany" && agent?.business?.id) {
            result = await findGuildByBusiness_({businessId:agent?.business?.id});
        } else {
            result = await Jobin.Guild.getFathers();
            result = result.result;
        }

        if (result.status === "error") {
            console.error("Error fetching services:", result.error);
            return [];
        }

        arr = result.map(service => {
            if (agent?.dept === "ConstructionCompany" && agent?.business?.id) {
                //result no es una lista de objetos de Parse en este punto; diferente transfomer
                return {
                    id: service.id,
                    name: service.name ?? "desconocido",
                    icon: service.icon?.url(),
                    selected: false
                };
            } else if (isGoldSession_ && elementOf(service.id, selectedIds)) {
                return {
                    id: service.id,
                    name: service.get("Type"),
                    icon: service.get("Icon")?.url(),
                    selected: false
                };
            } else if(!isGoldSession_){
                return {
                    id: service.id,
                    name: service.get("Type"),
                    icon: service.get("Icon")?.url(),
                    selected: elementOf(service.id, selectedIds)
                };
            }
        });

        arr = arr.filter((item)=> {return item!==undefined})
    } catch (error) {
        console.error(error);
    }

    return [
        {
            id: "all",
            name: "Todos",
            selected: false
        }
    ].concat(arr);
};

export default fetchServices;
