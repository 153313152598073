import React, { useState, useRef, useEffect } from "react";
import Jobin from "jobin-client";

import MainModal from "./MainModal";
import ExtraCell from "../search/detail/detailColumn/ExtraCell";
import PredefinedExtraCell from "../search/detail/detailColumn/PredefinedExtraCell";
import Button from "../button/Button";
import ButtonEmpty from "../button/ButtonEmpty";

import { Input } from "jobin-ui-components";

const ExtrasModal = props => {
    const style = {
        addContainer: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 16,
            borderTop: "1px solid #e8e8e8",
            marginTop: 24,
        },
        input: {
            borderRadius: 0,
            width: 160,
            border: "1px solid var(--greyish)",
        },
    };

    const [loading, setLoading] = useState(false);
    const [predefinedExtrasArray, setPredefinedExtrasArray] = useState([]);
    const [extrasArray, setExtrasArray] = useState(props.extrasArray);
    const [newExtrasArray, setNewExtrasArray] = useState([]);
    const [totalExtraPrice, setTotalExtraPrice] = useState(0);
    const [totalIVAPrice, setTotalIVAPrice] = useState(0);
    const [description, setDescription] = useState(null);
    const [descriptionOk, setDescriptionOk] = useState(true);
    const [price, setPrice] = useState(undefined);
    const [priceIVA, setPriceIVA] = useState(undefined);
    const [priceOk, setPriceOk] = useState(true);
    const [addButtonDisabled, setAddButtonDisabled] = useState(true);

    const inputDesc = useRef();
    const inputPrice = useRef();
    const inputPriceIVA = useRef();

    useEffect(() => {
        async function getPredefinedExtras() {
            setLoading(true);
            const result = await Jobin.Guild.getExtras(props.subvertical);
            if (result.status === "ok") setPredefinedExtrasArray(result.result);
            setLoading(false);
        }

        if (props.subvertical) getPredefinedExtras();

        //Remove urgency extra if needed
        const tempArray = extrasArray.filter(item => item.type !== "urgency");
        setExtrasArray(tempArray);
    }, []);

    const onDescriptionChange = input => {
        if (input.length > 0) {
            if (input.length > 500) {
                setDescription(undefined);
                setDescriptionOk(false);
                checkExtraInputs(undefined, price);
            } else {
                setDescription(input);
                setDescriptionOk(true);
                checkExtraInputs(input, price);
            }
        } else {
            setDescription(undefined);
            setDescriptionOk(true);
        }
    };

    const onPriceChange = input => {
        if (input.length > 0) {
            if (/^-?\d+\.\d{2}$/.test(input)) {
                setPrice(Number(input));
                setPriceIVA(Number(input * 1.21));
                setPriceOk(true);
                checkExtraInputs(description, input);
            } else {
                setPrice(undefined);
                setPriceIVA(undefined);
                setPriceOk(false);
                checkExtraInputs(description, null);
            }
        } else {
            setPrice(undefined);
            setPriceIVA(undefined);
            setPriceOk(true);
            checkExtraInputs(description, null);
        }
    };

    const onPriceIVAChange = input => {
        if (input.length > 0) {
            if (/^-?\d+\.\d{2}$/.test(input)) {
                setPriceIVA(Number(input));
                setPrice(Number(input / 1.21));
                setPriceOk(true);
                checkExtraInputs(description, input);
            } else {
                setPriceIVA(undefined);
                setPrice(undefined);
                setPriceOk(false);
                checkExtraInputs(description, null);
            }
        } else {
            setPriceIVA(undefined);
            setPrice(undefined);
            setPriceOk(true);
            checkExtraInputs(description, null);
        }
    };

    const checkExtraInputs = (description, price) => {
        //Active / disabled add button
        if (description && price) {
            setAddButtonDisabled(false);
        } else {
            setAddButtonDisabled(true);
        }
    };

    const addExtra = (item, units) => {
        //find the previous value for this extra
        let indexPrev = props.extrasArray.findIndex(
            prevExtra => prevExtra.name === item.name
        );
        const prevExtra = props.extrasArray[indexPrev];
        const tempItem = {
            ...item,
            units: prevExtra ? units - prevExtra.units : units,
        };

        let index = newExtrasArray.findIndex(
            prevExtra => prevExtra.name == tempItem.name
        );
        const tempArray = newExtrasArray;

        //The is no extra equals the new extra in the new extras array
        if (index === -1) tempArray.push(tempItem);
        else tempArray[index] = tempItem;

        setNewExtrasArray(tempArray);
        calculateTotalPrice();

        const tempItem2 = {
            ...item,
            units: units,
        };
        reorderExtrasArray(tempItem2);
    };

    const calculateTotalPrice = () => {
        const tempPrice = newExtrasArray.reduce((total, item) => {
            return total + item.pricePerUnit * item.units;
        }, 0);

        const ivaPrice = (tempPrice * 1.21).toFixed(2);

        setTotalExtraPrice(tempPrice.toFixed(2));
        setTotalIVAPrice(ivaPrice);
    };

    const addCustomExtra = () => {
        const extra = {
            name: description,
            units: 0,
            pricePerUnit: price,
        };
        setExtrasArray([...extrasArray, extra]);
        setNewExtrasArray([...newExtrasArray, extra]);
        setPrice(null);
        setPriceIVA(null);
        setDescription(null);
        setAddButtonDisabled(true);

        inputDesc.current.value = "";
        inputPrice.current.value = "";
        inputPriceIVA.current.value = "";
    };

    const addPredefinedExtra = (extra, units) => {
        //find the previous value for this extra
        let indexPrev = props.extrasArray.findIndex(
            prevExtra => prevExtra.id === extra.id
        );
        const prevExtra = props.extrasArray[indexPrev];

        const tempExtra = {
            id: extra.id,
            name: extra.get("Name"),
            pricePerUnit: extra.get("Price").default.user,
            units: prevExtra ? units - prevExtra.units : units,
        };

        //Search if the new extra is in the new extras array
        let index = newExtrasArray.findIndex(
            prevExtra => prevExtra.id === extra.id
        );
        const tempArray = newExtrasArray;
        if (index === -1) tempArray.push(tempExtra);
        else tempArray[index] = tempExtra;

        setNewExtrasArray(tempArray);
        calculateTotalPrice();

        //Add the object to extras array
        const tempExtra2 = {
            id: extra.id,
            name: extra.get("Name"),
            pricePerUnit: extra.get("Price").default.user,
            units: units,
        };
        reorderExtrasArray(tempExtra2);
    };

    const reorderExtrasArray = extra => {
        let index = extrasArray.findIndex(
            prevExtra => prevExtra.name === extra.name
        );
        if (index >= 0) {
            const tempArray = extrasArray.map((arr, i) => {
                if (i === index) return extra;
                else return arr;
            });
            setExtrasArray(tempArray);
        } else {
            setExtrasArray([...extrasArray, extra]);
        }
    };

    const saveExtras = () => {
        //Clean extras with no units
        const tempExtrasArray = extrasArray.filter(item => item.units !== 0);
        const tempNewExtrasArray = newExtrasArray.filter(
            item => item.units !== 0
        );

        //Create the urgent extra
        if (props.isUrgent) {
            const totalExtraPrice = tempExtrasArray.reduce(
                (accumulator, extra) => {
                    return accumulator + extra.pricePerUnit * extra.units;
                },
                0
            );

            const totalNewExtraPrice = tempNewExtrasArray.reduce(
                (accumulator, extra) => {
                    return accumulator + extra.pricePerUnit * extra.units;
                },
                0
            );

            const extraPrice = tempExtrasArray.reduce((accumulator, extra) => {
                if (extra.pricePerUnit >= 0)
                    return accumulator + extra.pricePerUnit * 0.5 * extra.units;
                else return accumulator;
            }, 0);

            const newExtraPrice = tempNewExtrasArray.reduce(
                (accumulator, extra) => {
                    if (extra.pricePerUnit >= 0)
                        return (
                            accumulator + extra.pricePerUnit * 0.5 * extra.units
                        );
                    else return accumulator;
                },
                0
            );

            tempExtrasArray.push({
                type: "urgency",
                name: "Incremento por servicio de urgencia",
                units: 1,
                pricePerUnit:
                    totalExtraPrice + props.basicPrice >= 200
                        ? 82.645
                        : extraPrice + props.basicPrice * 0.5,
            });

            let urgencyPrice = 82.645;
            if (totalExtraPrice + props.basicPrice >= 200) {
                urgencyPrice =
                    82.645 -
                    (totalExtraPrice - totalNewExtraPrice + props.basicPrice) *
                        0.5;
            } else {
                urgencyPrice = newExtraPrice;
            }

            tempNewExtrasArray.push({
                type: "urgency",
                name: "Incremento por servicio de urgencia",
                units: 1,
                pricePerUnit: urgencyPrice < 0 ? 0 : urgencyPrice,
            });
        }

        props.saveExtras(tempExtrasArray, tempNewExtrasArray);
    };

    const onClose = () => {
        //Create the urgent extra
        if (props.isUrgent) {
            const tempExtrasArray = extrasArray;
            const extraPrice = tempExtrasArray.reduce((accumulator, extra) => {
                if (extra.pricePerUnit >= 0) {
                    return accumulator + extra.pricePerUnit * 0.5 * extra.units;
                } else {
                    return accumulator;
                }
            }, 0);

            tempExtrasArray.push({
                type: "urgency",
                name: "Incremento por servicio de urgencia",
                units: 1,
                pricePerUnit: extraPrice + props.basicPrice * 0.5,
            });
        }
        props.onClose();
    };

    const renderExtraView = () => {
        return (
            <section>
                <div style={{ marginTop: 24 }}>
                    {predefinedExtrasArray && predefinedExtrasArray.length > 0 && (
                        <div>
                            {predefinedExtrasArray.map((item, index) => {
                                let result = props.extrasArray.filter(extra => {
                                    return extra.id === item.id;
                                });
                                return (
                                    <PredefinedExtraCell
                                        key={"predefinedExtra" + index}
                                        extra={item}
                                        units={
                                            result && result.length > 0
                                                ? result[0].units
                                                : 0
                                        }
                                        onValueChange={value =>
                                            addPredefinedExtra(item, value)
                                        }
                                    />
                                );
                            })}
                        </div>
                    )}
                    {extrasArray.map((item, index) => {
                        if (!item.id && !item.type) {
                            let result = props.extrasArray.filter(extra => {
                                return extra.name === item.name;
                            });
                            return (
                                <ExtraCell
                                    key={"extra" + index}
                                    extra={item}
                                    minUnits={
                                        result && result.length > 0
                                            ? result[0].units
                                            : 0
                                    }
                                    onValueChange={value =>
                                        addExtra(item, value)
                                    }
                                />
                            );
                        }
                    })}
                </div>
                <div style={style.addContainer}>
                    <Input
                        ref={inputDesc}
                        style={{
                            display: "flex",
                            flex: 1,
                            borderRadius: 0,
                            border: "1px solid var(--greyish)",
                        }}
                        badInput={!descriptionOk}
                        inputStyle={{ border: "1px solid #e8e8e8" }}
                        placeholder="Descripción"
                        onChange={e => onDescriptionChange(e.target.value)}
                    />
                    <Input
                        ref={inputPrice}
                        style={style.input}
                        badInput={!priceOk}
                        placeholder="€/unidad"
                        onChange={e => onPriceChange(e.target.value)}
                        value={price ? price.toFixed(2) : undefined}
                    />
                    <Input
                        ref={inputPriceIVA}
                        style={style.input}
                        badInput={!priceOk}
                        placeholder="€/unidad con IVA"
                        onChange={e => onPriceIVAChange(e.target.value)}
                        value={priceIVA ? priceIVA.toFixed(2) : undefined}
                    />
                    <ButtonEmpty
                        style={{ marginLeft: 16 }}
                        buttonText={"Añadir"}
                        disabled={addButtonDisabled}
                        onClick={addCustomExtra}
                    />
                </div>
                <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            marginTop: 12,
                            flexDirection: "column",
                        }}
                    >
                        <label style={{ fontSize: 16, fontWeight: "bold" }}>
                            {totalExtraPrice >= 0
                                ? "Total a pagar: " + totalExtraPrice + "€"
                                : "Total a devolver: " + totalExtraPrice + "€"}
                        </label>
                        <label style={{ fontSize: 12 }}>
                            {totalIVAPrice + "€ con IVA"}
                        </label>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            style={{ marginTop: 16, marginLeft: 8 }}
                            disabled={newExtrasArray.length > 0 ? false : true}
                            buttonText="Guardar cambios"
                            onClick={saveExtras}
                            loading={loading}
                        />
                    </div>
                </div>
            </section>
        );
    };

    return (
        <MainModal
            title={"Extras del servicio"}
            subtitle={
                "Añade extras al servicio básico contratado por el cliente."
            }
            isVisible={props.isVisible}
            onClose={onClose}
            big
        >
            {renderExtraView()}
        </MainModal>
    );
};

export default ExtrasModal;
