import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { useStateValue } from "../../state/context";
import StepTip from "./generic/StepTip";
import StepControlls from "./generic/StepControlls";
import BreadCrumb from "./generic/BreadCrumb";

import "../../styles/job/service-description.css";

import stepIconLeft from "../../assets/step-icon-left.svg";

export default function ServiceDescription(props) {
    const [{ job }, dispatch] = useStateValue();

    const [message, setMessage] = useState(job.description.message);
    const [tooltip, setTooltip] = useState(job.description.tooltip);
    const [styleType, setStyleType] = useState(job.description.styleType);
    const [error, setError] = useState(job.description.error);
    const [disabledBtn, setDisabledBtn] = useState(job.description.disabledBtn);

    const validateDescription = value => {
        let error, tooltip, styleType;

        if (value.length < props.minChar) {
            error = true;
            let charsLeft = props.minChar - value.length;
            if (charsLeft === 1) tooltip = `Falta ${charsLeft} caracter`;
            else tooltip = `Faltan ${charsLeft} caracteres`;
            styleType = "primary";
        } else if (value.length <= props.maxChar) {
            error = false;
            tooltip = `${value.length}/${props.maxChar}`;
            styleType = "success";

            setDisabledBtn(false);
        } else {
            error = false;
            tooltip = `Límite superado ${value.length}/${props.maxChar}`;
            styleType = "error";
        }

        setMessage(value);
        setTooltip(tooltip);
        setStyleType(styleType);
        setError(error);

        return error;
    };

    const update = () => {
        dispatch({
            type: "JOB_DESCRIPTION",
            message: message,
            tooltip: tooltip,
            styleType: styleType,
            error: error,
            disabledBtn: error,
        });
    };

    useEffect(() => {
        const getEmptyJob = async () => {
            const { status, result } = await Jobin.Guild.get(
                job.category.subCategoryId || job.category.categoryId
            );
            if (
                status === "ok" &&
                (!result.has("Includes") ||
                    result.get("Includes").length === 0) &&
                (!result.has("DoesntInclude") ||
                    result.get("DoesntInclude").length === 0)
            ) {
                validateDescription(
                    ""
                );
            }
        };

        if (!(props.location.state && "inner" in props.location.state))
            props.history.replace("/job/new");

        if (
            !job.description.message &&
            !job.transport &&
            job.price.selectedOption === "fixed"
        )
            getEmptyJob();
    }, []);

    return (
        <div id="service-description" className="section">
            <BreadCrumb history={props.history} location={props.location} />
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="title">Danos más detalles</h4>
                        <p className="hidden-sm hidden-xs">
                            Da los máximos detalles posible sobre la solicitud.
                            Los Wollers necesitan conocer todo lo posible antes
                            de ir al domicilio del cliente.
                        </p>
                        <p className="hidden-lg">
                            Da los máximos detalles posible sobre la solicitud.
                            Los Wollers necesitan conocer todo lo posible antes
                            de ir al domicilio del cliente.
                        </p>
                        <div className="content">
                            <textarea
                                name="description"
                                placeholder="Escribe aqui"
                                className="textarea"
                                onChange={e =>
                                    validateDescription(e.target.value)
                                }
                                value={message}
                            />
                            <div className="text-right">
                                <span className={styleType}>{tooltip}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-offset-1 col-md-4">
                        <StepTip
                            iconLeft={stepIconLeft}
                            title="¡Importante!"
                            excerpt="La descripción que escribas aquí aparecerá en el albarán del cliente. Indica particularidades del servicio y cosas que deba conocer el Jober que realice el servicio"
                        />
                    </div>
                </div>
            </div>
            <StepControlls
                page={props.step}
                disabled={disabledBtn}
                nextTrigger={error}
                next="job/new/picture"
                prev={
                    job.price.amount !== null
                        ? job.transport
                            ? "job/new/date"
                            : "job/new/availability"
                        : "job/new/location"
                }
                nextTitle="Siguiente"
                update={update}
            />
        </div>
    );
}
