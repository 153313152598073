import React, { useState } from 'react';
import MainModal from './MainModal'

const EditBiProModal = ({ user, userDetails, isVisible, title, subtitle, onClose, secondButtonText, buttonText, secondOnClick, handleChangeInfo }) => {
    const [ischangedInfo, setIschangedInfo] = useState(false)
    const [Phone, setPhone] = useState(user?.get("Phone"))
    const [email, setEmail] = useState(user?.get("email"))
    const [ExperienceYears, setExperienceYears] = useState(userDetails?.get("ExperienceYears"))
    const [DisplacementPrice, setDisplacementPrice] = useState(userDetails?.get("DisplacementPrice"))
    const [EmployeesNumber, setEmployeesNumber] = useState(userDetails?.get("EmployeesNumber"))
    const [changes, setChanges] = useState({
        Phone: null,
        email: null,
        Addres: null,
        ExperienceYears: null,
        DisplacementPrice: null,
        EmployeesNumber: null,
    })

    const handleChangeDescription = (data, key, type) => {
        switch (key) {
            case "Phone":
                changes.Phone = data
                setChanges(changes )
                break;
            case "email":
                changes.email = data
                setChanges(changes )
                break;
            case "Addres":
                changes.Addres = data
                setChanges(changes )
                break;
            case "ExperienceYears":
                changes.ExperienceYears = parseInt(data)
                setChanges(changes )
                break;
            case "DisplacementPrice":
                changes.DisplacementPrice = parseInt(data)
                setChanges(changes )
                break;
            case "EmployeesNumber":
                changes.EmployeesNumber = parseInt(data)
                setChanges(changes )
                break;

            default:
                break;
        }
    }
    const handleSaveData = () =>{
       let promises =  Object.keys(changes).map(async(key)=>{
            if(key =="Phone" || key =="email" || key =="wollerLocation"){
                let dataParsed={
                    key:key,
                    value:changes[key]
                }
                if(changes[key]){

                    return await handleChangeInfo(dataParsed,user.id)
                }
            }else{
                let dataParsed={
                    key:key,
                    value:changes[key]
                }
                if(changes[key]){

                    return await handleChangeInfo(dataParsed,user.id,true)
                }
            }
        })
        Promise.all(promises)
    }

    return (
        <MainModal
            med
            isVisible={isVisible}
            title={title}
            subtitle={subtitle}
            onClose={onClose}
            alert={true}
            center={false}
        >
            <section className="editBiPRoModalContainer">
                <div className="inputsContainer">
                    <div className="basicInfo">
                        <img src={require("../../assets/userDetails/phone.png")} alt={""}></img>
                        <span>
                            Mi telefono:
                        </span>
                        <input type='text' value={Phone} onChange={(event) => { handleChangeDescription(event.target.value, "Phone", "user"); setPhone(event.target.value); setIschangedInfo(true) }}></input>
                    </div>
                    <div className="basicInfo">
                        <img src={require("../../assets/userDetails/mail.png")} alt={""}></img>
                        <span>
                            Mi mail:
                        </span>
                        <input type='text' value={email} onChange={(event) => { handleChangeDescription(event.target.value, "email", "user"); setEmail(event.target.value); setIschangedInfo(true) }}></input>
                    </div>
                    {/* <div className="basicInfo">
                        <img src={require("../../assets/userDetails/location.png")} alt={""} ></img>
                        <span>
                            Provincia de trabajo:
                        </span>
                        <input type='text' value={Addres} onChange={(event) => { handleChangeDescription(event.target.value, "Addres",); setAddres(event.target.value); setIschangedInfo(true) }}></input>
                    </div> */}
                    <div className="basicInfo">
                        <img src={require("../../assets/userDetails/gear.png")} alt={""} ></img>
                        <span>
                            Mis años de experiencia:
                        </span>
                        <input type='text' value={ExperienceYears} onChange={(event) => { handleChangeDescription(event.target.value, "ExperienceYears", "userData"); setExperienceYears(event.target.value); setIschangedInfo(true) }}></input>
                    </div>
                    <div className="basicInfo">
                        <img src={require("../../assets/userDetails/car.png")} alt={""} ></img>
                        <span>
                            Cobro por desplazamiento:
                        </span>
                        <input type='text' value={DisplacementPrice} onChange={(event) => { handleChangeDescription(event.target.value, "DisplacementPrice", "userData"); setDisplacementPrice(event.target.value); setIschangedInfo(true) }}></input>
                    </div>
                    <div className="basicInfo">
                        <img src={require("../../assets/userDetails/woller.png")} alt={""} ></img>
                        <span>
                            En mi equipo somos:
                        </span>
                        <input type='text' value={EmployeesNumber} onChange={(event) => { handleChangeDescription(event.target.value, "EmployeesNumber", "userData"); setEmployeesNumber(event.target.value); setIschangedInfo(true) }}></input>
                    </div>
                </div>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20 }}>
                    {secondButtonText &&
                        <button
                            className="buttonModal"
                            style={{ marginRight: 8 }}
                            onClick={secondOnClick}
                        > {secondButtonText}</button>
                    }
                    <button
                        className="buttonModal"
                        onClick={() => handleSaveData()}
                        disabled={!ischangedInfo}
                    > {buttonText}</button>
                </div>
            </section>
        </MainModal>
    )
}

export default EditBiProModal;