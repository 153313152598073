import React from 'react';
import MapIcon from "../../assets/map-icon-yellow.png";

import "../../styles/JobsMaps/jobs-maps-marker.css";

const GarageMapsMarker = (params) => {

    if(params.position == "mainPlace"){
        return <img className='jobs-map-icon' src={MapIcon} />
    }

    return <div className="search-garages-card-position-wrapper">
                <p className="search-garages-card-position">{params.position}</p>
           </div>
}

export default GarageMapsMarker;