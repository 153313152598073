import React, {useEffect, useState} from "react";
import { useStateValue } from "../../state/context";


export default function SearchBar(props) {
    const [{search,currentSession},dispatch] = useStateValue();
    const [searchInput, setSearchInput] = useState("");
    const [currentSelected, setCurrentSelected] = useState();
    
    useEffect(()=>{
        if(props.type =="searchBarId"){
            let currentSelected_ =  props.types.filter((type)=>type.selected)[0]
            setCurrentSelected(currentSelected_.value)
            setSearchInput(`${currentSelected_.prefix}${props.searchTerm}`)     
        }
       
    },[])
    useEffect(()=>{
        if(props.isClear){
            setSearchInput("")
        }
    },[props.isClear])
    const handleKeyPress = (e)=>{
        if(e.key === 'Enter'){
            if(currentSession.allowedFilters?.mediator){
                props.searchMediatorFunction(searchInput)
            }else{
                props.searchFunction(searchInput)
            }
        }
    }
    const handleChangeInput = (e) => {
        
        setSearchInput(e.target.value)
    }
    const handleChangeSelect = (e) => {
        setCurrentSelected(e.target.value)
        let currentSelected_ =  props.types.filter((type)=>type.value == e.target.value)[0]
        setSearchInput(`${currentSelected_.prefix}`)
        props.handleChangeIdType(e.target.value)
    }
    const SearchIcon = ()=>{
        return(
            <svg width="20px" height="20px" viewBox="0 0 24 24" >
                <title>buscar</title>
                <g stroke="none" strokeWidth={"1"} fill="none" fillRule={"evenodd"} >
                    <path d="M22.707,21.293 C23.098,21.684 23.098,22.316 22.707,22.707 C22.316,23.098 21.684,23.098 21.293,22.707 L15.614,17.028 C14.074,18.259 12.125,19 10,19 C5.029,19 1,14.971 1,10 C1,5.029 5.029,1 10,1 C14.971,1 19,5.029 19,10 C19,12.125 18.259,14.074 17.028,15.614 L22.707,21.293 Z M3,10 C3,13.866 6.134,17 10,17 C13.866,17 17,13.866 17,10 C17,6.134 13.866,3 10,3 C6.134,3 3,6.134 3,10 Z" fill="#b0b0b0"></path>
                </g>
            </svg>
        )
    }
    
   if(props.type =="searchBarId"){
    return (
        <div className={"serchNavTypeJobsContainer "} style={{width: "calc(100% - 150px)"}}>
             {!currentSession.allowedFilters?.mediator ? (
             <>
                <select onChange={handleChangeSelect} multiple={false} value={currentSelected} className="serchNavTypeJobsSelect">
                    {props.types?.map((type)=>{
                        return(
                            <option key={type.value}   value={type.value}>{type.title}</option>
                        )
                    })}
                </select>
                <div className={"serchNavTypeJobsInputContainer"} style={{position: "relative"}}>
                    <input placeholder={props.placeholder} onKeyPress={(e)=>handleKeyPress(e)} onChange={handleChangeInput} value={searchInput} type="search" className="serchNavJobsInput"/>
                    <button className="serchNavJobsIconSearch">
                        <SearchIcon/>
                    </button>
                </div>
             </>
             ):(  
                <>
                    <input placeholder={props.placeholder} onKeyPress={(e)=>handleKeyPress(e)} onChange={handleChangeInput} value={searchInput} type="search" className="serchNavJobsInput"/>
                    <button className="serchNavJobsIconSearch">
                        <SearchIcon/>
                    </button>
                </>
             )}
                
            </div>
    )
   }else{
    return(
        <div className={props.className} style={{position: "relative"}}>
                <input placeholder={props.placeholder} onKeyPress={(e)=>handleKeyPress(e)} onChange={handleChangeInput} value={searchInput} type="search" className="serchNavJobsInput"/>
                <button className="serchNavJobsIconSearch">
                    <SearchIcon/>
                </button>
            </div>
    )
   }
}
