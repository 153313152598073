import Jobin from "jobin-client";
import { apiId, jsKey, urlServer } from "../credentials";
import { getCookie } from "../../utils/cookie";
import { elementOf } from "../../utils/formatter";

const fetchAgentsByDepartment = async prevSelection => {
    Jobin.initServer(apiId, jsKey, urlServer);
    const cookie = getCookie("agent");
    let currentAgentId;
    if (cookie) {
        let agentInfo = JSON.parse(decodeURIComponent(cookie));
        Jobin.initToken(agentInfo.token);
        currentAgentId = agentInfo.agentId;
    }

    let selectedIds =
        prevSelection && Array.isArray(prevSelection) && prevSelection.length > 0
            ? prevSelection.filter(item => item.selected).map(item => item.id)
            : [];
    try {
        const { status, result } = await Jobin.CRMUser.search({departments:["Ops","Tech","CEO","ChiefOps"], limit: 100 });
        if (status == "ok") {
            let departments = {};
            for (let i = 0; i < result.length; i++) {
                const agent = result[i];
                const dep = agent.has("Department")
                    ? agent.get("Department")
                    : "Otros";
                if (!departments[dep]) departments[dep] = [];
                departments[dep].push(agent);
            }
            const arr = Object.keys(departments).map(dep => ({
                parent: dep,
                collapsed:
                    !currentAgentId ||
                    !departments[dep].find(agent => currentAgentId == agent.id),
                items: departments[dep].map(agent => ({
                    id: agent.id,
                    name: agent.has("Lastname")
                        ? agent.get("Name") + " " + agent.get("Lastname")
                        : agent.get("Name"),
                    selected: elementOf(agent.id, selectedIds)
                }))
            }));
            return [
                {
                    id: "all",
                    name: "Todos",
                    selected: false
                },
                ...arr,
                {
                    id: "unAssigned",
                    name: "Sin asignar",
                    selected: false
                }
            ];
        }
    } catch (error) {
        console.error(error);
    }
};

export default fetchAgentsByDepartment;
