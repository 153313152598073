import React, { useState, useEffect } from "react";
import ButtonImage from '../../../button/ButtonImage'
import NewIncidentsModal from "../../../modal/NewIncidentModal";
import Parse from 'parse';

import { formatStringToNumberMonth } from "../../../../utils/formatter";
import { apiId, jsKey, urlServer } from '../../../../constants/credentials';
import { useStateValue } from "../../../../state/context";
import '../../../../styles/job/incidents.css';
import IncidentDetailModal from "../../../modal/IncidentDetailModal";
import AllIncidentModal from "../../../modal/AllIncidentsModal";

export default function IncidentsContainer(props) {
    const [data] = useStateValue();
    const [showNewIncidentModal, setShowNewIncidentModal] = useState(false);
    const [showIncidentDetailModal, setShowIncidentDetailModal] = useState(false);
    const [showAllIncidentModal, setShowAllIncidentModal] = useState(false);
    const [incidents, setIncidents] = useState([]);

    let pathname = window.location.href.split('/');
    useEffect(() => {
        fetchIncidents();
    }, []);

    const fetchIncidents = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();

        let data = {
            jobId: pathname[pathname.length - 1],
        }

        let res = await Parse.Cloud.run("getIncidentsByJob", data);
        setIncidents(res)
    }

    const formatDate = ( date ) => {
        return `${date.substring(16,21)}  ${date.substring(8,10)}-${formatStringToNumberMonth(date.substring(4,7))}-${date.substring(11,15)}`
    }

    const stateClassname = (state) => {
        if(state == 'en gestion') return 'in-progress';
        else return state;
    }

    return (
        <section className="infoContainer">
            <div style={{ display: "flex", flex: 1 }}>
                <div style={{ display: "flex", flex: 1}}>
                    <label className="title">Incidencias</label>
                </div>
                {!props.mediator && (
                    <ButtonImage
                        buttonText=""
                        src={require("../../../../assets/add.svg")}
                        onClick={() =>{
                            setShowNewIncidentModal(true);
                        }}
                        disabled={false}
                    />
                )}
            </div>
            {
                (incidents.length > 0) && (
                    <button className='incident-wrapper' onClick={() => {
                                return setShowIncidentDetailModal(true)
                            }
                            }>
                        <div className='space-between'>
                            <p className='incident-typology'>{incidents[0].get('ExtraInfo').typology}</p>
                            <p className={stateClassname(incidents[0].get('ExtraInfo').state)}>{incidents[0].get('ExtraInfo').state}</p>
                        </div>
                        <p className='incident-description'>{incidents[0].get('ExtraInfo').description}</p>
                        <div className='space-between'>
                            <p className='incident-createdAt'>{formatDate(incidents[0].get('createdAt').toString())}</p>
                            {
                                incidents[0].get('User') && (
                                    <p className='incident-woller'>{incidents[0].get('User').get('Name')}</p>
                                )
                            }
                        </div>
                    </button>
                )
            }

           {data.user.permissions && data.user.permissions.job.edit && (incidents.length > 0) &&( <button className='incident-show-more' onClick={() => setShowAllIncidentModal(true)}>Mostrar Todos</button>)}
            {
                showNewIncidentModal && (
                    <NewIncidentsModal
                        isVisible={true}
                        onClose={() => setShowNewIncidentModal(false)}
                        fetchIncidents={fetchIncidents}
                    />
                )
            }
            {
                (incidents.length > 0) && (
                    <IncidentDetailModal
                        isVisible={showIncidentDetailModal}
                        incidents={incidents}
                        incidentId={incidents[0].id}
                        onClose={() => setShowIncidentDetailModal(false)}
                        jobId= {pathname[pathname.length - 1]}
                        formatDate={formatDate}
                        fetchIncidents={fetchIncidents}
                    />
                )
            }
            {
                (incidents.length > 0) && showAllIncidentModal && (
                    <AllIncidentModal
                        isVisible={true}
                        onClose={() => setShowAllIncidentModal(false)}
                        incidents={incidents}
                        formatDate={formatDate}
                        stateClassname={stateClassname}
                        fetchIncidents={fetchIncidents}
                        mediator={props.mediator}
                    />
                )
            }
        </section>
    )
}