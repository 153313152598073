import React from "react";
import MainModal from "./MainModal";
import ImportContacts from '../user/signup/ImportContacts'

const ImportWollersModal = props => {
    return (
        <MainModal
            style={{ height: "800px", width: "1000px" }}
            title={"Importar Wollers"}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        >
            <ImportContacts
                onClose={props.onClose}
            />
        </MainModal>
    );
};

export default ImportWollersModal;