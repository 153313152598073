export const paymentMethodsArr = [
    {
        id: "all",
        name: "Todos",
        selected: false
    },
    {
        id: "cash",
        name: "Metálico",
        selected: false
    },
    {
        id: "paycard",
        name: "Tarjeta (Stripe)",
        selected: false
    },
    {
        id: "tpv",
        name: "Tarjeta (TPV)",
        selected: false
    },
    {
        id: "transfer",
        name: "Transferencia",
        selected: false
    },
    {
        id: "invoice",
        name: "Email",
        selected: false
    },
    {
        id: "funding",
        name: "Financiado",
        selected: false
    },
    {
        id: "business",
        name: "Finan. empresa",
        selected: false
    },
    {
        id: "credit",
        name: "Crédito",
        selected: false
    }
];
