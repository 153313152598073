import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { useStateValue } from "../../../state/context";

import { CallToAction } from 'jobin-ui-components';
import InfoCell from '../../search/detail/InfoCell';
import PaymentCell from './PaymentCell';
import PaymentMethodCell from './PaymentMethodCell';
import FiscalModal from '../../modal/FiscalModal';
import AddCardModal from '../../modal/AddCardModal';

import "../../../styles/search/detail/job-detail-info.css";

export default function Info() {
    const [{ userDetails }, dispatch] = useStateValue();
    const [showBillingInfo, setShowBillingInfo] = useState(false);
    const [showAddCard, setShowAddCard] = useState(false);
    const [data] = useStateValue();
    const paymentMethodOptionSelected = async (option, item) =>{
        if(option.id === 'mark-as-default'){
            dispatch({
                type: "USER_DETAILS_LOADING",
                loading: true
            });
            const result = await Jobin.PayCard.changeUserPayCardToDefault(item.id, userDetails.user);
            if(result.status === 'ok'){
                const paycards = await Jobin.PayCard.getAllUserPayCards(userDetails.user);
                if(paycards.status === 'ok'){
                    dispatch({
                        type: "USER_DETAILS",
                        paymentMethods: paycards.result
                    });
                }
            }
            else{
                alert(result.result);
            }
            dispatch({
                type: "USER_DETAILS_LOADING",
                loading: false
            });
        }
        else if(option.id === 'delete'){
            dispatch({
                type: "USER_DETAILS_LOADING",
                loading: true
            });
            const result = await Jobin.PayCard.deleteUserPayCard(item.get("CardId"));
            if(result.status === 'ok'){
                const paycards = await Jobin.PayCard.getAllUserPayCards(userDetails.user);
                if(paycards.status === 'ok'){
                    dispatch({
                        type: "USER_DETAILS",
                        paymentMethods: paycards.result
                    });
                }
            }
            else{
                alert(result.result);
            }
            dispatch({
                type: "USER_DETAILS_LOADING",
                loading: false
            });
        }
    }

    const paymentOptionSelected = async (option, payment) => {
        if (option.id === "get-bill") {
            const win = window.open(payment.get("Receipt").url(), "_blank");
            win.focus();
        } else if (option.id === "remake-bill") {
            dispatch({
                type: "USER_DETAILS_LOADING",
                loading: true
            });
            let { status, result } = await Jobin.PDF.remakeBill(payment);
            if (status == "ok") {
                const win = window.open(result, "_blank");
                win.focus();
                await payment.fetch();
            } else {
                alert(result);
            }
            dispatch({
                type: "USER_DETAILS_LOADING",
                loading: false
            });
        }
    };

    const billingInfoSaved = billingInfo =>{
        setShowBillingInfo(false);
        dispatch({
            type: "USER_DETAILS",
            billingInfo: billingInfo
        });
    }

    const cardSaved = async () =>{
        dispatch({
            type: "USER_DETAILS_LOADING",
            loading: true
        });
        const paymentMethods = await Jobin.PayCard.getAllUserPayCards(userDetails.user);
        if(paymentMethods.status === 'ok'){
            dispatch({
                type: "USER_DETAILS",
                paymentMethods: paymentMethods.result
            });
        }
        dispatch({
            type: "USER_DETAILS_LOADING",
            loading: false
        });
    }

    return data.user.permissions && data.user.permissions.user.edit && ( // :null
    // return data.user.permissions && (
        <div>
            {showBillingInfo &&
                <FiscalModal
                    isVisible={showBillingInfo}
                    user={userDetails.user}
                    onClose={()=>setShowBillingInfo(false)}
                    success={billingInfoSaved}
                />
            }
            {showAddCard &&
                <AddCardModal
                    isVisible={showAddCard}
                    title={"Añadir nuevo método de pago"}
                    subtitle={"Añade los datos de la tarjeta que el cliente quiera añadir a su perfil"}
                    buttonText={"Guardar"}
                    onClose={()=>setShowAddCard(false)}
                    cardSaved={cardSaved}
                    user={userDetails.user}
                />
            }
            <section className="jobDetailContainer">
                <div>
                    <div style={{display: "flex", flex: 1, flexDirection: 'row', overflow: 'hidden', position: 'relative'}}>
                        <label className="subTitle">Datos de facturación</label>
                        <CallToAction
                            style={{position: 'absolute', right: 0, color: '#3543FF'}}
                            buttonText={"Editar"}
                            onClick={() => setShowBillingInfo(true)}
                        />
                    </div>
                    {userDetails.billingInfo ? 
                        <div>
                            <div className="detailContainer">
                                <InfoCell
                                    title="Nombre"
                                    data={userDetails.billingInfo.get("FiscalName")}
                                    halfLeft={true}
                                />
                                <InfoCell
                                    title="NIF"
                                    data={userDetails.billingInfo.get("IdentityNumber")}
                                    halfRight
                                />
                            </div>
                            <div className="detailContainer">
                                <InfoCell
                                    title="Dirección"
                                    data={userDetails.billingInfo.get("FiscalAddress")}
                                    halfLeft={true}
                                />
                                <InfoCell
                                    title="Ciudad"
                                    data={userDetails.billingInfo.get("FiscalCity")}
                                    halfRight
                                />
                            </div>
                        </div>
                    :
                        <div>
                            <label>No hay datos de facturación</label>
                        </div>
                    }
                </div>
                <div style={{marginTop: 24}}>
                    <div style={{display: "flex", flex: 1, flexDirection: 'row', overflow: 'hidden', position: 'relative'}}>
                        <label className="subTitle">Métodos de pago</label>
                        <CallToAction
                            style={{position: 'absolute', right: 0, color: '#3543FF'}}
                            buttonText={"Añadir"}
                            onClick={() => setShowAddCard(true)}
                        />
                    </div>
                    {userDetails.paymentMethods.length > 0 ?
                        userDetails.paymentMethods.map((item) =>{
                            return(
                                <PaymentMethodCell
                                    key={item.id}
                                    paymentMethod={item}
                                    buttonItemSelected={(option) => paymentMethodOptionSelected(option, item)}
                                />
                            );
                        })
                    :
                        <div>
                            <label>No hay métodos de pago</label>
                        </div>
                    }
                </div>
                <div style={{marginTop: 24}}>
                    <label className="subTitle">Pagos realizados</label>
                    {userDetails.payments.length > 0 ?
                        userDetails.payments.map((item) =>{
                            return item.get("Action")!== "failed" && (
                                <PaymentCell
                                    key={item.id}
                                    payment={item}
                                    buttonItemSelected={option => paymentOptionSelected(option, item)}
                                />
                            )
                        })
                    :
                        <div>
                            <label>No hay pagos realizados</label>
                        </div>
                    }
                </div>
            </section>
        </div>
    )
}
