import React, { useState, useEffect } from "react";
import { useStateValue } from "../../state/context";
import { selectService } from "../../constants/selectService";
import Jobin from "jobin-client";
import { Input, RadioButton } from "jobin-ui-components";
import ExtrasModal from "../modal/ExtrasModal";
import Button from "../button/Button";
import ButtonEmpty from "../button/ButtonEmpty";
import StepControlls from "./generic/StepControlls";
import BreadCrumb from "./generic/BreadCrumb";
import ExtraInfoCell from "../search/detail/detailColumn/ExtraInfoCell";

import "../../styles/job/service-price.css";

export default function ServicePrice(props) {
    const [{ job }, dispatch] = useStateValue();

    const [tempPrice, setTempPrice] = useState(null);
    const [tempPriceIVA, setTempPriceIVA] = useState(null);
    const [price, setPrice] = useState(job.price.amount);
    const [priceOk, setPriceOk] = useState(true);
    const [extrasArray, setExtrasArray] = useState(job.price.extrasArray);
    const [showExtrasModal, setShowExtrasModal] = useState(false);
    const [editPrice, setEditPrice] = useState(false);
    const [subvertical, setSubvertical] = useState(undefined);
    const [includes, setIncludes] = useState([]);
    const [doesntInclude, setDoesntInclude] = useState([]);
    const [selectedOption, setSelectedOption] = useState(
        job.price.selectedOption
    );

    const style = {
        priceContainer: {
            display: "flex",
            flexDirection: "column",
            marginTop: 24,
            marginBottom: 24
        }
    };

    const saveExtras = async extraArr => {
        setShowExtrasModal(false);
        setExtrasArray(extraArr);

        const extraPrice = extraArr.reduce((accumulator, extra) => {
            return accumulator + extra.pricePerUnit * extra.units;
        }, 0);

        dispatch({
            type: "JOB_PRICE_EXTRAS",
            extrasArray: extraArr,
            extraAmount: extraPrice
        });
    };

    const disabled = () => {
        if (job.price.amount + job.price.extraAmount > 0 || job.transport)
            return false;
        else if (selectedOption === "lead") return false;
        else return true;
    };

    const update = () => {
        dispatch({
            type: "JOB_PRICE_SELECTED",
            selectedOption: selectedOption
        });
    };

    const checkPrice = input => {
        if (input.length > 0) {
            const reg = new RegExp("^-?\\d+\\.\\d{2,2}$");
            if (reg.test(input)) {
                const temp = Number(input).toFixed(3);
                setTempPrice(temp);
                setTempPriceIVA((temp * 1.21).toFixed(2));
                setPriceOk(true);
            } else {
                setTempPrice(null);
                setTempPriceIVA(null);
                setPriceOk(false);
            }
        } else {
            setTempPrice(null);
            setTempPriceIVA(null);
            setPriceOk(true);
        }
    };

    const checkPriceIVA = input => {
        if (input.length > 0) {
            var reg = new RegExp("^-?\\d+\\.\\d{2,2}$");
            if (reg.test(input)) {
                const temp = (Number(input) / 1.21).toFixed(3);
                setTempPrice(temp);
                setTempPriceIVA(Number(input).toFixed(2));
                setPriceOk(true);
            } else {
                setTempPrice(null);
                setTempPriceIVA(null);
                setPriceOk(false);
            }
        } else {
            setTempPrice(null);
            setTempPriceIVA(null);
            setPriceOk(true);
        }
    };

    useEffect(() => {
        if (!(props.location.state && "inner" in props.location.state))
            props.history.replace("/job/new");

        const getEmptyJob = async () => {
            const { status, result } = await Jobin.Guild.get(
                job.category.subCategoryId || job.category.categoryId
            );
            if (status === "ok") {
                setSubvertical(result);
                if (result.has("Includes")) setIncludes(result.get("Includes"));
                if (result.has("DoesntInclude"))
                    setDoesntInclude(result.get("DoesntInclude"));
            }
        };

        if (!job.transport) getEmptyJob();
    }, []);

    const changeOption = serviceId => {
        //Remove urgency extra
        const tempArray = extrasArray;
        tempArray.map((item, index) => {
            if (item.type === "urgency") {
                dispatch({
                    type: "JOB_PRICE_EXTRAS",
                    extraAmount: job.price.extraAmount - item.pricePerUnit
                });
                tempArray.splice(index, 1);
            }
        });

        //Add urgency extra
        if (serviceId === "urgent") {
            const extraPrice = tempArray.reduce((accumulator, extra) => {
                if (extra.pricePerUnit >= 0) {
                    return accumulator + extra.pricePerUnit * 0.5 * extra.units;
                } else {
                    return accumulator;
                }
            }, 0);

            let urgencyPrice = 82.645;
            if (job.price.amount + job.price.extraAmount < 200) {
                urgencyPrice = job.price.amount * 0.5 + extraPrice;
            }

            tempArray.push({
                type: "urgency",
                name: "Incremento por servicio de urgencia",
                units: 1,
                pricePerUnit: urgencyPrice
            });
            dispatch({
                type: "JOB_PRICE_EXTRAS",
                extraAmount: job.price.extraAmount + urgencyPrice,
                extrasArray: tempArray
            });
        }

        setExtrasArray(tempArray);
        setSelectedOption(serviceId);
    };

    return (
        <div id="service-price" className="section">
            <BreadCrumb history={props.history} location={props.location} />
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="title">Precio del servicio</h4>
                        <p>No te olvides de añadir extras si fuese necesario</p>
                        <div className="content">
                            <div className="price">
                                {editPrice ? (
                                    <div>
                                        <Input
                                            style={{
                                                marginTop: 26,
                                                fontWeight: 600,
                                                fontSize: 40,
                                                height: 46
                                            }}
                                            badInput={!priceOk}
                                            placeholder={
                                                (price * 1.21).toFixed(2) + "€"
                                            }
                                            onChange={e =>
                                                checkPriceIVA(e.target.value)
                                            }
                                            value={
                                                tempPriceIVA
                                                    ? tempPriceIVA + "€"
                                                    : null
                                            }
                                            autoFocus
                                        />
                                        <Input
                                            style={{
                                                marginTop: 12,
                                                marginBottom: 26,
                                                fontSize: 16
                                            }}
                                            badInput={!priceOk}
                                            placeholder={
                                                price.toFixed(2) + "€ sin IVA"
                                            }
                                            onChange={e =>
                                                checkPrice(e.target.value)
                                            }
                                            value={
                                                tempPrice
                                                    ? Number(tempPrice).toFixed(
                                                          2
                                                      ) + "€ sin IVA"
                                                    : null
                                            }
                                        />
                                    </div>
                                ) : (
                                    <div style={style.priceContainer}>
                                        <label
                                            style={{
                                                fontSize: 40,
                                                fontWeight: 600,
                                                color: "var(--greyish-brown)",
                                                lineHeight: 1.17,
                                                opacity:
                                                    selectedOption === "lead"
                                                        ? 0.5
                                                        : 1
                                            }}
                                        >
                                            {selectedOption === "urgent"
                                                ? (
                                                      (price +
                                                          job.price
                                                              .extraAmount) *
                                                      1.21
                                                  ).toFixed(2) + "€"
                                                : (
                                                      (price +
                                                          job.price
                                                              .extraAmount) *
                                                      1.21
                                                  ).toFixed(2) + "€"}
                                        </label>
                                        <label
                                            style={{
                                                fontSize: 16,
                                                opacity:
                                                    selectedOption === "lead"
                                                        ? 0.5
                                                        : 1
                                            }}
                                        >
                                            {selectedOption === "urgent"
                                                ? (
                                                      price +
                                                      job.price.extraAmount
                                                  ).toFixed(2) + "€ sin IVA"
                                                : (
                                                      price +
                                                      job.price.extraAmount
                                                  ).toFixed(2) + "€ sin IVA"}
                                        </label>
                                    </div>
                                )}
                                {job.transport && (
                                    <ButtonEmpty
                                        buttonText={
                                            editPrice
                                                ? "Guardar"
                                                : "Editar precio"
                                        }
                                        onClick={() => {
                                            if (editPrice && tempPrice > 0) {
                                                setPrice(Number(tempPrice));
                                                update();
                                            }
                                            setEditPrice(!editPrice);
                                        }}
                                        disabled={!priceOk}
                                    />
                                )}
                            </div>
                            {!job.transport && (
                                <div className="lead-or-fixed">
                                    {selectService.map((service, index) => {
                                        return (
                                            !(
                                                service.id === "lead" &&
                                                job.fromLeadToFixed
                                            ) && (
                                                <RadioButton
                                                    key={"radio-" + index}
                                                    item={{
                                                        title: service.name,
                                                        selected:
                                                            selectedOption ===
                                                            service.id
                                                                ? true
                                                                : false
                                                    }}
                                                    onClick={() =>
                                                        changeOption(service.id)
                                                    }
                                                />
                                            )
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                    {!job.transport && (
                        <div
                            className="col-md-offset-1 col-md-4"
                            style={{
                                opacity: selectedOption === "lead" ? 0.5 : 1
                            }}
                        >
                            <div className="add-extras">
                                <h5 className="title">Extras del servicio</h5>
                                <p />
                                {extrasArray.length > 0 ? (
                                    <div className="extras-list">
                                        {extrasArray.map((extra, index) => {
                                            return (
                                                <ExtraInfoCell
                                                    key={"extra" + index}
                                                    extra={extra}
                                                />
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <p>No hay extras añadidos</p>
                                )}
                                <div className="button-view">
                                    <Button
                                        onClick={() => setShowExtrasModal(true)}
                                        buttonText="Añadir extras"
                                    />
                                </div>
                            </div>
                            <div className="includes">
                                {includes && includes.length > 0 && (
                                    <React.Fragment>
                                        <h5 className="title">
                                            Este servicio incluye
                                        </h5>
                                        <ul>
                                            {includes.map((include, index) => {
                                                return (
                                                    <li key={index + "inc"}>
                                                        {include}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </React.Fragment>
                                )}
                                {doesntInclude && doesntInclude.length > 0 && (
                                    <React.Fragment>
                                        <h5 className="title">
                                            Este servicio no incluye
                                        </h5>
                                        <ul>
                                            {doesntInclude.map(
                                                (doesntinc, index) => {
                                                    return (
                                                        <li
                                                            key={index + "dont"}
                                                        >
                                                            {doesntinc}
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {showExtrasModal && (
                <ExtrasModal
                    isVisible={showExtrasModal}
                    onClose={() => setShowExtrasModal(false)}
                    extrasArray={extrasArray}
                    saveExtras={saveExtras}
                    allowDeleteItems={true}
                    subvertical={subvertical}
                    isUrgent={selectedOption === "urgent"}
                    basicPrice={job.price.amount}
                />
            )}
            <StepControlls
                page={props.step}
                next={job.transport ? "job/new/date" : "job/new/availability"}
                prev={job.transport ? "job/new/weight" : "job/new/location"}
                nextTitle={"Siguiente"}
                disabled={disabled && editPrice}
                update={update}
            />
        </div>
    );
}
