import React, { useState } from "react";

import { Modal, Button } from 'jobin-ui-components'

const PasswordModal = props => {
    const [password,setPassword] = useState("")
    const [error,setError] = useState({show:false,errorMessage:""})
    const [errorConfirm,setErrorConfirm] = useState({show:false,errorMessage:""})
    const [isReadyToSave,setIsReadyToSave] = useState(false)
    

    const style = {
        modal: {
            borderRadius: 4,
            width: "30%",
            ...props.style
        },
        broker: {
            borderRadius: 4,
            alignSelf: "center",
            width: "1500px",
            height: "860px",
            maxWidth: "1000000px",
            ...props.style
        },
        modalBig: {
            borderRadius: 4,
            width: "50%",
            ...props.style
        },
        modalGigant: {
            borderRadius: 4,
            width: "66%",
            ...props.style
        },
        modalSuper: {
            borderRadius: 30,
            height: "800px", 
            width: "1170px", 
            maxWidth:"1400px",
            padding:"2.2rem",
            ...props.style
        },
        headerView: {
            display: "flex",
            flexDirection: "column"
        },
        title: {
            fontSize: 18,
            fontWeight: 700,
            marginTop: 10
        },
        headerViewCenter: {
            display:"flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
        confirmButton:{
            width: "100px",
            padding: "13px",
            color: "var(--coral)",
            background: "#000",
            borderRadius: "5px",
            fontSize: "16px",
            fontWeight: "600",
            marginLeft: "auto",
        },

        
    };
    const checkPassword =(password_)=>{
        if (password_ === password){
            setIsReadyToSave(true)
            setErrorConfirm({show:false,errorMessage:""})
        }else{
            setIsReadyToSave(false)
            setErrorConfirm({show:true,errorMessage:"Las contraseñas deben ser iguales"})
        }
    }
    const setNewpassword = (password_)=>{
        // if(!password_.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)){
        //     setError({show:true,errorMessage:"La contraseña debe seguir los parametros de seguridad antes mencionados"})
        // }else{
        //     setError({show:false,errorMessage:""})
        // }
        setPassword(password_)
    
    }

    return (
        <Modal
        title={props.title}
        subtitle={props.subtitle}
        onClose={()=>{
                        props.onClose();
                        if(props.lottieLoad){
                            props.lottieLoad()
                        }
                    }}
    >           <div style={{marginBottom:"10px"}} >
                    <label style={{color: '#4a4a4a', fontSize: '14px', marginTop: '8px', width:"100%"}}>Nueva contraseña</label>
                    <input
                        className="textInput"
                        type="password" 
                        style={{
                            width: '95%',
                            padding: '5px',
                            border: '1px solid #4a4a4a',
                            borderRadius: '4px',
                            marginRight:"auto",
                            height:"44px"
                        }} 
                        value={password}
                        onChange={(e)=>setNewpassword(e.target.value)}
                        name="ExperienceYears"
                    ></input>
                    {error.show && (<p style={{color:"red",fontSize:"12px"}}>{error.errorMessage}</p>)}
                </div>

                    <div style={{marginBottom:"10px"}} >
                        <label style={{color: '#4a4a4a', fontSize: '14px', marginTop: '8px', width:"100%"}}>Confirmar contraseña</label>
                        <input
                            className="textInput"
                            type="password" 
                            style={{
                                width: '95%',
                                padding: '5px',
                                border: '1px solid #4a4a4a',
                                borderRadius: '4px',
                                marginRight:"auto",
                                height:"44px"
                            }} 
                            onChange={(e)=>checkPassword(e.target.value)}
                            name="ExperienceYears"
                        ></input>       
                        {errorConfirm.show && (<p style={{color:"red",fontSize:"12px"}}>{errorConfirm.errorMessage}</p>)}
                    </div>
            
            <div style={{width:"100%",display:"flex"}}>
                <button
                    style={style.confirmButton}
                    disabled={!isReadyToSave}
                    onClick={()=>props.functionParam(password,props.itemIndex ? props.itemIndex:null, props.itemType? props.itemType:null)}>
                        {props.buttonText}
                </button>
            </div>
        </Modal>
    );
};

export default PasswordModal;
