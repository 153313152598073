export const urgencyStates = [
    {
        id: "urgent",
        name: "Es urgente",
        subtitle: "Solo para trabajos que se necesiten en el momento",
        selected: false
    },
    {
        id: "normal",
        name: "En unos días",
        selected: false
    },
    {
        id: "target",
        name: "En unos meses",
        selected: false
    }
];
