import React, { useEffect, useRef, useState } from "react";

import { getPurchasedJobsBiPro ,getFavoritePacks as getFavoritePacks_} from "../../utils/ParserUtilities"

import { useStateValue } from "../../state/context";
import { formatCurrency, formatDateWhitoutHour } from "../../utils/formatter";
import {Loading} from 'jobin-ui-components'

import ServiceDetails  from "../../components/modal/ServiceDetails.jsx";
import RechargeMoneyModal  from "../../components/modal/RechargeMoneyModal.jsx";
import SingleSelect from "../singleComponents/SingleSelect.jsx";
import CSVExport from "../../utils/csvExport";
import {formatCreatedAtCSV, formatPostalCodeToProvince} from '../../utils/formatter'

import Parse from "parse";
import { apiId, jsKey, urlServer  } from '../../constants/credentials';

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import moment from "moment";
import "moment/locale/es"


import "../../styles/purchased-jobs/purchased-jobs.css";

export default function PurchasedJobs() {

  const [{ user, currentSession }, dispatch] = useStateValue();

  const [purchasedJobsData, setPurchasedJobsData] = useState(undefined);
  const [originalJobsData, setOriginalJobsData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [specificLoading, setSpecificLoading] = useState(false);
  const [showServiceDetails, setShowServiceDetails] = useState(false)
  const [jobSelected, setJobSelected] = useState(false);
  const [showMoneyModal, setShowMoneyModal] = useState(false);
  const [packs, setPacks] = useState([])
  const [type, setType] = useState(user?.isConstructionCompany ? "fixed" : "lead");
  const [finishJobFilter, setFinishJobFilter] = useState(false);

  const csvRef = useRef(null);
  moment.locale('es')

  useEffect(()=> {
    getFavoritePacks()
  }, [])

  useEffect(()=> {
      getPurchasedJobs()
  }, [type])


  useEffect(()=> {
    setSpecificLoading(false)
  }, [purchasedJobsData])


  const jobSlots = [
    {
      id: "fixed",
      name: "Precio cerrado",
      selected: false
    },
    ...(user?.isConstructionCompany ? [] : [
      {
        id: "lead",
        name: "Marketplace",
        selected: false
      }
    ]),
  ];
  const getPurchasedJobs = async () => {
    setSpecificLoading(true)
    getPurchasedJobsBiPro(user.goldId, type ? type:"lead").then( res => {
      setPurchasedJobsData(res);
      setOriginalJobsData(res);
      setLoading(false);
    })
  }

  const getJobsByWollerId = async (userId) => {
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();

    let csvData = {
        data: [],
        headers: [
            { label: "ID", key: "id" }, { label: "Título", key: "title" }, { label: "Descripción", key: "description" },
            { label: "Fecha de creación", key: "createdAt" }, { label: "Estado", key: "state" },
            { label: "Saldo", key: "nJober" }, { label: "Provincia", key: "postalCode" }, { label: "Dirección", key: "address" },
            { label: "TotalSlots", key: "totalSlots" }, { label: "Nivel", key: "level"}, { label: "Tipo", key: "jobType" },
            { label: "Precio", key: "jobLeadPrice" }, { label: "Gremio", key: "vertical"}, { label: "Teléfono", key: "phone"},
            { label: "Nombre", key: "name"}
        ],
        filename:"Servicios Wolly "+moment().format("d-MM-YYYY")
    }

    let res = await Parse.Cloud.run("getJobsByWollerId", { userId: userId });
    res.map( not => {
        const job = not?.get('Jobs');
        csvData.data.push( {
            id: job?.id, title: job?.get('Title'),
            description: job?.get('Description'),
            createdAt: formatCreatedAtCSV(job?.get('createdAt') ? job?.get('createdAt') : ""),
            state: job?.get('State'), nJober: job?.get('nJober'),
            postalCode: formatPostalCodeToProvince(job?.get('PostalCode') ? job?.get('PostalCode')?.substring(0, 2) : ""),
            address: job?.get('Address').replaceAll(',',';'),
            totalSlots: job?.get('TotalSlots'),
            level: job?.get('Level'), jobType: job?.get('JobType'),
            jobLeadPrice: job?.get('JobLeadPrice'),
            vertical: job?.get("Type"),
            phone: not.get("ToUser").get("Phone"),
            name: not.get("ToUser").get("Name")
        } );
    })

    return csvData;
  }

  const showDetailModal = (job) => {

    setJobSelected({
      fixedPrice:job.fixedPrice ? job.fixedPrice :null,
      appointment:job.appointment ? job.appointment :null,
      jobType: job.jobType,
      verticalLogo: job.verticalLogo,
      verticalName: job.verticalName,
      title: job.title,
      business: job.businessLogo,
      businessLogo: job.businessLogo?.get("Logo").url(),
      clientName: job.clientName,
      clientPhone: job.clientPhone,
      createdAt: job.date,
      address: job.address,
      description: job.description,
      images: job.images,
      type: job.verticalName,
      id: job.id,
      includes: job.includes,
      doesntIncludes: job.doesntIncludes,
      jobState: job.jobState
    })
    setShowServiceDetails(true);
  }
  const getFavoritePacks = async ()=>{
    let packs_ = await getFavoritePacks_()
    if(packs_ && packs_.length>0){
        packs_ = packs_.map((pak)=>{
            return {
                id:pak.id,
                freeFTBCredits:pak.get("FreeFTBLeads"),
                freeLeads:pak.get("FreeLeads"),
                image:pak.get("Image").url(),
                credits:pak.get("Leads"),
                popular:pak.get("Popular"),
                price:pak.get("Price"),
                priceId:pak.get("stripeId"),
                }
        })
        setPacks(packs_)
    }
 }

  const handleFinishJobChange = (event) => {
    setFinishJobFilter(event.target.checked);
    if (event.target.checked) {
      filterJobs();
    } else {
      setPurchasedJobsData(originalJobsData);
    }
  };

  const filterJobs = () => {
    if (originalJobsData) {
      const filteredJobs = originalJobsData.jobs.filter(job => {
        return job.state === 'finalizado' || job.jobState === 'finalizado';
      });
      setPurchasedJobsData({ ...originalJobsData, jobs: filteredJobs, countJobs: filteredJobs.length });
    }
  };

  if(loading){
    return (
      <div className="loading-complete">
        <Loading/>
      </div>
    )
  }

  const handleServiceDetailsModalClose = async (jobUpdated) => {
    if (jobUpdated) {
      await getPurchasedJobs();
    }
    setShowServiceDetails(false);
  };

  return (
    <div className="purchased-jobs-wrapper">
      {showMoneyModal && <RechargeMoneyModal
                isVisible={showMoneyModal}
                onClose={()=>setShowMoneyModal(false)}
                packs={packs}
            />}
      <div className="purchased-jobs-title-wrapper">
        <div className="purchased-jobs-title">{user?.isConstructionCompany ? "Mis trabajos" : "Mis trabajos comprados"}</div>
        {!user?.isConstructionCompany && (
        <div className="purchased-jobs-nJobs-wrapper">
          <p style={{marginRight: 15}}>Tu saldo disponible:</p>
          <button onClick={()=>setShowMoneyModal(true)} className={user.isUserSubscription ? "balanceButton infinite":"balanceButton"}> {user.isUserSubscription ? "∞":formatCurrency(currentSession.wollerData?.get("nJobs"))}</button>
        </div>
        )}
      </div>
      <div className="job-type-wrapper">
        <div className="job-type-selector-wrapper">
          <SingleSelect title="Tipo de servicio" values={jobSlots}
                        preSelected={{
                          id: user?.isConstructionCompany ? "fixed" : "lead",
                          name: user?.isConstructionCompany ? "Precio cerrado" : "Marketplace",
                          selected: false
                        }} handleSelected={setType}
          />
        </div>
        {user?.isConstructionCompany && (
            <div style={{ marginTop: 16 }}>
              <label>
                <input
                    type="checkbox"
                    checked={finishJobFilter}
                    onChange={handleFinishJobChange}
                    style={{ marginRight: '4px' }}
                />
                Mostrar trabajos cerrados
              </label>
            </div>
        )}
        <div style={{marginTop: 32}}>
          <a ref={csvRef} style={{display: "none"}}/>
          <CSVExport
              children={"Descargar mis servicios"}
              getDataForCsv={() => getJobsByWollerId(user.goldId)}
              bipro={true}
          />
        </div>
      </div>

      <div className="job-type-selector-wrapper-mobile">
        <button
            className={`purchased-jobs-type-button ${type == "lead" ? "selected"
                : ""}`} onClick={() => setType("lead")}>Marketplace
        </button>
        <button className={`purchased-jobs-type-button ${type == "fixed" ? "selected" : ""}`} onClick={() => setType("fixed")}>Precio cerrado</button>
        {user?.isConstructionCompany && (
            <div style={{ marginTop: 16, marginLeft: 16, marginBottom: 16 }}>
              <label>
                <input
                    type="checkbox"
                    checked={finishJobFilter}
                    onChange={handleFinishJobChange}
                    style={{ marginRight: '4px' }}
                />
                Mostrar trabajos cerrados
              </label>
            </div>
        )}
      </div>

      <div className="purchased-jobs-stats-responsive-wrapper">
        <div>
          {
            !specificLoading ? (
              <>
                <p className="purchased-jobs-stats-number">{purchasedJobsData ? purchasedJobsData.countJobs : ""}</p>
                <p className="purchased-jobs-stats-p">{type == "lead" ? "Número de trabajos comprados en total":"Número de trabajos"}</p>
              </>
            ) : <Loading/>
          }
        </div>
        <div>
          {
            !specificLoading ? (
              <>
                <p className="purchased-jobs-stats-number">{purchasedJobsData ? formatCurrency(purchasedJobsData.countLeads) : ""}</p>
                <p className="purchased-jobs-stats-p">{type == "lead" ? "Invertido en total":"Ganados"}</p>
              </>
            ) : <Loading/>
          }
        </div>
      </div>

      <div className="purchased-jobs-data">
          <div className="purchased-jobs-main-wrapper">
            {
              purchasedJobsData && purchasedJobsData.jobs.map( job => (
                <div className="purchased-jobs-main-cell" key={job.id}>
                  {
                    job.jobType == "fixed" ? (
                      <div className="jobType">Precio Cerrado</div>
                    )
                    : <div className="jobType">Marketplace</div>
                  }
                  {
                    job.images ? (
                        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                            {job.images.map((image,imageIndex)=>{
                                return(
                                    <SwiperSlide className="jobSlideImage" ><div style={{background:"url("+image.url()+")"}}></div></SwiperSlide>
                                )
                            })}
                        </Swiper>
                      ) : (<div className="noPics" style={{background:"url("+require("../../assets/noPics.png")+")"}}></div>)
                  }
                  <div className="purchased-jobs-main-details">
                    <div className="purchased-jobs-main-details-title-wrapper">
                      <div className="purchased-jobs-flex-jobs">
                        <img style={{height:30}} src={job.verticalLogo && job.verticalLogo._url ? job.verticalLogo._url : ""}/>
                        <p className="purchased-jobs-main-details-title">{job.title}</p>
                      </div>

                      {job.businessLogo && (
                        <img className="bussinessImg" src={job.businessLogo.get("Logo").url()}/>
                      )}
                    </div>
                    <div className="purchased-jobs-main-details-wrapper">
                      <img src={require("../../assets/icons/User.svg")} alt="" />
                      <p className="purchased-jobs-main-details-p">{job.clientName}</p>

                    </div>
                    <div className="purchased-jobs-main-details-wrapper">
                      <img src={require("../../assets/location-tag.svg")} alt="" />
                      <p className="purchased-jobs-main-details-p">{job.address}</p>

                    </div>
                    <div className="purchased-jobs-main-details-wrapper">
                      <img src={require("../../assets/icons/Clock.svg")} alt="" />
                      {
                        job.jobType == "lead" ? <p className="purchased-jobs-main-details-p">Subido {moment(job?.date).fromNow()}</p>
                                          : <p className="purchased-jobs-main-details-p">Cita con el cliente:
                                            {job?.appointment?.toString().substring(7,10)} {job?.appointment?.toString().substring(3  ,8)} {job?.appointment?.toString().substring(11,15)}</p>
                      }
                    </div>
                    <div className="purchased-jobs-main-footer-wrapper">
                      <p className="purchased-jobs-main-details-footer-p"> {job.fixedPrice ? `Ganancias: ${formatCurrency(job.fixedPrice)} + IVA` : ""} </p>
                      <button onClick={ () => showDetailModal(job)} className="balanceButton">Mas Información</button>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>

          <div className="purchased-jobs-stats-wrapper">

            <div className="purchased-jobs-stats">
              {
                !specificLoading ? (
                  <>
                    <div className="w-full">
                      <p className="purchased-jobs-stats-number">{purchasedJobsData ? purchasedJobsData.countJobs : ""}</p>
                      <p className="purchased-jobs-stats-p">{type == "lead" ? "Número de trabajos comprados en total":"Número de trabajos"}</p>
                    </div>
                    <div className="w-full">
                      <p className="purchased-jobs-stats-number">{purchasedJobsData ? formatCurrency(purchasedJobsData.countLeads) : ""}</p>
                      <p className="purchased-jobs-stats-p">{type == "lead" ? "Invertido en total":"Ganados"}</p>
                    </div>
                  </>
                ) : <Loading/>
              }
            </div>
          </div>
      </div>
      {showServiceDetails && <ServiceDetails
                isVisible={showServiceDetails}
                forOwn={false}
                service={jobSelected}
                onClose={(jobUpdated)=>handleServiceDetailsModalClose(jobUpdated)}
            />}
    </div>
  )
}
