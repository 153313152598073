import Jobin from "jobin-client";
import { apiId, jsKey, urlServer } from "../credentials";
import { getCookie } from "../../utils/cookie";
import { elementOf } from "../../utils/formatter";

const fetchDepartments = async( prevSelection, isAgentManagers_ )=> {
    Jobin.initServer(apiId, jsKey, urlServer);
    const cookie = getCookie("agent");
    Jobin.initToken(
        cookie ? JSON.parse(decodeURIComponent(cookie)).token : undefined
    );
    if (isAgentManagers_){

        var selectedIds =
            prevSelection && Array.isArray(prevSelection)
                ? prevSelection.map(item => item)
                : [];
    }else{
        var selectedIds =
            prevSelection && Array.isArray(prevSelection)
                ? prevSelection.filter(item => item.selected).map(item => item.id)
                : [];
    }
    
    try {
        const { status, result } = await Jobin.CRMUser.search({ limit: 100 });
        if (status == "ok") {
            let departments = {};
            for (let i = 0; i < result.length; i++) {
                const dep = result[i].get("Department");
                if (dep) departments[dep] = true;
            }
            const arr = Object.keys(departments).map(dep => {
                return {
                    id: dep,
                    name: dep,
                    selected: elementOf(dep, selectedIds)
                };
            });
            if (isAgentManagers_){
                return arr
            }else{
                return [
                    {
                        id: "all",
                        name: "Todos",
                        selected: false
                    }
                ].concat(arr);
            }
        }
    } catch (error) {
        console.error(error);
    }
};

export default fetchDepartments;
