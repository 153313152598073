import React from "react";

import DetailContainer from "./DetailContainer";

import "../../../../styles/search/detail/job-detail-container.css";

export default function DetailColumn(props) {
    return (
        <div className="jobDetailColumn">
            <DetailContainer
                history={props.history}
                location={props.location}
            />
        </div>
    );
}
