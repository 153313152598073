import React, {useState} from "react";

export default function ButtonImage(props) {

    const styles = {
        buttonContainer: {
           
            cursor: (props.disabled || props.loading) ? null : 'pointer',
           
            opacity: (props.disabled || props.loading) ? 0.5 : 1.0,
           
            ...props.style
        },
        buttonContainerHover: {
           
            cursor: (props.disabled || props.loading) ? null : 'pointer',
            
            opacity: (props.disabled || props.loading) ? 0.5 : 1.0,
           
            ...props.style
        },
    }

    const [isHover, setIsHover] = useState(false);

    return (
        <button
            style={(isHover && !props.disabled) ? styles.buttonContainerHover : styles.buttonContainer}
            onClick={(props.disabled || props.loading) ? null : props.onClick}
            onMouseEnter={() => props.disableHover? null: setIsHover(true)}
            onMouseLeave={() => props.disableHover? null: setIsHover(false)}
            className={props.className? props.className + " buttonImageContainer" : "buttonImageContainer"}
        >
            <img style={{marginBottom: 4, height: 20, width: 20}} alt="img-button" src={props.src}/>
            <p style={{color:"inherit"}}> {props.buttonText}</p>
        </button>
    );
}
