import React from "react";
import GaragePage from "../../components/search-garages/SearchGarages";

export default function SearchGarages(props) {
    return (
        <React.Fragment>
            <GaragePage history={props.history} location={props.location} />
        </React.Fragment>
    );
}
