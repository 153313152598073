import React, {useState, useRef, useEffect } from "react";
import Parse from "parse";

import GenericTable from "../GenericTable";
import { Loading,Button } from "jobin-ui-components"
import Switch from "../singleComponents/switch"
import AlertModal from "../modal/Alert";
import StateModal  from "../modal/StateModal";
import ReviewModal from "../modal/ReviewModal";
import ButtonOwn from "../button/Button";


import { useStateValue } from "../../state/context";
import { updateVerified,buyJobForWoller,getUserData } from "../../utils/ParserUtilities";
import { formatCreatedAtCSV, formatPostalCodeToProvince, getState, } from "../../utils/formatter";
import { apiId, jsKey, urlServer  } from "../../constants/credentials";
import CSVExport  from "../../utils/csvExport"

import "../../styles/search/result-table.css";


export default function ResultTable({history,location}) {
    const [{ search, user,currentSession},dispatch] = useStateValue();
    const [jobSelected, setJobSelected] = useState(false);
    const [showBudgetModal, setShowBudgetModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showReviewModal, setShowReviewModal] = useState(false);
    const business = user.business;
    const statesTraduction = [   
        {title:"EN ESPERA" ,state:"standby",type:["lead"]},
        {title:"Sin Wollers" ,state:"noJobers",type:["lead"]},
        {title:"Congelado" ,state:"frozen",type:["lead"]},
    {title:"PRESUPUESTO PENDIENTE" ,state:"draft",type:["fixed",]},
    {title:"PENDIENTE DE CITA" ,state:"pending",type:["fixed",]},
    {title:"PENDIENTE ASIGNACIÓN PROFESIONAL" ,state:"abierto",type:["fixed","lead"]},
    {title:"EN PROCESO" ,state:"abierto",type:["fixed","lead"]},
    {title:"PENDIENTE DE FIRMA" ,state:"signaturePending",type:["fixed",]},
    {title:"FIRMADO",titleLead:"ESTIMADO" ,state:"finalizado",type:["fixed","lead"],optional:true},
    {title:"VALORADO" ,state:"review",type:["fixed","lead"],optional:true},
    {title:"ELIMINADO" ,state:"eliminado",type:["fixed","lead"],optional:true},
    {title:"FALSO" ,state:"falso",type:["fixed","lead"],optional:true},
    {title:"LLENO" ,state:"full",type:["fixed"],optional:true},
]
    
    const getNotificationImage = (not) => {
        let image;
        switch(not){
            case "Falta información":
                image = require("../../assets/notifications/no_data.png");
                break;
            case "Urgente":
                image = require("../../assets/notifications/ray.png");
                break;
            case "Ilocalizable":
                image = require("../../assets/notifications/location.png");
                break;
            case "Whatsapp enviado":
                image = require("../../assets/notifications/whatsapp.png");
                break;
            case "Cliente confirma diagnóstico":
                image = require("../../assets/notifications/check.png");
                break;
            default:
                break;
        }

        return image;
    }
    const getStateTranslate = (state)=>{
        return statesTraduction.map((trad)=>{
            if(trad.type.some((value)=>search.currentIdType==value) && state == trad.state){
                return trad.title
            }
        }).filter(x=>x).pop()
        
    }
    const manageButton = (job)=>{
        switch (job.state) {
            case "abierto":
                if(job.takenSlots > 0){
                    return (<Button
                        buttonText={'Reportar'}
                        onClick={()=>{setJobSelected(job); setShowBudgetModal(true); }}
                        className="stateButtonOnly"
                        disabled ={false}
                    />)
                }else{
                    return getStateTranslate(job.state)
                }
                break;
            case "full":
                return (<Button
                    buttonText={'Reportar'}
                    onClick={ ()=>{setJobSelected(job); setShowBudgetModal(true); }}
                    className="stateButtonOnly"
                    disabled ={false}
                />)
                break;
            
            case "finalizado":
                return (<Button
                    className="stateButton review"
                    onClick={()=>{setJobSelected(job); setShowReviewModal(true); }}
                    buttonText={"Valorar"}
                />)
                break;
        
            case "review":
                return (<Button
                    className="stateButton  reviewed"
                    onClick={null}
                    buttonText={"valorado"}
                />)
                break;
        
            default:
                return getStateTranslate(job.state)
                break;
        }



    }
    const manageIncidence =(type)=>{
        switch (type) {
            case "abierto":
                return (<div className="incidence-green"></div>)
                break;
            case "in-progress":
                return (<div className="incidence-yellow"></div>)
                break;
            case "cerrada":
                return (<div className="incindece-red"></div>)
                break;
        
            default:
                break;
        }
    }
    //actions
    const verifyJob= async (jobId,isVerify)=>{
        await updateVerified(jobId,isVerify)
     }
    const asignWoller = async (jobId,wollerId) => {
        let results = await  buyJobForWoller(jobId,wollerId)
        try {
            search.findJobs({page:1})
            setShowConfirmModal(false)
            let promiseResult = await getUserData(wollerId)
            dispatch({
                type: "SET_GOLD_USER_DATA",
                wollerData: promiseResult
            });
        } catch (error) {
            alert(results.error)
            search.findJobs({})
            setShowConfirmModal(false)
        }
    };
    const reviewSaved = async () => {
        
    };
   
    const itemDetail = id => {
        window.open(`${window.location.href}/${id}`, "_blank")
    };
    const wollerDetail = id => {
        history.push({
            pathname: "/user/" + id,
            state: {
                inner: true
            }
        });
    };
    //actions
    //csv
    const csvRef = useRef(null);
    const getFixedCSVInfo = async () => { 
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();

        let csvData = {
            data: [],
            headers: [
                { label: "ID", key: "id" }, { label: "Gremio", key: "type" }, { label: "Titulo", key: "title" },
                { label: "Estado", key: "state" }, { label: "Direccion", key: "address" },
                { label: "Nombre", key: "name" }, { label: "Telefono", key: "phone" },
                { label: "Desde", key: "from" }, { label: "Precio", key: "price" },  { label: "Precio con IVA", key: "price2" }, 
                { label: "Negocio", key: "bussines"}, { label: "Fecha de creacion", key: "createdAt" }, 
                { label: "Cita", key: "availableToDate" }, { label: "fixedID", key: "fixedID" }
                , { label: "Profesional", key: "fromUser" }
            ]
        }

        for(let i=0; i<20; i++){
            let res = await Parse.Cloud.run("getCSVInfo", { type: "fixed", page: i});
            res.map( job => {
                csvData.data.push( { 
                    id: job?.id,
                    type: job?.type,
                    title: job?.title.replaceAll(","," "),
                    state: job?.state, 
                    address: job?.address,
                    name: job?.name,
                    phone: job?.phone,
                    from: job?.from,
                    price: job?.price,
                    price2: job?.price2,
                    price3: job?.price3,
                    bussines: job?.bussines, 
                    createdAt: job?.createdAt ? formatCreatedAtCSV(job?.createdAt) : null,
                    availableToDate: formatCreatedAtCSV(job?.availableToDate),
                    fixedID: job?.fixedID,
                    fromUser: job?.fromUser
                } );
            })
        }
        return csvData;
    }

    const getCSVInfo = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();

        let csvData = {
            data: [],
            headers: [
                { label: "ID", key: "id" }, { label: "Gremio", key: "type" }, { label: "Título", key: "title" },
                { label: "Estado", key: "state" }, { label: "Dirección", key: "address" }, { label: "P.Libres", key: "nJober" },
                { label: "Nombre", key: "name" }, { label: "Teléfono", key: "phone" }, { label: "Desde", key: "from" },
                { label: "Precio por oferta(€)", key: "price" }, { label: "Negocio", key: "bussines"}, { label: "Fecha de creación", key: "createdAt" },
                { label: "Total slots", key: "totalSlots" }, { label: "Provincia", key: "postalCode" }, { label: "Estado del presupuesto1", key: "budgetState1" },
                { label: "Estado del presupuesto2", key: "budgetState2" },{ label: "Estado del presupuesto3", key: "budgetState3" },{ label: "Woller 1", key: "firstWoller" },
                { label: "Woller 2", key: "secondWoller" },{ label: "Woller 3", key: "thirdWoller" }, { label: "ExternalId", key: "externalId" }
            ]
        }

        for(let i=0; i<60; i++){
            let res = await Parse.Cloud.run("getCSVInfo", { type: "lead", page: i});
            res.map( job => {
                if(job.id){
                    csvData.data.push( { 
                        id: job?.id, type: job?.get("Type"),
                        title: job?.get("Title"), 
                        state: job?.get("State"), 
                        address: job?.get("Address"), 
                        nJober: job?.get("nJober"), 
                        name: job?.get("CreatedBy")?.get("Name"),
                        phone: job?.get("CreatedBy")?.get("Phone"),
                        from: job?.get("From"),
                        price: job?.get("JobLeadPrice"),
                        bussines: job?.get("Business")?.get("Name") ? job?.get("Business")?.get("Name") : "", 
                        createdAt: formatCreatedAtCSV(job?.get("createdAt")),
                        totalSlots: job?.get("TotalSlots"), 
                        postalCode: formatPostalCodeToProvince(job?.get("PostalCode").substring(0, 2)),
                        budgetState1: job.get("Budget") && job.get("Budget")[0] && job.get("Budget")[0].state ? getState(job.get("Budget")[0]?.state):"",
                        budgetState2: job.get("Budget") && job.get("Budget")[1] && job.get("Budget")[1].state ? getState(job.get("Budget")[1]?.state):"",
                        budgetState3: job.get("Budget") && job.get("Budget")[2] && job.get("Budget")[2].state ? getState(job.get("Budget")[2]?.state):"",
                        externalId: job.get("externalId") ? job.get("externalId") : null
                    } );
                } else {
                    csvData.data.push( { 
                        id: job[0].get("Jobs").id, 
                        type: job[0]?.get("Jobs").get("Type"), 
                        title: job[0]?.get("Jobs").get("Title"), 
                        state: job[0]?.get("Jobs").get("State"), 
                        address: job[0]?.get("Jobs").get("Address"), 
                        nJober: job[0]?.get("Jobs").get("nJober"), 
                        name: job[0]?.get("ToUser")?.get("Name"),
                        phone: job[0]?.get("ToUser")?.get("Phone"), 
                        from: job[0]?.get("Jobs").get("From"),
                        price: job[0]?.get("Jobs").get("JobLeadPrice"),
                        bussines: job[0]?.get("Jobs").get("Business")?.get("Name") ? job[0]?.get("Jobs").get("Business")?.get("Name") : "", 
                        createdAt: job[0]?.get("Jobs")?.get("createdAt") ? formatCreatedAtCSV(job[0]?.get("Jobs")?.get("createdAt")) : "No hay fecha",
                        totalSlots: job[0]?.get("Jobs").get("TotalSlots"), 
                        postalCode: formatPostalCodeToProvince(job[0]?.get("Jobs").get("PostalCode")?.substring(0, 2)),
                        budgetState1: job[0].get("Jobs").get("Budget") && job[0].get("Jobs").get("Budget")[0] && job[0].get("Jobs").get("Budget")[0].state ? getState(job[0].get("Jobs").get("Budget")[0]?.state):"",
                        budgetState2: job[0].get("Jobs").get("Budget") && job[0].get("Jobs").get("Budget")[1] && job[0].get("Jobs").get("Budget")[1].state ? getState(job[0].get("Jobs").get("Budget")[1]?.state):"",
                        budgetState3: job[0].get("Jobs").get("Budget") && job[0].get("Jobs").get("Budget")[2] && job[0].get("Jobs").get("Budget")[2].state ? getState(job[0].get("Jobs").get("Budget")[2]?.state):"",
                        firstWoller: job[0] ? job[0].get("FromUser")?.get("Name") : "",
                        secondWoller: job[1] ? job[1].get("FromUser")?.get("Name") : "",
                        thirdWoller: job[2] ? job[2].get("FromUser")?.get("Name") : "",
                        externalId: job[0]?.get("Jobs").get("externalId") ? job[0]?.get("Jobs").get("externalId") : null
                    } );
                }
            })
        }
        return csvData;
    }

    const getB2BCSVInfo = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();

        let csvData = {
            data: [],
            headers: [
                { label: "ID", key: "id" }, { label: "Gremio", key: "type" }, { label: "Titulo", key: "title" },
                { label: "Estado", key: "state" }, { label: "Direccion", key: "address" },
                { label: "Nombre", key: "name" }, { label: "Telefono", key: "phone" }, { label: "Desde", key: "from" },
                { label: "Negocio", key: "bussines"}, { label: "Fecha de creacion", key: "createdAt" },
                { label: "Total slots", key: "totalSlots" }, { label: "Provincia", key: "postalCode" },
                { label: "Estado del presupuesto1", key: "budgetState1" }, { label: "Estado del presupuesto2", key: "budgetState2" },
                { label: "Estado del presupuesto3", key: "budgetState3" },{ label: "Woller 1", key: "firstWoller" },
                { label: "Woller 2", key: "secondWoller" },{ label: "Woller 3", key: "thirdWoller" }
            ]
        }

        for(let i=0; i<50; i++){ 
            let res = await Parse.Cloud.run("getBusinessCSV", { type: "lead", page: i, business: business.id});
            res.map( job => {
                if(job.id){
                    csvData.data.push( {
                        id: job?.id, 
                        type: job?.get("Type"),
                        title: job?.get("Title"), 
                        state: job?.get("State"), 
                        address: job?.get("Address"),  
                        name: job?.get("CreatedBy")?.get("Name"),
                        phone: job?.get("CreatedBy")?.get("Phone"),
                        from: job?.get("From"),
                        bussines: job?.get("Business")?.get("Name") ? job?.get("Business")?.get("Name") : "", 
                        createdAt: formatCreatedAtCSV(job?.get("createdAt")),
                        totalSlots: job?.get("TotalSlots"), 
                        postalCode: formatPostalCodeToProvince(job?.get("PostalCode").substring(0, 2)),
                        budgetState1: job.get("Budget") && job.get("Budget")[0] && job.get("Budget")[0].state ? getState(job.get("Budget")[0]?.state):"",
                        budgetState2: job.get("Budget") && job.get("Budget")[1] && job.get("Budget")[1].state ? getState(job.get("Budget")[1]?.state):"",
                        budgetState3: job.get("Budget") && job.get("Budget")[2] && job.get("Budget")[2].state ? getState(job.get("Budget")[2]?.state):""
                    });
                } else {
                    let newNotifications = job.filter( not => {
                        if(not.get("Type") == "aceptoT") return true
                        else return false
                    });
                    csvData.data.push( {
                        id: newNotifications[0].get("Jobs").id, 
                        type: newNotifications[0].get("Jobs").get("Type"),
                        title: newNotifications[0].get("Jobs").get("Title"), 
                        state: newNotifications[0].get("Jobs").get("State"), 
                        address: newNotifications[0].get("Jobs").get("Address"),  
                        name: newNotifications[0].get("Jobs").get("CreatedBy")?.get("Name"),
                        phone: newNotifications[0].get("Jobs").get("CreatedBy")?.get("Phone"),
                        from: newNotifications[0].get("Jobs").get("From"),
                        bussines: newNotifications[0].get("Jobs").get("Business")?.get("Name") ? newNotifications[0].get("Jobs").get("Business")?.get("Name") : "", 
                        createdAt: formatCreatedAtCSV(newNotifications[0].get("Jobs").get("createdAt")),
                        totalSlots: newNotifications[0].get("Jobs").get("TotalSlots"), 
                        postalCode: formatPostalCodeToProvince(newNotifications[0].get("Jobs").get("PostalCode").substring(0, 2)),
                        budgetState1: newNotifications[0].get("Jobs").get("Budget") && newNotifications[0].get("Jobs").get("Budget")[0] && newNotifications[0].get("Jobs").get("Budget")[0].state ? getState(newNotifications[0].get("Jobs").get("Budget")[0]?.state):"",
                        budgetState2: newNotifications[0].get("Jobs").get("Budget") && newNotifications[0].get("Jobs").get("Budget")[1] && newNotifications[0].get("Jobs").get("Budget")[1].state ? getState(newNotifications[0].get("Jobs").get("Budget")[1]?.state):"",
                        budgetState3: newNotifications[0].get("Jobs").get("Budget") && newNotifications[0].get("Jobs").get("Budget")[2] && newNotifications[0].get("Jobs").get("Budget")[2].state ? getState(newNotifications[0].get("Jobs").get("Budget")[2]?.state):"",
                        firstWoller: newNotifications[0] ? newNotifications[0].get("FromUser")?.get("Name") : "",
                        secondWoller: newNotifications[1] ? newNotifications[1].get("FromUser")?.get("Name") : "",
                        thirdWoller: newNotifications[2] ? newNotifications[2].get("FromUser")?.get("Name") : ""
                    } );
                }
            })
        }

        return csvData;
    }

    const getHistoricCSV = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();

        let csvData = {
            data: [],
            headers: [
                { label: "Job", key: "job" }, { label: "Woller", key: "woller" }, { label: "State", key: "state" },
                { label: "From", key: "from" }
            ]
        }

        for(let i=0; i<100; i++){ 
            let jobs = await Parse.Cloud.run("getHistoricCSV", { page: i });
            
            if(jobs.length > 0){
                jobs.map( job => {
                    let budgets = job.get("Budget");
                    if(budgets.length){
                        budgets.map( bud => {
                            csvData.data.push( {
                                job: job.id, 
                                woller: bud.userId,
                                state: bud.state,
                                from: bud.coments == "Vía JustNow" ? "Vía JustNow" : bud.origin
                            } );
                        })
                    } else {
                        csvData.data.push( {
                            job: job.id, 
                            woller: budgets.userId,
                            state: budgets.state,
                            from: budgets.coments == "Vía JustNow" ? "Vía JustNow" : budgets.origin
                        } );
                    }
                })
            }
        }

        return csvData;
    }
    //csv

    const fixedTable = (data)=>{
        let rows = data.map(job=>{
                return(<tr key={job.id}>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.level === 2 ? (
                                                <img
                                                    src={require("../../assets/badge-silver.svg")}
                                                    alt="Plata"
                                                />
                                            ) : job.level === 3 ? (
                                                <img
                                                    src={require("../../assets/badge-gold.svg")}
                                                    alt="Oro"
                                                />
                                            ) : job.level === 4 ? (
                                                <img
                                                    src={require("../../assets/badge-platinum.svg")}
                                                    alt="Oro"
                                                />
                                            ) : (
                                                <img
                                                    src={require("../../assets/badge-bronce.svg")}
                                                    alt="Bronce"
                                                />
                                            )}</td>
                        <td className="mediumText" onClick={()=>{itemDetail(job.id)}}>{job.id}</td>
                        <td className="mediumText" onClick={()=>{itemDetail(job.id)}}>{job.vertical?.get("Type")}</td>
                        <td className="longText" onClick={()=>{itemDetail(job.id)}}>{job.title}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{getStateTranslate(job.state)}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.postalCode}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.locality}</td>
                        <td className="longText" onClick={()=>{itemDetail(job.id)}}>{job.createdBy?.get("Name")}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{
                            job.jobsNotifications? job.jobsNotifications.map( not => (
                                <img key={job.id+not} style={{widht: 17, height:17}} src={getNotificationImage(not)}/>
                                )):null}
                        </td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.incidence?.get("ExtraInfo")?.state ? manageIncidence(job.incidence?.get("ExtraInfo")?.state):null}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{`${job.fixedPrice?job.fixedPrice:0}€`}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{`${(job.fixedPrice*0.85).toFixed(2)}€`}</td>
                        {job.jobType && job.jobType == "fixed" && (
                            <td onClick={()=>{
                                if(job.suscribedWollers != undefined && job.suscribedWollers != false  && job.suscribedWollers?.get("FromUser")){
                                    wollerDetail(job.suscribedWollers?.get("FromUser")?.id)
                                } 
                            }
                            }>{job.suscribedWollers != undefined && job.suscribedWollers != false ? job.suscribedWollers?.get("FromUser")?.get("Name"):(<p style={{color: "red"}}>Sin asignar</p>)}</td>
                        )}
                        <td onClick={()=>{itemDetail(job.id)}}>{job.business && job.business.get("Logo") ? <img style={{width: 70, height: 70}} src={ job.business.get("Logo")?.url()} alt="Negocio" /> : null}</td>
                        <td>{ <Switch value={job.verified} getChangeValue={verifyJob} jobId={job.id}/>}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>  {`${job.createdAt?.toLocaleDateString("es-ES")} - ${job.createdAt?.toLocaleTimeString()}`}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>  {job.appointment ? `${job.appointment.getUTCDate()}/${job.appointment.getMonth()+1}/${job.appointment.getUTCFullYear()}`:null}</td>
                    </tr>)
            })
        return(
            <GenericTable className="tableSearch" heads={search.itemsTitle} rows={rows}/>
        )
    }
    const leadTable = (data)=>{
        let rows = data.map(job=>{
                return(<tr key={job.id}>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.level === 2 ? (
                                                <img
                                                    src={require("../../assets/badge-silver.svg")}
                                                    alt="Plata"
                                                />
                                            ) : job.level === 3 ? (
                                                <img
                                                    src={require("../../assets/badge-gold.svg")}
                                                    alt="Oro"
                                                />
                                            ) : job.level === 4 ? (
                                                <img
                                                    src={require("../../assets/badge-platinum.svg")}
                                                    alt="Oro"
                                                />
                                            ) : (
                                                <img
                                                    src={require("../../assets/badge-bronce.svg")}
                                                    alt="Bronce"
                                                />
                                            )}</td>
                        <td className="mediumText" onClick={()=>{itemDetail(job.id)}}>{job.id}</td>
                        <td className="mediumText" onClick={()=>{itemDetail(job.id)}}>{job.vertical?.get("Type")}</td>
                        <td>  {manageButton(job)}</td>
                        <td className="longText" onClick={()=>{itemDetail(job.id)}}>{job.title}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.postalCode}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.locality}</td>
                        <td style={{color:job.nJober == job.totalSlots ? "red":"inherit"}} onClick={()=>{itemDetail(job.id)}}><b>{job.nJober ? job.nJober : 0}</b></td>
                        <td className="longText" onClick={()=>{itemDetail(job.id)}}>{job.createdBy?.get("Name")}</td>
                        <td className="incidenceRow" onClick={()=>{itemDetail(job.id)}}>{job.jobsNotifications?.map( not => (
                            <img key={job.id+not} style={{widht: 17, height:17}} src={getNotificationImage(not)}/>
                        ))}</td>
                        <td className="mediumText" onClick={()=>{itemDetail(job.id)}}>{job.agent ? `${job.agent.get("Name")} ${job.agent.get("Lastname")}`:(<p style={{color: "red"}}>Sin asignar</p>)}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.incidence?.get("ExtraInfo")?.state ? manageIncidence(job.incidence?.get("ExtraInfo")?.state):null}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.jobLeadPrice?`${job.jobLeadPrice}€`:(<p style={{color: "red"}}>Sin asignar</p>)}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.business && job.business.get("Logo") ? 
                                                                <img style={{width: 60, height: 60}} src={ job.business.get("Logo")?.url()} alt="Negocio" /> : 
                                                                null }
                        </td>
                        <td><Switch value={job.verified} getChangeValue={verifyJob} jobId={job.id}/></td>
                        <td onClick={()=>{itemDetail(job.id)}}>  {`${job.createdAt.toLocaleDateString("es-ES")} - ${job.createdAt.toLocaleTimeString()}`}</td>

                    </tr>)
            })
        return(
            <GenericTable className="tableSearch" heads={search.itemsTitle} rows={rows}/>
        )
    }
    const goldTable = (data)=>{
        let rows = data.map(job=>{
                return(<tr key={job.id}>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.level === 2 ? (
                                                <img
                                                    src={require("../../assets/badge-silver.svg")}
                                                    alt="Plata"
                                                />
                                            ) : job.level === 3 ? (
                                                <img
                                                    src={require("../../assets/badge-gold.svg")}
                                                    alt="Oro"
                                                />
                                            ) : job.level === 4 ? (
                                                <img
                                                    src={require("../../assets/badge-platinum.svg")}
                                                    alt="Oro"
                                                />
                                            ) : (
                                                <img
                                                    src={require("../../assets/badge-bronce.svg")}
                                                    alt="Bronce"
                                                />
                                            )}</td>
                        <td className="mediumText" onClick={()=>{itemDetail(job.id)}}>{job.id}</td>
                        <td className="mediumText" onClick={()=>{itemDetail(job.id)}}>{job.vertical?.get("Type")}</td>
                        <td className="longText" onClick={()=>{itemDetail(job.id)}}>{job.title}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.address}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.locality}</td>
                        <td style={{color:job.nJober == job.totalSlots ? "red":"inherit"}} onClick={()=>{itemDetail(job.id)}}><b>{job.nJober ? job.nJober : 0}</b></td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.verified ? "Si":"No"}</td>
                        <td >
                            { job.suscribedWollers?.length > 0 && job.suscribedWollers.some((woller)=>woller.id == user.goldId) ?       
                                    <ButtonOwn 
                                        className="owned"
                                        buttonText={job.phone} 
                                    />
                                :(
                                <ButtonOwn 
                                    className={currentSession.wollerData.get("nJobs") >(job.jobLeadPrice? job.jobLeadPrice : job.subVertical?.get("nLeadsPerPC")?.default)? "balance":"noBalance"} 
                                    buttonText={"Precio " + (job.jobLeadPrice ? job.jobLeadPrice + '€' : job.subVertical ? job.subVertical?.get("nLeadsPerPC")?.default + '€' : null) } 
                                    onClick={()=> {
                                        setJobSelected(job) 
                                        if(currentSession.wollerData.get("nJobs") >(job.jobLeadPrice? job.jobLeadPrice : job.subVertical?.get("nLeadsPerPC")?.default)){
                                            setShowConfirmModal(true)
                                        }else{
                                            alert("No dispones de saldo suficiente para comprar este servicio")
                                        }
                                    }}
                                />
                            )}
                        </td>
                        <td onClick={()=>{itemDetail(job.id)}}>
                            {job.business && job.business.get("Logo") ? 
                                <img style={{width: 60, height: 60}} src={ job.business.get("Logo")?.url()} alt="Negocio" /> : 
                                null 
                            }
                        </td>
                        <td onClick={()=>{itemDetail(job.id)}}>  {`${job.createdAt.toLocaleDateString("es-ES")} - ${job.createdAt.toLocaleTimeString()}`}</td>

                    </tr>)
            })
        return(
            <GenericTable className="tableSearch" heads={search.itemsTitle} rows={rows}/>
        )
    }
    const mediatorTable = (data)=>{
        let rows = data.map(job=>{
                return(<tr key={job.id}>
                        <td className="mediumText" onClick={()=>{itemDetail(job.id)}}>{job.id}</td>
                        <td className="mediumText" onClick={()=>{itemDetail(job.id)}}>{job.vertical?.get("Type")}</td>
                        <td className="longText" onClick={()=>{itemDetail(job.id)}}>{job.title}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.address}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.locality}</td>
                        <td style={{color:job.nJober == job.totalSlots ? "red":"inherit"}} onClick={()=>{itemDetail(job.id)}}><b>{job.nJober ? job.nJober : 0}</b></td>
                        <td className="longText" onClick={()=>{itemDetail(job.id)}}>{job.createdBy?.get("Name")}</td>
                        <td className="incidenceRow" onClick={()=>{itemDetail(job.id)}}>{job.jobsNotifications?.typologies?.map( not => (
                            <img key={job.id+not} style={{widht: 17, height:17}} src={getNotificationImage(not)}/>
                        ))}</td>
                        <td className="mediumText" onClick={()=>{itemDetail(job.id)}}>{job.agent ? `${job.agent.get("Name")} ${job.agent.get("Lastname")}`:"Sin asignar"}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.incidence?.get("ExtraInfo")?.state ? manageIncidence(job.incidence?.get("ExtraInfo")?.state):null}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.business && job.business.get("Logo") ? 
                                                                <img style={{width: 60, height: 60}} src={ job.business.get("Logo")?.url()} alt="Negocio" /> : 
                                                                null }
                        </td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.verified ? "Si":"No"}</td>
                        <td>{job.state}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>  {`${job.createdAt.toLocaleDateString("es-ES")} - ${job.createdAt.toLocaleTimeString()}`}</td>
                    </tr>)
            })
        return(
            <GenericTable className="tableSearch" heads={search.itemsTitle} rows={rows}/>
        )
    }
    const b2bTableFixed = (data)=>{
        let rows = data.map(job=>{
                return(<tr key={job.id}>
                        <td onClick={()=>{
                            itemDetail(job.id)}}>{job.level === 2 ? (
                                    <img
                                        src={require("../../assets/badge-silver.svg")}
                                        alt="Plata"
                                    />
                                ) : job.level === 3 ? (
                                    <img
                                        src={require("../../assets/badge-gold.svg")}
                                        alt="Oro"
                                    />
                                ) : job.level === 4 ? (
                                    <img
                                        src={require("../../assets/badge-platinum.svg")}
                                        alt="Oro"
                                    />
                                ) : (
                                    <img
                                        src={require("../../assets/badge-bronce.svg")}
                                        alt="Bronce"
                                    />
                                )
                            }
                        </td>
                        <td className="mediumText" onClick={()=>{itemDetail(job.id)}}>{job.id}</td>
                        <td className="mediumText" onClick={()=>{itemDetail(job.id)}}>{job.vertical?.get("Type")}</td>
                        <td className="longText" onClick={()=>{itemDetail(job.id)}}>{job.title}</td>
                        <td className="longText" onClick={()=>{itemDetail(job.id)}}>{job.address}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.locality}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.state}</td>
                        <td className="longText" onClick={()=>{itemDetail(job.id)}}>{job.createdBy?.get("Name")}</td>
                        <td className="mediumText" onClick={()=>{itemDetail(job.id)}}>{job.agent ? `${job.agent.get("Name")} ${job.agent.get("Lastname")}`:"Sin asignar"}</td>
                        <td className="mediumText" onClick={()=>{itemDetail(job.id)}}>{job.verified ? "Verificado":"Sin verificar"}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>  {`${job.createdAt.toLocaleDateString("es-ES")} - ${job.createdAt.toLocaleTimeString()}`}</td>
                    </tr>)
            })
        return(
            <GenericTable className="tableSearch" heads={search.itemsTitle} rows={rows}/>
        )
    }
    const b2bTablelead = (data)=>{
        let rows = data.map(job=>{
                return(<tr key={job.id}>
                        <td onClick={()=>{
                            itemDetail(job.id)}}>{job.level === 2 ? (
                                    <img
                                        src={require("../../assets/badge-silver.svg")}
                                        alt="Plata"
                                    />
                                ) : job.level === 3 ? (
                                    <img
                                        src={require("../../assets/badge-gold.svg")}
                                        alt="Oro"
                                    />
                                ) : job.level === 4 ? (
                                    <img
                                        src={require("../../assets/badge-platinum.svg")}
                                        alt="Oro"
                                    />
                                ) : (
                                    <img
                                        src={require("../../assets/badge-bronce.svg")}
                                        alt="Bronce"
                                    />
                                )
                            }
                        </td>
                        <td className="mediumText" onClick={()=>{itemDetail(job.id)}}>{job.id}</td>
                        <td className="mediumText" onClick={()=>{itemDetail(job.id)}}>{job.vertical?.get("Type")}</td>
                        <td className="longText" onClick={()=>{itemDetail(job.id)}}>{job.title}</td>
                        <td className="longText" onClick={()=>{itemDetail(job.id)}}>{job.address}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.locality}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>{job.state}</td>
                        <td style={{color:job.nJober == job.totalSlots ? "red":"inherit"}} onClick={()=>{itemDetail(job.id)}}><b>{job.nJober ? job.nJober : 0}</b></td>
                        <td className="longText" onClick={()=>{itemDetail(job.id)}}>{job.createdBy?.get("Name")}</td>
                        <td className="mediumText" onClick={()=>{itemDetail(job.id)}}>{job.agent ? `${job.agent.get("Name")} ${job.agent.get("Lastname")}`:"Sin asignar"}</td>
                        <td onClick={()=>{itemDetail(job.id)}}>  {`${job.createdAt.toLocaleDateString("es-ES")} - ${job.createdAt.toLocaleTimeString()}`}</td>

                    </tr>)
            })
        return(
            <GenericTable className="tableSearch" heads={search.itemsTitle} rows={rows}/>
        )
    }
    if (search.loading) {
        return (
            <section className="loading-container">
                <Loading />
            </section>
        );
    } else {
        return (
            <>
                {showBudgetModal &&
                    <StateModal
                        isVisible={showBudgetModal}
                        data={jobSelected}
                        onClose={()=>setShowBudgetModal(false)}
                        refreshJobs={search.findJobs}
                    />
                }
                {showConfirmModal && (
                    <AlertModal
                        isVisible={showConfirmModal}
                        onClose={() => setShowConfirmModal(false)}
                        title={jobSelected?.title}
                        subtitle="¿Seguro que deseas comprar este trabajo?"
                        buttonText="Comprar"
                        onClick = {()=>{asignWoller(jobSelected.id,currentSession.wollerData.id)}}
                    />
                )}
                {showReviewModal && (
                    <ReviewModal
                        isVisible={showReviewModal}
                        onClose={() => setShowReviewModal(false)}
                        job={jobSelected}
                        reviewSaved={search.findJobs}
                        onReviewSaved={setShowReviewModal}
                    />
                )}
                <div className="table-wrapper">
                <div style={{display: "flex", flexDirection: "row"}}>
                    {!user?.isGoldWoller && !user?.isMediator && (
                        <div>
                            <a ref={csvRef} style={{ display: "none" }} />
                            <CSVExport
                                children={"Descargar"}
                                asyncExportMethod={user?.permissions?.job?.edit ? search.currentIdType == "lead" ? getCSVInfo : getFixedCSVInfo : getB2BCSVInfo}
                            />
                        </div>
                    )}
                    {search.currentIdType !== "lead" && !user?.isGoldWoller && !user?.isMediator && user?.permissions?.job?.create && (
                        <div style={{marginLeft: 10}}>
                            <a ref={csvRef} style={{ display: "none" }} />
                            <CSVExport
                                children={"Est/Deses"}
                                asyncExportMethod={getHistoricCSV}
                            />
                        </div>
                    )}
                </div>
                    {
                    search.services.length > 0 &&  search.currentIdType == "lead" && !user.isB2b && !currentSession.wollerData  && !user.isMediator? leadTable(search.services)
                    :search.services.length > 0 &&  search.currentIdType == "fixed" && !user.isB2b && !currentSession.wollerData  && !user.isMediator?  fixedTable(search.services)
                    :search.services.length > 0 &&  currentSession.wollerData && !user.isMediator? goldTable(search.services)
                    :user.isMediator? mediatorTable(search.services)
                    :search.services.length > 0 &&  search.currentIdType == "lead" && user.isB2b  && !user.isMediator? b2bTablelead(search.services)
                    :search.services.length > 0 &&  search.currentIdType == "fixed" && user.isB2b  && !user.isMediator?  b2bTableFixed(search.services)
                    :null }
                </div>
            </>
        )
    }
}
