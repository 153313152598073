import React, { useEffect, useState, useRef, useMemo } from "react";



export default function GenericTable({className,heads, rows}) {
  const [heads_,setHeads_] = useState([])
useEffect(()=>{
  setHeads_(heads)
},[heads])


  return (
    <table className={className}>
      <thead>
        <tr>
          {heads_.map((head)=>{
            return (<th key={head.Header+"header"}>
                      
                      {head.id=="level" || head.id=="status"?(<img style={{width: 30, height: 30}} src={ require("../assets/wolly.png")} alt="Negocio" />):head.Header}
                    </th>)
          })}
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  )
}
