import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { useStateValue } from "../state/context";
import { useCookies } from "react-cookie";
import logo from "../assets/icons/isotipo-negro.png"
import "../styles/login.css";

export default function LogIn(props) {
    const [_, dispatch] = useStateValue();
    const [cookies, setCookie, removeCookie] = useCookies(["agent"]);

    const [user, setUser] = useState("");
    const [userError, setUserError] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [togglePwdVisible, setTogglePwdVisible] = useState(false);
    const [showPwd, setShowPwd] = useState(false);
    const [authError, setAuthError] = useState(false);

    // const logo = (
    //     <svg viewBox="0 0 180 68" id="logo" className="coral-fill">
    //         <title>Jobin</title>
    //         <g>
    //             <path d="M38.95,5.29s-3.61-3-4.63-3.24a29.48,29.48,0,0,0-7.25-.79A37.16,37.16,0,0,0,.88,12,23,23,0,0,1,13.2,13.6a8.86,8.86,0,0,0-3.86,4.31C18.53,17.49,24.46,20,26.7,26c0,0,7.27-1,10,3A12.71,12.71,0,0,0,19.6,40.67c0,10.92,12.91,26.07,12.91,26.07S46,51.79,46,36.7c0-7.46-5.49-14-10.55-17.32L55.24,7.93l.08,0,.08-.05a8.77,8.77,0,0,0,4.2-6.18Zm-6.44.6A2.08,2.08,0,1,1,30.43,8,2.08,2.08,0,0,1,32.51,5.89Z" />
    //         </g>
    //         <g className="navbar-brand-text hidden-xs">
    //             <path d="M153.94,9.93a5,5,0,0,1-5.37,3.34,3.44,3.44,0,0,1-2.5-4.78,5,5,0,0,1,5.37-3.34A3.43,3.43,0,0,1,153.94,9.93Z" />
    //             <path d="M96.47,41C88,41,85.58,36.34,85.58,31.83a17.06,17.06,0,0,1,3.83-10.89,15.13,15.13,0,0,1,11.71-5.07c8.36,0,10.9,4.53,10.9,9.21a17.29,17.29,0,0,1-3.84,10.84A15,15,0,0,1,96.47,41ZM93.24,27.76c-1.09,4.62-.4,8.78,4,8.78,3.49,0,5.88-2.55,7.1-7.57.57-2.32,1.57-8.59-4-8.59C96.84,20.39,94.4,22.86,93.24,27.76Z" />
    //             <path d="M172.39,15.87c-3.82,0-8.09,2.29-9.49,3.18l-1.6-2.78-4.35.77-4.17,23.63h6.83l3-17.25a10.32,10.32,0,0,1,5.82-2.19c3.72,0,3.82,2.19,3.2,5.68l-2.43,13.76h6.83l2.7-15.34C179.87,19.05,178.56,15.87,172.39,15.87Z" />
    //             <path d="M78.4,12.76,75.5,29.24c-.64,3.61-2.45,5.45-5.39,5.45-2,0-3.53-.86-5.11-2.87l-5.39,4.52C61.73,39.44,64.93,41,69.13,41c7.64,0,12-4.74,13-10.61l3.32-18.89Z" />
    //             <polygon points="145.19 19.12 141.39 40.67 148.22 40.67 152.24 17.87 145.19 19.12" />
    //             <path d="M139.74,20.94c-.67-1.82-2.59-5.07-8-5.07a13.59,13.59,0,0,0-8.05,3l2.54-14.49-7,1.24-5.91,33.64A31.28,31.28,0,0,0,123.89,41,16,16,0,0,0,137,34.6a16.42,16.42,0,0,0,3-7A13.31,13.31,0,0,0,139.74,20.94Zm-7,7.11c-1.25,7-5.42,8.64-7.84,8.64-3.14,0-4.26-.72-4.26-.72l2.19-12.55a10.57,10.57,0,0,1,5.82-2.19C130.41,21.23,133.93,21.44,132.74,28.06Z" />
    //         </g>
    //     </svg>
    // );

    const pwdToggler = (
        <svg width="24px" height="24px" viewBox="0 0 24 24">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                {showPwd ? (
                    <path
                        d="M12,18 C13.43,18 14.698,17.692 15.801,17.215 L14.747,16.161 C13.958,16.687 13.018,17 12,17 C9.243,17 7,14.757 7,12 C7,10.982 7.313,10.042 7.84,9.254 L6.397,7.812 C4.289,9.295 3.106,11.26 3.003,12 C3.178,13.246 6.363,18 12,18 Z M9.298,10.712 C9.109,11.103 9,11.537 9,12 C9,13.654 10.346,15 12,15 C12.463,15 12.897,14.891 13.288,14.702 L9.298,10.712 Z M3.707,2.293 L21.707,20.293 C22.098,20.684 22.098,21.316 21.707,21.707 C21.512,21.902 21.256,22 21,22 C20.744,22 20.488,21.902 20.293,21.707 L17.305,18.719 C15.81,19.485 14.042,20 12,20 C4.917,20 1,13.916 1,12 C1,10.869 2.39,8.304 4.965,6.379 L2.293,3.707 C1.902,3.316 1.902,2.684 2.293,2.293 C2.684,1.902 3.316,1.902 3.707,2.293 Z M12,6 C11.436,6 10.902,6.059 10.388,6.146 L8.718,4.476 C9.724,4.182 10.813,4 12,4 C19.083,4 23,10.084 23,12 C23,12.886 22.153,14.659 20.555,16.313 L19.141,14.898 C20.286,13.722 20.922,12.536 20.997,12 C20.822,10.754 17.636,6 12,6 Z M11.3115,7.0693 C11.5395,7.0383 11.7645,7.0003 11.9995,7.0003 C14.7565,7.0003 16.9995,9.2433 16.9995,12.0003 C16.9995,12.2353 16.9615,12.4613 16.9305,12.6883 L11.3115,7.0693 Z"
                        fill="#b0b0b0"
                    />
                ) : (
                    <path
                        d="M12,4 C19.083,4 23,10.084 23,12 C23,13.916 19.083,20 12,20 C4.917,20 1,13.916 1,12 C1,10.084 4.917,4 12,4 Z M12,6 C6.363,6 3.178,10.754 3.003,12 C3.178,13.246 6.363,18 12,18 C17.636,18 20.822,13.246 20.997,12 C20.822,10.754 17.636,6 12,6 Z M12,9 C10.346,9 9,10.346 9,12 C9,13.654 10.346,15 12,15 C13.654,15 15,13.654 15,12 C15,10.346 13.654,9 12,9 Z M12,17 C9.243,17 7,14.757 7,12 C7,9.243 9.243,7 12,7 C14.757,7 17,9.243 17,12 C17,14.757 14.757,17 12,17 Z"
                        fill="#b0b0b0"
                    />
                )}
            </g>
        </svg>
    );

    const onUserChange = e => {
        setUser(e.target.value);
    };

    const onPwdChange = e => {
        setPassword(e.target.value);
    };

    const onPwdFocus = () => {
        setTogglePwdVisible(true);
    };

    const togglePassword = () => {
        setShowPwd(!showPwd);
    };

    const validateUser = () => {
        if (user === "") setUserError(true);
        else setUserError(false);
    };

    const validatePassword = () => {
        if (password === "" || !/.{5,}/.test(password)) setPasswordError(true);
        else setPasswordError(false);
    };

    const onInputKeyPress = e => {
        const keyCode = e.keyCode || e.which;
        if (keyCode === 13) authUser();
    };

    const authUser = async () => {
        if (!userError && !passwordError) {
            const result = await Jobin.auth({
                user,
                password
            });
            
            if (result.status == "ok" && result.result && result.result.status != false) {
                let auth = {
                    token: result.result.token,
                    agentId: result.result.agentId,
                    dept: result.result.dept,
                    id: result.result.id,
                    ...(result.result.business && {
                        business: {
                            id: result.result.business.id
                        }
                    }),
                    ...(result.result.promotion && {
                        promotion: {
                            id: result.result.promotion.id
                        }
                    })
                };
                auth.wollerGuilds= null
                Jobin.initToken(auth.token);
                const date = new Date();
                date.setHours(date.getHours() + 5);

                try {
                    setCookie("agent", JSON.stringify(auth), {
                        expires: date
                    });
                    dispatch({
                        type: "SET_TOKEN_STORES",
                        token: auth.token,
                        stores: auth && auth.id ? [{ id: auth.id }] : []
                    });
                   
                    setAuthError(false);
                    window.location.href = "/";
                } catch (error) {
                    console.error(error)
                    throw error
                }


                
            } else if (result.result.message && typeof result.result.message === "string") {
                setAuthError(true);
            } else {
                alert(
                    typeof result.result === "string"
                        ? result.result
                        : JSON.stringify(result.result)
                );
            }
        }
    };

    useEffect(() => {
        let didCancel = false;

        async function setToken() {
            const agent = cookies && cookies.agent ? cookies.agent : null;
            if (agent && agent.token) {
                const result = await Jobin.auth({
                    token: agent.token
                });
                if (result.status === "ok" && !didCancel) {
                    let auth = {
                        token: result.result.token,
                        agentId: result.result.agentId,
                        dept: result.result.dept,
                        id: result.result.id,
                        ...(result.result.business && {
                            business: {
                                id: result.result.business.id
                            }
                        }),
                        ...(result.result.promotion && {
                            promotion: {
                                id: result.result.promotion.id
                            }
                        })
                    };
                    Jobin.initToken(auth.token);
                    const date = new Date();
                    date.setHours(date.getHours() + 5);
                    setCookie("agent", JSON.stringify(auth), {
                        expires: date
                    });
                    dispatch({
                        type: "SET_TOKEN_STORES",
                        token: auth.token,
                        stores: [auth.id ? auth.id : undefined]
                    });
                    window.location.href = "/";
                }
            }
        }

        setToken();

        // unmount
        return () => {
            didCancel = true;
        };
    }, [props.history, props.location.search]);

    return (
        <div id="login">
            <div className="container-fluid">
                <div className="row">
                    <div className="container-xs">
                        <div id="signin-form-container">
                            <img src={logo} id="logo" alt="logo"/>
                            <h3 className="line-height-14">
                                Bienvenido de nuevo
                            </h3>
                            {authError && (
                                <div
                                    id="auth-err"
                                    className="jobin-app-alert danger"
                                    role="alert"
                                >
                                    <span>
                                        Usuario o Contraseña incorrectos.
                                    </span>
                                </div>
                            )}
                            <div className="jobin-app-form-group">
                                <div className="jobin-app-input-group">
                                    <input
                                        id="user-input"
                                        className="jobin-app-input"
                                        type="text"
                                        placeholder="Usuario"
                                        value={user}
                                        onChange={onUserChange}
                                        onBlur={validateUser}
                                    />
                                    {userError && (
                                        <small
                                            id="user-warning"
                                            className="input-warning"
                                        >
                                            Escribe un usuario válido
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="jobin-app-form-group">
                                <div
                                    id="input-password"
                                    className="jobin-app-input-group"
                                >
                                    <input
                                        id="password-input"
                                        className="jobin-app-input"
                                        type={showPwd ? "text" : "password"}
                                        placeholder="Contraseña"
                                        value={password}
                                        onChange={onPwdChange}
                                        onFocus={onPwdFocus}
                                        onBlur={validatePassword}
                                        onKeyPress={onInputKeyPress}
                                    />
                                    {togglePwdVisible && (
                                        <React.Fragment>
                                            <input
                                                id="toggle-password"
                                                className="native-hidden"
                                                type="checkbox"
                                                defaultChecked={showPwd}
                                            />
                                            <label
                                                htmlFor="toggle-password"
                                                className="toggle-password"
                                                onClick={togglePassword}
                                            >
                                                {pwdToggler}
                                            </label>
                                        </React.Fragment>
                                    )}
                                    {passwordError && (
                                        <React.Fragment>
                                            <small className="input-warning">
                                                Escribe una contraseña válida
                                            </small>
                                            <small id="pass-warning">
                                                <ul>
                                                    <li>
                                                        Debe tener 5 caracteres
                                                        o más.
                                                    </li>
                                                </ul>
                                            </small>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                            <div
                                id="mail-pass-forgot-group"
                                className="line-height-14"
                            >
                                <p id="p-mail-pass-forgot">
                                    ¿Has olvidado tu usuario o contraseña?
                                </p>
                                <p id="p-tech-call">
                                    No te preocupes, llama al 911086067
                                </p>
                            </div>
                            {/* <div className="jobin-app-form-check">
                                <input
                                    id="remember-credentials"
                                    name="remeber-credentials"
                                    className="form-check-input native-hidden"
                                    type="checkbox"
                                />
                                <label
                                    htmlFor="remember-credentials"
                                    className="form-check-label"
                                >
                                    Recordar nombre y contraseña.
                                </label>
                            </div> */}
                            <div>
                                <button
                                    type="submit"
                                    id="signin-submit"
                                    className="jobin-app-btn-submit"
                                    onClick={authUser}
                                >
                                    Iniciar sesión
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
