const  getFilterAccess = async (userType,dept=false) => {
    switch (userType == "agent"? dept:userType) {
        case "goldUser":
            return ["date","province","service","level", "clear"]
        case "B2B":
            if (dept = "ConstructionCompany") {
                return ["date", "province", "service", "state", "jobType", "extendedSearch", "businesses"];
            } else {
                return ["date", "province", "service", "state", "jobType", "extendedSearch"];
            }
        case "mediator":
            return ["mediator"]
        case "Ops":
            return ["date","province","service","level", "slot", "state", "budget","incidences","businesses","extendedSearch","appointment", "clear","jobType"]
        case "ChiefOps":
            return ["date","province","service","level", "slot", "state", "budget","incidences","businesses","agents","extendedSearch","appointment", "clear","jobType"]
        case "CEO":
            return ["date","province","service","level", "slot", "state", "budget","incidences","businesses","agents","extendedSearch","appointment", "clear","jobType"]
        case "Tech":
            return ["date","province","service","level", "slot", "state", "budget","incidences","businesses","agents","extendedSearch","appointment", "clear","jobType"]
        case "ConstructionCompany":
            return ["date","province","service","level", "slot", "state", "budget","incidences","businesses","extendedSearch","appointment", "clear","jobType"]
        default:
            break;
    }
}
const  getActionsAccess = async (userType,dept=false,agentId=false)=>{
    switch (agentId ? agentId :userType == "agent"? dept:userType) {
        case "qseY8pc1lj":
            return ["new","pipedrive","standBy","manageJob","verified"]
        case "dI8EC5E2x6":
            return ["new","pipedrive","standBy","manageJob","verified"]
        case "HhjJLJTauL": // Hugo
            return ["new","pipedrive","standBy","manageJob","verified","dispat","booster"]
        case "goldUser":
            return ["manageJob"]
        case "B2B":
            return ["pipedrive","new"]
        case "mediator":
            return []
        case "Ops":
            return ["new","pipedrive","manageJob","verified"]
        case "ChiefOps":
            return ["new","pipedrive","standBy","manageJob","verified","dispat","booster","uploadExcel"]
        case "CEO":
            return ["new","pipedrive","standBy","dispat","booster","manageJob","verified","uploadExcel"]
        case "Tech":
            return ["new","pipedrive","standBy","dispat","booster","manageJob","verified","uploadExcel"]
        case "qseY8pc1lj":
            return ["new","pipedrive","standBy","manageJob","verified"]
        case "dI8EC5E2x6":
            return ["new","pipedrive","standBy","manageJob","verified"]
        case "EKtCUAxwTK":
            return ["new","pipedrive","standBy","manageJob","verified"]
        case "gdMnCpCtux":
            return ["new","pipedrive","standBy","manageJob","verified"]
        case "ConstructionCompany":
            return ["new","pipedrive","manageJob","verified"]
        default:
            break;
    }
}
const  getDataAccess = async (userType,dept=false)=>{
    switch (userType == "agent"? dept:userType) {
        case "goldUser":
            return ["balance"]
        case "B2B":
            return []
        case "mediator":
            return []
        case "Ops":
            return []
        case "ChiefOps":
            return []
        case "CEO":
            return []
        case "Tech":
            return []
        case "qseY8pc1lj":
            return []
        case "dI8EC5E2x6":
            return []
        case "ConstructionCompany":
            return []
        default:
            break;
    }
}

export const getFilterAndActions = (userType,dept,agentId=null,type)=>{
    switch (type) {
        case "filters":
            return getFilterAccess(userType,dept)
            break;
    
        case "actions":
            return getActionsAccess(userType, dept, agentId)
            break;
    
        case "data":
            return getDataAccess(userType, dept)
            break;

        default:
            break;
    }
}

