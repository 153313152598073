import React, { useState, useEffect } from "react";
import MainModal from "./MainModal";
import Parse from 'parse';

import { Input } from "jobin-ui-components";
import Button from "../button/Button";
import { apiId, jsKey, urlServer } from '../../constants/credentials';

import "../../styles/job/new-incidents-modal.css"

const optionsTypology = [
    {
        id: 1,
        label: "Pro no ha llamado al cliente"
    },
    {
        id: 2,
        label: "Pro no ha acudido a la cita"
    },
    {
        id: 3,
        label: "Cliente no quiere pagar"
    },
    {
        id: 4,
        label: "Otros"
    }
]

const NewIncidentsModal = props => {

    const [wollers, setWollers] = useState([]);
    const [typology, setTypology] = useState();
    const [description, setDescription] = useState("");
    const [woller, setWoller] = useState();
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        fetchWollers();
    }, []);

    useEffect(() => {
        checkDisabledButton();
    }, [typology, woller, description]);

    const fetchWollers = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let pathname = window.location.href.split('/');
        let data = {
            jobId: pathname[pathname.length - 1],
        }
        
        let res = await Parse.Cloud.run("getWollersByJob", data);
        setWollers(res);
    }

    const handleTypologyChange = (e) => {
        if(e.target.value != 'Elige una opcion'){
            setTypology(e.target.value);
        } else setTypology(undefined);
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    }

    const handleWollerChange = (woll) => {
        setWoller(woll.get('FromUser').id);
    }

    const checkDisabledButton = async () => {
        if(typology && description) setDisabled(false);
        else setDisabled(true);
    }

    const saveIncident = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let pathname = window.location.href.split('/');
        const data = {
            jobId: pathname[pathname.length - 1],
            userId: woller,
            comment: description,
            typology: typology
        }

        let res = await Parse.Cloud.run("createIncident", data);
        props.fetchIncidents();
        props.onClose();
    }

    return (
        <MainModal
            style={{ height: "570px", width: "1000px" }}
            title={"Nueva Incidencia"}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        > 
            <div style={{display: "flex", flexDirection: "row"}}>
                <div style={{width:"50%"}}>
                    <p className="new-incidence-title">TIPOLOGÍA DE LA INCIDENCIA</p>
                    <select name="select" onChange={handleTypologyChange} style={{ padding: '4px', width: '230px'}}>
                        <option>Elige una opcion</option>
                        {
                            optionsTypology.map( option => (
                                    <option value={option.label}>{option.label}</option>
                            ))
                        }
                    </select>
                    <p className="new-incidence-title">BREVE DESCRIPCIÓN</p>
                    <Input
                        style={{
                            display: "flex",
                            flex: 1,
                            borderRadius: 0,
                            border: "1px solid var(--greyish)",
                        }}
                        inputStyle={{ border: "1px solid #e8e8e8" }}
                        placeholder="Breve descripcion"
                        onChange={handleDescriptionChange}
                    />
                </div>

                <div style={{width:"50%"}}>
                    <p style={{ fontWeight: "bold", marginTop: "40px"}}>PROFESIONAL DE LA INCIDENCIA</p>
                    {
                        wollers.map(woll => (    
                            <button className="woller-wrapper" style={woller == woll.get('FromUser').id ? {border: "2px solid yellow"} : {}} 
                                    onClick={() => handleWollerChange(woll)}>
                                <div className="imageView">
                                    <img className="image" src={woll.get('FromUser').get("UserPic") ? woll.get('FromUser').get("UserPic").url() : require("../../assets/jober-icon.svg")} alt="joberIcon"/>
                                    {woll.get('FromUser').get("Level") > 1 && (
                                        <img
                                            className="medalImage"
                                            src={
                                                woll.get('FromUser').get("Level") === 2
                                                    ? require("../../assets/badge-silver.svg")
                                                    : woll.get('FromUser').get("Level") === 3
                                                    ? require("../../assets/badge-gold.svg")
                                                    : woll.get('FromUser').get("Level") === 4
                                                    ? require("../../assets/badge-platinum.svg")
                                                    : null
                                            }
                                            alt="medal"
                                        />
                                    )}
                                </div>
                                <div className="dataView">
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between"
                                        }}
                                    >
                                        <label className="nameLabel">{woll.get('FromUser').get("Name")}</label>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center"
                                            }}>
                                            <label className={"pointsLabel"}>
                                                {woll.get('FromUser').get("Points") > 0 && woll.get('FromUser').get("Reviews") > 0 ? woll.get('FromUser').get("Points").toFixed(1) : "NUEVO"}
                                            </label>
                                            {woll.get('FromUser').get("Points") > 0 && woll.get('FromUser').get("Reviews") > 0 && (
                                                <img style={{ height: 16, width: 16 }} src={require("../../assets/star-fill.svg")} alt="star"/>
                                            )}
                                        </div>
                                    </div>
                                    <div style={{margin: "10px"}}>{woll.get('FromUser').get("Phone")}</div>
                                </div>
                            </button>
                        ))
                    }
                </div>
            </div>
            <div style={{display:'flex', justifyContent:'center', marginTop:'75px'}}>
                <Button 
                    disabled={disabled}
                    buttonText="Guardar cambios"
                    onClick={ () => saveIncident() }
                />
            </div>
        </MainModal>
    )
}

export default NewIncidentsModal;