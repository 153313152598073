import React, { useState } from "react";
import { Counter } from "jobin-ui-components";

export default function ExtraCell(props) {
    const style = {
        container: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            marginTop: 8,
            marginBottom: 8,
            alignItems: "center"
        },
        contentContainer: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            marginRight: 16
        },
        priceContainer: {
            display: "flex",
            flexDirection: "column",
            marginRight: 16
        },
        nameLabel: {
            display: "flex",
            flex: 1,
            fontSize: 16
        },
        descriptionLabel: {
            display: "flex",
            flex: 1,
            fontSize: 13,
            color: "var(--warm-grey)"
        },
        priceLabel: {
            textAlign: "right",
            fontSize: 13
        },
        totalPriceLabel: {
            textAlign: "right",
            fontWeight: "bold",
            fontSize: 16
        }
    };

    const [totalPrice, setTotalPrice] = useState(
        props.extra.units
            ? Number(props.extra.units) * Number(props.extra.pricePerUnit)
            : 0
    );

    const onNumberChange = input => {
        setTotalPrice(Number(input) * Number(props.extra.pricePerUnit));
        props.onValueChange(input);
    };

    return (
        <section style={style.container}>
            <div style={style.contentContainer}>
                <label style={style.nameLabel}>{props.extra.name}</label>
                <label style={style.descriptionLabel}>
                    {props.extra.description ? props.extra.description : ""}
                </label>
            </div>
            <div style={style.priceContainer}>
                <label style={style.totalPriceLabel}>
                    {(totalPrice).toFixed(2) + " €"}
                </label>
                {props.extra.unit ? (
                    <label style={style.priceLabel}>
                        {(props.extra.pricePerUnit).toFixed(2) +
                            " €/" +
                            props.extra.unit}
                    </label>
                ) : (
                    <label style={style.priceLabel}>
                        {(props.extra.pricePerUnit).toFixed(2) +
                            " €/unidad"}
                    </label>
                )}
            </div>
            <Counter
                unit=""
                min={Number(props.minUnits)}
                onClick={value => onNumberChange(value)}
            />
        </section>
    );
}
