import React, {useState, useRef} from 'react';
import Jobin from 'jobin-client'
import { stripeKey } from "../../constants/credentials";

import { PaycardForm, Button } from 'jobin-ui-components';
import MainModal from './MainModal'

const AddCardModal = (props) => {

    const paycardForm = useRef();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const savePaymentMethod = async () =>{
        if(paycardForm.current){
            setLoading(true);
            const result = await paycardForm.current.createPaymentMethod();
            const result2 = await Jobin.PayCard.saveUserPayCard(result.paymentMethod.billing_details.name, result.paymentMethod.card.exp_month, result.paymentMethod.card.exp_year, result.paymentMethod.id, props.user);
            if(result2.status === 'ok'){
                setLoading(false);
                props.cardSaved();
            }
            else{
                setLoading(false);
                alert(result2.result);
            }
        }
    }

    return (
        <MainModal
			title={props.title}
			subtitle={props.subtitle}
            onClose={props.onClose}
            isVisible={props.isVisible}
		>
            <section>
                <PaycardForm
                    ref={paycardForm}
                    containerStyle={{marginTop: 24, marginBottom: 12}}
                    stripeKey={stripeKey}
                    onChange={(result) => setDisabled(!result)}
                />
            </section>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent:'flex-end', marginTop: 24}}>
                <Button
                    buttonText = "Guardar"
                    loading={loading}
                    disabled={disabled}
                    onClick={savePaymentMethod}
                />
            </div>
        </MainModal>
    )
}

export default AddCardModal;