import React, { useState, useEffect } from "react";
import moment from "moment";
import { DayPickerSingleDateController } from "react-dates";
import { useStateValue } from "../../state/context";
import StepControlls from "./generic/StepControlls";
import BreadCrumb from "./generic/BreadCrumb";
import ButtonEmpty from "../button/ButtonEmpty";

import "../../styles/job/service-date.css";

export default function ServiceDate(props) {
    const [{ job }, dispatch] = useStateValue();

    const [focused, setFocused] = useState(true);

    const clearDate = () => {
        dispatch({
            type: "JOB_DATE",
            dateTransport: null,
            buttonTitle: "Continuar sin fecha"
        });
    };

    const onDateChangeDate = date => {
        dispatch({
            type: "JOB_DATE",
            dateTransport: date.utc().startOf("day"),
            buttonTitle: "Continuar"
        });
    };

    useEffect(() => {
        if (!(props.location.state && "inner" in props.location.state))
            props.history.replace("/job/new");
    }, []);

    const RenderDate = () => {
        return (
            <DayPickerSingleDateController
                onDateChange={onDateChangeDate}
                onFocusChange={() => setFocused(true)}
                focused={focused}
                date={job.date.dateTransport}
                numberOfMonths={2}
                hideKeyboardShortcutsPanel={true}
                isOutsideRange={date => date.isBefore(moment().endOf("day"))}
            />
        );
    };

    return (
        <div id="service-date" className="section">
            <BreadCrumb history={props.history} location={props.location} />
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="title">Fecha del transporte</h4>
                        <p>
                            Puedes seleccionar la fecha del envío de transporte,
                            o concretar luego.
                        </p>
                        <div className="content">
                            <RenderDate />
                            {job.date.dateTransport && (
                                <ButtonEmpty
                                    buttonText="Limpiar fecha"
                                    onClick={clearDate}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <StepControlls
                page={props.step}
                next="job/new/description"
                prev="job/new/price"
                nextTitle={job.date.buttonTitle}
            />
        </div>
    );
}
