import React, { useState, useEffect } from "react";
import MainModal from "./MainModal";
import Button from "../button/Button";
import Parse from "parse";
import Lottie from 'react-lottie'
import BrokerComponent from "../../components/broker/BrokerComponent"

import { Input } from "jobin-ui-components";
import { apiId, jsKey, urlServer  } from '../../constants/credentials';

import * as cardSpinnerData from "../../assets/animations/button-loading.json";

const BoosterModal = props => {
    const [less, setLess] = useState(0);
    const [multiplier, setMultiplier] = useState(0);
    const [loading, setLoading] = useState(false);

    const [provinces, setProvinces] = useState([]);
    const [weekDays, setWeekDays] = useState([]);
    const [verticals, setVerticals] = useState([]);

    useEffect(()=> {
        getProvinces();
        getWeekDays();
        getFatherVerticals();
    }, [])

    const onLessChange = input => {
        if (input.length > 0) 
            setLess(input);   
        else 
            setLess(0);    
    };

    const onMultiplierChange = input => {
        if (input.length > 0) 
            setMultiplier(input);   
        else 
            setMultiplier(0);
    };

    const updateAllVerticalMultiplier = async (multiplier, less) => {
        setLoading(true);
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        for(let i=0; i<5; i++){
            await Parse.Cloud.run("updateAllVerticalMultiplier", { multiplier: multiplier, less: less, page: i });
        }
        setLoading(false);
    }

    const getFatherVerticals = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let res = await Parse.Cloud.run("getFatherVerticals", {});
        setVerticals(res);
    }

    const getProvinces = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let res = await Parse.Cloud.run("getProvinces", {});
        setProvinces(res);
    }

    const getWeekDays = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let res = await Parse.Cloud.run("getWeekDays", {});
        setWeekDays(res);
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: cardSpinnerData.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <MainModal
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
            broker={true}
        >
            <div style={{display: "flex", justifyContent: "center", width: "100%", alignItems: "center", flexDirection: "column"}}>
                <img style={{width:"200px"}} src={require("../../assets/proBroker.png")} alt="Guardar" />
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center"}}>
                    <p style={{fontWeight: "bold", marginTop: 5, marginRight: 5}}>Powered by:</p>
                    <img style={{width:"30px"}} src={require("../../assets/wolly.png")} alt="Guardar" />
                </div>
            </div>
            <p style={{fontWeight: "bold", fontSize: 20}}>1. Booster</p>

            <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row"}}>
                <div>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <p style={{marginTop: "15px", width: 500, fontSize: 16, marginLeft: 17}}>Escoge el Multiplicador de los leads con 1 presupuesto</p>
                        <div>
                            <Input
                                style={{
                                    display: "flex",
                                    flex: 1,
                                    borderRadius: 0,
                                    border: "1px solid var(--greyish)",
                                    boxShadow: "0px 11px 20px 1px rgba(130,130,130,0.67)",
                                    borderRadius: 15,
                                    height: 40,
                                    marginTop: 10,
                                    backgroundColor: "white"
                                }}
                                inputStyle={{ border: "1px solid #e8e8e8" }}
                                placeholder="Multiplicador"
                                onChange={e => onMultiplierChange(e.target.value)}
                            />
                        </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row"}}>
                        <p style={{marginTop: "25px", width: 500, fontSize: 16, marginLeft: 17}}>Filtrar por los oficios con precio menor que</p>
                        <div>
                            <Input
                                style={{
                                    display: "flex",
                                    flex: 1,
                                    border: "1px solid var(--greyish)",
                                    boxShadow: "0px 11px 20px 1px rgba(130,130,130,0.67)",
                                    borderRadius: 15,
                                    height: 40,
                                    marginTop: 20,
                                    backgroundColor: "white"
                                }}
                                inputStyle={{ border: "1px solid #e8e8e8" }}
                                placeholder="Todas"
                                onChange={e => onLessChange(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop:'40px', height: "50px" }}>
                    { !loading && (
                        <Button 
                            disabled={false}
                            buttonText="Guardar cambios"
                            onClick={ () => updateAllVerticalMultiplier(multiplier, less) }
                        />
                    )}
                    { loading && (
                        <button style={{ backgroundColor: 'black', borderRadius: 10}}>
                            <Lottie
                                style={{height: 40, width: 65}}
                                options={defaultOptions}
                            />
                        </button>
                    )}
                </div>
            </div>

            <p style={{fontWeight: "bold", fontSize: 20, marginTop: 20}}>2. Smart Pricing</p>

            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "0px 75px"}}>
                    <BrokerComponent
                        title={"Provincia"}
                        data={provinces}
                        updateAmount={getProvinces}
                    />
                    <BrokerComponent
                        title={"Gremio"}
                        data={verticals}
                        updateAmount={getFatherVerticals}
                    />
                    <BrokerComponent
                        title={"Dia"}
                        data={weekDays}
                        updateAmount={getWeekDays}
                    />
            </div>
            
        </MainModal>
    );
};

export default BoosterModal;