import React from "react";

import "../../../styles/job/step-tip.css";

export default function StepTip(props) {
    return (
        <div className="step-tip hidden-sm hidden-xs">
            <div className="row">
                <div className="col-sm-6">
                    {props.iconLeft && (
                        <img src={props.iconLeft} alt="bright" />
                    )}
                </div>
                <div className="col-sm-6">
                    {props.iconRight && (
                        <img
                            className="right"
                            src={props.iconRight}
                            alt="bright"
                        />
                    )}
                </div>
            </div>
            <p className="title">{props.title}</p>
            <p className="excerpt">{props.excerpt}</p>
            {props.linkTitle && props.linkUrl && (
                <a href={props.linkUrl}>{props.linkTitle}</a>
            )}
        </div>
    );
}
