import React, { useEffect } from "react";
import '../../../styles/search/detail/job-detail-history.css';

export default function UserHistoryContainer(props) {

    const style = {
        container: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            border: "1px solid var(--soft-grey)",
            borderRadius: 4,
            padding: 16,
            marginTop: 6,
            marginBottom: 6,
            backgroundColor: "#fff",
            boxShadow: "5px 5px 5px 0px var(--soft-grey)"
        },
        titleContainer: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            marginBottom: 4
        },
        title: {
            flex: 1,
            fontSize: 13,
            color: "var(--greyish)"
        },
        userContainer: {
            display: "flex",
            flex: 1,
            marginTop: 12,
            alignItems: "center"
        },
        userLabel: {
            fontSize: 13
        },
    };

    useEffect(() => {
        props.getUserCRMHistory();
    }, []);

    let userCRMHistory = props.userCRMHistory;
    let newNotes = props.newNotes;

    return (
        <section className="userHistoryContainer">
            <div className="listContainer">
                {newNotes && (
                    newNotes.map( not => (
                        <section style={style.container}>
                            <div style={style.titleContainer}>
                                <label style={style.title}>Nota</label>
                                <label style={{ ...style.title, textAlign: "right" }}>
                                    {not.createdAt}
                                </label>
                            </div>
                            <label> {not.description} </label>
                            <div style={style.userContainer}>
                                <label style={style.userLabel}>{not.name}</label>
                            </div>
                        </section>
                    ))
                )}
                {userCRMHistory && (
                    userCRMHistory.map( hist => (
                        <section style={style.container}>
                            <div style={style.titleContainer}>
                                <label style={style.title}>Nota</label>
                                <label style={{ ...style.title, textAlign: "right" }}>
                                    {hist.get('createdAt').toString().substring(4,15)}
                                </label>
                            </div>
                            <label> {hist.get('ExtraInfo').newNotes} </label>
                            <div style={style.userContainer}>
                                <label style={style.userLabel}>{hist.get('CRMUser').get('Name')}</label>
                            </div>
                        </section>
                    ))
                )}
            </div>
        </section>
    );
}