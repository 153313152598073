import React, {useState, useEffect, useRef} from 'react'

import { getCampainClick } from "../../utils/ParserUtilities"

import DynamicContainer from "./components/DynamicContainer";
import Filters from "./components/Filters";

import "../../styles/campains/campains.css"

const components = [
  ["Dynamic / Overflow Container", DynamicContainer],
];

export default function Campains() {

  const [selectedCampain, setSelectedCampain] = useState(null);
  const [dateRange, setDateRange] = useState([ new Date().setDate(new Date().getDate() - 7), new Date() ]);

  const [totalClicks, setTotalClicks] = useState(0);
  const [biProTotalClicks, setBiProTotalClicks] = useState(0);
  const [AndroidTotalClicks, setAndroidTotalClicks] = useState(0);
  const [IosTotalClicks, setIosTotalClicks] = useState(0);
  const [campainData, setCampainData] = useState([
    {
      "label": "Selecciona un tipo para mostrar estadísticas",
      "data": [
        {
          primary: "Selecciona un tipo para mostrar estadísticas",
          secondary: 0
        }
      ]
    }
  ]);

  const firstUpdate = useRef(true);

  useEffect(()=>{
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    getCampainClickRequest();
  },[selectedCampain, dateRange])

  const checkDaterange = (newDateRange) => {
      if(typeof(newDateRange[0]) == "number"){
          return [new Date (newDateRange[0]), newDateRange[1] ];
      } else return newDateRange;
  }

  const getCampainClickRequest = async () => {
    if(dateRange[0] && dateRange[1]){

      const result = await getCampainClick(checkDaterange(dateRange), selectedCampain.value);

      setTotalClicks(result.totalClicks);
      setAndroidTotalClicks(result.androidTotalClicks)
      setBiProTotalClicks(result.biproTotalClicks)
      setIosTotalClicks(result.iosTotalClicks)

      setCampainData(result.campainData)
    }
  }

  return (
    <div className='campains-wrapper'>
        <DynamicContainer 
          campainData={campainData}
        />
        <div style={{display: "flex", flexDirection: "row"}}>
          <Filters
            selectedCampain={selectedCampain}
            setSelectedCampain={setSelectedCampain}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
          <div style={{width:"50%", display: "flex", alignItems: "center", flexDirection: "column", marginTop: "40px"}}>
              <div className='campains-total-clicks'>
                  <p className='campains-total-clicks-main-number'>{totalClicks}</p>
                  <p>Total</p>
              </div>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "80%", marginTop: "40px"}}>
                  <div className='campains-total-clicks orange'>
                    <p className='campains-total-clicks-light-number'>{AndroidTotalClicks}</p>
                    <p className='orange'>Android</p>
                  </div>
                  <div className='campains-total-clicks blue'>
                    <p className='campains-total-clicks-light-number'>{biProTotalClicks}</p>
                    <p className='blue'>BI Pro</p>
                  </div>
                  <div className='campains-total-clicks red'>
                    <p className='campains-total-clicks-light-number'>{IosTotalClicks}</p>
                    <p className='red'>IOS</p>
                  </div>
              </div>
          </div>
        </div>
        
    </div>
  )
}
