export const slotArr = [
    {
        id: "all",
        name: "Llena",
        selected: false
    },
    {
        id: 1,
        name: "1 llena",
        selected: false
    },
    {
        id: 2,
        name: "2 llenas",
        selected: false
    },
    {
        id: 3,
        name: "3 llenas",
        selected: false
    }
];
