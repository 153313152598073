import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import BillingEditor from "../../components/billing/BillingEditor";
import ButtonImage from "../../components/button/ButtonImage";

export function GeneralEdit(props) {
    const initFromBreakdown = payment => {
        let array = [];
        if (payment && payment.has("ReceiptInfo")) {
            let priceBreakdown = payment.get("ReceiptInfo").priceBreakdown;
            for (let i = 0; i < priceBreakdown.name.length; i++) {
                array.push({
                    description: priceBreakdown.name[i],
                    amount: priceBreakdown.units[i],
                    pricePerUnit: Number(priceBreakdown.pricePerUnitNoIVA[i])
                });
            }
        }
        return array;
    };

    const [payment, setPayment] = useState(props.payment);
    const [billingInfo, setBillingInfo] = useState(props.billingInfo);
    const [totalPrice, setTotalPrice] = useState(props.payment.get("Price"));
    const [rowArray, setRowArray] = useState(initFromBreakdown(props.payment));

    useEffect(() => {
        setPayment(props.payment);
        setTotalPrice(props.payment.get("Price"));
        setBillingInfo(props.billingInfo);
        setRowArray(initFromBreakdown(props.payment));
    }, [
        props.payment && props.payment.id,
        props.billingInfo && props.billingInfo.id
    ]);

    useEffect(() => {
        const totalPrice = rowArray.reduce((total, item) => {
            return total + item.amount * item.pricePerUnit;
        }, 0);
        setTotalPrice(totalPrice);
    }, [rowArray]);

    const addRow = ({ description, amount, pricePerUnit }) => {
        const newRow = {
            description: description,
            amount: amount,
            pricePerUnit: pricePerUnit
        };
        const newRowArray = [...rowArray, newRow];
        setRowArray(newRowArray);
    };

    const editRow = (index, { description, amount, pricePerUnit }) => {
        const newRow = {
            description: description,
            amount: amount,
            pricePerUnit: pricePerUnit
        };
        rowArray[index] = newRow;
        setRowArray([...rowArray]);
    };

    const enableEdit = index => {
        rowArray[index].editable = true;
        setRowArray([...rowArray]);
    };

    const deleteRow = index => {
        rowArray.splice(index, 1);
        setRowArray([...rowArray]);
    };

    const saveBill = async () => {
        console.info(rowArray);
    };

    const renderNavBarButtons = (
        <section style={props.style.buttonContainer}>
            <div className={`filter custom publish-no-hide`}>
                <ButtonImage
                    src={require("../../assets/available.svg")}
                    buttonText="Guardar"
                    onClick={saveBill}
                />
            </div>
        </section>
    );
    return (
        <React.Fragment>
            <NavBar
                history={props.history}
                location={props.location}
                title={"Editar factura"}
                buttonView={renderNavBarButtons}
            />
            <div style={props.style.container}>
                <BillingEditor
                    payment={payment}
                    billingInfo={billingInfo}
                    rowArray={rowArray}
                    totalPrice={totalPrice}
                    onBillingInfoChanged={setBillingInfo}
                    addRow={addRow}
                    editRow={editRow}
                    deleteRow={deleteRow}
                    enableEdit={enableEdit}
                />
            </div>
        </React.Fragment>
    );
}
