import React, { useState } from "react";
import LinkerImage from "../../assets/pro-linker.png";
import GoogleMapReact from 'google-map-react';
import SearchGaragesAutoComplete from "./SearchGarageAutoComplete";
import GarageMapsMarker from "./GarageMapsMarker";

import "../../styles/search-garages/search-garages.css";
import AddCarPlate from "../modal/AddCarPlate";

const SearchGarages = () => {

    const [center, setCenter] = useState({
        lat: 40,
        lng: -3.5
    });
    const [zoom, setZoom] = useState(6.6);
    const [googlemaps, setGooglemaps] = useState(null);
    const [map, setMap] = useState([]);
    const [mapApiLoaded, setMapApiLoaded] = useState(false);
    const [showAddCarPlate, setShowAddCarPlate] = useState(false);

    const [garages, setGarages] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [itemSelected, setItemSelected] = useState([]);


    const handleApiLoaded = (map, maps) => {
        if (map && maps) {
            setMap(map);
            setGooglemaps(maps);
            setMapApiLoaded(true); 
        }
    };

    const getAddresseByPosition = pos => {
        let newAddresse = "none"
        addresses.map( addresse => {
            if(addresse.position == pos){
                newAddresse = addresse.addresse
            } 
        })

        return newAddresse;
    }

    const selectGarage = (item) => {
        setItemSelected(item);
        setShowAddCarPlate(true);
    }

    return (
        <div className="search-garages-wrapper">
            <img className="search-garages-main-image" src={LinkerImage}/>
            {mapApiLoaded && (
                <SearchGaragesAutoComplete 
                    map={map} 
                    mapApi={googlemaps} 
                    setGarages={setGarages} 
                    setAddresses={setAddresses} 
                    addresses={addresses}
                />
            )}
            <div className="search-garages-info">
                <div className="search-garages-cards">
                {
                    garages.length > 0 && (
                        garages.map( (item, i) => (
                            <div key={i} className="search-garages-card">
                                <p className="search-garages-card-p">Nombre: {item.get("Name")}</p>
                                <p className="search-garages-card-p">
                                    Dirección: {getAddresseByPosition(i)}
                                </p>
                                <div className="search-garages-card-button-wrapper">
                                    <button onClick={() => selectGarage(item)} className="search-garages-card-button">Seleccionar Taller</button>
                                    <div className="search-garages-card-position-wrapper">
                                            <p className="search-garages-card-position">{i}</p>
                                    </div>
                                </div>
                            </div>
                        ))  
                    )
                }
                </div>
                <div className="search-garages-map">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyDYwng9doYyVkIIyzYLX6crFuJLii07Cus" }}
                        defaultCenter={center}
                        defaultZoom={zoom}
                        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    >
                    {
                        garages.map( (garage, i) => {
                            return <GarageMapsMarker
                                        key={i}
                                        position={i}
                                        lat={garage.get("Location")._latitude}
                                        lng={garage.get("Location")._longitude}
                                    />
                        })
                    }
                        
                    </GoogleMapReact>
                </div>
            </div>
            {showAddCarPlate && (
                <AddCarPlate
                    isVisible={true}
                    onClose={() => setShowAddCarPlate(false)}
                    itemSelected={itemSelected}
                />
            )}
        </div>
    )
}

export default SearchGarages;