import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";

import { Loading } from "jobin-ui-components";
import { GeneralEdit } from "./GeneralEdit";
import { JoberPayoffEdit } from "./JoberPayoffEdit";

export default function Edit(props) {
    const [loading, setLoading] = useState(true);
    const [payment, setPayment] = useState();
    const [billingInfo, setBillingInfo] = useState();
    const [jobs, setJobs] = useState();
    const [transportations, setTransportations] = useState();
    const [fixedInfos, setFixedInfos] = useState();

    useEffect(() => {
        (async () => {
            const paymentId = props.match.params.paymentId;
            setLoading(true);
            let {
                status: pStatus,
                result: payment
            } = await Jobin.Payment.getById(paymentId);

            if (pStatus === "ok") {
                let {
                    status: bStatus,
                    result: billingInfo
                } = await Jobin.Client.getBillingInfo(payment.get("User").id);

                if (bStatus === "ok") {
                    setPayment(payment);
                    setBillingInfo(billingInfo);
                } else {
                    alert("Error al cargar los datos");
                }

                if (payment.get("Type") === "joberPayoff") {
                    let {
                        status: jStatus,
                        result: jResult
                    } = await Jobin.Job.getJoberPayoffJobs(paymentId);

                    if (jStatus === "ok") {
                        setJobs(jResult.jobs);
                        setFixedInfos(jResult.fixedInfos);
                        setTransportations(jResult.transportations);
                    } else {
                        alert("Error al cargar los datos");
                    }
                }
            } else {
                alert("Error al cargar los datos");
            }

            setLoading(false);
        })();
    }, [props.match.params.paymentId]);

    const style = {
        loadingContainer: {
            display: "flex",
            flex: 1,
            backgroundColor: "var(--soft-grey)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
        },
        container: {
            display: "flex",
            backgroundColor: "var(--soft-grey)",
            height: "calc(100vh - 72px)",
            justifyContent: "center"
        }
    };

    if (loading) {
        return (
            <section style={style.loadingContainer}>
                <Loading />
            </section>
        );
    } else if (payment && payment.get("Type") === "joberPayoff") {
        return (
            <JoberPayoffEdit
                history={props.history}
                location={props.location}
                payment={payment}
                billingInfo={billingInfo}
                jobs={jobs}
                fixedInfos={fixedInfos}
                transportations={transportations}
                style={style}
            />
        );
    } else {
        return (
            <GeneralEdit
                history={props.history}
                location={props.location}
                payment={payment}
                billingInfo={billingInfo}
                style={style}
            />
        );
    }
}
