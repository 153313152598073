import React,  { useState, useEffect } from "react";
import { useStateValue } from "../../../state/context";
import {createJoberTask,deleteJoberTask} from "../../../utils/ParserUtilities"
import "../../../styles/Users/wollers/description.css";

export default function Tasks({userTasks,alltasks}) {
    const [isTrigred,setIsTrigred] = useState(false)
    const [{user}, dispatch] = useStateValue();

    const handleTaskToggle = async (task_,event)=>{
        let changeHandled =userTasks
        if(event == "remove"){
            alltasks.forEach(async task=>{
                if(task.name === task_.name){
                    //remove
                    changeHandled = userTasks?.filter((userTask_)=>{
                        return userTask_.id != task_.id
                    })
                    await deleteJoberTask({wollerId:user.goldId,verticalId:task_.id, type:task_.name})
                }
            })
        }else{
            changeHandled.push(task_)
            await createJoberTask({wollerId:user.goldId,verticalId:task_.id, type:task_.name})
        }
        dispatch({
            type:"USER_DETAILS_SET_INFO",
            tasks:changeHandled
        })
    }

    const getAlltasks=()=>{
      
        return alltasks.map(task_=>{
            if(userTasks?.some((value)=>task_.name == value.name)){
                return (<div onClick={()=>handleTaskToggle(task_,"remove")} className="individualTaskContainer" key={task_.id}>
                            <img className="taskIcon" src={task_.icon && task_.icon? task_.icon:require("../../../assets/wolly.png")} alt={task_.name}></img>
                            <p>{task_.name}</p>
                        </div>)
            }else if(task_.name != "Todos" && task_.name != "Instalaciones Leroy Merlin"  ){
                return (<div onClick={()=>handleTaskToggle(task_,"add")} className="individualTaskContainer disabled" key={task_.id}>
                            <img className="taskIcon" src={task_.icon && task_.icon? task_.icon:require("../../../assets/wolly.png")} alt={task_.name}></img>
                            <p>{task_.name}</p>
                        </div>)
            }
        })
    }

    return (
        <div className="tasksContainer userDetails">
           { userTasks && userTasks?.map(userTask_=>{
           return alltasks && alltasks.map(task_=>{
                    let taskType = userTask_.name
                    if(task_.name == taskType){
                        return (<div className="individualTaskContainer external" key={task_.id}>
                                    <img className="taskIcon" src={task_.icon && task_.icon? task_.icon:require("../../../assets/wolly.png")} alt={task_.name}></img>
                                </div>)
                    }
                })
           })}
           <div className="individualTaskContainer external" onClick={()=>setIsTrigred(!isTrigred)} >
                <img  className="taskIcon addTask" src={require("../../../assets/icons/Plus.svg")} alt={"addMore"}></img>
            </div>
            <div className={isTrigred ? "moretasks active":"moretasks"}>
                {getAlltasks()}
            </div>
        </div>
    )
}