export const jobStateArr = [
    {
        id: "all",
        name: "Todas",
        selected: false
    },
    {
        parent: "Ver abiertos",
        collapsed: true,
        items: [
            {
                id: "abierto",
                name: "Abierto",
                selected: false
            },
            {
                id: "full",
                name: "Lleno",
                selected: false
            },
            {
                id: "frozen",
                name: "Congelado",
                selected: false
            },
            {
                id: "standby",
                name: "En espera",
                selected: false
            }
        ]
    },
    {
        parent: "Ver eliminados",
        collapsed: true,
        items: [
            {
                id: "falso",
                name: "Falso",
                selected: false
            },
            {
                id: "eliminado",
                name: "Eliminado",
                selected: false
            },
            {
                id: "noJobers",
                name: "No hay Jobers",
                selected: false
            }
        ]
    },
    {
        parent: "Ver completados",
        collapsed: true,
        items: [
            {
                id: "finalizado",
                name: "Finalizado",
                selected: false
            },
            {
                id: "review",
                name: "Review",
                selected: false
            }
        ]
    }
];

export const fixedStateArr = [
    {
        id: "all",
        name: "Todas",
        selected: false
    },
    {
        id: "draft",
        name: "Presup. pendiente",
        selected: false
    },
    {
        id: "pending",
        name: "Pend. de cita",
        selected: false
    },
    {
        id: "abierto",
        name: "Pend. de asignación",
        selected: false
    },
    {
        id: "full",
        name: "En proceso",
        selected: false
    },
    {
        id: "signaturePending",
        name: "Pend. de firma",
        selected: false
    },
    {
        id: "finalizado",
        name: "Firmado",
        selected: false
    },
    {
        id: "review",
        name: "Valorado",
        selected: false
    },
    {
        parent: "Eliminados",
        collapsed: true,
        items: [
            {
                id: "eliminado",
                name: "Servicios eliminados",
                selected: false
            },
            {
                id: "falso",
                name: "Servicios falsos",
                selected: false
            }
        ]
    },
];


export const finishedFixedStateArr = [
    {
        id: "all",
        name: "Todas",
        selected: false
    },
    {
        id: "draft",
        name: "Presup. pendiente",
        selected: false
    },
    {
        id: "pending",
        name: "Pend. de cita",
        selected: false
    },
    {
        id: "abierto",
        name: "Pend. de asignación",
        selected: false
    },
    {
        id: "full",
        name: "En proceso",
        selected: false
    },
    {
        id: "signaturePending",
        name: "Pend. de firma",
        selected: false
    },
    {
        id: "finalizado",
        name: "Firmado",
        selected: true
    },
    {
        id: "review",
        name: "Valorado",
        selected: true
    },
    {
        parent: "Eliminados",
        collapsed: true,
        items: [
            {
                id: "eliminado",
                name: "Servicios eliminados",
                selected: false
            },
            {
                id: "falso",
                name: "Servicios falsos",
                selected: false
            }
        ]
    },
];