import React, { useState } from "react";
import ReceiptHeader from "./receipt/ReceiptHeader";
import BillingInfo from "./receipt/BillingInfo";
import {
    BillHeaders,
    DisplayRow,
    InputRow,
    TotalFooter,
    ButtonRow
} from "./receipt/BillRows";
import { styles } from "./receipt/styles";

export default function BillingCreator(props) {
    const [showNewRow, setShowNewRow] = useState(true);

    const [menuItems] = useState([
        {
            id: "edit",
            title: "Editar"
        },
        {
            id: "delete",
            title: "Borrar"
        }
    ]);

    return (
        <section style={styles.container}>
            <ReceiptHeader custom={true} />
            <BillingInfo
                custom={true}
                changeName={props.changeName}
                changeIdentityNumber={props.changeIdentityNumber}
                changeAddress1={props.changeAddress1}
                changeAddress2={props.changeAddress2}
            />
            <div style={styles.billContainer}>
                <table>
                    <BillHeaders />
                    <tbody>
                        {props.rowArray.map((item, index) => (
                            item.editable ? (
                                <InputRow
                                    index={index}
                                    item={item}
                                    saveRow={data => {
                                        props.editRow(index, data);
                                    }}
                                />
                            ) : (
                                <DisplayRow
                                    index={index}
                                    item={item}
                                    menuItems={menuItems}
                                    buttonItemSelected={option => {
                                        if (option.id === "edit")
                                            props.enableEdit(index);
                                        else if (option.id === "delete")
                                            props.deleteRow(index);
                                    }}
                                />
                            )
                        ))}
                        {showNewRow && (
                            <InputRow
                                index={props.rowArray.length}
                                saveRow={data => {
                                    props.addRow(data);
                                    setShowNewRow(false);
                                }}
                            />
                        )}
                        <ButtonRow
                            buttonText="Añadir nueva partida"
                            onClick={() => setShowNewRow(true)}
                            disabled={showNewRow}
                        />
                    </tbody>
                    <TotalFooter totalPrice={props.totalPrice} />
                </table>
            </div>
        </section>
    );
}
