import React, { useState, useEffect } from "react";
import { useStateValue } from "../../state/context";
import { useDropzone } from "react-dropzone";
import StepTip from "./generic/StepTip";
import StepControlls from "./generic/StepControlls";
import BreadCrumb from "./generic/BreadCrumb";
import SignIn from "../../components/job/SignIn";

import "../../styles/job/service-picture.css";

import imgUpload from "../../assets/img-upload.svg";
import imgAdd from "../../assets/img-add.svg";
import imgRemove from "../../assets/img-remove.svg";
import stepIconLeft from "../../assets/step-icon-left.svg";

export default function ServicePicture(props) {
    const [{ job }, dispatch] = useStateValue();

    const [files, setFiles] = useState(job.picture.files);
    const {
        getRootProps,
        getInputProps,
        open,
        isDragAccept,
        isDragReject,
        isDragActive
    } = useDropzone({
        accept: "image/*",
        multiple: true,
        maxSize: 5120000000,
        disabled: files.length > 3,
        onDrop: acceptedFiles =>
            files.length < 4
                ? setFiles(
                      files.concat(
                          acceptedFiles.map(file =>
                              Object.assign(file, {
                                  preview: URL.createObjectURL(file)
                              })
                          )
                      )
                  )
                : false
    });

    const removeImg = e => {
        const toDelete = files.splice(e.target.getAttribute("data-id"), 1);
        setFiles(files.filter(n => !toDelete.includes(n)));
    };

    const thumbs = files.map((file, index) => (
        <div key={`${file.name}-${index}`} className="thumb">
            <div className="img-remove">
                <img
                    src={imgRemove}
                    alt="remove"
                    data-id={index}
                    onClick={removeImg}
                />
            </div>
            <img src={file.preview} alt={file.name} />
        </div>
    ));

    const imgPlaceholder = files.length > 0 && files.length < 4 && (
        <div className="img-placeholder" onClick={open}>
            <img src={imgAdd} alt="add" />
        </div>
    );

    const showSignInModal = () => {
        dispatch({
            type: "JOB_SIGNIN_SHOW_HIDE",
            visible: true
        });
        dispatch({
            type: "JOB_SIGNIN_STEP",
            step: 1
        });
    };

    const update = () => {
        dispatch({
            type: "JOB_PICTURE",
            files: files
        });
        if (job.price.selectedOption === "lead") {
            job.history.push({
                pathname: "/job/new/urgency",
                search: job.location.search,
                state: {
                    inner: true,
                }
            });
        } else if (job.fromLeadToFixed) {
            job.history.push({
                pathname: "/job/new/summary",
                search: job.location.search,
                state: {
                    inner: true,
                }
            });
        } else showSignInModal();
    };

    useEffect(() => {
        if (!(props.location.state && "inner" in props.location.state))
            props.history.replace("/job/new");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            id="service-picture"
            className={files.length > 0 ? "files-added section" : "section"}
        >
            <BreadCrumb history={props.history} location={props.location} />
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="title">Añade fotos</h4>
                        <p className="hidden-md hidden-sm hidden-xs">
                            Si el cliente te ha proporcionado alguna imagen puedes añadirla aquí. Puedes continuar sin ellas.
                        </p>
                        <p className="hidden-lg">
                            Si el cliente te ha proporcionado alguna imagen puedes añadirla aquí. Puedes continuar sin ellas.
                        </p>
                        <div className="content">
                            <div
                                {...getRootProps({
                                    className: isDragAccept
                                        ? "drop-zone drag-acept"
                                        : isDragReject
                                        ? "drop-zone drag-reject"
                                        : "drop-zone"
                                })}
                            >
                                <div className="middle-helper">
                                    <input {...getInputProps()} />
                                    <button type="button">
                                        <img src={imgUpload} alt="img-upload" />
                                        Añadir fotos
                                    </button>
                                    {isDragAccept && (
                                        <p>O arrástalas hasta aquí</p>
                                    )}
                                    {isDragReject && (
                                        <p>
                                            Solo puedes subir imágenes de hasta
                                            5mb cada una
                                        </p>
                                    )}
                                    {!isDragActive && (
                                        <p>O arrástalas hasta aquí</p>
                                    )}
                                </div>
                            </div>
                            <aside>
                                {thumbs}
                                {imgPlaceholder}
                            </aside>
                        </div>
                    </div>
                    <div className="col-md-offset-1 col-md-4">
                        <StepTip
                            iconLeft={stepIconLeft}
                            title="Transporte"
                            excerpt="Es importante tratar de añadir imagenes de las facturas del material que se va a transportar para tener registro."
                        />
                    </div>
                </div>
            </div>
            <StepControlls
                page={props.step}
                disabled={false}
                nextTrigger={true}
                nextTitle={
                    files.length > 0 ? "Siguiente" : "Continuar sin fotos"
                }
                prev="job/new/description"
                update={update}
            />
            {job.signIn.visible && <SignIn step={job.signIn.step} />}
        </div>
    );
}
