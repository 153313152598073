import React from "react";
import { useStateValue } from "../../../state/context";

import "../../../styles/job/step-controlls.css";

import errorModal from "../../../assets/error-modal.svg";

export default function ErrorModal(props) {
    const [ , dispatch] = useStateValue();

    const next = () => {
        props.updateStep(1);
    };

    const close = (
        <svg width="40px" height="40px" viewBox="0 0 40 40">
            <defs>
                <path
                    d="M10.4141,9 L16.7781,2.636 C17.1691,2.245 17.1691,1.612 16.7781,1.222 C16.3881,0.831 15.7551,0.831 15.3641,1.222 L9.0001,7.586 L2.6361,1.222 C2.2451,0.831 1.6121,0.831 1.2221,1.222 C0.8311,1.612 0.8311,2.245 1.2221,2.636 L7.5861,9 L1.2221,15.364 C0.8311,15.755 0.8311,16.388 1.2221,16.778 C1.4171,16.974 1.6731,17.071 1.9291,17.071 C2.1851,17.071 2.4401,16.974 2.6361,16.778 L9.0001,10.414 L15.3641,16.778 C15.5601,16.974 15.8151,17.071 16.0711,17.071 C16.3271,17.071 16.5831,16.974 16.7781,16.778 C17.1691,16.388 17.1691,15.755 16.7781,15.364 L10.4141,9 Z"
                    id="path-1"
                />
                <path
                    d="M21.4141,20 L27.7781,13.636 C28.1691,13.245 28.1691,12.612 27.7781,12.222 C27.3881,11.831 26.7551,11.831 26.3641,12.222 L20.0001,18.586 L13.6361,12.222 C13.2451,11.831 12.6121,11.831 12.2221,12.222 C11.8311,12.612 11.8311,13.245 12.2221,13.636 L18.5861,20 L12.2221,26.364 C11.8311,26.755 11.8311,27.388 12.2221,27.778 C12.4171,27.974 12.6731,28.071 12.9291,28.071 C13.1851,28.071 13.4401,27.974 13.6361,27.778 L20.0001,21.414 L26.3641,27.778 C26.5601,27.974 26.8151,28.071 27.0711,28.071 C27.3271,28.071 27.5831,27.974 27.7781,27.778 C28.1691,27.388 28.1691,26.755 27.7781,26.364 L21.4141,20 Z"
                    id="path-3"
                />
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <circle id="circle" fill="transparent" cx="20" cy="20" r="20" />
                <g transform="translate(11.000000, 11.000000)">
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1" />
                    </mask>
                    <g />
                </g>
                <mask fill="white">
                    <use xlinkHref="#path-3" />
                </mask>
                <use fill="#444444" xlinkHref="#path-3" />
            </g>
        </svg>
    );

    return (
        <div className="modal error">
            <div
                className="close-modal"
                onClick={() =>
                    dispatch({
                        type: "JOB_SIGNIN_SHOW_HIDE",
                        visible: false
                    })
                }
            >
                <button className="nostyle">{close}</button>
            </div>
            <img src={errorModal} alt="Error" />
            <h4 className="title">{props.title}</h4>
            <p className="excerpt">{props.excerpt}</p>
            <div className="one-row-button retry">
                <button type="button" onClick={next}>
                    {props.button}
                </button>
            </div>
        </div>
    );
}
