export const provinceArr = [
    {
        id: "all",
        name: "Todas",
        selected: false
    },
    // {
    //     parent: "España",
    //     collapsed: true,
    //     items: [
            {
                id: "01",
                name: "Alava",
                selected: false
            },
            {
                id: "02",
                name: "Albacete",
                selected: false
            },
            {
                id: "03",
                name: "Alicante",
                selected: false
            },
            {
                id: "04",
                name: "Almería",
                selected: false
            },
            {
                id: "05",
                name: "Ávila",
                selected: false
            },
            {
                id: "06",
                name: "Badajoz",
                selected: false
            },
            {
                id: "07",
                name: "Islas Baleares",
                selected: false
            },
            {
                id: "08",
                name: "Barcelona",
                selected: false
            },
            {
                id: "09",
                name: "Burgos",
                selected: false
            },
            {
                id: "10",
                name: "Cáceres",
                selected: false
            },
            {
                id: "11",
                name: "Cádiz",
                selected: false
            },
            {
                id: "12",
                name: "Castellón",
                selected: false
            },
            {
                id: "13",
                name: "Ciudad Real",
                selected: false
            },
            {
                id: "14",
                name: "Córdoba",
                selected: false
            },
            {
                id: "15",
                name: "La Coruña",
                selected: false
            },
            {
                id: "16",
                name: "Cuenca",
                selected: false
            },
            {
                id: "17",
                name: "Gerona",
                selected: false
            },
            {
                id: "18",
                name: "Granada",
                selected: false
            },
            {
                id: "19",
                name: "Guadalajara",
                selected: false
            },
            {
                id: "20",
                name: "Guipúzcoa",
                selected: false
            },
            {
                id: "21",
                name: "Huelva",
                selected: false
            },
            {
                id: "22",
                name: "Huesca",
                selected: false
            },
            {
                id: "23",
                name: "Jaén",
                selected: false
            },
            {
                id: "24",
                name: "León",
                selected: false
            },
            {
                id: "25",
                name: "Lérida",
                selected: false
            },
            {
                id: "26",
                name: "La Rioja",
                selected: false
            },
            {
                id: "27",
                name: "Lugo",
                selected: false
            },
            {
                id: "28",
                name: "Madrid",
                selected: false
            },
            {
                id: "29",
                name: "Málaga",
                selected: false
            },
            {
                id: "30",
                name: "Murcia",
                selected: false
            },
            {
                id: "31",
                name: "Navarra",
                selected: false
            },
            {
                id: "32",
                name: "Orense",
                selected: false
            },
            {
                id: "33",
                name: "Asturias",
                selected: false
            },
            {
                id: "34",
                name: "Palencia",
                selected: false
            },
            {
                id: "35",
                name: "Las Palmas",
                selected: false
            },
            {
                id: "36",
                name: "Pontevedra",
                selected: false
            },
            {
                id: "37",
                name: "Salamanca",
                selected: false
            },
            {
                id: "38",
                name: "Santa Cruz de Tenerife",
                selected: false
            },
            {
                id: "39",
                name: "Cantabria",
                selected: false
            },
            {
                id: "40",
                name: "Segovia",
                selected: false
            },
            {
                id: "41",
                name: "Sevilla",
                selected: false
            },
            {
                id: "42",
                name: "Soria",
                selected: false
            },
            {
                id: "43",
                name: "Tarragona",
                selected: false
            },
            {
                id: "44",
                name: "Teruel",
                selected: false
            },
            {
                id: "45",
                name: "Toledo",
                selected: false
            },
            {
                id: "46",
                name: "Valencia",
                selected: false
            },
            {
                id: "47",
                name: "Valladolid",
                selected: false
            },
            {
                id: "48",
                name: "Vizcaya",
                selected: false
            },
            {
                id: "49",
                name: "Zamora",
                selected: false
            },
            {
                id: "50",
                name: "Zaragoza",
                selected: false
            },
            {
                id: "51",
                name: "Ceuta",
                selected: false
            },
            {
                id: "52",
                name: "Melilla",
                selected: false
            }
    //     ]
    // },
    // {
    //     parent: "Francia",
    //     collapsed: true,
    //     items: [
    //         {
    //             id: "75",
    //             name: "Francia",
    //             selected: false
    //         }
    //     ]
    // }
];
export const provinceArrEsp = [
    
    {
        id: "01",
        name: "Alava",
        selected: false
    },
    {
        id: "02",
        name: "Albacete",
        selected: false
    },
    {
        id: "03",
        name: "Alicante",
        selected: false
    },
    {
        id: "04",
        name: "Almería",
        selected: false
    },
    {
        id: "05",
        name: "Ávila",
        selected: false
    },
    {
        id: "06",
        name: "Badajoz",
        selected: false
    },
    {
        id: "07",
        name: "Islas Baleares",
        selected: false
    },
    {
        id: "08",
        name: "Barcelona",
        selected: false
    },
    {
        id: "09",
        name: "Burgos",
        selected: false
    },
    {
        id: "10",
        name: "Cáceres",
        selected: false
    },
    {
        id: "11",
        name: "Cádiz",
        selected: false
    },
    {
        id: "12",
        name: "Castellón",
        selected: false
    },
    {
        id: "13",
        name: "Ciudad Real",
        selected: false
    },
    {
        id: "14",
        name: "Córdoba",
        selected: false
    },
    {
        id: "15",
        name: "La Coruña",
        selected: false
    },
    {
        id: "16",
        name: "Cuenca",
        selected: false
    },
    {
        id: "17",
        name: "Gerona",
        selected: false
    },
    {
        id: "18",
        name: "Granada",
        selected: false
    },
    {
        id: "19",
        name: "Guadalajara",
        selected: false
    },
    {
        id: "20",
        name: "Guipúzcoa",
        selected: false
    },
    {
        id: "21",
        name: "Huelva",
        selected: false
    },
    {
        id: "22",
        name: "Huesca",
        selected: false
    },
    {
        id: "23",
        name: "Jaén",
        selected: false
    },
    {
        id: "24",
        name: "León",
        selected: false
    },
    {
        id: "25",
        name: "Lérida",
        selected: false
    },
    {
        id: "26",
        name: "La Rioja",
        selected: false
    },
    {
        id: "27",
        name: "Lugo",
        selected: false
    },
    {
        id: "28",
        name: "Madrid",
        selected: false
    },
    {
        id: "29",
        name: "Málaga",
        selected: false
    },
    {
        id: "30",
        name: "Murcia",
        selected: false
    },
    {
        id: "31",
        name: "Navarra",
        selected: false
    },
    {
        id: "32",
        name: "Orense",
        selected: false
    },
    {
        id: "33",
        name: "Asturias",
        selected: false
    },
    {
        id: "34",
        name: "Palencia",
        selected: false
    },
    {
        id: "35",
        name: "Las Palmas",
        selected: false
    },
    {
        id: "36",
        name: "Pontevedra",
        selected: false
    },
    {
        id: "37",
        name: "Salamanca",
        selected: false
    },
    {
        id: "38",
        name: "Santa Cruz de Tenerife",
        selected: false
    },
    {
        id: "39",
        name: "Cantabria",
        selected: false
    },
    {
        id: "40",
        name: "Segovia",
        selected: false
    },
    {
        id: "41",
        name: "Sevilla",
        selected: false
    },
    {
        id: "42",
        name: "Soria",
        selected: false
    },
    {
        id: "43",
        name: "Tarragona",
        selected: false
    },
    {
        id: "44",
        name: "Teruel",
        selected: false
    },
    {
        id: "45",
        name: "Toledo",
        selected: false
    },
    {
        id: "46",
        name: "Valencia",
        selected: false
    },
    {
        id: "47",
        name: "Valladolid",
        selected: false
    },
    {
        id: "48",
        name: "Vizcaya",
        selected: false
    },
    {
        id: "49",
        name: "Zamora",
        selected: false
    },
    {
        id: "50",
        name: "Zaragoza",
        selected: false
    },
    {
        id: "51",
        name: "Ceuta",
        selected: false
    },
    {
        id: "52",
        name: "Melilla",
        selected: false
    }
      
];

export const getProvinceArrEsp=  ()=>{
     provinceArrEsp.forEach((data_)=>{
        data_.selected= false
    })
    return provinceArrEsp // array con los datos segun filtro si estan seleccionados o no 
}
