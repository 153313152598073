import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import "../styles/dropZone.css"

export default function DropZone({prompText,handleFile,acceptedFormats,className,promtImage}) {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
        handleFile(file)
      const reader = new FileReader()

    //   reader.onabort = () => console.log('file reading was aborted')
    //   reader.onerror = () => console.log('file reading has failed')
    //   reader.onload = () => {
    //   // Do whatever you want with the file contents
    //     const binaryStr = reader.result
    //     console.log(binaryStr)
    //   }
    //   reader.readAsArrayBuffer(file)
    })
    
  }, [])
  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <div className={`${className} dropZone`} {...getRootProps()}>
      <input accept={acceptedFormats} {...getInputProps()} />
      {prompText ? (<p>{prompText}</p>):(<img width={20} height={20} src={promtImage}/>)}
    </div>
  )
}