import React, { useState, useEffect } from "react";
import MainModal from "./MainModal";
import Parse from "parse";
import '../../styles/modal/edit-vertical-woller-modal.css';

import { apiId, jsKey, urlServer  } from '../../constants/credentials';

const EditBusinessWollerModal = props => { 

    const [business, setBusiness] = useState(undefined);
    const [businessId, setBusinessId] = useState(undefined);


    useEffect(()=>{
        getAllBusiness();
    },[])

    const getAllBusiness = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let res = await Parse.Cloud.run("getAllBusiness", {});

        setBusiness(res);
    }

    const setWollerBusiness = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let res = await Parse.Cloud.run("setWollerBusiness", {userId: props.userId.id, businessId: businessId});

        props.setUserBusiness(res.get("Business"))
    }

    const handleChange = async (e) => {
        setBusinessId(e.target.value);
    }

    return (
        <MainModal
            style={{ height: "350px", width: "700px" }}
            title={"Editar Business del woller"}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        >
            <p style={{margin: "40px 0px 20px 0px"}}>Selecciona el Business que le quieres asignar a {props.name}</p>
            <select name="select" onChange={handleChange} style={{padding: "5px"}}>
                {
                    business?.map( option => (
                        <option value={option.id}>{option.get("Name")}</option>
                    ))
                }
            </select>
            <button className="woller-bussines-button" onClick={() => setWollerBusiness()}>Guardar</button>
        </MainModal>
    )
}

export default EditBusinessWollerModal;
