export const levelArr = [
    {
        id: "all",
        name: "Todos",
        selected: false
    },
    {
        id: "1",
        name: "Bronce",
        selected: false
    },
    {
        id: "2",
        name: "Plata",
        selected: false
    },
    {
        id: "3",
        name: "Oro",
        selected: false
    },
    {
        id: "4",
        name: "Platino",
        selected: false
    }
];
