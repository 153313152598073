export const optionsArray = [
    {
        id: "search",
        name: "Busqueda",
        selected: false
    },
    {
        id: "predefined",
        name: "Predefinidos",
        selected: false
    }
];
