import React from "react";
import { useStateValue } from "../../../state/context";

import { Loading } from "jobin-ui-components";
import JobCell from "../../JobCell";
import JobSearchNav from "./JobSearchNav";

import "../../../styles/search/detail/job-detail-history.css";

export default function Jobs(props) {
    const [{ userDetails }] = useStateValue();
    const [data] = useStateValue();

    const style = {
        loadingView: {
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 24
        }
    };

    const goToJob = id => {
        props.history.push({
            pathname: "/job/" + id,
            state: {
                inner: true
            }
        });
    };

    return data.user.permissions && (
        <div className="businessDetailColumn">
            <p className="near-jobs-main-title">Trabajos comprados</p>
            <JobSearchNav biPro={props.biPro}/>
            {userDetails.jobsLoading ? (
                <div style={style.loadingView}>
                    <Loading />
                </div>
            ) : (
                userDetails.jobs.length > 0 && userDetails.jobs.map(item => {
                    return (
                        <JobCell
                            key={item.id}
                            biPro={props.biPro}
                            job={item}
                            permissions={data.user.permissions}
                            onClick={() => goToJob(item.id)}
                            style={{
                                marginTop: 24,
                                marginRight: 24,
                                marginLeft: 24
                            }}
                        />
                    );
                })
            )}
        </div>
    )
}
