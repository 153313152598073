import React from "react";
import { useStateValue } from "../../../state/context";
import { Link } from "react-router-dom";

import "../../../styles/job/service-item.css";

export default function CustomServiceItem(props) {
    const [{ job }, dispatch] = useStateValue();

    const clickHandler = e => {
        dispatch({
            type: "JOB_CATEGORY_GUILD_ID_TYPE",
            categoryId: e.target.getAttribute("dataid"),
            categoryType: e.target.getAttribute("datatype")
        });
    };

    return (
        <div className="service-item custom">
            <div className="title-container">
                <h3>¿No encuentras lo que necesitas?</h3>
                <p>Selecciona lo que sea, intentaremos cubrir tu necesidad.</p>
            </div>
            <div className="img-container">
                <img
                    src={props.service.get("Icon").url()}
                    alt={props.service.get("Type")}
                />
            </div>
            <div className="excerpt">
                <h4 className="title">
                    <Link
                        to={{
                            pathname: "/job/new/location",
                            search: job.location.search,
                            state: {
                                inner: true
                            }
                        }}
                        dataid={props.service.id}
                        datatype={props.service.get("Type")}
                        onClick={clickHandler}
                    >
                        {props.service.get("Type")}
                    </Link>
                </h4>
            </div>
        </div>
    );
}
