import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { useStateValue } from "../../../state/context";
import { Link } from "react-router-dom";
import { apiServer } from "../../../constants/credentials";

import {SearchBar} from 'jobin-ui-components'

import "../../../styles/job/search-service.css";

export default function SearchService() {
    const [{ job }, dispatch] = useStateValue();

    const [query, setQuery] = useState("");
    const [results, setResults] = useState([]);
    const [resultsVisible, setResultsVisible] = useState(false);
    const [timer, setTimer] = useState(0);

    const searchQuery = async query => {
        if (query !== "") {
            try {
                const result = await Jobin.Guild.searchWithString(
                    apiServer,
                    query,
                    "eVxqt79FQMFtAgMN8XfZ9O1yiWg0AY7b",
                    true
                );
                if (result.status === "ok") {
                    setResults(result.result ? result.result : []);
                    setResultsVisible(true);
                } else {
                    setResults([]);
                    setResultsVisible(false);
                }
            } catch (error) {
                setResults([]);
                setResultsVisible(false);
            }
        } else {
            setResults([]);
            setResultsVisible(false);
        }
    };

    const changeHandler = e => {
        const value = e.target.value;
        setQuery(value);

        // check if query contains a word to search, a word is meassured if the query contains spaces
        if (value.split(" ").length > 1) searchQuery(value);

        // search every 300ms
        if (timer) clearTimeout(timer);
        setTimer(
            setTimeout(() => {
                searchQuery(value);
            }, 300)
        );
    };

    const focusHandler = () => {
        if (results.length > 1) setResultsVisible(true);
    };

    const clickHandler = e => {
        dispatch({
            type: "JOB_CATEGORY_GUILD_ID_TYPE",
            categoryId: e.target.getAttribute("dataid"),
            categoryType: e.target.getAttribute("datatype")
        });
    };

    const bodyClickHandler = e => {
        if (!e.target.classList.contains("no-hide")) setResultsVisible(false);
    };

    useEffect(() => {
        document.body.addEventListener("click", bodyClickHandler, false);

        // unmount
        return () => {
            document.body.removeEventListener("click", bodyClickHandler, false);
        };
    }, []);

    return (
        <div className="search-service">
            <SearchBar
                placeholder="Busca el servicio"
                value={query}
                onChange={changeHandler}
                onFocus={focusHandler}
            />
            {resultsVisible && (
                <ul className="query-results">
                    {results.map((result, index) => {
                        return (
                            <li key={result.id + index} className="no-hide">
                                <Link
                                    to={{
                                        pathname: `new/category/${result.id}`,
                                        search: job.location.search,
                                        state: {
                                            inner: true
                                        }
                                    }}
                                    dataid={
                                        result.fatherId
                                            ? result.fatherId
                                            : result.id
                                    }
                                    datatype={
                                        result.fatherName
                                            ? result.fatherName
                                            : result.name
                                    }
                                    onClick={clickHandler}
                                    className="no-hide"
                                >
                                    {result.name}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
}
