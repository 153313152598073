import React, {useState, useEffect} from 'react';
import GenericPdfDownloader from "./GenericPdfDownloader";
import "../../styles/pdfConstructor.css"
import {
    getAddressFromJob,
    getLMPDFData
} from "../../utils/ParserUtilities";
import {
    formatDateWhitoutHour,
    getFormattedAddressDetail
} from '../../utils/formatter';

const PdfConstructor = () => {
    const [jobData, setJobData] = useState({});
    const [addressDetail, setAddressDetail] = useState();

    useEffect(()=> {
        getData();
    },[]);

    const getData = async () => {
        let url = window.location.href.split('pdf-constructor/');
        let res = await getAddressFromJob(url[1]);
        let newJobData = await getLMPDFData(url[1]);
        setJobData(newJobData);

        if (res) {
            setAddressDetail((getFormattedAddressDetail(res)).replace("undefined", ""));
        } else {
            setAddressDetail("-");
        }
    }

    return(
        <div className='pdf-wrapper'>
            <GenericPdfDownloader 
                downloadFileName="CustomPdf" 
                rootElementId="testId" 
            />
            <div id="testId" className='pdf-constructor'> 
                <div className='pdf-constructor-images-wrapper'>
                    <img className='pdf-constructor-logo' src={require("../../assets/pdfConstructor/ACR.jpg")}/>
                    <img className='pdf-constructor-logo' src={require("../../assets/wolly-logo-billing.png")}/>
                </div>

                <div className='pdf-constructor-box-wrapper'>
                    <p className='pdf-constructor-green-title'>Número de Servicio</p>
                    <p className='pdf-constructor-white-title mr-20'>{jobData.order ? jobData.order : ""}</p>
                    <p className='pdf-constructor-parte'>PARTE DE FINALIZACIÓN DEL SERVICIO</p>
                </div>
                <div className='pdf-constructor-box-wrapper'>
                    <p className='pdf-constructor-green-title'>Orden Trabajo</p>
                    <p className='pdf-constructor-white-title mr-20'>{jobData.jobDescription ? jobData.jobDescription.substring(0,11) : ""}</p>
                    <p className='pdf-constructor-green-title'>Fecha de emisión</p>
                    <p className='pdf-constructor-white-title'>{jobData.imagesNotification ? formatDateWhitoutHour(jobData.imagesNotification.date.toString()) : ""}</p>
                </div>

                <p className='pdf-constructor-big-green-title'>Datos del cliente</p>
                <div className='pdf-constructor-box-wrapper'>
                    <p className='pdf-constructor-green-title'>Nombre del cliente</p>
                    <p className='pdf-constructor-white-title mr-20'>{jobData.clientName ? jobData.clientName : ""}</p>
                    <p className='pdf-constructor-green-title'>Telf del cliente</p>
                    <p className='pdf-constructor-white-title'>{jobData.clientPhone ? jobData.clientPhone : ""}</p>
                </div>
                <div className='pdf-constructor-box-wrapper'>
                    <p className='pdf-constructor-green-title'>Dirección</p>
                    <p className='pdf-constructor-white-big-title'>{addressDetail}</p>
                </div>

                <p className='pdf-constructor-big-green-title'>Descripción de los trabajos</p>
                <p className='pdf-constructor-white-ultra-title'>{jobData.jobDescription ? jobData.jobDescription : ""}</p>

                <p className='pdf-constructor-big-green-title'>Conforme cliente</p>
                <div className='pdf-constructor-client-wrapper'>
                    <p className='pdf-constructor-little-p'>El objeto de la firma de este parte es dejar constancia de la conformidad
                        del Cliente a la ejecución de los servicios contratados con la promotora
                        reflejados en este documento en las fechas asimismo indicadas, sin
                        perjuicio de las posibles observaciones que se puedan realizar en el
                        momento de su firma. En ningún caso la firma de este documento
                        sustituye ni limita los derechos reconocidos al Cliente en virtud de la
                        normativa de consumidores y usuarios vigente (Real Decreto Ley /2021,
                        de 27 de abril y otras leyes complementarias o norma que lo sustituya).</p>
                        <img src={jobData.imagesNotification ? jobData.imagesNotification.signature ? jobData.imagesNotification.signature : "" : ""} className="lm-photo-signature"/>
                </div>

                <p className='pdf-constructor-big-green-title'>Conforme Profesional </p>
                <div className='pdf-constructor-woller-wrapper'>
                    <p className='pdf-constructor-woller-p'>Fecha de firma: {jobData.imagesNotification ? formatDateWhitoutHour(jobData.imagesNotification.date.toString()) : ""}</p>
                    <img src={jobData.imagesNotification ? jobData.imagesNotification.signaturePro ? jobData.imagesNotification.signaturePro : "" : ""} className="lm-photo-signature"/>
                </div>
                <p className='pdf-constructor-footer-bold-p'>Protección de datos de caracter personal</p>
                <p className='pdf-constructor-footer-p'>
                    RESPONSABLE DE TRATAMIENTO: ACR, S.L.U. ("ACR") / FINALIDAD: Verificar, evidenciar y documentar la completa
                    ejecución de los servicios contratados por él Cliente en la promoción / LEGITIMACIÓN: Ejecución de un contrato solicitado por el Cliente /
                    DESTINATARIOS: No se comunicarán datos a terceros, salvo a prestadores de servicios de la promoción autorizada. (Encargados del
                    Tratamiento ) o en el supuesto de una obligación legal | / DERECHOS: Acceso, rectificación, supresión y oposición, entre otros, en la
                    Dirección: privacidad@acr.es / INFORMACIÓN ADICIONAL: Puede consultar nuestra Política de Privacidad en: https://acr.es/politica-de-privacidad/
                </p>

            </div>
        </div>
    )
}

export default PdfConstructor;

