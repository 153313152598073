export function getCookie(name) {
    let cookie = {};
    document.cookie.split(";").forEach(el => {
        const [k, v] = el.split("=");
        cookie[k.trim()] = v;
    });
    return cookie[name];
}

export function deleteCookie(name) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    return true;
}
