import React, {  useState,useEffect } from "react";
import {Loading} from 'jobin-ui-components'
import Lottie from 'react-lottie'
import * as cardSpinnerData from "../../assets/animations/button-loading.json";
import FilterSingleSelection from "../filter/FilterSingleSelection";
import { elementOf } from "../../utils/formatter";
import fetchBusinesses from "../../constants/businesses";
import fetchUsers  from "../../constants/user/fetchUsers";
import AlertModal from "../modal/Alert";
import PasswordModal from "../modal/PasswordModal";
import { useStateValue } from "../../state/context";
import {GetAllAgents,changeAgentParams,DeleteAgent} from "../../utils/ParserUtilities"

export default function ResultDashboard(props) {
    const [{agentsSearch},dispatch] = useStateValue();
    const [agents, setAgents] = useState([]);
    const [userName, setUserName] = useState([]);
    const [businessName, setBusinessName] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingAgent, setLoadingAgent] = useState([]);
    const [Businesses, setBusinesses] = useState([]);
    const [Users, setUsers] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [passwordDialog, setPasswordDialog] = useState(false);
    const [currentAgentId, setCurrentAgentId] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(false);
    // const [businessName, setBusinessName] = useState([]);
    const [wollerName, setWollerName] = useState([]);

    useEffect(()=>{
        async function  fetchAsync(){
            let Businesses_ = await fetchBusinesses([],true)
            let Users_ = await fetchUsers([],true)
            setBusinesses(Businesses_.filter((element)=>element.id!="all"))
            setUsers(Users_.filter((element)=>element.id!="all"))
            loadAgents()
            

        }
        fetchAsync()
    },[])
    useEffect(()=>{
        if(agentsSearch.refresh){
            loadAgents()
        }
        
    },[agentsSearch.refresh])

    const loadAgents = async ()=>{
        setLoading(true)
        let agents_ = await GetAllAgents();
        let agentPromise_ = agents_.map(async (agent)=>{
            return {
                id:agent.id,
                name:agent.get("Name"),
                lastname:agent.get("Lastname"),
                userName:agent.get("email"),
                dept:agent.get("Department"),
                permissions:agent.get("Permissions"),
                user:agent.get("User")? {id:agent.get("User").id,name:agent.get("User").get("Name")}:undefined,
                business:agent.get("Business")? {id:agent.get("Business").id,name:agent.get("Business").get("Name")}:undefined,
                isActive:agent.get("Active"),                
                password:null
            }
        })
        
        agents_ =  await Promise.all([...agentPromise_]).then((agent)=>{
            return agent
        })
        
        setAgents(agents_)
                   
       let loadingsAgents = Array(agents_.length).fill(false)
       let deptNames =agents_.map((agent)=>{
           if(agent.dept != null){
               return agent.dept
           }else{
               return "Departamento"
           }
       })
       let userNames =agents_.map((agent)=>{
           if(agent.user != null){
               
               return agent.user.name
           }else{
               return "Usuario"
           }
       })
       let businessNames =agents_.map((agent)=>{
           if(agent.business != null){
               return agent.business.name
           }else{
               return "Negocio"
           }
       })
       setUserName(userNames)
       setBusinessName(businessNames)
       setLoadingAgent(loadingsAgents)
       setLoading(false)
       dispatch({
            type: "SEARCH_AGENT_REFRESH",
            refresh: false
        });
    }
    const styles = {
        select: {
             width: "200px",
             height: "35px",
             padding: "5px",
             borderRadius: "5px",
             border: "transparent",
             boxShadow: "rgb(0 0 0 / 30%) 0px 0px 10px 3px",
             margin: "15px",
         },
         permisionsButton: {
             width: "180px",
             height: "35px",
             padding: "5px",
             borderRadius: "5px",
             border: "transparent",
             boxShadow: "rgb(0 0 0 / 30%) 0px 0px 10px 3px",
             margin: "15px",
         },
        
         subTitle:{
             fontWeight: "700", 
         },
         noRecordsDiv:{
             margin:"50px 0"
         },
         noRecordsTitle:{
             fontWeight: "700", 
             fontSize: "18px",
         },
         activateButton:{
             height: "35px ",
             width: "180px ",
             margin:"0 7.5px",
             backgroundColor:" black ",
             border: "2px solid black  ",
             color:"var(--coral) ",
             borderRadius: "8px",
             transition: "all 0.5s",
             cursor:"pointer"
 
         },
         activatedButton:{
             padding: "0px",
             width: "180px",
             margin:"0 7.5px",
             backgroundColor:"#1536ff",
             color:"white",
             height: "35px",
             borderRadius: "5px",
             border: "transparent",
             boxShadow: "rgb(0 0 0 / 30%) 0px 0px 10px 3px",
             transition: "all 0.5s",
             cursor:"pointer"
 
         },
         addAgent:{
             color:"#3543FF",
             cursor:"pointer"
         },
         addAgentSimbol:{
             color: "white",
             background: "black",
             padding: "3px 6px",
             borderRadius: "50px",
             fontWeight: 100,
             fontSize: "11px",
             cursor:"pointer"
         },
         deleteAgentSimbol:{
             color: "#fff",
             background: "red",
             padding: "2px 8px",
             borderRadius: "50px",
             fontWeight: "bold",
             fontSize: "14px",
             cursor:"pointer",
             marginLeft: "15px",
             
         },
         buttonFilter:{
             background:"white",
             padding:"10px",
             width:"180px",
             border: "#0000",
             boxShadow: "rgb(0 0 0 / 30%) 0px 0px 10px 3px",
             color:"#000"
         },
         agentsContainer:{
            marginTop:0,
            padding:"85px 20px 20px ",
            display:"flex",
            flexDirection:"row"
         },
         input:{
            width: '90%',
            padding: '5px',
            border: '1px solid #E3E3E3',
            borderRadius: '4px',
            margin:"0 7.5px",
            height:"37px",
            boxShadow: "rgb(0 0 0 / 30%) 0px 0px 10px 3px",
            
         },
         tableHead:{
            fontSize: "18px",
            width:"150px",
            marginLeft:"15px"
         },
         goldRow:{
             background:"#ffd70038"
         },
         b2bRow:{
             background:"#1536ff38"
         },
         tableCell:{
             minWidth:"200px",
             border: "1px transparent ",
             borderCollapse:" collapse"
         },
         cellDelete:{
             minWidth:"50px",
             border: "1px transparent ",
             borderCollapse:" collapse"
         },
         tableRow:{
            height:"55px"
         },
         table:{
             width:"100%"
         },
         tableResults:{
            border: "1px transparent ",
            borderCollapse:" collapse"
         },
         singleSelector:{
            margin:"0 7.5px",
         } 
     }
     const defaultOptions = {
         loop: true,
         autoplay: true,
         animationData: cardSpinnerData.default,
         rendererSettings: {
             preserveAspectRatio: "xMidYMid slice"
         }
     }
    const preProcessData= (selectedData_,allData_)=>{
        if(selectedData_?.length >0 && allData_.length >0 ){
            const arr = allData_.map(data => {
                return {
                    id: data.id,
                    name: data.name,
                    selected:
                        elementOf(data.id, selectedData_)
                };
            });
            return arr // array con los datos segun filtro si estan seleccionados o no  
        }else{
            const arr = [...allData_]
            return arr // array con los datos segun filtro si estan seleccionados o no 
        }
        
    }
    const activate = async (agentIndex)=>{
        let agents_ = agents
        let isLoadingAgent = loadingAgent 
        isLoadingAgent[agentIndex] = true
       agents_[agentIndex].isActive = !agents_[agentIndex].isActive
        setLoadingAgent([...isLoadingAgent]) 
        let currentAgent =agents_[agentIndex]
        let agentEdited = await changeAgentParams(currentAgent.id, null, currentAgent.isActive ,null,null,null,null,null,null,null)
        setAgents([...agents_])
        isLoadingAgent[agentIndex] = false
        setLoadingAgent([...isLoadingAgent]) 
        if(!agentEdited){
    
            setLoading(false)
            console.error(agentEdited)
            alert("Hubo un problema al activar esta regla")
        }
    }
    const changeAgentParam = async (dataSelected,itemIndex,type)=>{
        let isLoadingAgent = loadingAgent
        isLoadingAgent[itemIndex] = true
        setLoadingAgent([...isLoadingAgent]) 

        let currentData = agents[itemIndex]
        
        switch (type) {
            case "Lastname":
                currentData.lastname= dataSelected

                break;
            case "Active":
                currentData.isActive= dataSelected
            
                break;
            case "hashedPassword":
                currentData.password= dataSelected
                setPasswordDialog(false)
            
                break;
            case "Name":
                currentData.name= dataSelected
            
                break;
            case "Department":
                currentData.dept= dataSelected
            
                break;
            case "email":
                currentData.userName= dataSelected
            
                break;
            case "Business":
                currentData.business= dataSelected.id
                let businessNames_ = businessName
                businessNames_[itemIndex] =dataSelected.name
                setBusinessName([...businessNames_]) 

            
                break;
            case "User":
                currentData.user= dataSelected.id
                let userNames_ = userName
                userNames_[itemIndex] =dataSelected.name
                setUserName([...userNames_]) 

            
                break;
            case "Permissions":
                currentData.permissions= dataSelected
                
                break;
                
            default:
                break;
        }
        let allAgents = agents
        allAgents[itemIndex]= currentData
        setAgents(allAgents)
         let result = await changeAgentParams(currentData.id,
                                              currentData.lastname,
                                              currentData.isActive,
                                              currentData.password,
                                              currentData.name,
                                              currentData.dept,
                                              currentData.userName,
                                              currentData.business,
                                              currentData.user,
                                              currentData.permissions)
        if(result){
            isLoadingAgent[itemIndex] = false
            setLoadingAgent([...isLoadingAgent]) 
        }
        
    }
    const preDeleteAgent=(agent,index)=>{
        let isLoadingAgent = loadingAgent 
        isLoadingAgent[index] = true
        setLoadingAgent([...isLoadingAgent]) 
        setConfirmDialog(true)
        setCurrentAgentId(agent.id)
        setCurrentIndex(index)
        
    }
    const showModalPassword = async(agent,index)=>{
        let isLoadingAgent = loadingAgent 
        isLoadingAgent[index] = true
        setLoadingAgent([...isLoadingAgent]) 
        setPasswordDialog(true)
        setCurrentAgentId(agent.id)
        setCurrentIndex(index)
    }
    const deleteAgent = async (agentId,index,agents_,deleteAgent_,setLoadingAgent_,setAgents_)=>{
        await deleteAgent_(agentId)
        let isLoadingAgent = loadingAgent 
        agents_ =agents_.filter((agent_,index_)=> index !== index_)
        isLoadingAgent = isLoadingAgent.filter((loadingAgent,index_)=> index !== index_)
        setLoadingAgent_([...isLoadingAgent]) 
        setAgents_(agents_)
    }
    
    return loading ? (
        <section style={{minHeight:"100vh"}} className=" display-flex flex-align-center flex-justify-center">
            <Loading />
        </section>
    ):(<>
        {confirmDialog && (
            <AlertModal
                title={"¿Estas seguro que deseas eleminar este agente?"}
                subtitle={`${agents[currentIndex].name} ${agents[currentIndex].lastname}`}
                isVisible={confirmDialog}
                onClose={() => {
                    
                    setConfirmDialog(false);
                }}
                onClick={()=>{deleteAgent(currentAgentId,currentIndex,agents,DeleteAgent,setLoadingAgent,setAgents);setConfirmDialog(false);}}
                buttonText={"Eliminar"}
            />
        )}
        {passwordDialog && (
            <PasswordModal
                big={true}
                title={"Cambiar Contraseña"}
                subtitle={`La nueva contraseña debe contener caracteres especiales (!"#$%&/()='?¿¡), letras (a-z, A-Z) numeros (0-9) y debe tener una longitud minima de 8 caracteres`}
                isVisible={passwordDialog}
                onClose={() => {
                    setPasswordDialog(false);
                }}
                lottieLoad={()=>{
                    let isLoadingAgent = loadingAgent 
                    isLoadingAgent[currentIndex] = false
                    setLoadingAgent([...isLoadingAgent])}
                }
                functionParam={changeAgentParam}
                buttonText={"Aceptar"}
                itemType={"hashedPassword"}
                itemIndex={currentIndex}
            />
        )}
        {/* {permisionsModal && (
            <PermisionsModal
                big={true}
                title={"Cambiar Permisos de la cuenta"}
                isVisible={permisionsModal}
                onClose={() => {
                    setPermisionsModal(false);
                }}
                lottieLoad={()=>{
                    let isLoadingAgent = loadingAgent 
                    isLoadingAgent[currentIndex] = false
                    setLoadingAgent([...isLoadingAgent])}
                }
                functionParam={changeAgentParam}
                buttonText={"Aceptar"}
                itemType={"Permissions"}
                itemIndex={currentIndex}
                agent={currentAgent}

            />
        )} */}
        <div style={styles.agentsContainer}>
           {agents.length>0 ? (
                <>
                    <table style={styles.table}>
                        <thead>
                            <tr >
                                <th style={styles.tableCell}>Nombre</th>
                                <th style={styles.tableCell}>Apellido</th>
                                <th style={styles.tableCell}>Usuario/correo</th>
                                <th style={styles.tableCell}>Usuario</th>
                                <th style={styles.tableCell}>Negocio</th>
                                <th style={styles.tableCell}></th>
                                <th style={styles.tableCell}></th>
                                <th style={styles.cellDelete}></th>
                            </tr>
                        </thead>
                        <tbody>
                        { agents.map((agent,index)=>{
                            return(
                                <tr key={agent.id} style={styles.tableRow}>
                                    <td style={styles.tableCell}>
                                        <input
                                        className="textInput"
                                        type="text" 
                                        style={styles.input} 
                                        value={agent.name }
                                        name="name"
                                        onChange={(e)=>changeAgentParam(e.target.value,index,"Name")}
                                        ></input>
                                    </td>
                                    <td style={styles.tableCell}>
                                        <input
                                        className="textInput"
                                        type="text" 
                                        style={styles.input} 
                                        value={agent.lastname}
                                        name="lastname"
                                        onChange={(e)=>changeAgentParam(e.target.value,index,"Lastname")}
                                        ></input>
                                    </td>
                                    <td style={styles.tableCell}>
                                        <input
                                        className="textInput"
                                        type="text" 
                                        style={styles.input} 
                                        value={agent.userName}
                                        name="userName"
                                        onChange={(e)=>changeAgentParam(e.target.value,index,"email")}
                                        ></input>
                                    </td>
                                    
                                    <td style={styles.tableCell}>
                                        <FilterSingleSelection
                                            style={styles.singleSelector}
                                            styleMenu={{ maxHeight:"500px"}}
                                            type="User"
                                            title={userName[index]}
                                            items={ preProcessData(agent.user,Users)}
                                            selectionChange={changeAgentParam}
                                            styleButton={styles.buttonFilter}
                                            itemData={index}
                                            idElement={agent.id}
                                        />  </td>
                                    <td style={styles.tableCell}> 
                                        <FilterSingleSelection
                                            style={styles.singleSelector}
                                            styleMenu={{ maxHeight:"500px"}}
                                            type="Business"
                                            title={businessName[index]}
                                            items={ preProcessData(agent.business,Businesses)}
                                            selectionChange={changeAgentParam}
                                            styleButton={styles.buttonFilter}
                                            itemData={index}
                                            idElement={agent.id}
                                        /> 
                                    </td>          
                                    <td style={styles.tableCell}> 
                                        <button
                                            style={styles.permisionsButton}
                                            onClick={()=>{showModalPassword(agent,index)}}
                                        >Contraseña
                                        </button>
                                    </td>          
                                    
                                    <td style={styles.tableCell}> 
                                        <button
                                    style={loadingAgent[index] ? styles.activateButton : agent.isActive ? styles.activatedButton:styles.activateButton}
                                    onClick={()=>{activate(index)}}
                                    >
                                        { loadingAgent[index] ?  (<Lottie
                                                style={{height: 30, width: 80}}
                                                options={defaultOptions}
                                            />):agent.isActive ? "Activo":"Inactivo"}
                                </button>
                                    </td>          
                                    <td style={styles.cellDelete}> 
                                        <span onClick={()=>preDeleteAgent(agent,index)} style={styles.deleteAgentSimbol}>&times;</span>
                                    </td>          
                                </tr>)
                                })}
                        </tbody>
                    </table>           
                </>
            ):(
                (<div style={styles.noRecordsDiv}>
                    <p  style={styles.noRecordsTitle}>No hay agentes registrados o que coincidan con tus parametros de busqueda</p>
                </div>)
           )}
        </div>
        </>)
}
