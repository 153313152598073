export const paymentTypesArr = [
    {
        id: "all",
        name: "Todos",
        selected: false
    },
    {
        id: "pack",
        name: "Pack",
        selected: false
    },
    {
        id: "subscription",
        name: "Suscripción",
        selected: false
    },
    {
        id: "jobinPay",
        name: "Comisión Jobin Pay",
        selected: false
    },
    {
        id: "fixed",
        name: "Precio cerrado",
        selected: false
    },
    {
        id: "transportation",
        name: "Transporte",
        selected: false
    },
    {
        id: "payoff",
        name: "Liquidación empresas",
        selected: false
    },
    {
        id: "joberPayoff",
        name: "Liquidación Wollers",
        selected: false
    },
    {
        id: "custom",
        name: "Otros",
        selected: false
    }
];
