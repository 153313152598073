import React, { useEffect, useRef, useState } from "react";
import Jobin from "jobin-client";
import moment from "moment";
import { paymentMethodsArr } from '../../constants/billing/paymentMethods'
import { paymentTypesArr } from '../../constants/billing/paymentTypes'
import { paymentStatesArr } from '../../constants/billing/paymentState'
import { paymentPriceSignArr } from '../../constants/billing/paymentPriceSign'
import { provinceArr } from '../../constants/provinces'
import fetchBusinesses from "../../constants/businesses";

import {SearchBar} from 'jobin-ui-components'
import ButtonImage from '../button/ButtonImage'
import ButtonEmpty from "../../components/button/ButtonEmpty";
import FilterDate from "../filter/FilterDate";
import FilterMultipleSelection from '../../components/filter/FilterMultipleSelection'

import { useStateValue } from "../../state/context";

import "../../styles/search/search-nav.css";

export default function SearchNav(props) {

    const [{ billingSearch, user }, dispatch] = useStateValue();
    const [businessArray, setBusinessArray] = useState([]);
    const [paymentStates, setPaymentStates] = useState([]);

    const csvRef = useRef(null);

    const styles = {
        above:{
            borderBottom: '1px solid var(--soft-grey)',
            paddingTop: 16,
            paddingBottom: 8,
        }
    }

    useEffect(()=> {
        fetchBusinesses(
            billingSearch.businessIdArray.map(id => ({
                id: id,
                selected: true
            }))
        ).then(setBusinessArray);

        let paymentStates = JSON.parse(JSON.stringify(paymentStatesArr))
        paymentStates.find(item => item.name == "Pagado").selected = true;
        setPaymentStates(paymentStates)
    }, [])

    useEffect(() => {
        getPayments();
    }, [
        billingSearch.startDate,
        billingSearch.endDate,
        billingSearch.methodArray,
        billingSearch.typeArray,
        billingSearch.stateArray,
        billingSearch.signArray,
        billingSearch.provinces,
        billingSearch.businessIdArray,
        billingSearch.id
    ]);

    const getPayments = async () => {
        dispatch({
            type: "BILLING_SEARCH_LOADING",
            loading: true
        });

        const result = await Jobin.Payment.getPayments({
            startDate: billingSearch.startDate,
            endDate: billingSearch.endDate,
            methodArray: billingSearch.methodArray,
            typeArray: billingSearch.typeArray,
            stateArray: billingSearch.stateArray,
            postalPrefixes: billingSearch.provinces,
            signArray: billingSearch.signArray, //[ "positive" | "negative" ]
            businessIdArray: billingSearch.businessIdArray,
            id: billingSearch.id,
        });
        if(result.status === 'ok'){
            dispatch({
                type: "BILLING_SEARCH",
                items: result.result
            });
        }
        dispatch({
            type: "BILLING_SEARCH_LOADING",
            loading: false
        });
    }

    const inputOnKeyPress = e => {
        const keyCode = e.keyCode || e.which;
        const input = e.target.value;
        if (keyCode === 13) {
            dispatch({
                type: "BILLING_SEARCH_FILTER",
                id: input
            });
        }
    };


    const downloadPaymentsCSV = async () => {
        let csvString = await Jobin.Analytics.exportPaymentsCsv({
            startDate: billingSearch.startDate,
            endDate: billingSearch.endDate,
            methodArray: billingSearch.methodArray,
            typeArray: billingSearch.typeArray,
            stateArray: billingSearch.stateArray,
            postalPrefixes: billingSearch.provinces,
            signArray: billingSearch.signArray, //[ "positive" | "negative" ]
            businessIdArray: billingSearch.businessIdArray,
            id: billingSearch.id,
        });
        csvRef.current.href = `data:text/csv,${encodeURI(csvString)}`;
        csvRef.current.download = `payments-${new Date().toLocaleString('es-ES')}.csv`;
        csvRef.current.click();
    };

    return (
        <section className="search-nav">
            <div style={styles.above}>
                <div className="container-fluid">
                    <div className="col-md-6">
                        <div className="row">
                            <SearchBar
                                placeholder="Buscar por número de factura"
                                onKeyPress={inputOnKeyPress}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 text-right" style={{display:"flex", justifyContent: "flex-end"}}>
                        <ButtonImage
                            buttonText="Nueva"
                            src={require("../../assets/add.svg")}
                            onClick={() =>{
                                props.history.push({
                                    pathname: "/billing/create/",
                                    state: {
                                        inner: true
                                    }
                                });
                            }}
                            disabled={!(user.permissions && user.permissions.billing && user.permissions.billing.create)}
                        />
                    </div>
                </div>
            </div>
            <div className="below">
                <div className="below-left" style={{display:'flex', flex:1}}>
                    <FilterDate
                        style={{ marginRight: 4 }}
                        type="date"
                        title="Fecha de pago"
                        startDate={billingSearch.startDate ? moment(billingSearch.startDate) : undefined}
                        endDate={billingSearch.endDate ? moment(billingSearch.endDate) : undefined}
                        selectionChange={({startDate, endDate}) =>{
                            console.info({startDate, endDate})
                            dispatch({
                                type: "BILLING_SEARCH_FILTER",
                                startDate: startDate ? new Date(startDate) : null,
                                endDate: endDate ? new Date(endDate) : null
                            });
                        }}
                    />
                    <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="method"
                        title="Método de pago"
                        items={paymentMethodsArr}
                        selectionChange={selectionArray =>{
                            dispatch({
                                type: "BILLING_SEARCH_FILTER",
                                methodArray: selectionArray.map(item => item.id)
                            });
                        }}
                    />
                    <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="type"
                        title="Producto"
                        items={paymentTypesArr}
                        selectionChange={selectionArray =>{
                            dispatch({
                                type: "BILLING_SEARCH_FILTER",
                                typeArray: selectionArray.map(item => item.id)
                            });
                        }}
                    />
                    <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="state"
                        title="Estado"
                        items={paymentStates}
                        selectionChange={selectionArray =>{
                            dispatch({
                                type: "BILLING_SEARCH_FILTER",
                                stateArray: selectionArray.map(item => item.id)
                            });
                        }}
                    />
                    <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="priceSign"
                        title="Tipo"
                        items={paymentPriceSignArr}
                        selectionChange={selectionArray =>{
                            dispatch({
                                type: "BILLING_SEARCH_FILTER",
                                signArray: selectionArray.map(item => item.id)
                            });
                        }}
                    />
                    <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="province"
                        title="Provincia"
                        items={provinceArr}
                        selectionChange={selectionArray =>{
                            dispatch({
                                type: "BILLING_SEARCH_FILTER",
                                provinces: selectionArray.map(item => item.id)
                            });
                        }}
                    />
                    <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="business"
                        title="Negocio"
                        items={businessArray}
                        selectionChange={selectionArray =>{
                            dispatch({
                                type: "BILLING_SEARCH_FILTER",
                                businessIdArray: selectionArray.map(item => item.id)
                            });
                        }}
                    />
                </div>
                <div className="below-right">
                    <a ref={csvRef} style={{ display: "none" }} />
                    <ButtonEmpty
                        buttonText="Descargar CSV"
                        onClick={downloadPaymentsCSV}
                    />
                </div>
            </div>
        </section>
    );
}
