export const jobTypeArr = [
    {
        id: " ",
        name: "Lead",
        selected: true
    },
    {
        id: "PC-",
        name: "Precio fijo",
        selected: false
    },
    {
        id: "TR-",
        name: "Transporte",
        selected: false
    }
];