import React, { useState } from "react";
import MainModal from "./MainModal";
import {updateNJobs} from "../../utils/ParserUtilities";

const EditNJobstModal = props => {

    const [newNJobs, setNewNJobs] = useState(0);

    const saveNjobs = async () => {
        await updateNJobs(props.userDetails.user.id, parseInt(newNJobs))
        window.location.reload();
    }

    return (
        <MainModal
            style={{ height: "350px", width: "1000px" }}
            title={"Editar Saldo"}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        >  
            <p style={{marginTop: 20, fontWeight: "bold"}}>Escribe el nuevo saldo del woller:</p>
            <input onChange={ e => setNewNJobs(e.target.value)} style={{marginTop: 40, padding: 8}}/>
            <button style={{height: "37px", padding: 0, width: 110, backgroundColor: "black", border: "2px solid black", color: "var(--coral)", marginLeft: 75}} onClick={() => saveNjobs()}>
                Guardar
            </button>

        </MainModal>
    )
}

export default EditNJobstModal;