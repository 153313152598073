import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import moment from "moment";
import { useStateValue } from "../../../state/context";
import { provinceArr } from "../../../constants/provinces";
import fetchServices from "../../../constants/services";

import { SearchBar } from "jobin-ui-components";
import FilterMultipleSelection from "../../filter/FilterMultipleSelection";
import FilterDate from "../../filter/FilterDate";

export default function JobSearchNav() {
    const [{ businessDetails }, dispatch] = useStateValue();
    const [guildArr, setGuildArr] = useState([]);
    const [jobId, setJobId] = useState("PC-");

    const style = {
        searchNav: {
            paddingRight: 24,
            paddingLeft: 24,
            paddingTop: 24,
            borderBottom: "2px solid var(--soft-grey)"
        },
        filterView: {
            paddingTop: 8,
            paddingBottom: 12
        }
    };

    useEffect(() => {
        async function fetchAsync() {
            setGuildArr(await fetchServices(businessDetails.filters.services));
        }
        fetchAsync();

        fetchJobsByDate({
            startDate: businessDetails.filters.startDate,
            endDate: businessDetails.filters.endDate
        });
    }, []);

    const fetchJobsByDate = async date => {
        dispatch({
            type: "BUSINESS_JOB_LOADING",
            jobsLoading: true
        });

        const businessJobs = await Jobin.Job.getJobs(
            null,
            100,
            date && date.startDate ? date.startDate.format() : undefined,
            date && date.endDate ? date.endDate.format() : undefined,
            businessDetails.filters.provinces.length > 0
                ? businessDetails.filters.provinces.map(province => province.id)
                : undefined,
            businessDetails.filters.services.length > 0
                ? businessDetails.filters.services
                : undefined,
            undefined,
            undefined,
            undefined,
            jobId !== "" ? jobId : "PC-",
            undefined,
            undefined,
            undefined,
            undefined,
            [{ id: businessDetails.business.id }]
        );
        dispatch({
            type: "SET_JOBS_RELATED_TO_BUSINESS",
            jobsLoading: false,
            jobs: businessJobs.result.jobs,
            starDate: date && date.startDate ? moment(date.startDate) : undefined,
            endDate: date && date.endDate ? moment(date.endDate) : undefined
        });
    };

    const fetchJobsByProvince = async selection => {
        dispatch({
            type: "BUSINESS_JOB_LOADING",
            jobsLoading: true
        });

        const businessJobs = await Jobin.Job.getJobs(
            null,
            100,
            businessDetails.filters.startDate
                ? businessDetails.filters.startDate.format()
                : undefined,
            businessDetails.filters.endDate
                ? businessDetails.filters.endDate.format()
                : undefined,
            selection.map(province => province.id),
            businessDetails.filters.services.length > 0
                ? businessDetails.filters.services
                : undefined,
            undefined,
            undefined,
            undefined,
            jobId !== "" ? jobId : "PC-",
            undefined,
            undefined,
            undefined,
            undefined,
            [{ id: businessDetails.business.id }]
        );
        dispatch({
            type: "SET_JOBS_RELATED_TO_BUSINESS",
            jobsLoading: false,
            jobs: businessJobs.result.jobs,
            provinces: selection
        });
    };

    const fetchJobsByGuild = async selection => {
        dispatch({
            type: "BUSINESS_JOB_LOADING",
            jobsLoading: true
        });

        const businessJobs = await Jobin.Job.getJobs(
            null,
            100,
            businessDetails.filters.startDate
                ? businessDetails.filters.startDate.format()
                : undefined,
            businessDetails.filters.endDate
                ? businessDetails.filters.endDate.format()
                : undefined,
            businessDetails.filters.provinces.length > 0
                ? businessDetails.filters.provinces.map(province => province.id)
                : undefined,
            selection,
            undefined,
            undefined,
            undefined,
            jobId !== "" ? jobId : "PC-",
            undefined,
            undefined,
            undefined,
            undefined,
            [{ id: businessDetails.business.id }]
        );
        dispatch({
            type: "SET_JOBS_RELATED_TO_BUSINESS",
            jobsLoading: false,
            jobs: businessJobs.result.jobs,
            services: selection
        });
    };

    const fetchJobsById = async e => {
        const keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            dispatch({
                type: "BUSINESS_JOB_LOADING",
                jobsLoading: true
            });
            const businessJobs = await Jobin.Job.getJobs(
                null,
                100,
                businessDetails.filters.startDate
                    ? businessDetails.filters.startDate.format()
                    : undefined,
                businessDetails.filters.endDate
                    ? businessDetails.filters.endDate.format()
                    : undefined,
                businessDetails.filters.provinces.length > 0
                    ? businessDetails.filters.provinces.map(
                          province => province.id
                      )
                    : undefined,
                businessDetails.filters.services.length > 0
                    ? businessDetails.filters.services
                    : undefined,
                undefined,
                undefined,
                undefined,
                jobId !== "" ? jobId : "PC-",
                undefined,
                undefined,
                undefined,
                undefined,
                [{ id: businessDetails.business.id }]
            );
            dispatch({
                type: "SET_JOBS_RELATED_TO_BUSINESS",
                jobsLoading: false,
                id: jobId !== "" ? jobId : "PC-",
                jobs: businessJobs.result.jobs
            });
        }
    };

    return (
        <div style={style.searchNav}>
            <SearchBar
                style={{ display: "flex", flex: 1 }}
                placeholder="Buscar por ID"
                onKeyPress={fetchJobsById}
                value={jobId}
                onChange={e => setJobId(e.target.value)}
            />
            <div style={style.filterView}>
                <FilterDate
                    style={{ marginRight: 4 }}
                    type="date"
                    title="Fecha de creación"
                    startDate={businessDetails.filters.startDate}
                    endDate={businessDetails.filters.endDate}
                    selectionChange={fetchJobsByDate}
                />
                <FilterMultipleSelection
                    style={{ marginRight: 4 }}
                    type="province"
                    title="Provincias"
                    items={provinceArr}
                    selectionChange={fetchJobsByProvince}
                />
                <FilterMultipleSelection
                    style={{ marginRight: 4 }}
                    type="service"
                    title="Gremios"
                    items={guildArr}
                    selectionChange={fetchJobsByGuild}
                />
            </div>
        </div>
    );
}
