import React from "react";
import icon_rating from "../../../assets/icons/icon-rating.png";
import { useStateValue } from "../../../state/context";


export default function ContentBox(props) {
    const [data] = useStateValue();
    const business = !(data.user?.permissions?.job?.edit);

    if(props.isSingle, props.globalContext, props.data){
        return (
            <div className={props.isSingle ? "singleBox display-flex flex-column flex-align-center ":"duplexBox "} style={props.style}>
                {props.isSingle ? (
                    <>
                        <span className="globalContext">{props.globalContext}</span>
                        <div className="display-flex flex-column flex-align-center">
                            {props.data.map((data)=>{
                                if((data.context == "Ingresos Acumulados" || data.context == "Rating Medio") && business){
                                    return <></>
                                }
                                return(
                                    <>
                                        <span className="singleContextTitles">{data.context}</span>
                                        <div className={data.type == "rating" ? "display-flex flex-justify-center":""}>
                                            <h6 className="singleData" >{data.value}{data.type == "money"? "€":""}</h6>
                                            {data.change !== null ? (
                                                <div className="display-flex flex-justify-center flex-align-center">
                                                    <span className={data.change.direction}>{data.change.value}%</span>
                                                </div>
                                            ):null}
                                            {data.type == "rating" && <img alt="rating" style={{width:"50px", height:"50px",marginTop:"auto"}} src={icon_rating}></img>}

                                        </div>
                                    </> 
                                    )
                            })}
                        </div>
                    </>
                ):(
                    <>
                        <div className="display-flex flex-justify-center flex-align-center">
                            <span className="singleContextTitles">{props.globalContext}</span>
                        </div>
                        <div className="display-flex flex-justify-around">
                            <div className="display-flex flex-column flex-align-center">
                                {props.data.col1.map((data)=>{
                                    if((data.context == "Lead medio" || data.context == "Ticket medio") && business){
                                        return <></>
                                    }
                                    return(
                                    <>
                                        <span className="contextTitles">{data.context}</span>
                                        <div className="display-flex flex-column flex-align-center">
                                            <h6 className="duplexData">{data.value}{data.type == "money"? "€":""}</h6>
                                            {data.change !== null ? (
                                                <div className="display-flex flex-justify-center flex-align-center">
                                                    <span className={data.change.direction}>{data.change.value}%</span>
                                                </div>
                                            ):(
                                                <div className="display-flex flex-justify-center flex-align-center v-hidden">
                                                    <span >%</span>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                    
                                        )
                                })}
                                
                            </div>
                            <div className="display-flex flex-column flex-align-center">
                                {props.data.col2.map((data)=>{
                                    if((data.context == "Ingreso total" || data.context == "ingreso por oferta") && business){
                                        return <></>
                                    }
                                    return(
                                    <>
                                        <span className="contextTitles">{data.context}</span>
                                        <div>
                                            <h6 className="duplexData">{data.value}{data.type == "money"? "€":""}</h6>
                                            {data.change != null ? (
                                                <div className="display-flex flex-justify-center flex-align-center">
                                                    <span className={data.change.direction}>{data.change.value}%</span>
                                                </div>
                                            ):null}
                                        </div>
                                    </>
                                    
                                        )
                                })}
                                
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }else{
        return null
    }
}
