import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import { DayPickerRangeController,SingleDatePicker,isInclusivelyBeforeDay } from "react-dates";
import moment from "moment";
import FilterCustom from "./FilterCustom";
import ButtonEmpty from '../button/ButtonEmpty'

import "react-dates/lib/css/_datepicker.css";

export default function FilterDate(props) {
    const [title, setTitle] = useState(props.title);
    const [filtered, setFiltered] = useState(false);
    const [date, setDate] = useState({
        startDate: undefined,
        endDate: undefined
    });
    const [focused, setFocused] = useState("startDate");
    const [focusedStart, setFocusedStart] = useState(false);
    const [focusedEnd, setFocusedEnd] = useState(false);
    const [_startDate, setStartDate] = useState(false);
    const [_endDate, setEndDate] = useState(false);

    useEffect(() => {
        if (props.startDate && props.endDate) {
            setFiltered(true);
            setTitle(
                `${props.startDate.format("MMM Do")} - ${props.endDate.format("MMM Do")}`);
            setDate({
                startDate: props.startDate,
                endDate: props.endDate
            })
        }else if(props.startDate === undefined && props.endDate === undefined ){
            setDate({
                startDate: props.startDate,
                endDate: props.endDate
            })
            setTitle(props.title);
            setFiltered(false);
        }
        else{
            setFiltered(false);
            setTitle(props.title);
        }
    }, [props.startDate, props.endDate, props.title]);
    useEffect(() => {
        if(props.isClearFilters){
            clearDates()
        }
    }, [props.isClearFilters]);

    useEffect(()=>{
        if (_startDate && _endDate){
            filterDate(_startDate,_endDate)
        }
    },[_startDate,_endDate])

    const style = {
        footer:{
            display: 'flex',
            flex: 1,
            marginTop: 16
        },
        footerKpis:{
            display: 'flex',
            marginTop: 16,
            width:"100%"
        }
    }

   

    const filterDate = (startDate, endDate) => {
        setDate({
            startDate: startDate ? startDate.utc().startOf("day") : null,
            endDate: endDate ? endDate.utc().endOf("day") : null
        });
        setFiltered(true);
        if (startDate && endDate) {
            setTitle(
                `${startDate.format("MMM Do")} - ${endDate.format("MMM Do")}`
            );
            props.selectionChange({
                startDate: startDate.format(),
                endDate: endDate.format()
            });
        }
    };
    const clearDates= ()=>{
        setTitle(props.title);
        setFiltered(false);
        setDate({
            startDate: undefined,
            endDate: undefined
        });
        setDate({
            startDate: undefined,
            endDate: undefined
        });
        setStartDate(undefined)
        setEndDate(undefined)
        setFocusedStart(false)
        setFocusedEnd(false)
        props.selectionChange({
            startDate: null,
            endDate: null
        });
    }
    const onFocusChangeDate = focusedInput => {
        // Force the focusedInput to always be truthy so that dates are always selectable
        setFocused(!focusedInput ? "startDate" : focusedInput);
    };
    const onFocusChangeDateStart = () => {
        // Force the focusedInput to always be truthy so that dates are always selectable
        setFocusedStart(!focusedStart);
    };
    const onFocusChangeDateEnd = () => {
        // Force the focusedInput to always be truthy so that dates are always selectable
        setFocusedEnd(!focusedEnd);
    };
    const renderFooter = () =>{
        return(
            <section style={props.isKpis ? style.footerKpis:style.footer}>
                <ButtonEmpty
                    buttonText="Limpiar fechas"
                    onClick={()=>{clearDates()
                       
                    }}
                />
            </section>
        )
    }
   
    const renderCalendar = () => {
       return props.isKpis? (
            <>
                {!focusedEnd && (<SingleDatePicker
                    onFocusChange={onFocusChangeDateStart}
                    date={_startDate}
                    focused={focusedStart}
                    id="startDate" // PropTypes.string.isRequired,
                    numberOfMonths={1}
                    minDate={moment('2021-01-01')}
                    hideKeyboardShortcutsPanel={true}
                    style={{width:"150px"}}
                    onDateChange={(_startDate)=>{setStartDate(_startDate);setEndDate(false)}}
                    isOutsideRange={() => false}
                    placeholder="fecha de inicio"/>
                )}
                    
               {!focusedStart && ( 
                <SingleDatePicker
                    onFocusChange={onFocusChangeDateEnd}
                    date={_endDate}
                    focused={focusedEnd}
                    id="endDate" // PropTypes.string.isRequired,
                    numberOfMonths={1}
                    minDate={moment('2021-01-01')}
                    hideKeyboardShortcutsPanel={true}
                    style={{width:"150px"}}
                    onDateChange={(_endDate)=>{setEndDate(_endDate)}}
                    isOutsideRange={day => day.isBefore(_startDate)}
                    placeholder="fecha de fin"
                 
                />
               )}
            </>
        ):
            <>
               { !focusedEnd&& props.appointmentFilter && (<SingleDatePicker
                onFocusChange={onFocusChangeDateStart}
                date={_startDate}
                focused={focusedStart}
                id="startDate" // PropTypes.string.isRequired,
                numberOfMonths={1}
                minDate={moment('2021-01-01')}
                hideKeyboardShortcutsPanel={true}
                style={{width:"150px"}}
                onDateChange={(_startDate)=>{setStartDate(_startDate);setEndDate(false)}}
                isOutsideRange={() => false}
                placeholder="fecha de inicio"/>
            )}
                
          { !focusedStart && props.appointmentFilter&& (<SingleDatePicker
                onFocusChange={onFocusChangeDateEnd}
                date={_endDate}
                focused={focusedEnd}
                id="endDate" // PropTypes.string.isRequired,
                numberOfMonths={1}
                minDate={moment('2021-01-01')}
                hideKeyboardShortcutsPanel={true}
                style={{width:"150px"}}
                onDateChange={(_endDate)=>{setEndDate(_endDate)}}
                isOutsideRange={day => day.isBefore(_startDate)}
                placeholder="fecha de fin"
             
            />)}
            {!focusedEnd && !focusedStart && (<DayPickerRangeController
                onDatesChange={({ startDate, endDate }) =>
                    filterDate(startDate, endDate)
                }
                onFocusChange={onFocusChangeDate}
                focusedInput={focused}
                startDate={date.startDate}
                endDate={date.endDate}
                numberOfMonths={2}
                minDate={moment('2021-01-01')}
                hideKeyboardShortcutsPanel={true}
            />)}
            </>
        ;
    };

    

    return (
        <FilterCustom
            idElement={"search"}
            style={props.style} 
            type={props.type}
            title={title}
            filtered={filtered}
            menu={renderCalendar()}
            footer={props.isKpis? false:renderFooter()}
            isKpis={props.isKpis?"iskpis":""}
            dateActive={focusedStart || focusedEnd? "active":"Inactive"}
            right={""}
        />
    );
}
