import React, {useEffect, useState} from "react";
import {getBusinessData} from '../../utils/ParserUtilities';
import ColorRing from "../colorRing.js";

export default function NotificationCell(props) {

    const style = {
        container:{
            borderBottom: '1px solid var(--soft-grey)',
            paddingRight: 24,
            paddingLeft: 24,
            paddingTop: 16,
            paddingBottom: 16
        },
        icon:{
            width: "80px",
            backgroundPosition:"center",
            backgroundRepeat:"no-repeat",
            width:"60px",
            height:"60px",
            backgroundSize:"contain"
        },
        badge:{
            height: 12,
            width: 12,
            borderRadius: 6,
            backgroundColor: 'var(--aqua-jobin-darker)',
            marginLeft: 18,
            border: 'none'
        },
        content:{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
        },
        title:{
            fontSize: 16,
            fontWeight: 700,
            marginBottom: 8,
            lineHeight: '18px'
        },
        description:{
            fontSize: 13,
            marginBottom: 8,
            color: 'var(--greyish)'
        },
        jobTitle:{
            fontSize: 14,
            fontWeight: 700,
        },
        date:{
            fontSize: 13,
            fontWeight: 700,
            color: 'var(--greyish)'
        },
        container_logo:{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        containerLeft:{
            borderRight: '1px solid var(--soft-grey)',
            paddingTop: 16,
            paddingBottom: 16
        },
    }

    const [jobObject, setJobObject] = useState();
    const [background, setBackground] = useState();
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [state, setState] = useState();
    const [type, setType] = useState();
    const [vertical, setVertical] = useState();
    const [Address, setAddress] = useState();
    const [NotificationType, setNotificationType] = useState();
    const [timeElapsed, setTimeElapsed] = useState({h:0,m:0,s:0});

  

    const secondsToTime = (secs)=>{

        let seconds = Math.floor( secs / 1000)
        let secondsInThisMinute = Math.floor( seconds % 60)
        let minutes = Math.floor( seconds / 60)
        let minutesinThisHour = Math.floor( minutes % 60)
        let hours =  Math.floor( minutes / 60) < 1 ? 0 : Math.floor( minutes/ 60)
        let obj = {
            "h" : hours,
            "m": minutesinThisHour,
            "s": secondsInThisMinute,
            
        };
       setTimeElapsed(obj)
    }
    useEffect(()=>{
        let diff = new Date().getTime() - props.notification.get("Timestamp").getTime();
        const timer=setTimeout(() => {
                        secondsToTime(diff)
                    }, 1000);
        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    })

    useEffect(() =>{
        
      const fetchAsync = async (id) =>{
          let businessData = await getBusinessData (id)
         businessData.get("Logo") ? setBackground(businessData.get("Logo").url()):setBackground(null)
      }
        
      let result = getParsedInfo(props.notification);  
      setTitle(result.result.title);
      setDescription(result.result.description);
      if(props.notification.get("Job")){
          let jobObject = props.notification.get("Job")

          setJobObject (jobObject)
          let verticalObject = jobObject.get("Vertical")
          setState(jobObject.get("State"))
          setType(jobObject.get("JobType"))
          setVertical(verticalObject.get("Type"))
          setAddress(jobObject.get("Address"))

          if(jobObject.get("Business")){

            fetchAsync(jobObject.get("Business").id)
          }
      
      }
    },[]);
    const goToJob = id => {
        props.history.push({
            pathname: "/job/" + id,
            state: {
                inner: true
            }
        });
    };
    const timeFormatter = (time)=>{
        if(time.h > 0){
            return "Hace "+time.h+" Hrs "+time.m+" Mins "+time.s+"s"
        }else{
            if(time.m>1){
                return "Hace "+time.m+" Mins "+time.s+"s"

            }else{
                return "Hace "+time.s+" s"
            }
        }
    }
        const getParsedInfo = (notification)=> {
        let title, description, extraInfo;
        setNotificationType(notification.get("Type"))
      
        switch (notification.get("Type")) {
          //Subida de trabajos
          case "new_job":
            title = "Nuevo servicio pendiente";
            description = "Hay un nuevo servicio pendiente de gestionar.";
            extraInfo = ""
            break;
      
          case "newDraft":
            title = "Nuevo presupuesto";
            description = "Se ha subido un nuevo presupuesto";
            extraInfo = ""
            break;
          //Deadlines
      
          case "stillDraft":
            title = "Aviso: No se ha acepato el presupuesto";
            description = "Ha pasado demasiado tiempo desde que se emitió el presupuesto y aun no se ha aceptado";
            extraInfo = ""
            break;
      
          case "stillNoJober":
            title = "Aviso: Aún no hay jober";
            description = "El servicio ya debería tener un jober asignado";
            extraInfo = ""
            break;
      
          case "stillNoDate":
            title = "Aviso: Aún no hay cita";
            description = "El servicio ya debería tener una cita asignada";
            extraInfo = ""
            break;
      
          case "stillNotFinished":
            title = "Aviso: Aún no se ha completado el servicio";
            description = "El servicio ya debería haberse finalizado";
            extraInfo = ""
            break;
          //Acciones en pagos
      
          case "newPaymentPending":
            {
              const payment = notification.get("Payment");
              const formattedPrice = payment.has("Price") ? (payment.get("Price") * 1.21).toFixed(2) : "0.00";
              title = "Nuevo pago pendiente";
              description = "Se ha enviado un email al usuario con la pasarela de pago o plataforma de financiación";
              extraInfo = `${formattedPrice} € mediante ${formatMethod(payment)}`;
            }
            break;
      
          case "newPaymentFailed":
            {
              const payment = notification.get("Payment");
              const formattedPrice = payment.has("Price") ? (payment.get("Price") * 1.21).toFixed(2) : "0.00";
              title = "Fallo en pago";
              description = "El usuario ha intentado pagar pero ha fallado";
              extraInfo = `${formattedPrice} € mediante ${formatMethod(payment)}`;
            }
            break;
            //Otros
            case "commentInJobB2b":
              {
                title = "Nuevo comentario de sesion b2b";
                description = "Una aseguradora ha hecho un comentario en un servicio publicado";
                extraInfo = "";
              }
              break;
            case "newPaymentSucces":
              {
                title = "Nuevo pago efectuado";
                description = "Una usuario ha pagado un servicio";
                extraInfo = "";
              }
              break;
      
          default:
            title = "Notificación desconocida";
            description = "Has recibido una notificación que el sistema no reconoce. Tipo: " + notification.get("Type");
            extraInfo = "";
            break;
        }
      
        return {
          status: "ok",
          result: {
            title: title,
            description: description,
            extraInfo: extraInfo,
            importance: notification.get("Importance")
          }
        };
      }
      const formatMethod = (payment)=> {
        const method = payment.get("Method");
      
        switch (method) {
          case "paycard":
            return "Tarjeta (Stripe)";
      
          case "tpv":
            return "Tarjeta (TPV)";
      
          case "cash":
            return "Metálico";
      
          case "transfer":
            return "Transferencia";
      
          case "invoice":
            return "Email";
      
          case "credit":
            return "Crédito";
      
          case "business":
            return "Finan. empr.";
      
          case "funding":
            return "Financiado";
      
          default:
            return method;
        }
      }
    const calcPercentage=(timeElapsed,state,type) =>{
      let percent 
      let color 
      if(NotificationType == "newPaymentPending" || NotificationType  == "newPaymentFailed"){
        
        timeElapsed.h < 23 && timeElapsed.m < 59  ? percent= (timeElapsed.h*100)/24 : percent =100
        timeElapsed.h < 23 && timeElapsed.m < 59  ? color ="#ffbb00":color ="#ff4d4d"

      }else{

        timeElapsed.h < 1  ? percent= (timeElapsed.m*100)/60: percent =100
        timeElapsed.h < 1 && timeElapsed.m < 30  ? color ="#00e62699" :timeElapsed.h > 0 ? color ="#ff4d4d":color ="#ffbb00"
      }
     
      return {color:color,percent:percent}
    }
    return (
        <div style={style.container} onClick={()=>goToJob(jobObject.id)}> 
            <div style={style.container_logo}>
                <div>
                  <h5 style={style.title}>{title}</h5>  
                  <p style={style.description}>{description}</p>
                </div>
                {background!=null ? (<div style={style.icon,{backgroundImage: `url(${background})`}}  alt="icon"></div>):null}
              </div>
              <p style={style.jobTitle}>{props.notification.get("Job") && props.notification.get("Job").get("Title")}</p>
              <p className="containerLeft">Estado: {state}</p>
              <p className="containerLeft">Tipo de trabajo: {type}</p>
              <p className="containerLeft">Vertical: {vertical}</p>
              <p>{Address}</p>
              <div className="display-flex horizontal-flex-justify-between vertical-flex-align-center">
                  <label  style={style.date}>{timeFormatter(timeElapsed)}</label>
                  <ColorRing percent={calcPercentage(timeElapsed,state,type).percent} radius={20} color={calcPercentage(timeElapsed,state,type).color}/>
              </div>
        </div>
    );
}
