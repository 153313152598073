import React, { useState } from "react";
import { Counter } from "jobin-ui-components";

export default function PredefinedExtraCell(props) {
    const style = {
        container: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            marginTop: 8,
            marginBottom: 8,
            alignItems: "center"
        },
        contentContainer: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            marginRight: 16
        },
        priceContainer: {
            display: "flex",
            flexDirection: "column",
            marginRight: 16
        },
        nameLabel: {
            display: "flex",
            flex: 1,
            fontSize: 16
        },
        descriptionLabel: {
            display: "flex",
            flex: 1,
            fontSize: 13,
            color: "var(--warm-grey)"
        },
        priceLabel: {
            textAlign: "right",
            fontSize: 13
        },
        totalPriceLabel: {
            textAlign: "right",
            fontWeight: "bold",
            fontSize: 16
        }
    };

    const [totalPrice, setTotalPrice] = useState(
        props.units
            ? Number(props.units) *
                  Number(props.extra.get("Price").default.user)
            : 0
    );

    const onNumberChange = input => {
        setTotalPrice(
            Number(input) * Number(props.extra.get("Price").default.user)
        );
        props.onValueChange(input);
    };

    return (
        <section style={style.container}>
            <div style={style.contentContainer}>
                <label style={style.nameLabel}>{props.extra.get("Name")}</label>
                <label style={style.descriptionLabel}>
                    {props.extra.get("Description")}
                </label>
            </div>
            <div style={style.priceContainer}>
                <label style={style.totalPriceLabel}>
                    {totalPrice ? (totalPrice).toFixed(2) + "€" : "0.00€"}
                </label>
                <label style={style.priceLabel}>
                    {(props.extra.get("Price").default.user).toFixed(2) +
                        " €/" +
                        props.extra.get("Unit")}
                </label>
            </div>
            <Counter
                unit=""
                min={props.units}
                onClick={value => onNumberChange(value)}
            />
        </section>
    );
}
