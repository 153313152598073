import React, { useState, useEffect } from "react";

import { useStateValue } from "../../state/context";

import { provinceArr } from "../../constants/provinces";
import { fetchKpis } from "../../utils/ParserUtilities";
import fetchBusinesses from "../../constants/businesses";
import fetchServices from "../../constants/services";

import FilterDate from "../filter/FilterDate";
import FilterMultipleSelection from "../filter/FilterMultipleSelection";
import moment from "moment";
import "../../styles/search/search-nav.css";

export default function SearchNav() {
    const [{kpisDetail, agent},dispatch] = useStateValue();
    const [guildArr, setGuildArr] = useState([]);
    const [businessArr, setBusinessArr] = useState([]);
    
    const styles = {
        
        title:{
            color:"#000",
            margin:"12px 0px",
            fontSize: "33.8px",
            fontWeight: 700,
            cursor: "default",
        },
        main:{
           
            padding:"17px 20px 14px"
        },
        below: {
            display: "flex",
            height: "48px",
      
        },
        buttonFilter:{
            cursor: "pointer",
            padding: "6px",
            borderRadius: "4px",
            marginRight:  "4px",
            backgroundColor:"#1A3B42"
        }
        
    }
     
    
    useEffect(() => {
        async function fetchAsync() {
            setGuildArr(await fetchServices(kpisDetail.filters.services));
            setBusinessArr(await fetchBusinesses(kpisDetail.filters.businesses));
        }
        fetchKpisData(kpisDetail.filters)
        fetchAsync()
    }, []);

    const fetchKpisData = async (data) =>{
        dispatch({
            type: "KPIS_DETAILS_LOADING",
            loading:true
        })

        let result =  await fetchKpis(
            data.startDate ? 
                data.startDate._d:null,
            data.endDate ? 
                data.endDate._d:null,
            data.provinces ? 
                data.provinces:null,
            data.guilds ? 
                data.guilds:null,
            agent.business ? // Filter by bussiness if B2B session
            [
                {
                    id: agent.business.id, 
                    selected: true
                }
            ] :
            data.business ? 
                data.business:null,
            data.serviceType ? 
                data.serviceType:null,
        )

        dispatch({
            type: "SET_ITEMS_KPIS",
            items:result
        })

        dispatch({
            type: "KPIS_DETAILS_LOADING",
            loading:false
        })
    };
   
    const fetchKpisByDate = async (date) =>{
        if(date.startDate === undefined || date.endDate === undefined){
            date.endDate = moment()
            .utc()
            .endOf("day")
            date.startDate = moment()
            .utc()
            .startOf("month")
        }
        dispatch({
            type: "SET_KPIS_FILTER",
            startDate:moment(date.startDate)
            .utc()
                ,
            endDate:moment(date.endDate)
            .utc()

        })

        let data={
            ...kpisDetail.filters,
            startDate:moment(date.startDate)
            .utc()
            
            ,
            endDate:moment(date.endDate)
            .utc()
            
            
        }
        await fetchKpisData(data)

        return null
    };
    const fetchKpisByprovince = async (_provinces) =>{
        _provinces = _provinces.map((province)=>province.id)
        dispatch({
            type: "SET_KPIS_FILTER",
            provinces:_provinces
        })
    

        let data={
            ...kpisDetail.filters,
            provinces:_provinces
        }
        await fetchKpisData(data)

        return null
    };
    const fetchKpisByservice = async (_guilds) =>{

        dispatch({
            type: "SET_KPIS_FILTER",
            guilds:_guilds,
            
        })
     

        let data={
            ...kpisDetail.filters,
            guilds:_guilds,
        }
        
        await fetchKpisData(data)

       
        return null
    };
    const fetchKpisBybusinesses = async (_business) =>{
        dispatch({
            type: "SET_KPIS_FILTER",
            business:_business,
            
        })

        let data={
            ...kpisDetail.filters,
            business:_business,
        }
      
        await fetchKpisData(data)

       return null
    };


    return (
        <section className="search-nav iskpis" style={styles.main}>
            <div>
                <h6 style={styles.title}>Vertical Editor</h6>
            </div>
            
        </section>
    );
}
