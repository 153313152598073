import React, { useState } from "react";
import * as cardSpinnerData from "../../assets/animations/button-loading.json";
import { useStateValue } from "../../state/context";
import Lottie from 'react-lottie'

import { uploadDocument,getCertificationStatus } from "../../utils/ParserUtilities";

import MainModal from "./MainModal"

import check from "../../assets/icons/Check.svg"
import prohibit from "../../assets/icons/Prohibit.svg"
import warning from "../../assets/icons/ClockClockwise.svg"

import "../../styles/modal/level-up-modal.css"
import { useEffect } from "react";
import { alteredState } from "../../state/alteredState";

const LevelUpModal = ({isVisible,onClose}) => {
    const [{user,currentSession}, dispatch] = useStateValue();
    const [dni,setDni] = useState(" DNI o CIF")
    const [autonomous,setAutonomous] = useState("Último recibo de cuota de autónomos")
    const [taxes,setTaxes] = useState("Certificado de situacion de estar al corriente en las obligaciones tributarias")
    const [insurance,setInsurance] = useState("Último recibo pagado de Poliza de Responsabilidad Civil")
    const [readyToSent, setReadyToSent] = useState(false)
    const [isSending, setIsSending] = useState(false)

    useEffect(()=>{
        if(typeof dni  != "string"|| typeof autonomous  != "string"|| typeof taxes  != "string"|| typeof insurance != "string"){
            handleUploadDocument()

        }
    },[dni, autonomous, taxes, insurance])
    
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: cardSpinnerData.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    const  getBase64 = file => {
        return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve({fileName:file.name,base64:baseURL});
            };
        });
    };

  

    const handleDocument= async (document,type)=>{
        setIsSending(true)
        document=document.target.files[0]
        if(!document.size / (1024 * 1024) > 8){
            alert("El documento seleccionado tiene un tamaño superior a 8MB")
            setIsSending(false)
        }else{
            let base64 = await getBase64(document).then(async (result)=> {
                             return result.base64.split("base64,")[1]
                        }).catch(err => {
                            console.error(err);
                        })
            switch (type) {
                case "dni":
                    setDni({document:base64, format:document.type.split("/")[1], name:document.name,type:"dni"})
                    handleUploadDocument(base64, document.type.split("/")[1], document.name,"dni")
                    break;
                case "autonomous":
                    setAutonomous({document:base64, format:document.type.split("/")[1], name:document.name,type:"autonomous"})
                    handleUploadDocument(base64, document.type.split("/")[1], document.name,"autonomous")
                    break;
                case "taxes":
                    setTaxes({document:base64, format:document.type.split("/")[1], name:document.name,type:"taxes"})
                    handleUploadDocument(base64, document.type.split("/")[1], document.name,"taxes")
                    break;
                case "insurance":
                    setInsurance({document:base64, format:document.type.split("/")[1], name:document.name,type:"insurance"})
                    handleUploadDocument(base64, document.type.split("/")[1], document.name,"insurance")
                    break;
            
                default:
                    break;
            }
        
        }
    }

    const handleUploadDocument =async (document=undefined,format=undefined,name=undefined,type = undefined)=>{
        
        try{
            if(document && format && name && type){
                let documentPromise = await uploadDocument({document:document,documenName:format,format:name ,type:type,wollerId:user.goldId})
                if(documentPromise){
                    let newStatus =  await getCertificationStatus(user.goldId)
          
                    dispatch({
                          type: "SET_GOLD_USER_DATA",
                          certificationState:newStatus 
                    });
                }
                setIsSending(false)
            }
       }catch(error){
            console.error(error)
            alert("Hubo un problema al cargar el documento")
            setIsSending(false)
        }
    }

    return (
   
        <MainModal
            classNamesModal={{modal:"levelModal",closeButton:"closeButtonMoney"}}
			isVisible={isVisible}
			onClose={()=>onClose()}
            center={true}
            banner={require("../../assets/badgePlatinum.png")}
            title={"HOMOLÓGATE"}
            subtitle={"Conviértete en un profesional de la red oro de Wolly y accede a todos los servicios de nuestros grandes clientes"}

		>
            <p className="sectionTitle">Envia tu documentación</p>

            <div className="buttonsContainer">
                <div className="buttonDocument">
                   {typeof dni == "string"?dni:dni.name.replace(/\..+/,"").slice(0,10)+"..."+dni.format}
                    <input type="file"  accept=".jpg, .jpeg, .png"  className="hiddenInput"  onInput={(input)=>{handleDocument(input, "dni")}}/>
                    {currentSession.certificationState?.nif ? (<img className="documentStateButton" src={currentSession.certificationState?.nif.state == "approved"? check:currentSession.certificationState?.nif.state == "pending" ? warning:prohibit} alt="" srcset="" />):null}
                </div>
                <div className="buttonDocument">
                    {typeof autonomous == "string"?autonomous:autonomous.name.replace(/\..+/,"").slice(0,10)+"..."+autonomous.format}
                    <input type="file"  accept=".jpg, .jpeg, .png"  className="hiddenInput" onInput={(input)=>{handleDocument(input, "autonomous")}}/>
                    {currentSession.certificationState?.lastRecipe ? (<img className="documentStateButton" src={currentSession.certificationState?.lastRecipe.state == "approved"? check:currentSession.certificationState?.lastRecipe.state == "pending" ? warning:prohibit} alt="" srcset="" />):null}
                </div>
                <div className="buttonDocument">
                    {typeof taxes == "string"?taxes:taxes.name.replace(/\..+/,"").slice(0,10)+"..."+taxes.format} 
                    <input type="file"  accept=".jpg, .jpeg, .png"  className="hiddenInput" onInput={(input)=>{handleDocument(input, "taxes")}}/>
                    {currentSession.certificationState?.taxAgency ? (<img className="documentStateButton" src={currentSession.certificationState?.taxAgency.state == "approved"? check:currentSession.certificationState?.taxAgency.state == "pending" ? warning:prohibit} alt="" srcset="" />):null}
                </div>
                <div className="buttonDocument">
                    {typeof insurance == "string"?insurance:insurance.name.replace(/\..+/,"").slice(0,10)+"..."+insurance.format}
                    <input type="file"  accept=".jpg, .jpeg, .png"  className="hiddenInput" onInput={(input)=>{handleDocument(input, "insurance")}}/>
                    {currentSession.certificationState?.socialSecurity ? (<img className="documentStateButton" src={currentSession.certificationState?.socialSecurity.state == "approved"? check:currentSession.certificationState?.socialSecurity.state == "pending" ? warning:prohibit} alt="" srcset="" />):null}
                </div>
            </div>
            <p className="footerText">Una vez revisada y verificada activaremos tu perfil homologado. Los archivos deben ser de formato .png, .jpeg o .jpg; no superior a 8MB</p>
            {
                isSending ? (
                    <div className=" sendDocument" >
                        <Lottie
                            style={{height: 30, width: 80}}
                            options={defaultOptions}
                        />
                    </div>):(
                        <p className="footerTextClose" onClick={()=>{onClose()}}>Lo hago más tarde</p>
                    )
            }
             
        </MainModal>
    )
}

export default LevelUpModal;