import React, {useState, useEffect} from 'react';
import GenericPdfDownloader from "./GenericPdfDownloader";
import { getGenericPdfPCData } from "../../utils/ParserUtilities";
import { formatDateWhitoutHour } from "../../utils/formatter";

import "../../styles/genericPDF.css";

const GenericPdfPC = () => {
    const [jobData, setJobData] = useState({});

    useEffect(()=> {
        getData();
    },[]);

    const getData = async () => {
        let url = window.location.href.split('generic-pdf-pc/');
        let newJobData = await getGenericPdfPCData(url[1]);

        setJobData(newJobData);
    }

    return(
        <div className='pdflm-wrapper'>
            <GenericPdfDownloader 
                downloadFileName="CustomPdf" 
                rootElementId="testId" 
            />
            <div id="testId" className='generic-pdf'> 
                <div className='lm-images-wrapper'>
                    <img className='generic-pdf-wolly-logo' src={require("../../assets/wolly-logo-billing.png")}/>
                    {
                        jobData.business && (
                            <img className='generic-pdf-generic-logo' src={ require("../../assets/pdfLM/Mutua-Logo.png") }/>
                        )
                    }
                </div>

                <div className='generic-pdf-title-wrapper'>
                    <p className='generic-pdf-title'>Presupuesto</p>
                    <p className='generic-pdf-subtitle'>Copia para el cliente</p>
                </div>

                <div className='generic-pdf-boxes-wrapper'>
                    <div className='generic-pdf-little-box bold'>
                        <p>ID del Presupuesto</p>
                    </div>
                    <div className='generic-pdf-little-box'>
                        <p>{jobData.ID ? jobData.ID : ""}</p>
                    </div>
                    <div className='generic-pdf-little-box bold'>
                        <p>Fecha de emisión</p>
                    </div>
                    <div className='generic-pdf-little-box generic-pdf-box-border-right'>
                        <p>{jobData.createdAt ? formatDateWhitoutHour(jobData.createdAt.toString()) : ""}</p>
                    </div>                  
                </div>

                <div className='generic-pdf-box-title-wrapper'>
                    <p className='generic-pdf-box-title'>Datos del cliente</p>
                </div>
                <div className='generic-pdf-boxes-wrapper'>
                    <div className='generic-pdf-little-box bold'>
                        <p>Nombre del cliente</p>
                    </div>
                    <div className='generic-pdf-little-box'>
                        <p>{jobData.clientName ? jobData.clientName : ""}</p>
                    </div>
                    <div className='generic-pdf-little-box bold'>
                        <p>Teléfono del cliente</p>
                    </div>
                    <div className='generic-pdf-little-box generic-pdf-box-border-right'>
                        <p>{jobData.clientPhone ? jobData.clientPhone : ""}</p>
                    </div>                  
                </div>

                <div className='generic-pdf-boxes-wrapper'>
                    <div className='generic-pdf-little-box bold'>
                        <p>Dirección del servicio</p>
                    </div>
                    <div className='generic-pdf-75-box generic-pdf-box-border-right'>
                        <p>{jobData.jobAddress ? jobData.jobAddress : ""}</p>
                    </div>             
                </div>

                <div className='generic-pdf-box-title-wrapper'>
                    <p className='generic-pdf-box-title'>Firmas</p>
                </div>

                <div className='generic-pdf-boxes-wrapper'>
                    <div className='generic-pdf-medium-box bold'>
                        <p>Firma del cliente:</p>
                    </div>
                    <div className='generic-pdf-medium-box generic-pdf-box-border-right bold'>
                        <p>Firma del profesional:</p>
                    </div>
                </div>
                <div className='generic-pdf-boxes-wrapper'>
                    <div className='generic-pdf-medium-box bold'>
                        {
                            jobData.signatures && jobData.signatures.signature && jobData.signatures.signature != "" && (
                                <img className='generic-pdf-signature' src={jobData.signatures.signature}/>
                            )
                        }
                    </div>
                    <div className='generic-pdf-medium-box generic-pdf-box-border-right bold'>
                        {
                            jobData.signatures && jobData.signatures.signaturePro && jobData.signatures.signaturePro != "" && (
                                <img className='generic-pdf-signature' src={jobData.signatures.signaturePro}/>
                            )
                        }
                    </div>
                </div>

                <div className='generic-pdf-box-title-wrapper'>
                    <p className='generic-pdf-box-title'>Datos del servicio</p>
                </div>

                <div className='generic-pdf-boxes-wrapper'>
                    <div className='generic-pdf-little-box bold'>
                        <p>Servicio</p>
                    </div>
                    <div className='generic-pdf-75-box generic-pdf-box-border-right'>
                        <p>{jobData.verticalName ? jobData.verticalName : ""}</p>
                    </div>             
                </div>

                <div className='generic-pdf-boxes-wrapper'>
                    <div className='generic-pdf-little-box bold'>
                        <p>Descripción</p>
                    </div>
                    <div className='generic-pdf-75-box generic-pdf-box-border-right'>
                        <p>{jobData.jobDescription ? jobData.jobDescription : ""}</p>
                    </div>
                </div>

                <div className='generic-pdf-boxes-wrapper'>
                    <div className='generic-pdf-medium-box bold'>
                        <p>El servicio básico incluye:</p>
                    </div>
                    <div className='generic-pdf-medium-box generic-pdf-box-border-right bold'>
                        <p>El servicio básico NO incluye:</p>
                    </div>
                </div>

                <div className='generic-pdf-boxes-wrapper'>
                    <div className='generic-pdf-medium-box'>
                        {
                            jobData.includes && jobData.includes.map( include => (
                                <p className='generic-pdf-includes-p'>- {include}</p>
                            ))
                        }
                    </div>
                    <div className='generic-pdf-medium-box generic-pdf-box-border-right'>
                        {
                            jobData.doesntIncludes && jobData.doesntIncludes.map( doesntIncludes => (
                                <p className='generic-pdf-includes-p'>- {doesntIncludes}</p>
                            ))
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}

export default GenericPdfPC;
