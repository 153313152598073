import React from "react";
import Campains from "../../components/campains/Campains";

export default function Search(props) {

    return (
        <React.Fragment>
            <Campains history={props.history} location={props.location}/>
        </React.Fragment>
    );
}