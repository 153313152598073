import React, { useState } from "react";
import MainModal from "./MainModal";
import { updateFixedPrice } from "../../utils/ParserUtilities";
import Jobin from "jobin-client";

import "../../styles/modal/edit-fixed-price-modal.css";

const EditFixedPriceModal = props => {

    const [newPrice, setNewPrice] = useState(0);
    const [disabled, setDisabled] = useState(false);

    const onChangeHandler = event => {
        setNewPrice(event.target.value);
    };

    const onSaveNewPrice = async () => {
        setDisabled(true)
        let newPayment = await updateFixedPrice(props.jobDetails.job.id ,parseFloat(newPrice));

        await Jobin.PDF.remakeBill(newPayment);
        
        setTimeout(() => {
            document.location.reload();
          }, 1000);
    }

    return (
        <MainModal
            style={{ height: "300px", width: "400px" }}
            title={"Editar Precio"}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        >
            <div className="edit-fixed-price-modal-wrapper">
                <input
                    type="text"
                    name="name"
                    onChange={onChangeHandler}
                    value={newPrice}
                    className="edit-fixed-price-modal-input"
                />

                <button onClick={onSaveNewPrice} className="edit-fixed-price-modal-button" disabled={disabled}>Guardar</button>
            </div>

        </MainModal>
    )
}

export default EditFixedPriceModal;