import { leadTitle, fixedTitle, leadTitleGoldUsers, fixedTitleb2b, leadTitleb2b } from "../constants/jobTitles";
import { joberTitles, clientTitles } from "../constants/user/userTitles";
export const reducer = (state, action) => {
    switch (action.type) {
        //General &  Session
        case "SET_TOKEN_STORES":
            return {
                ...state,
                token: action.token,
                search: {
                    ...state.search,
                    stores: action.stores
                }
            };
        case "SET_USER_PERMISSIONS":
            return {
                ...state,
                user: {
                    ...state.user,
                    permissions: action.permissions
                }
            };
        case "SET_HOLD_STANDBY":
            return {
                ...state,
                general:{
                    ...state.general,
                    holdStandBy:action.holdStandBy,
                } 
                
            };
        case "SET_ID_OPTIONS":
           
            return {
                ...state,
                search: {
                    ...state.search,
                    idTypes: action.idTypes,
                }
            };
        case "SET_ITEMS_TITLES":
            return {
                ...state,
                search: {
                    ...state.search,
                    itemsTitle: action.itemsTitle,
                }
            };
        case "SET_JOBS_RELATED_TO_BUSINESS":
            return {
                ...state,
                businessDetails: {
                    ...state.businessDetails,
                    jobsLoading: action.jobsLoading,
                    jobs: action.jobs || state.businessDetails.jobs,
                    filters: {
                        ...state.businessDetails.filters,
                        id: action.id ? action.id : "PC-",
                        startDate: action.startDate
                            ? action.startDate
                            : state.businessDetails.filters.startDate,
                        endDate: action.endDate
                            ? action.endDate
                            : state.businessDetails.filters.endDate,
                        provinces: action.provinces
                            ? action.provinces
                            : state.businessDetails.filters.provinces,
                        services: action.services
                            ? action.services
                            : state.businessDetails.filters.services
                    }
                }
            };
        case "SET_SEARCH_FIND_JOBS":
            return {
                ...state,
                search: {
                    ...state.search,
                    findJobs:action.findJobs
                }
            };
        case "SET_ALLOWED_DATA":
            return {
                ...state,
                currentSession: {
                    ...state.currentSession,
                    allowedFilters:action.allowedFilters,
                    allowedActions:action.allowedActions,
                    allowedData:action.allowedData
                }
            };
        case "SHOW_MONEY_MODAL":
            return {
                ...state,
                currentSession: {
                    showMoneyModal:action.showMoneyModal
                }
            };
        case "SET_GOLD_USER_DATA":
            return {
                ...state,
                currentSession: {
                    ...state.currentSession,
                    wollerData:action.wollerData ? action.wollerData:state.currentSession.wollerData,
                    guilds:action.guilds ? action.guilds:state.currentSession.guilds,
                    certificationState:action.certificationState ? action.certificationState : state.currentSession.certificationState 
                }
            };
        case "GENERAL_ALL_PACKS":
            return {
                ...state,
                general:{
                    ...state.general,
                    packs:action.packs,
                } 
                
            };
        case "GENERAL_ALL_GUILDS":
            return {
                ...state,
                general:{
                    ...state.general,
                    allGuilds:action.guilds,
                } 
                
            };
        case "GENERAL_FILTER_GUILDS":
            return {
                ...state,
                general:{
                    ...state.general,
                    guilds:action.guilds,
                } 
                
            };
        case "GENERAL_FILTER_AGENTS":
            return {
                ...state,
                general:{
                    ...state.general,
                     agents:action.agents,
                } 
                
            };
        case "GENERAL_FILTER_BUSINESSES":
            return {
                ...state,
                general:{
                    ...state.general,
                    businesses:action.businesses,
                } 
                
            };
        //b2b session 
        case "SET_B2B_ROLE":
            return {
            ...state,
            user: {
                    ...state.user,
                    isB2b: action.isB2b,
                }
            };
        case "SET_USER_BUSINESS":
            return {
            ...state,
            user: {
                ...state.user,
                business: action.business,
            }
        };
        //gold session
        case "SET_USER_ROLE":
            return {
                ...state,
                user: {
                    ...state.user,
                    isGoldWoller: action.isGoldWoller ? action.isGoldWoller:state.user.isGoldWoller,
                    isConstructionCompany: action.isConstructionCompany ? action.isConstructionCompany:state.user.isConstructionCompany,
                    promotionData:action.promotionData ? action.promotionData:state.user.promotionData
                }
            };
        case "SET_GOLD_USER_ID":
            return {
                ...state,
                user: {
                    ...state.user,
                    goldId: action.goldId,
                }
            };
        case "SET_GOLD_USER_PHONE":
            return {
                ...state,
                user: {
                    ...state.user,
                    goldPhone: action.goldPhone,
                }
            };
        case "SET_GOLD_USER_GUILDS":
            return {
                ...state,
                user: {
                    ...state.user,
                    goldGuilds: action.goldGuilds,
                }
            };
        case "SET_GOLD_USER_BALANCE":
            return {
                ...state,
                user: {
                    ...state.user,
                    goldBalance: action.goldBalance,
                }
            };
        case "SET_GOLD_USER_SUBSCRIPTION":
            return {
                ...state,
                user: {
                    ...state.user,
                    isUserSubscription: action.isUserSubscription,
                }
            };
        case "SET_SESSION_LOADED":
            return {
                ...state,
                currentSession: {
                    ...state.currentSession,
                    loaded: action.loaded,
                }
            };
        case "SET_CONTRACTOR_DATA":
            return {
                ...state,
                currentSession: {
                    ...state.currentSession,
                    contractor: action.contractor?action.contractor:state.currentSession.contractor,
                    marketplaceBaseUrl: action.marketplaceBaseUrl?action.marketplaceBaseUrl:state.currentSession.marketplaceBaseUrl,
                }
            };
        //mediator session
        case "SET_USER_MEDIATOR":
            return {
                ...state,
                user: {
                    ...state.user,
                    isMediator: action.isMediator,
                }
            };
        //agent Manager
        case "SEARCH_AGENT_KEYWORD":
                return {
                    ...state,
                    agentsSearch: {
                        searchKeyword: action.searchKeyword,
                    }
                };
        case "SEARCH_AGENT_REFRESH":
                return {
                    ...state,
                    agentsSearch: {
                        refresh: action.refresh,
                    }
                };
        // business
        case "BUSINESS_SEARCH_LOADING":
            return {
                ...state,
                businessSearch: {
                    ...state.businessSearch,
                    loading: action.loading
                }
            };
        case "BUSINESS_SEARCH":
            return {
                ...state,
                businessSearch: {
                    ...state.businessSearch,
                    items: action.items
                }
            };
        case "BUSINESS_SEARCH_FILTER":
            return {
                ...state,
                businessSearch: {
                    ...state.businessSearch,
                    levels: action.levels
                        ? action.levels
                        : state.businessSearch.levels,
                    id: action.id ? action.id : state.businessSearch.id
                }
            };
        case "BUSINESS_DETAIL":
            return {
                ...state,
                businessDetails: {
                    ...state.businessDetails,
                    business: action.business || state.businessDetails.business,
                    billing: action.billing,
                    promotion: action.promotion,
                    jobs: action.jobs || state.businessDetails.jobs
                }
            };
        case "BUSINESS_JOB_LOADING":
            return {
                ...state,
                businessDetails: {
                    ...state.businessDetails,
                    jobsloading: action.jobsLoading
                }
            };
        case "BUSINESS_BILL_LOADING":
            return {
                ...state,
                businessDetails: {
                    ...state.businessDetails,
                    billsLoading: action.billsLoading
                }
            };
        case "BUSINESS_DETAILS_RESET":
            return {
                ...state,
                businessDetails: {
                    ...state.businessDetails,
                    business: action.business,
                    billing: action.billing,
                    jobs: action.jobs,
                    jobsLoading: action.jobsLoading,
                    bills: action.bills,
                    billsLoading: action.billsLoading,
                    billsTotal: action.billsTotal,
                    filters: {
                        ...state.businessDetails.filters,
                        id: action.id,
                        startDate: action.startDate,
                        endDate: action.endDate,
                        provinces: action.provinces,
                        services: action.services,
                        month: action.month
                    }
                }
            };
        //Billing
        case "BILLING_SEARCH_LOADING":
            return {
                ...state,
                billingSearch: {
                    ...state.billingSearch,
                    loading: action.loading
                }
            };
        case "BILLING_SEARCH":
            return {
                ...state,
                billingSearch: {
                    ...state.billingSearch,
                    items: action.items
                }
            };
        case "BILLING_SEARCH_FILTER":
            return {
                ...state,
                billingSearch: {
                    ...state.billingSearch,
                    startDate: action.startDate
                        ? action.startDate
                        : action.startDate === null
                        ? undefined
                        : state.billingSearch.startDate,
                    endDate: action.endDate
                        ? action.endDate
                        : action.endDate === null
                        ? undefined
                        : state.billingSearch.endDate,
                    methodArray: action.methodArray
                        ? action.methodArray
                        : state.billingSearch.methodArray,
                    typeArray: action.typeArray
                        ? action.typeArray
                        : state.billingSearch.typeArray,
                    businessIdArray: action.businessIdArray
                        ? action.businessIdArray
                        : state.billingSearch.businessIdArray,
                    stateArray: action.stateArray
                        ? action.stateArray
                        : state.billingSearch.stateArray,
                    signArray: action.signArray
                        ? action.signArray
                        : state.billingSearch.signArray,
                    provinces: action.provinces
                        ? action.provinces
                        : state.billingSearch.provinces,
                    id: action.id ? action.id : state.billingSearch.id
                }
            };
        // search
        case "SEARCH_LOADING":
            return {
                ...state,
                search: {
                    ...state.search,
                    loading: action.loading
                }
            };
        case "SEARCH_PIPEDRIVE":
            return {
                ...state,
                search: {
                    ...state.search,
                    pipedrive: action.pipedrive
                }
            };
        case "SEARCH_FILTER_ID":
            return {
                ...state,
                search: {
                    ...state.search,
                    currentIdType:action.currentIdType,
                    itemsTitle:
                        action.currentIdType === "lead" && !state.user.isGoldWoller && !state.user.isB2b ? leadTitle 
                        :action.currentIdType === "lead" && state.user.isGoldWoller ? leadTitleGoldUsers 
                        :action.currentIdType === "lead" && state.user.isB2b ? leadTitleb2b 
                        :action.currentIdType === "fixed" && state.user.isB2b ? fixedTitleb2b 
                        :action.currentIdType === "fixed" ? fixedTitle :null
                }
            };
        case "SEARCH_FILTER_INCIDENCES":
            return {
                ...state,
                search: {
                    ...state.search,
                    onlyIncidences:action.incidences,
                }
            };
        case "SEARCH_FILTER_PROVINCES":
            return {
                ...state,
                search: {
                    ...state.search,
                    provinces: action.provinces,
                }
            };
        case "SEARCH_FILTER_GUILDS":
            return {
                ...state,
                search: {
                    ...state.search,
                    guilds: action.guilds,
                }
            };
        case "SEARCH_FILTER_BUSINESSES":
            return {
                ...state,
                search: {
                    ...state.search,
                    businesses: action.businesses,
                }
            };
        case "SEARCH_FILTER_AGENTS":
            return {
                ...state,
                search: {
                    ...state.search,
                    agents: action.agents,
                }
            };
        case "SEARCH_FILTER_LEVELS":
            return {
                ...state,
                search: {
                    ...state.search,
                    levels: action.levels,
                }
            };
        case "SEARCH_FILTER_SLOTS":
            return {
                ...state,
                search: {
                    ...state.search,
                    slots: action.slots,
                }
            };
        case "SEARCH_FILTER_BUDGET":
            return {
                ...state,
                search: {
                    ...state.search,
                    budget: action.budget,
                }
            };
        case "SEARCH_FILTER_JOBSTATE":
            return {
                ...state,
                search: {
                    ...state.search,
                    jobStates: action.jobStates,
                }
            };
        case "SEARCH_FILTER_DATE":
            return {
                ...state,
                search: {
                    ...state.search,
                    startDate: action.startDate,
                    endDate: action.endDate,
                }
            };
        case "SEARCH_FILTER_APPOINTMENT":
            return {
                ...state,
                search: {
                    ...state.search,
                    appointmentStart: action.appointmentStart,
                    appointmentEnd: action.appointmentEnd,
                }
            };
        case "SEARCH_RESULTS":
            return {
                ...state,
                search: {
                    ...state.search,
                    services: action.services,
                    count: action.count
                }
            };
        case "SEARCH_FILTER_USER":
            return {
                ...state,
                search: {
                    //userId, userPhone
                    items: action.items
                }
            };
        case "SEARCH_NAVCOLLAPSED":
            return {
                ...state,
                search: {
                    ...state.search,
                    navCollapsed: action.navCollapsed
                }
            };
        case "SEARCH_CHANGE_PAGE":
            return {
                ...state,
                search: {
                    ...state.search,
                    currentPage: action.currentPage
                }
            };
        case "SEARCH_CLEAN":
            return {
                ...state,
                search: {
                    ...state.search,
                    startDate: undefined,
                    endDate: undefined,
                    provinces:  undefined,
                    guilds:  action.specialGuilds? action.specialGuilds:undefined,
                    levels:  undefined,
                    slots:  undefined,
                    jobStates:  action.specialJobStates? action.specialJobStates:undefined,
                    agents:  undefined,
                    businesses:  action.businesses,
                    budget : undefined,
                    onlyIncidences:false,
                    prevIDSearch:"",
                    prevClientNameSearch:"",
                    prevClientPhoneSearch:"",
                    prevServiceNameSearch:"",
                    appointmentStart:undefined,
                    appointmentEnd:undefined,
                    services:[]
                }
            };
         // explore
        case "EXPLORE_LOADING":
            return {
                ...state,
                explore: {
                    ...state.explore,
                    loading: action.loading
                }
            };
        case "EXPLORE_FILTER_PROVINCES":
            return {
                ...state,
                explore: {
                    ...state.explore,
                    provinces: action.provinces,
                }
            };
        case "EXPLORE_FILTER_DISTANCE":
            return {
                ...state,
                explore: {
                    ...state.explore,
                    distance: action.distance,
                }
            };
        case "EXPLORE_FILTER_GUILDS":
            return {
                ...state,
                explore: {
                    ...state.explore,
                    guilds: action.guilds,
                }
            };

        case "EXPLORE_FILTER_LEVELS":
            return {
                ...state,
                explore: {
                    ...state.explore,
                    levels: action.levels,
                }
            };
        case "EXPLORE_FILTER_SLOTS":
            return {
                ...state,
                explore: {
                    ...state.explore,
                    slots: action.slots,
                }
            };
        case "EXPLORE_FILTER_DATE":
            return {
                ...state,
                explore: {
                    ...state.explore,
                    startDate: action.startDate,
                    endDate: action.endDate,
                }
            };
        case "EXPLORE_RESULTS":
            return {
                ...state,
                explore: {
                    ...state.explore,
                    services: action.services,
                    count: action.count
                }
            };
            //Matchs
        case "MATCH_DETAILS_LOADING":
            return {
                ...state,
                matchDetails: {
                    ...state.matchDetails,
                    loading: action.loading,
                }
            };
        case "SET_MATCH_TITLES":
            return {
                ...state,
                matchDetails: {
                    ...state.matchDetails,
                    matchTitles: action.matchTitles,
                }
            };
        case "SET_MATCH_ITEMS":
            return {
                ...state,
                matchDetails: {
                    ...state.matchDetails,
                    services: action.items,
                }
            };
        case "MATCH_FILTER_BUSINESS":
            return {
                ...state,
                matchDetails: {
                    ...state.matchDetails,
                    business: action.business,
                }
            };
        case "MATCH_FILTER_AGENTS":
            return {
                ...state,
                matchDetails: {
                    ...state.matchDetails,
                    agents: action.agents,
                }
            };
        case "MATCH_FILTER_AGENTS":
            return {
                ...state,
                matchDetails: {
                    ...state.matchDetails,
                    provinces: action.provinces,
                }
            };
        case "MATCH_FILTER_DATE":
            return {
                ...state,
                matchDetails: {
                    ...state.matchDetails,
                    startDate: action.startDate,
                    endDate: action.endDate,
                }
            };
        case "MATCH_FILTER_SERVICE_DATE":
            return {
                ...state,
                matchDetails: {
                    ...state.matchDetails,
                    startServiceDate: action.startDate,
                    endServiceDate: action.endDate,
                }
            };
        case "MATCH_CHANGE_PAGE":
            return {
                ...state,
                matchDetails: {
                    ...state.matchDetails,
                    currentPage: action.currentPage
                }
            };
        case "MATCH_CLEAN":
            return {
                ...state,
                matchDetails: {
                    ...state.matchDetails,
                    startServiceDate: undefined,
                    endServiceDate: undefined,
                    startDate:  undefined,
                    endDate:  undefined,
                    business:  undefined,
                    agents:  undefined,
                    services:[]
                }
            };
        // job
        case "JOB_GUILD":
            return {
                ...state,
                job: {
                    ...state.job,
                    guild: action.guild
                }
            };
        case "JOB_STORE":
            return {
                ...state,
                job: {
                    ...state.job,
                    store: {
                        ...state.job.store,
                        exists: action.exists
                    }
                }
            };
        case "JOB_ROUTER":
            return {
                ...state,
                job: {
                    ...state.job,
                    history: action.history,
                    location: action.location,
                    match: action.match
                }
            };
        case "JOB_UPDATE_BREADCRUMB":
            return {
                ...state,
                job: {
                    ...state.job,
                    breadCrumb: action.breadCrumb
                }
            };
        case "JOB_CATEGORY_GUILD_ID_TYPE":
            return {
                ...state,
                job: {
                    ...state.job,
                    category: {
                        ...state.job.category,
                        categoryId: action.categoryId,
                        categoryType: action.categoryType
                    }
                }
            };
        case "JOB_CATEGORY_GUILD_SUBCATEGORY":
            return {
                ...state,
                job: {
                    ...state.job,
                    category: {
                        ...state.job.category,
                        subCategoryId: action.subCategoryId
                    },
                    store: {
                        ...state.job.store,
                        latitude: action.latitude,
                        longitude: action.longitude,
                        exists: action.exists
                    }
                }
            };
        case "JOB_LOCATE_GMAPS":
            return {
                ...state,
                job: {
                    ...state.job,
                    locate: {
                        ...state.job.locate,
                        map: action.map ? action.map : state.job.locate.map,
                        maps: action.maps ? action.maps : state.job.locate.maps,
                        area: action.area ? action.area : state.job.locate.area
                    },
                    directionsService: action.directionsService
                        ? action.directionsService
                        : state.job.directionsService,
                    directionsDisplay: action.directionsDisplay
                        ? action.directionsDisplay
                        : state.job.directionsDisplay
                }
            };
        case "JOB_LOCATE":
            return {
                ...state,
                job: {
                    ...state.job,
                    locate: {
                        ...state.job.locate,
                        address: action.address,
                        address2: action.address2,
                        addressSelected: action.addressSelected,
                        center: action.center,
                        disabledBtn: action.disabledBtn,
                        error: action.error,
                        postalCode: action.postalCode
                    }
                }
            };
        case "JOB_TRANSPORT":
            return {
                ...state,
                job: {
                    ...state.job,
                    transport: action.transport
                }
            };
        case "JOB_WEIGHT":
            return {
                ...state,
                job: {
                    ...state.job,
                    weight: {
                        ...state.job.weight,
                        amount: action.amount,
                        disabledBtn: action.disabledBtn,
                        error: action.error
                    }
                }
            };
        case "JOB_PRICE":
            return {
                ...state,
                job: {
                    ...state.job,
                    price: {
                        ...state.job.price,
                        amount: action.amount
                    }
                }
            };
        case "JOB_PRICE_EXTRAS":
            return {
                ...state,
                job: {
                    ...state.job,
                    price: {
                        ...state.job.price,
                        extrasArray: action.extrasArray,
                        extraAmount: action.extraAmount
                    }
                }
            };
        case "JOB_PRICE_SELECTED":
            return {
                ...state,
                job: {
                    ...state.job,
                    price: {
                        ...state.job.price,
                        selectedOption: action.selectedOption,
                        buttonTitle: action.buttonTitle
                    },
                    urgency: {
                        ...state.job.urgency,
                        selectedOption: action.selectedOption
                    }
                }
            };
        case "JOB_AVAILABILITY":
            return {
                ...state,
                job: {
                    ...state.job,
                    availability: {
                        ...state.job.availability,
                        dateTo: action.dateTo,
                        buttonTitle: action.buttonTitle
                    }
                }
            };
        case "JOB_DATE":
            return {
                ...state,
                job: {
                    ...state.job,
                    date: {
                        ...state.job.date,
                        dateTransport: action.dateTransport,
                        buttonTitle: action.buttonTitle
                    }
                }
            };
        case "JOB_DESCRIPTION":
            return {
                ...state,
                job: {
                    ...state.job,
                    description: {
                        ...state.job.description,
                        message: action.message,
                        tooltip: action.tooltip,
                        styleType: action.styleType,
                        error: action.error,
                        disabledBtn: action.error
                    }
                }
            };
        case "JOB_PICTURE":
            return {
                ...state,
                job: {
                    ...state.job,
                    picture: {
                        ...state.job.picture,
                        files: action.files
                    }
                }
            };
        case "JOB_URGENCY":
            return {
                ...state,
                job: {
                    ...state.job,
                    urgency: {
                        ...state.job.urgency,
                        selectedOption: action.selectedOption
                    }
                }
            };
        case "JOB_SIGNIN_STEP":
            return {
                ...state,
                job: {
                    ...state.job,
                    signIn: {
                        ...state.job.signIn,
                        step: action.step
                    }
                }
            };
        case "JOB_SIGNIN_SHOW_HIDE":
            return {
                ...state,
                job: {
                    ...state.job,
                    signIn: {
                        ...state.job.signIn,
                        visible: action.visible
                    }
                }
            };
        case "JOB_SIGNIN_PHONE":
            return {
                ...state,
                job: {
                    ...state.job,
                    signIn: {
                        ...state.job.signIn,
                        phoneModal: {
                            ...state.job.signIn.phoneModal,
                            areaCode: action.areaCode,
                            phone: action.phone
                        }
                    }
                }
            };
        case "JOB_SIGNIN_NAME":
            return {
                ...state,
                job: {
                    ...state.job,
                    signIn: {
                        ...state.job.signIn,
                        nameModal: {
                            ...state.job.signIn.nameModal,
                            name: action.name,
                            email: action.email
                        }
                    },
                    currentUser: action.currentUser
                }
            };
        case "JOB_LEAD_TO_FIXED":
            return {
                ...state,
                job: {
                    ...state.job,
                    fromLeadToFixed: action.fromLeadToFixed,
                    leadToFixedId: action.leadToFixedId,
                    signIn: {
                        ...state.job.signIn,
                        nameModal: {
                            ...state.job.signIn.nameModal,
                            name: action.name,
                            email: action.email
                        },
                        phoneModal: {
                            ...state.job.signIn.phoneModal,
                            areaCode: "",
                            phone: action.phone
                        }
                    }
                }
            };
        case "JOB_RESULTS_FIXED_DRAFT":
            return {
                ...state,
                job: {
                    ...state.job,
                    results: {
                        ...state.job.results,
                        fixedDraftId: action.fixedDraftId,
                        userPDF: action.userPDF,
                        jobinPDF: action.jobinPDF,
                        joberPDF: action.joberPDF
                    }
                }
            };
        case "JOB_RESULTS_FIXED":
            return {
                ...state,
                job: {
                    ...state.job,
                    results: {
                        ...state.job.results,
                        fixedId: action.fixedId,
                        userPDF: action.userPDF,
                        jobinPDF: action.jobinPDF,
                        joberPDF: action.joberPDF
                    }
                }
            };
        case "JOB_RESULTS_LEAD":
            return {
                ...state,
                job: {
                    ...state.job,
                    results: {
                        ...state.job.results,
                        leadId: action.leadId
                    }
                }
            };
        case "JOB_RESULTS_TRANSPORT":
            return {
                ...state,
                job: {
                    ...state.job,
                    results: {
                        ...state.job.results,
                        transportId: action.transportId,
                        userPDF: action.userPDF,
                        jobinPDF: action.jobinPDF,
                        joberPDF: action.joberPDF,
                        warehousePDF: action.warehousePDF
                    }
                }
            };
        case "JOB_RESET":
            const history = state.job.history;
            const location = state.job.location;
            const match = state.job.match;
            return {
                ...state,
                job: {
                    ...action.job,
                    history: history,
                    location: location,
                    match: match
                }
            };
        // jobDetails
        case "JOB_DETAILS_JOB":
            return {
                ...state,
                jobDetails: {
                    ...state.jobDetails,
                    job: action.job ? action.job : state.jobDetails.job,
                    fixedInfo: action.fixedInfo
                        ? action.fixedInfo
                        : state.jobDetails.fixedInfo,
                    transportInfo: action.transportInfo
                        ? action.transportInfo
                        : state.jobDetails.transportInfo,
                    paymentsArray: action.paymentsArray
                        ? action.paymentsArray
                        : state.jobDetails.paymentsArray,
                    historyArray: action.historyArray
                        ? action.historyArray
                        : state.jobDetails.historyArray,
                    extrasArray: action.extrasArray
                        ? action.extrasArray
                        : state.jobDetails.extrasArray,
                    jobersArray: action.jobersArray
                        ? action.jobersArray
                        : state.jobDetails.jobersArray,
                    getPayments: action.getPayments
                        ? action.getPayments
                        : state.jobDetails.getPayments,
                    getHistoryOfJob: action.getHistoryOfJob
                        ? action.getHistoryOfJob
                        : state.jobDetails.getHistoryOfJob,
                    paymentClientData: action.paymentClientData
                        ? action.paymentClientData
                        : state.jobDetails.paymentClientData,
                    readMode: action.readMode
                        ? action.readMode
                        : state.jobDetails.readMode,
                }
            };
        case "JOB_DETAILS_RESET":
            return {
                ...state,
                jobDetails: {
                    ...state.jobDetails,
                    fixedInfo: action.fixedInfo,
                    transportInfo: action.transportInfo,
                    paymentsArray: action.paymentsArray,
                    historyArray: action.historyArray,
                    extrasArray: action.extrasArray,
                    jobersArray: action.jobersArray,
                    readMode: action.readMode
                }
            };
        case "SET_FISCAL_INFO":
            return {
                ...state,
                job: {
                    ...state.job,
                    billingInfo: {
                        ...state.job.billingInfo,
                        id: action.id,
                        fiscalName: action.fiscalName,
                        identityNumber: action.identityNumber,
                        fiscalAddress: action.fiscalAddress,
                        fiscalPostalCode: action.fiscalPostalCode,
                        fiscalCity: action.fiscalCity
                    }
                }
            };
        case "NOTIFICATION_MENU":
            return {
                ...state,
                crmNotifications: {
                    ...state.crmNotifications,
                    showMenu: action.showMenu
                }
            };
        //user search
        case "USER_SEARCH_LOADING":
            return {
                ...state,
                userSearch: {
                    ...state.userSearch,
                    loading: action.loading
                }
            };
        case "USER_SEARCH":
            return {
                ...state,
                userSearch: {
                    ...state.userSearch,
                    items: action.items
                }
            };
        case "USER_SEARCH_OPTION":
            return {
                ...state,
                userSearch: {
                    ...state.userSearch,
                    idType: action.idType,
                    itemsTitle:
                        action.idType === "jober" ? joberTitles : clientTitles
                }
            };
        case "USER_SEARCH_FILTER":
            return {
                ...state,
                userSearch: {
                    ...state.userSearch,
                    startDate: action.startDate
                        ? action.startDate
                        : state.userSearch.startDate,
                    endDate: action.endDate
                        ? action.endDate
                        : state.userSearch.endDate,
                    levels: action.levels
                        ? action.levels
                        : state.userSearch.levels,
                    distance: action.distance
                        ? action.distance
                        : state.userSearch.distance,
                    location: action.location
                        ? action.location
                        : state.userSearch.location,
                    points: action.points
                        ? action.points
                        : state.userSearch.points,
                    leads: action.leads ? action.leads : state.userSearch.leads,
                    guilds: action.guilds
                        ? action.guilds
                        : state.userSearch.guilds,
                    isReaded: action.isReaded !=null ? action.isReaded : state.userSearch.isReaded,
                    business: action.business
                        ? action.business
                        : state.userSearch.business
                }
            };
        case "USER_SEARCH_SET_IDTYPE":
            return{
                ...state,
                userSearch: {
                    ...state.userSearch,
                    idType:action.idType
                }
            };
        case "USER_SEARCH_SELECTED":
            return{
                ...state,
                userSearch: {
                    ...state.userSearch,
                    selected:action.selected
                }
            };
        case "USER_SEARCH_SET_OPTION_ARRAY":
            return{
                ...state,
                userSearch: {
                    ...state.userSearch,
                    typeArray:action.typeArray
                }
            };
        case "USER_DETAILS_LOADING":
            return {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    loading: action.loading
                }
            };
        case "USER_DETAILS_JOBS_LOADING":
            return {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    jobsLoading: action.jobsLoading
                }
            };
        case "USER_DETAILS":
            return {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    user: action.user ? action.user : state.userDetails.user,
                    tasks: action.tasks
                        ? action.tasks
                        : state.userDetails.tasks,
                    info: action.info ? action.info : state.userDetails.info,
                    billingInfo: action.billingInfo
                        ? action.billingInfo
                        : state.userDetails.billingInfo,
                    paymentMethods: action.paymentMethods
                        ? action.paymentMethods
                        : state.userDetails.paymentMethods,
                    payments: action.payments
                        ? action.payments
                        : state.userDetails.payments,
                    jobs: action.jobs ? action.jobs : state.userDetails.jobs,
                    chat:action.chat ? action.chat : state.userDetails.chat,
                }
            };
        case "USER_DETAILS_FILTERS":
            return {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    filters: {
                        id: action.id
                            ? action.id
                            : state.userDetails.filters.id,
                        startDate: action.startDate
                            ? action.startDate
                            : state.userDetails.filters.startDate,
                        endDate: action.endDate
                            ? action.endDate
                            : state.userDetails.filters.endDate,
                        provinces: action.provinces
                            ? action.provinces
                            : state.userDetails.filters.provinces,
                        services: action.services
                            ? action.services
                            : state.userDetails.filters.services
                    }
                }
            };
        case "USER_SET_ITEMS_TITLES":
                return {
                    ...state,
                    userSearch: {
                        ...state.userSearch,
                        itemsTitle: action.itemsTitle,
                    }
                };
        //userDetailsBIPRO
        case "USER_DETAILS_LOADING":
            return {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    loading: action.loading
                }
            };
        case "USER_DETAILS_SET_INFO":
            return {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    reviews:action.reviews ? action.reviews :state.userDetails.reviews,
                    tasks:action.tasks ? action.tasks :state.userDetails.tasks,
                    user:action.user ? action.user :state.userDetails.user,
                    wollerData:action.wollerData ? action.wollerData :state.userDetails.wollerData,
                    wollerLocation:action.wollerLocation ? action.wollerLocation :state.userDetails.wollerLocation,
                }
            };
        //notifications
        case "CRM_NOTIFICATIONS":
            return {
                ...state,
                crmNotifications: {
                    ...state.crmNotifications,
                    notifications: action.notifications
                }
            };
        //kpis
        case "KPIS_DETAILS_LOADING":
            return {
                ...state,
                kpisDetail: {
                    ...state.kpisDetail,
                    loading: action.loading
                }
            };
        case "SET_ITEMS_KPIS":
            return {
                ...state,
                kpisDetail:{
                    ...state.kpisDetail,
                    results:
                            action.items !== undefined
                                ? action.items
                                : state.search.items,
                        
                }
            };
        case "SET_KPIS_FILTER":

            return {
                ...state,
                kpisDetail:{
                    ...state.kpisDetail,
                    filters:{
                        startDate: action.startDate
                            ? action.startDate
                            : state.kpisDetail.filters.startDate,
                        endDate: action.endDate
                            ? action.endDate
                            : state.kpisDetail.filters.endDate,
                        provinces: action.provinces
                            ? action.provinces
                            : state.kpisDetail.filters.provinces,
                        guilds: action.guilds
                            ? action.guilds
                            : state.kpisDetail.filters.guilds,
                        business: action.business
                            ? action.business
                            : state.kpisDetail.filters.business
                    }
                }
            };
        default:
            return state;
    }
};
