import React, { useState, useRef, useEffect } from "react";
import { RadioButton,Button } from "jobin-ui-components";
import makeAnimated from 'react-select/animated';
import Select from 'react-select';



import { changeBudgetData, closeAuctionJob,sendMailBudgetState, getCrmUserData,getWollesbyJob } from "../../utils/ParserUtilities";
import { useStateValue } from "../../state/context";



import MainModal from "./MainModal";


const StateModal = props => {
    const [{user, currentSession}] = useStateValue()
    const [budgetState, setBudgetState] = useState(null);
    const [budgetOrigin, setBudgetOrigin] = useState(null);
    const [coments, setComents] = useState("");
    const [wollers, setWollers] = useState([]);
    const [selectedWoller, setSelectedWoller] = useState(null);
    const animatedComponents = makeAnimated();
    const budgetStates = 
        [
            {id:"estimated",title:"Estimado"},
            {id:"desestimated",title:"Desestimado"},
            // {id:"pending",title:"Pendiente"},
            // {id:"undefined",title:"Indefinido"},

        ]
    const budgetOriginOptions = [
        {id:"presential",title:"Presencial"},
        {id:"telephonic",title:"Via telefonica"},
    ]
    const style = {
        addContainer: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 16,
            borderTop: "1px solid #e8e8e8",
            marginTop: 24,
        },
        input: {
            borderRadius: 0,
            width: 160,
            border: "1px solid var(--greyish)",
        },
    };

    useEffect(()=>{
        if(!props.data.Budget){
            setBudgetState("undefined")
            setBudgetOrigin("undefined")
        }else{
            setBudgetState(props.data.Budget.state)
            setBudgetOrigin(props.data.Budget.origin)
            setComents(props.data.Budget.coments);

        }
        async function fetchData(){
           let response = await getWollesbyJob(props.data.id)
            let wollersData = []
           response.map((woller)=>{
               if(!user?.isGoldWoller){
                   wollersData.push({id:woller.id , label:woller.get("Name") , value:woller.id})
                }else if(user?.isGoldWoller && currentSession?.wollerData?.id == woller.id){
                    wollersData.push({id:woller.id , label:woller.get("Name") , value:woller.id})
               }
           })

           setWollers(wollersData)
        }
        fetchData()
    },[props.data])

    const onClose = async () => {

        if((budgetState ==="estimated" || budgetState === "desestimated"/*|| budgetState === "pending"*/) && budgetOrigin !== null && selectedWoller != null ){
            var isSavedState =  changeBudgetData({state:budgetState,origin:budgetOrigin,coments:coments,userId:selectedWoller},props.data, user.isGoldWoller)
            // await sendMailBudgetState(props.data,coments,{state:budgetState,origin:budgetOrigin})
            Promise.all([isSavedState]).then(async ([response])=>{
                if( budgetState ==="estimated"){
                    closeAuctionJob(props.data.id)
                    props.onClose();
                    props.refreshJobs({})
                }else if(isSavedState){
                    props.onClose();
                    props.refreshJobs({})
                }
            })
        
        }else{
            alert("Debe completar todos los campos")
        }
       
    };
    const cancelBudget = async () => {
        props.onClose();
    };
    const handleChangeState = async stateId => {
        if(stateId !== "estimated"){
            setBudgetOrigin(null);
        }
        setBudgetState(stateId);
    };
    const handleChangeOrigin = async originId => {
        setBudgetOrigin(originId);
    };
    const handleChangeComents = async originId => {
        setComents(originId.target.value);
    };
    const handleChangeWoller = async (_data)=>{
        
        setSelectedWoller(_data.value)
    }

    const renderStateView = () => {
        return (
            <section>
                
                    {budgetStates.map((state, index) => {
                        return (
                            <RadioButton
                                key={"radio" + index}
                                item={{
                                    title:state.title,
                                    selected: budgetState === state.id
                                }}
                                onClick={() => handleChangeState(state.id)}
                            />
                        );
                    })
                    
                
                }
                <div>
                    {budgetState === "estimated" || budgetState === "desestimated" /*|| budgetState === "pending" */  ? (
                        <>
                        <br/>
                        {budgetOriginOptions.map((origin, index) => {
                            return (
                                <RadioButton
                                    style={{width:"170px"}}
                                    key={"radioOrigin"+origin.id}
                                    item={{
                                        title:origin.title,
                                        selected: budgetOrigin === origin.id
                                    }}
                                    onClick={() => handleChangeOrigin(origin.id)}
                                />
                            );
                        })}
                        <Select 
                            style={{width:"100% !important"}}
                            closeMenuOnSelect={true}
                            options={wollers}
                            component={animatedComponents}
                            defaultValue={[]}
                            onChange={handleChangeWoller}
                            />
                       <div className="display-flex flex-column">
                            <label style={{color: '#B0B0B0', fontSize: '12px', marginTop: '8px', width:"100%"}}>Comentarios</label>
                            <textarea  name="w3review" rows="4" cols="50" value={coments} onChange={handleChangeComents}></textarea>
                       </div>

                           
                        </>
                    ):null}
                </div>
                
            <div className="display-flex flex-column mr-auto ">
                <Button
                    className="stateButtonOnly"
                    onClick={onClose}
                    buttonText={"Almacenar"}
                    />
                    </div>
            </section>
        );
    };

    return (
        <MainModal
            title={"Estado del presupuesto"}
            subtitle={
                "Indica el estado actual del presupuesto."
            }
            isVisible={props.isVisible}
            value={wollers}
            onClose={ cancelBudget}
            defaultValue={wollers.length==1?wollers[0]:null}
            big
        >
            {renderStateView()}
        </MainModal>
    );
};

export default StateModal;
