import React,{useEffect, useState} from "react";
import { useStateValue } from "../../state/context";
import Parse from "parse";

import { PushMenu } from "jobin-ui-components";
import NotificationCell from "./NotificationCell";
import NotificationMessageCell from "./NotificationMessageCell";
import { apiId, jsKey, urlServer  } from '../../constants/credentials';

export default function NotificationMenu(props) {
    const [{ crmNotifications }, dispatch] = useStateValue();
    const [isWollerMessage, setIsWollerMessage] = useState(false);

    const style = {
        title: {
            paddingRight: 24,
            paddingLeft: 24,
            fontSize: 22,
            fontWeight: 700
        },
        separator: {
            width: 1,
            height: 40,
            backgroundColor: "black"
        },
        wrapper: {
            display: "flex",
            justifyContext: "row"
        },
        underlineOne: {
            width: 207,
            height: 1,
            backgroundColor: "black"
        },
        underlineTwo: {
            marginTop: -1,
            marginLeft: 207,
            width: 230,
            height: 1,
            backgroundColor: "black"
        }
    };
    useEffect(()=>{
        getPrevious()
    },[])
    async function getPrevious() {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();

        const notifications = await Parse.Cloud.run("updateAllVerticalMultiplier");

        if (notifications.status === "ok") {
            dispatch({
                type: "CRM_NOTIFICATIONS",
                notifications: notifications.result
            });
        }
    }
    
    return (
        <PushMenu
            onClose={() => {
                dispatch({
                    type: "NOTIFICATION_MENU",
                    showMenu: false
                });
            }}
        >
            <div style={{ overflowY: "scroll" }}>
                <div style={style.wrapper}>
                    <label style={style.title} onClick={ () => setIsWollerMessage(false)}>Notificaciones</label>
                    <div style={style.separator}></div>
                    <label style={style.title} onClick={ () => setIsWollerMessage(true)}>Mensajes</label>
                </div>
                {isWollerMessage && (
                    <div style={style.underlineOne}></div>
                )}
                {!isWollerMessage && (
                    <div style={style.underlineTwo}></div>
                )}
                {!isWollerMessage && crmNotifications.notifications.map(item => {
                    if(item.get("Type") != "newNotificationMessage"){
                        return <NotificationCell history={props.history} key={item.id} notification={item}/>
                    }
                })}
                {isWollerMessage && crmNotifications.notifications.map(item => {
                    if(item.get("Type") == "newNotificationMessage"){
                        return <NotificationMessageCell history={props.history} key={item.id} notification={item}/>
                    }
                })}
            </div>
        </PushMenu>
    );
}
