import React from "react";
import ActionCell from "../../../button/ButtonEmpty";

export default function FileInfoCell(props) {
    const style = {
        container: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            marginTop: 8,
            marginBottom: 8,
            alignItems: "center"
        },
        contentContainer: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            marginRight: 16
        },
        nameLabel: {
            display: "flex",
            flex: 1
        },
        priceLabel: {
            textAlign: "right",
            fontSize: 12
        },
        totalPriceLabel: {
            textAlign: "right",
            fontWeight: "bold"
        }
    };

    return (
        <section style={style.container}>
            <div style={style.contentContainer}>
                <label style={style.nameLabel}>
                    <a href={props.extra.url} target="_blank">
                        {props.extra.filename}
                    </a>
                </label>
            </div>
            <div style={style.contentContainer}>
                <label style={style.priceLabel}>
                    <ActionCell
                        buttonText="Eliminar"
                        onClick={props.onClick}
                        dataAttr={{ "data-filename": props.extra.filename }}
                    />
                </label>
            </div>
        </section>
    );
}
