import React, { useEffect } from "react";
import { useStateValue } from "../../state/context";
import StepTip from "./generic/StepTip";
import StepControlls from "./generic/StepControlls";

import "../../styles/job/service-completed.css";

import stepIconLeft from "../../assets/step-icon-left-2.svg";
import stepIconRight from "../../assets/step-icon-right-2.svg";
import publishIcon from "../../assets/completed-publish.svg";
import likeIcon from "../../assets/completed-like.svg";
import payIcon from "../../assets/completed-pay.svg";

export default function ServiceCompleted(props) {
    const [{ job }] = useStateValue();

    useEffect(() => {
        if (!(props.location.state && "inner" in props.location.state))
            props.history.replace("/job/new");
    }, []);

    const firstTimeList = (
        <React.Fragment>
            <div className="first-time-item">
                <div className="img-container">
                    <img src={publishIcon} alt="publica" />
                </div>
                <div className="excerpt">
                    <h4 className="title">Solicitud publicada</h4>
                    <p>
                        Hemos avisado a nuestros Wollers sobre la solicitud. En
                        breve recibirá una respuesta.
                    </p>
                </div>
            </div>
            <div className="first-time-item">
                <div className="img-container">
                    <img src={likeIcon} alt="escoge" />
                </div>
                <div className="excerpt">
                    <h4 className="title">Escoger el Woller</h4>
                    <p>
                        El cliente tiene total libertad a la hora de escoger al
                        Woller que mejor se adapte a sus necesidades. Lo mejor es
                        que compare diferentes presupuestos.
                    </p>
                </div>
            </div>
            <div className="first-time-item">
                <div className="img-container">
                    <img src={payIcon} alt="paga" />
                </div>
                <div className="excerpt">
                    <h4 className="title">Pagar y valorar</h4>
                    <p>
                        Una vez resuelta tu solicitud, podrá pagar a través de
                        la app y valorar el trabajo del Woller.
                    </p>
                </div>
            </div>
        </React.Fragment>
    );

    const firstTime = (
        <div className="first-time">
            <p className="title">¿Y ahora qué?</p>
            <p className="excerpt">
                La solicitud se ha publicado con éxito. Guía al cliente en los
                próximos pasos.
            </p>
            {firstTimeList}
        </div>
    );

    return (
        <div id="service-completed" className="section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="title">¡Solicitud publicada!</h4>
                        <ul>
                            {job.results.userPDF && (
                                <li>
                                    <a
                                        href={job.results.userPDF}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Albarán de usuario
                                    </a>
                                </li>
                            )}
                            {job.results.jobinPDF && (
                                <li>
                                    <a
                                        href={job.results.jobinPDF}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Albarán de Wolly
                                    </a>
                                </li>
                            )}
                            {job.results.joberPDF && (
                                <li>
                                    <a
                                        href={job.results.joberPDF}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Albarán de Woller
                                    </a>
                                </li>
                            )}
                            {job.results.warehousePDF && (
                                <li>
                                    <a
                                        href={job.results.warehousePDF}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Albarán de almacen
                                    </a>
                                </li>
                            )}
                        </ul>
                        <div className="content">{firstTime}</div>
                    </div>
                    <div className="col-md-offset-1 col-md-4">
                        <StepTip
                            iconLeft={stepIconLeft}
                            iconRight={stepIconRight}
                            title="Garantía Wolly"
                            excerpt="Gracias a nuestra colaboración con Allianz tu solicitud está cubierta. Para activar el seguro solo tienes que pagar el servicio por Jobin, así de fácil."
                        />
                    </div>
                </div>
            </div>
            <StepControlls
                nextTitle="Ir al detalle del trabajo"
                next={`job/${job.results.leadId ||
                    job.results.transportId ||
                    job.results.fixedId}`}
            />
        </div>
    );
}
