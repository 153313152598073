import React from "react";

import MainModal from "./MainModal"

const ModalEventsBiPro = ({isVisible,onClose,image,acction,title,subTitle,buttonText,classNamesModal,aditionalData=undefined, campain}) => {
    return (
   
        <MainModal
            modalMed = {true}
			isVisible={isVisible}
			onClose={()=>onClose()}
            center={true}
            banner={image}
            title={title}
            subtitle={subTitle}
            classNamesModal={classNamesModal}
		>
            
          {(acction && !campain) && <button onClick={()=>acction(aditionalData)} className="buttonModal">{buttonText}</button>}
        </MainModal>
    )
}

export default ModalEventsBiPro;