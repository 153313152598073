import React, { useState, useEffect } from "react";
import MainModal from "./MainModal";
import Parse from 'parse';
import { initialState } from "../../state/initialState";

import '../../styles/job/incidents.css';
import { apiId, jsKey, urlServer } from '../../constants/credentials';

const IncidentDetailModal = props => {
    const [incidentDetail, setIncidentDetail] = useState();
    const [state, setState] = useState();
    const [description, setDescription] = useState();
    const [comments, setComments] = useState();

    useEffect(() => {
        props.incidents.map( incident => {
            if(incident.id === props.incidentId){
                setIncidentDetail(incident);
                setState(incident.get('ExtraInfo').state);
            } 
        })
        getIncidentCommentsById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeState = async ( newState ) => {
        if(newState !== state){
            Parse.initialize(apiId, jsKey);
            Parse.serverURL = urlServer;
            Parse.User.enableUnsafeCurrentUser();

            const data = {
                incidentId: props.incidentId,
                newState: newState
            }
    
            await Parse.Cloud.run("changeIncidentState", data);
            setState(newState);
        }
    }

    const addNewComment =  async () => {
        if(description){
            Parse.initialize(apiId, jsKey);
            Parse.serverURL = urlServer;
            Parse.User.enableUnsafeCurrentUser();
            
            const data = {
                incidentId: props.incidentId,
                description: description,
                CRMUser: initialState.agent.agentId,
                jobId: props.jobId
            }
    
            await Parse.Cloud.run("addNewIncidentComment", data);
            setDescription('');
            getIncidentCommentsById();
        }
    }

    const getIncidentCommentsById = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let res = await Parse.Cloud.run("getIncidentCommentsById", {incidentId: props.incidentId, jobId: props.jobId});
        setComments(res)
    }

    const deleteIncident = async () =>{
        if(window.confirm("¿Estas seguro que desea eliminar esta incidencia?")){
            Parse.initialize(apiId, jsKey);
            Parse.serverURL = urlServer;
            Parse.User.enableUnsafeCurrentUser();
            await Parse.Cloud.run("deleteIncident", {incidentId: props.incidentId, jobId: props.jobId});
            props.fetchIncidents();
            props.onClose();
        }
    }

    return(
        <MainModal
            style={{ height: "700px", width: "800px" }}
            title={"Detalle de la Incidencia"}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        > 
            <div style={{display:"flex", justifyContent: "space-between"}}>
                <p className='incident-detail-typology'>{incidentDetail?.get('ExtraInfo').typology}</p>
                <button onClick={() => deleteIncident()} className='delete-incident-button'>Eliminar</button>
            </div>

            <div className='choose-state'>
                <button className={(state === 'abierto') ? 'abierto': 'state-grey' } onClick={ () => changeState('abierto')}>Abierto</button>
                <button className={(state === 'in-progress') ? 'in-progress': 'state-grey'} onClick={ () => changeState('in-progress')}>En gestion</button>
                <button className={(state === 'cerrada') ? 'cerrada': 'state-grey' } onClick={ () => changeState('cerrada')}>Cerrada</button>
            </div>

            <p className='incident-comment-title'> Comentar:</p>
            <input 
                type="textarea" 
                name="textValue"
                value={description}
                className="incident-textarea"
                placeholder= 'Nuevo comentario...'
                onChange={e => setDescription(e.target.value)}
            />
            <button className='incident-save-comment' onClick={ () => addNewComment()}>Guardar</button>
            {
                comments && (
                    comments.slice(0, 6).map( comment => (
                        props.incidentId && (comment.get('ExtraInfo').incidentId === props.incidentId) && (
                            <div className='incident-comment-wrapper'>
                                <p className='incident-comment-description'>{comment.get('ExtraInfo').description}</p>
                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                    <p className='incident-comment-user'>{comment.get('CRMUser').get('Name')}</p>
                                    <p className='incident-comment-date'>{props.formatDate(comment.get('createdAt').toString())}</p>
                                </div>
                            </div>
                        )
                    ))
                )
            }

        </MainModal>
    )
}

export default IncidentDetailModal;