import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { hasClassInTree } from "../../../utils/checkAncestorDOM";

import {MenuList} from 'jobin-ui-components'

export default function PaymentCell(props) {
    const [action] = useState(Jobin.Payment.formatAction(props.payment));
    const [optionsMenu, setOptionMenu] = useState(null);
    const [showOptions, setShowOptions] = useState(false);

    const style = {
        container: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            marginTop: 12,
            marginBottom: 12,
            alignItems: "center"
        },
        typeLabel: {
            width: 120,
            marginRight: 8
        },
        totalPriceLabel: {
            textAlign: "right",
            fontWeight: "bold"
        },
        priceLabel: {
            textAlign: "right",
            fontSize: 12
        },
        dateLabel: {
            marginRight: 18,
            color: "var(--greyish)"
        },
        stateLabel: {
            marginRight: 12,
            color: action === "Pago pendiente" ? "var(--coral)" : action === "Pago facturado" ? "#FFC900" : null
        },
        button: {
            border: 0,
            outline: 0,
            cursor: "pointer",
            display: props.buttonHidden ? "none" : "block"
        },
        buttonImage: {
            height: 20,
            width: 20
        },
        buttonContainer: {
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
            marginRight: 32
        },
        priceView:{
            display: 'flex',
            flex: 1,
            flexDirection: 'column'
        }
    };

    useEffect(() => {
        setOptionMenu(createOptionsMenu());

        const bodyClickHandler = e => {
            if (!hasClassInTree(e.srcElement, `filter custom options-no-hide`))
                setShowOptions(false);
        };

        document.body.addEventListener("click", bodyClickHandler, false);

        // unmount
        return () => {
            document.body.removeEventListener("click", bodyClickHandler, false);
        };
    }, []);

    const createOptionsMenu = () => {
        if(props.payoff){
            return [
                {
                    id: "mark-as-paid",
                    title: "Marcar como pagado",
                    disabled: props.payment.get("Action") === "pending" ? false : true
                },
                {
                    id: "get-bill",
                    title: "Descargar factura",
                    disabled: props.payment.get("Receipt") ? false : true
                }
            ];
        }
        return [
            {
                id: "see-job",
                title: "Ver trabajo asociado"
            }
        ];
    };

    const buttonItemSelected = item => {
        setShowOptions(false);
        props.buttonItemSelected(item);
    };

    return (
        <section style={style.container}>
            <label style={style.dateLabel}>
                {Jobin.Date.toSortFormat(props.payment.createdAt).result}
            </label>
            <label style={style.stateLabel}>{action}</label>
            <div style={style.priceView}>
                <label style={style.totalPriceLabel}>
                    {(props.payment.get("Price")).toFixed(2) + "€"}
                </label>
                <label style={style.priceLabel}>
                    {(props.payment.get("Price")*1.21).toFixed(2) + "€ con IVA"}
                </label>
            </div>
            {!props.hideOptions &&
                <div className={`filter custom options-no-hide`}>
                    <button
                        style={style.button}
                        onClick={() => setShowOptions(!showOptions)}
                    >
                        <img
                            style={style.buttonImage}
                            src={require("../../../assets/option-dots.svg")}
                            alt="dots"
                        />
                    </button>
                    {showOptions && (
                        <MenuList
                            items={optionsMenu}
                            buttonItemSelected={buttonItemSelected}
                            left={true}
                        />
                    )}
                </div>
            }
        </section>
    );
}
