export const paymentStatesArr = [
    {
        id: "all",
        name: "Todos",
        selected: false
    },
    {
        id: undefined,
        name: "Pagado",
        selected: false
    },
    {
        id: "pending",
        name: "Pendiente",
        selected: false
    },
    {
        id: "payoff",
        name: "Liquidado",
        selected: false
    }
];
