import React,  { useState, useEffect } from "react";

import Tasks from "./Tasks.jsx";
import Reviews from "./Reviews.jsx";
import Photos from "./Photos.jsx";
import EditBiProModal from "../../modal/EditBiProModal";
import {editUserData} from  "../../../utils/ParserUtilities.js";
import ShowWollersJobs from "../..//modal/ShowWollersJobs";


import "../../../styles/Users/wollers/description.css";

export default function Description({user,userDetails,tasks,reviews,alltasks,wollerLocation,history}) {
    const [showEditModal,setShowEditModal]= useState(false)
    const [showJobsModal,setShowJobsModal]= useState(false)

    
    return (
        <div className="descriptionContainer userDetails">
             {showEditModal && (
                <EditBiProModal
                    title={"Edita tu informacion"}
                    isVisible={showEditModal}
                    onClose={() => {
                        setShowEditModal(false);
                    }}
                    buttonText={"Guardar"}
                    user={user}
                    userDetails={userDetails}
                    handleChangeInfo={editUserData}
                    wollerLocation={wollerLocation}
                />
            )}
            <h4 className="title">Sobre mi</h4>
            <button className="editProfile" onClick={()=>{setShowEditModal(true)}}>Edita tu perfil</button>
            <div className="basicInformation">
                <div className="basicInfo">
                    <img src={require("../../../assets/icons/Phone.svg")} alt={""}></img>
                    <span>
                        Mi telefono: {user?.get("Phone")}
                    </span>
                </div>
                <div className="basicInfo">
                    <img src={require("../../../assets/icons/Envelope.svg")} alt={""}></img>
                    <span>
                        Mi mail: {user?.get("email")}
                    </span>
                </div>
                <div className="basicInfo">
                    <img src={require("../../../assets/icons/MapPinLine.svg")} alt={""}></img>
                    <span>
                        Provincia de trabajo: {wollerLocation?.provincia}
                    </span>
                </div>
                <div className="basicInfo">
                    <img src={require("../../../assets/icons/Calendar.svg")} alt={""}></img>
                    <span>
                        Mis años de experiencia: {userDetails?.get("ExperienceYears")} años
                    </span>
                </div>
                <div className="basicInfo">
                    <img src={require("../../../assets/icons/Car.svg")} alt={""}></img>
                    <span>
                        Cobro por desplazamiento: {userDetails?.get("DisplacementPrice")}€
                    </span>
                </div>
                <div className="basicInfo">
                    <img src={require("../../../assets/icons/Users.svg")} alt={""}></img>
                    <span>
                        En mi equipo somos: {userDetails?.get("EmployeesNumber")}
                    </span>
                </div>
            </div>
            <h4 className="title">Servicios que ofreces</h4>
            <Tasks userTasks={tasks} alltasks={alltasks}/>
            <h4 className="title">Evaluaciones de {user?.get("Name")}</h4>
            <Reviews reviews={reviews} />
            <h4 className="title">Fotos de trabajos anteriores</h4>
            <Photos wollerDetails={userDetails} />
            <button className="editProfileJobs" onClick={()=> setShowJobsModal(true)}>Mostrar Servicios Comprados</button>
            {showJobsModal && (
            <ShowWollersJobs
                title={"Prueba"}
                isVisible={showJobsModal}
                history={history}
                onClose={() => {
                    setShowJobsModal(false);
                }}
            />
        )}
        </div>
    )
}