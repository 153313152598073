import React, { useEffect } from "react";
import { useStateValue } from "../../state/context";

import PhoneModal from "./generic/PhoneModal";
import NameModal from "./generic/NameModal";
import ErrorModal from "./generic/ErrorModal";
import NoJobersModal from "./generic/NoJobersModal";
import JobinProModal from "./generic/JobinProModal";

import "../../styles/job/sign-in.css";

export default function SignIn(props) {
    const [{ job }, dispatch] = useStateValue();

    const update = childData => {
        if (childData.type === "phone") {
            dispatch({
                type: "JOB_SIGNIN_PHONE",
                areaCode: childData.areaCode,
                phone: childData.phone,
                currentUser: childData.user
            });
        } else {
            dispatch({
                type: "JOB_SIGNIN_NAME",
                name: childData.name,
                email: childData.email
            });
        }
    };

    const updateStep = childData => {
        dispatch({
            type: "JOB_SIGNIN_STEP",
            step: childData
        });
    };

    const hideModal = childData => {
        dispatch({
            type: "JOB_SIGNIN_HIDE",
            visible: childData
        });
    };

    useEffect(() => {
        document.querySelector("html").classList.add("noscroll");
        document.body.classList.add("noscroll");

        // unmount
        return () => {
            document.querySelector("html").classList.remove("noscroll");
            document.body.classList.remove("noscroll");
        };
    }, []);

    return (
        <div className="overlay">
            {job.signIn.step === 1 && (
                <PhoneModal
                    title="Nº teléfono del cliente"
                    excerpt="Introduce un número de teléfono donde podamos contactar con el cliente para gestionar la solicitud."
                    checkbox="Prefiero que me contacten por chat"
                    placeholder="Número de teléfono"
                    button="Siguiente"
                    type="phone"
                    step={job.signIn.step}
                    updateStep={updateStep}
                    hideModal={hideModal}
                    update={update}
                />
            )}
            {job.signIn.step === 2 && (
                <NameModal
                    title="Datos del cliente"
                    excerpt="Necesitamos completar todos los datos del cliente. El email no es obligatorio."
                    placeholder="Nombre"
                    placeholder2="Correo electrónico"
                    button="Siguiente"
                    type="name"
                    step={job.signIn.step}
                    updateStep={updateStep}
                    hideModal={hideModal}
                    update={update}
                />
            )}
            {job.signIn.step === 3 && (
                <ErrorModal
                    title="Ups! Algo ha salido mal"
                    excerpt="Prueba de nuevo o llámanos al (+34) 910 601825 si necesitas que te hechemos una mano."
                    button="Reintentar"
                    type="error"
                    step={job.signIn.step}
                    updateStep={updateStep}
                    hideModal={hideModal}
                    update={update}
                />
            )}
            {job.signIn.step === 4 && (
                <NoJobersModal
                    title="¡Vaya! No hay Jobers disponibles en este momento"
                    excerpt="Estamos trabajando para aumentar el número de profesionales en tu zona."
                    button="Aceptar"
                    type="noJobers"
                    step={job.signIn.step}
                />
            )}
            {job.signIn.step === 5 && (
                <JobinProModal
                    title="¡Ups! Este teléfono está asociado a otra cuenta"
                    excerpt="Alguien más está usando este mismo número de teléfono para una cuenta de Jober. Prueba con tro número o llámanos al 910 60 18 25 si necesitas más información."
                    button="Aceptar"
                    type="jobinPro"
                    step={job.signIn.step}
                />
            )}
        </div>
    );
}
