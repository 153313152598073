import React from 'react';

import MainModal from './MainModal'

const ImageModal = (props) => {
    const style = {
        image:{
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            justifyContent: "center",
        }
    }
    return (
   
        <MainModal
            gigant = {true}
			isVisible={props.isVisible}
			onClose={()=>props.onClose()}
		>
           <img style={style.image} src={props.image} alt="image" srcset="" />
        </MainModal>
    )
}

export default ImageModal;