import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { useStateValue } from "../../../state/context";
import { hasClassInTree } from "../../../utils/checkAncestorDOM";

import { Loading, MenuList } from "jobin-ui-components";
import InfoCell from "../../search/detail/InfoCell";
import FilterSingleSelection from "../../filter/FilterSingleSelection";
import PaymentCell from "./PaymentCell";

import { monthArr } from "../../../constants/months";
import { options } from '../../../constants/businessPaymentOptions';

import "../../../styles/search/detail/job-detail-info.css";

export default function Billing(props) {
    const [{ businessDetails, user }, dispatch] = useStateValue();
    const [showEditBilling, setShowEditBilling] = useState(false);
    const [BillingPreference, setBillingPreference] = useState(() =>{
        switch (businessDetails.business.get("BillingPreference")){
            case "manual":
                return "Facturación manual";
            case "service":
                return "Por servicio finalizado";
            case "monthly":
                return "A final de cada mes";
            default:
                return "-";
        }
    })

    const style = {
        filterView: {
            display: "flex",
            flex: "1 1 0%",
            justifyContent: "flex-end"
        }
    };

    const paymentOptionSelected = (item, option) => {
        if(option.id === "see-job"){
            props.history.push({
                pathname: "/job/" + item.get("Job").id,
                state: {
                    inner: true
                }
            });
        }
        else if(option.id === "mark-as-paid"){
            //TODO: finalizar
        }
        else if(option.id === "get-bill"){
            const win = window.open(item.get("Receipt").url(), "_blank");
            win.focus();
        }
    };

    const fetchBillsByMonth = async selection => {
        dispatch({
            type: "BUSINESS_BILL_LOADING",
            billsLoading: true
        });

        const businessBills = await Jobin.Business.getPayments(
            businessDetails.business,
            selection ? selection.id : undefined
        );
        const businessPayoffs = await Jobin.Business.getPayoffs(
            businessDetails.business,
            selection ? selection.id : undefined
        );
        const total = businessBills.result.reduce(
            (accumulator, bill) => accumulator + bill.get("Price"),
            0
        );
        dispatch({
            type: "SET_BILLS_RELATED_TO_BUSINESS",
            billsLoading: false,
            bills: businessBills.result,
            payoffs: businessPayoffs.result,
            billsTotal: total,
            month: selection ? selection : undefined,
        });
    };

    useEffect(() => {

        //Click listener
        const bodyClickHandler = e => {
            if (!hasClassInTree(e.srcElement, `filter custom options-no-hide`))
                setShowEditBilling(false);
        };
        document.body.addEventListener("click", bodyClickHandler, false);

        //Download the business payments
        fetchBillsByMonth(businessDetails.filters.month);

        // unmount
        return () => {
            document.body.removeEventListener("click", bodyClickHandler, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessDetails.filters.month]);

    const changeBillingPeriod = async (option) =>{
        setShowEditBilling(false);
        const result = await Jobin.Business.setBillingPreference(businessDetails.business, option.id);
        if(result.status === 'ok'){
            switch (option.id){
                case "manual":
                    setBillingPreference("Facturación manual");
                    return true;
                case "service":
                    setBillingPreference("Por servicio finalizado");
                    return true;
                case "monthly":
                    setBillingPreference("A final de cada mes");
                    return true;
                default:
                    setBillingPreference("-");
                    return true;
            }
        }
        else{
            alert(result.result);
        }
    }

    return (
        <div className="jobDetailColumn">
            <section className="jobDetailContainer">
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        overflow: "hidden"
                    }}
                >
                    <label className="subTitle">Preferencias</label>
                </div>
                <div className={`filter custom options-no-hide`} style={{display: 'flex', flex: 1}}>
                    <InfoCell
                        style={{flex: 1}}
                        title="Periodo de facturación"
                        data={BillingPreference}
                        optionText="Editar"
                        optionAction={user.permissions.business && user.permissions.business.edit ? () => setShowEditBilling(!showEditBilling) : null}
                    />
                    {showEditBilling &&
                        <MenuList
                            items={options}
                            buttonItemSelected={changeBillingPeriod}
                            left={true}
                        />
                    }
                </div>
            </section>
            <section className="jobDetailContainer">
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                    }}
                >
                    <label className="subTitle">Facturas</label>
                    <div style={style.filterView}>
                        <FilterSingleSelection
                            type="month"
                            title={businessDetails.filters.month.name}
                            items={monthArr}
                            selectionChange={fetchBillsByMonth}
                            right={true}
                        />
                    </div>
                </div>
                <div style={{ marginTop: 12 }}>
                    {businessDetails.billsLoading ? (
                        <Loading />
                    ) : businessDetails.payoffs.length <= 0 ? (
                        <div>No hay facturas emitidas este mes</div>
                    ) : (
                        businessDetails.payoffs.map((item, index) => {
                            return (
                                <PaymentCell
                                    key={"bill" + index}
                                    payment={item}
                                    buttonItemSelected={option =>
                                        paymentOptionSelected(item, option)
                                    }
                                    payoff
                                    hideOptions={user.permissions.business.edit ? false : true}
                                />
                            );
                        })
                    )}
                </div>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        marginTop: 24,
                        paddingTop: 24,
                        borderTop: '2px solid var(--soft-grey)'
                    }}
                >
                    <label className="subTitle">Pagos del negocio</label>
                </div>
                <div style={{ marginTop: 12 }}>
                    {businessDetails.billsLoading ? (
                        <Loading />
                    ) : businessDetails.bills.length <= 0 ? (
                        <div>No hay pagos este mes</div>
                    ) : (
                        businessDetails.bills.map((item, index) => {
                            return (
                                <PaymentCell
                                    key={"payment" + index}
                                    payment={item}
                                    buttonItemSelected={option =>
                                        paymentOptionSelected(item, option)
                                    }
                                />
                            );
                        })
                    )}
                </div>
            </section>
        </div>
    );
}
