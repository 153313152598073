import React, { useState, useRef } from "react";
import Jobin from "jobin-client";
import { useStateValue } from "../../../../state/context";

import Button from "../../../button/Button";

import "../../../../styles/search/detail/job-detail-history.css";

export default function AddNote(props) {
    const [data] = useStateValue();
    const [{ user }] = useStateValue();

    const [loading, setLoading] = useState(false);
    const [inputText, setInputText] = useState("");
    const textFied = useRef();

    const onInputChange = text => {
        setInputText(text);
    };

    const saveNote = async () => {
        setLoading(true);
        const result = await Jobin.History.postNote(
            data.jobDetails.job,
            inputText
        );
        if (result.status === "ok") {
            await data.jobDetails.getHistoryOfJob(data.jobDetails.job);
        } else {
            alert(result.result);
        }
        setInputText('');
        setLoading(false);
        textFied.current.value = "";
    };

    return (
        <>
             
                <section className="noteContainer">
                    <input
                        ref={textFied}
                        className="textInput"
                        type="text"
                        placeholder="Escribe aquí"
                        onChange={e => onInputChange(e.target.value)}
                        disabled={user?.isGoldWoller}
                    />
                    <div className="buttonContainer">
                        <Button
                            buttonText="Guardar"
                            style={{ width: 85, color: "var(--coral)" }}
                            loading={loading}
                            disabled={!user?.isGoldWoller && inputText.length > 0 ? false : true}
                            onClick={saveNote}
                        />
                    </div>
                </section>
            
        </>
    );
}
