export const jobIdArr = [
    {
        value: "lead",
        title: "ID lead",
        prefix: "",
        selected: true
    },
    {
        value: "fixed",
        title: "ID precio cerrado",
        prefix: "PC-",
        selected: false
    },
    
];
