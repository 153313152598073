import React, {useState} from 'react';
import Jobin from 'jobin-client'
import { useStateValue } from "../../state/context";

import MainModal from './MainModal'

import {Input, Button} from 'jobin-ui-components'

const ContactModal = (props) => {
    const style={
        inputView:{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            marginTop: 18,
            marginBottom: 18
        },
        buttonView:{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent:'flex-end',
            marginTop: 8
        }
    }

    const [, dispatch] = useStateValue();
    const [name, setName] = useState(null);
    const [nameOk, setNameOk] = useState(true);
    const [phone, setPhone] = useState(null);
    const [phoneOk, setPhoneOk] = useState(true);
    const [email, setEmail] = useState(null);
    const [emailOk, setEmailOk] = useState(true);
    const [loading, setLoading] = useState(false);

    const onNameChange = (text) =>{
        if(text.length > 0){
            setName(text);
            setNameOk(true);
        }
        else{
            setNameOk(true);
        }
    }

    const onPhoneChange = (text) =>{
        if(text.length > 0){
            if (/^([0-9]{9})$/.test(text)) {
                setPhone(text);
                setPhoneOk(true);
            } else {
                setPhone(null);
                setPhoneOk(false);
            }
        }
        else{
            setPhoneOk(true);
        }
    }

    const onEmailChange = (text) =>{
        if(text.length > 0){
            if (/^\S+@\S+$/.test(text)) {
                setEmail(text);
                setEmailOk(true);
            } else {
                setEmail(null);
                setEmailOk(false);
            }
        }
        else{
            setEmailOk(true);
        }
    }

    const saveData = async () =>{
        setLoading(true);
        const result = await Jobin.Business.setContact(props.business, name, phone, email);
        if(result.status === 'ok'){
            dispatch({
                type: "BUSINESS_DETAIL",
                business: result.result
            });
            setLoading(false);
            props.onClose();
        }
        else{
            setLoading(false);
            alert(result.result);
            props.onClose();
        }
    }

    return (
        <MainModal
			title={"Información de contacto"}
			subtitle={"Añade información de la persona de contacto de la empresa"}
			isVisible={props.isVisible}
            onClose={props.onClose}
            style={{ height: "260px", width: "500px" }}
		>
            <section>
                <div style={style.inputView}>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center'}}>
                        <Input
                            placeholder="Nombre"
                            onChange={(e) => onNameChange(e.target.value)}
                            badInput={!nameOk}
                            halfLeft
                        />
                        <Input
                            style={{marginLeft: 8}}
                            placeholder="Teléfono"
                            onChange={(e) => onPhoneChange(e.target.value)}
                            badInput={!phoneOk}
                            halfRight
                        />
                    </div>
                    <Input
                        style={{marginTop: 8}}
                        placeholder="Email"
                        onChange={(e) => onEmailChange(e.target.value)}
                        badInput={!emailOk}
                    />
                </div>
                <div style={style.buttonView}>
                    <Button
                        buttonText = {"Guardar"}
                        disabled={(phone && name && email) ? false : true}
                        loading={loading}
                        onClick={saveData}
                    />
                </div>
            </section>
        </MainModal>
    )
}

export default ContactModal;