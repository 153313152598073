import React, { useEffect, useState } from "react";
import Jobin from "jobin-client";
import { useStateValue } from "../state/context";
import { Switch, Route, Redirect } from "react-router-dom";
import { getCookie, deleteCookie } from "../utils/cookie";
import { leadTitleGoldUsers, leadTitle,leadTitleb2b,mediatorTitle } from "../constants/jobTitles";

import { matchTitles } from "../constants/matchTitles";
import { jobIdArr } from "../constants/jobIds";
import { userSearchType } from "../constants/user/searchType";
import { joberTitles, joberTitlesConstructora, goldJoberTitles } from "../constants/user/userTitles";
import fetchServices from "../constants/services";
import fetchAgentsByDepartment from "../constants/crm/agents";
import fetchBusinesses from "../constants/businesses";


import {changeAgentParams,getUserData,getFavoritePacks as getFavoritePacks_,getContractorDataByField} from "../utils/ParserUtilities";
import {clickBiproSession} from "../utils/campainsUtils"
import PasswordModal from "../components/modal/PasswordModal"

import JobsMaps from "./JobsMaps/JobsMaps"
import PurchasedJobs from "./purchased-jobs/PurchasedJobs"
import Campains from "./campains/Campains"
import SideMenu from "../components/SideMenu";
import Search from "./search/Search";
import SearchGarges from "./search-garages/SearchGarages";
import JobDetails from "./search/JobDetails";
import Job from "./job/Job";
import Explore from "./explore/Explore";
import Business from "./business/Search";
import BusinessDetails from "./business/BusinessDetails";
import Kpis from "./kpis/Search";
import Match from "./match/Search";
import Agents from "./agents/Search";
import Verticals from "./verticals/Search";
import Billing from "./billing/Search";
import BillingCreate from "./billing/Create";
import BillingEdit from "./billing/Edit";
import NotificationMenu from "../components/notification/NotificationMenu";
import User from "./user/Search";
import UserDetails from "./user/UserDetails";
import UserSignUp from "./user/SignUp";
import PdfLM from "../components/pdfs/PdfLM";
import GenericPdfPC from "../components/pdfs/GenericPdfPC";

import "../styles/dashboard.css";
import { accesControl } from "../utils/beforeRuteCheker";
import PdfConstructor from "../components/pdfs/PdfConstructor";

export default function Dashboard(props) {
    const [{ user, crmNotifications ,currentSession, agent }, dispatch] = useStateValue();
    const [passwordDialog, setPasswordDialog] = useState(false);
    const getFavoritePacks = async ()=>{
        let packs_ = await getFavoritePacks_()
        if(packs_ && packs_.length>0){
            packs_ = packs_.map((pak)=>{
                return {
                    id:pak.id,
                    freeFTBCredits:pak.get("FreeFTBLeads"),
                    freeLeads:pak.get("FreeLeads"),
                    image:pak.get("Image").url(),
                    credits:pak.get("Leads"),
                    popular:pak.get("Popular"),
                    price:pak.get("Price"),
                    priceId:pak.get("stripeId"),
                    }
            })
           return packs_
        }else{
            return []
        }
    
    }
    
    useEffect(() => {
        const agent = JSON.parse(
            getCookie("agent") ? decodeURIComponent(getCookie("agent")) : null
        );
        if (!agent || !agent.token) {
            props.history.replace({
                pathname: "/login",
                search: props.location.search,
                state: {
                    inner: true
                }
            });
        } else {
            const authPromise = Jobin.auth({
                token: agent.token
            });
            authPromise.then(
              async ( result) => {
                    if (result.status == "ok" && result.result?.permissions ) {      
                        dispatch({
                            type: "SET_USER_PERMISSIONS",
                            permissions: result.result.permissions
                        });
                        if(result.result.wollerUserId){
                            let promiseResult = await getUserData(result.result.wollerUserId);
                            // await clickBiproSession(result.result.wollerUserId);

                            dispatch({
                                type: "SET_GOLD_USER_DATA",
                                wollerData: promiseResult,
                                guilds:result.result.wollerGuilds ? result.result.wollerGuilds : undefined,
                                certificationState:result.result.certification ? result.result.certification : undefined
                                
                            });
                            dispatch({
                                type: "SEARCH_FILTER_GUILDS",
                                guilds:result.result.wollerGuilds ? result.result.wollerGuilds.map(guild=>guild.id).filter(x=>x) : undefined
                            });
                            dispatch({
                                type: "SEARCH_FILTER_JOBSTATE",
                                jobStates:["abierto", "full"],
                            });
                            dispatch({
                                type: "SET_GOLD_USER_ID",
                                goldId:result.result.wollerUserId,
                            });
                            dispatch({
                                type: "SET_GOLD_USER_SUBSCRIPTION",
                                isUserSubscription:result.result.wollerSubscription,
                            });
                        }
                        //contractors
                        if(result.result.dept === 'ConstructionCompany' && result.result.business){
                            dispatch({
                                type: "SET_CONTRACTOR_DATA",
                                contractor: result.result.contractor,
                                marketplaceBaseUrl:result.result.marketplaceBaseUrl
                            });
                            dispatch({
                                type: "SEARCH_FILTER_ID",
                                currentIdType:'fixed',
                            });
                            dispatch({
                                type: "SET_USER_ROLE",
                                promotionData:result.result.promotion
                            });
                            
                        }
                        //contractors
                        dispatch({
                            type: "SET_HOLD_STANDBY",
                            holdStandBy:result.result.holdStandBy
                        });
                        /* business session */
                        dispatch({
                            type: "SET_USER_BUSINESS",
                            business: result.result.business ? result.result.business : undefined
                        
                        });
            
                        dispatch({
                            type: "SEARCH_FILTER_BUSINESSES",
                            businesses: result.result.contractor
                                ? result.result.contractor.promotions.map(promotion => promotion.business)
                                : result.result.business
                                    ? [result.result.business.id]
                                    : undefined
                        });
                        dispatch({
                            type: "SET_B2B_ROLE",
                            isB2b: result.result.business && !result.result.wollerUserId ? true:false
                        });                           
                        /* gold session */
                        dispatch({
                            type: "SET_USER_ROLE",
                            isGoldWoller: result.result.wollerUserId ? true:false,
                            isConstructionCompany: result.result.dept === 'ConstructionCompany',
                        });

        
                        //Search
                        dispatch({
                            type: "SEARCH_PIPEDRIVE",
                            pipedrive: result.result.wollerUserId ? false:true
                        });
                        dispatch({
                            type: "SET_ITEMS_TITLES",
                            itemsTitle: result.result.wollerUserId ? leadTitleGoldUsers 
                            : result.result.permissions.mediator ? mediatorTitle 
                            : result.result.business ? leadTitleb2b:leadTitle
                        });
                        dispatch({
                            type: "SET_MATCH_TITLES",
                            matchTitles: matchTitles
                        });
                        dispatch({
                            type: "SET_USER_MEDIATOR",
                            isMediator: result.result.permissions.mediator ? true : false
                        });
                        dispatch({
                            type: "GENERAL_ALL_PACKS",
                            packs: await  getFavoritePacks()
                        });
                        dispatch({
                            type: "GENERAL_ALL_GUILDS",
                            guilds: await  fetchServices( [] ,false)
                        });
                        // gold guild
                        dispatch({
                            type: "GENERAL_FILTER_GUILDS",
                            guilds: await  fetchServices( [] ,false)
                        });
                        dispatch({
                            type: "GENERAL_FILTER_AGENTS",
                            agents: result.result.wollerUserId ? []: await fetchAgentsByDepartment([]) 
                        });
                        const allBusinesses = await fetchBusinesses([]);
                        dispatch({
                            type: "GENERAL_FILTER_BUSINESSES",
                            businesses: result.result.contractor
                                ? [{ id: "all", name: "Todos", selected: false },
                                    ...result.result.contractor.promotions.map(promotion => ({
                                        id: promotion.business,
                                        name: promotion.name,
                                        selected: false}))]
                                : result.result.business
                                    ? [{id:result.result.business.id,name:result.result.business.name,selected:true}]
                                    : allBusinesses
                              });
                        dispatch({
                            type: "USER_SEARCH_SET_IDTYPE",
                            idType: "jober"
                        });
                        dispatch({
                            type: "USER_SEARCH_SELECTED",
                            selected: userSearchType[0]
                        });
                        dispatch({
                            type: "USER_SEARCH_SET_OPTION_ARRAY",
                            typeArray:  result.result.wollerUserId ? [userSearchType[0]]:userSearchType
                        });
                        dispatch({
                            type: "USER_SET_ITEMS_TITLES",
                            itemsTitle: result.result.wollerUserId ? goldJoberTitles:joberTitles
                        });
                        dispatch({
                            type: "SET_ID_OPTIONS",
                            idTypes: result.result.wollerUserId  || result.result.permissions.mediator ? [jobIdArr[0]]:jobIdArr
                        });
                        dispatch({
                            type: "SET_SESSION_LOADED",
                            loaded: true
                        });

                        /* gold session */
                    } else {
                        deleteCookie("agent");
                        dispatch({
                            type: "SET_USER_PERMISSIONS",
                            permissions: undefined
                        });
                        props.history.replace({
                            pathname: "/login",
                            search: props.location.search,
                            state: {
                                inner: true
                            }
                        });
                    }
                },
                () => {
                    deleteCookie("agent");
                    dispatch({
                        type: "SET_USER_PERMISSIONS",
                        permissions: undefined
                    });
                    props.history.replace({
                        pathname: "/login",
                        search: props.location.search,
                        state: {
                            inner: true
                        }
                    });
                }
            );
        }
    }, [props.history, props.location.search]);

    useEffect(() => {
        //Subscribe to notification live query
        async function initSubscription() {
            let subscription = await Jobin.CRMNotification.subscribe();
            subscription.result.on("create", object => {
            });
        }

        //Get the previous notifications
        async function getPrevious() {
            const notifications = await Jobin.CRMNotification.search();
            if (notifications.status === "ok") {
                dispatch({
                    type: "CRM_NOTIFICATIONS",
                    notifications: notifications.result
                });
            }
        }
       
        initSubscription();
        getPrevious();
    }, []);
    const changeAgentParam = async (dataSelected,userId)=>{
       
        let result = await changeAgentParams(userId,
                                             null,
                                             null,
                                             dataSelected,
                                             null,
                                             null,
                                             null,
                                             null,
                                             null,
                                             null)
        setPasswordDialog(false)
      
       
   }
   
   const showPassword = ()=>{
    setPasswordDialog(true);
   }

   useEffect(()=>{

        const isConstructionUser = (!user.isGoldWoller && user.isConstructionCompany) ?? false;
        dispatch({
            type: "USER_SET_ITEMS_TITLES",
            itemsTitle: (isConstructionUser ? joberTitlesConstructora : joberTitles)
        });
       
       async function fetchAsync(){
           let isAllowed = await accesControl(props.location.pathname,
               user.isConstructionCompany && !user.isGoldWoller
                   ? "ConstructionCompany"
                   : user.isGoldWoller
                       ? "goldUser"
                       : user.isB2b
                           ? "B2B"
                           :user.isMediator
                               ?"mediator"
                               :agent.dept,
               user.isGoldWoller
                   ? currentSession?.wollerData?.id
                   :user.isB2b
                       ?user.business.id
                       :null)
           return isAllowed
        }
        if(currentSession.loaded){
            fetchAsync().then((result)=>{
                if(result !== true && user.isConstructionCompany && user.isGoldWoller && props.location.pathname.search("/user/"+currentSession?.wollerData?.id) == -1) {
                    props.history.replace({
                        pathname: "/purchased-jobs",
                        search: props.location.search,
                        state: {
                            inner: true
                        }
                    });

                }
             else if(result !== true && user.isGoldWoller && props.location.pathname.search("/user/"+currentSession?.wollerData?.id) == -1) {
                props.history.replace({
                    pathname: "/explore",
                    search: props.location.search,
                    state: {
                        inner: true
                    }
                });
               
             }else if(result !== true && user.isB2b && props.location.pathname.search("/business/"+user.business.id)== -1) {
                props.history.replace({
                    pathname: "/job",
                    search: props.location.search,
                    state: {
                        inner: true
                    }
                });
               
             }else if(result !== true){
                props.history.goBack() 
             }
             
                 
           }).catch(error=>{
                console.error(error)
           })
       }
   },[props.history,props.location,user,currentSession.loaded])
    return (
        <div>
            {crmNotifications.showMenu && <NotificationMenu history={props.history}/>}
            <SideMenu
                history={props.history}
                location={props.location}
                match={props.match}
                showPassword={showPassword}
            />
            {passwordDialog && (
                <PasswordModal
                    big={true}
                    title={"Cambiar Contraseña"}
                    subtitle={`La nueva contraseña debe contener caracteres especiales (!"#$%&/()='?¿¡), letras (a-z, A-Z) numeros (0-9) y debe tener una longitud minima de 8 caracteres`}
                    isVisible={passwordDialog}
                    onClose={() => {
                        setPasswordDialog(false);
                    }}
                    itemIndex={agent.agentId}
                    functionParam={changeAgentParam}
                    buttonText={"Aceptar"}
                    itemType={"hashedPassword"}
                />
            )}
            {currentSession.loaded && (
                <div className="dashboard-content">
                    <div className={user.isB2b ? "bannedInResponsive":""}>
                        <Switch>
                            {user.permissions.search_garages
                                ? <Redirect exact from="/" to="/search-garages" />
                                : user.isGoldWoller
                                    ? user.isConstructionCompany
                                        ? <Redirect exact from="/" to="/purchased-jobs" />
                                        : <Redirect exact from="/" to="/explore" />
                                    : <Redirect exact from="/" to="/job" />}
                            {/* <Redirect exact from="/" to="/job" /> */}
                            <Route exact path="/maps" component={JobsMaps} />
                            <Route exact path="/purchased-jobs" component={PurchasedJobs} />
                            <Route exact path="/search-garages" component={SearchGarges} />
                            <Route exact path="/job" component={Search} />
                            <Route path="/job/new" component={Job} />
                            <Route path="/job/pdflm/:jobid" component={PdfLM} />
                            <Route path="/job/pdf-constructor/:jobid" component={PdfConstructor} />
                            <Route path="/job/generic-pdf-pc/:jobid" component={GenericPdfPC} />
                            <Route exact path="/job/:jobId" component={JobDetails} />
                            <Route exact path="/business" component={Business} />
                            <Route exact path="/campains" component={Campains} />
                            <Route
                                path="/business/:businessId"
                                component={BusinessDetails}
                            />
                            <Route exact path="/billing" component={Billing} />
                            <Route
                                exact
                                path="/billing/create"
                                component={BillingCreate}
                            />
                            <Route
                                exact
                                path="/billing/edit/:paymentId"
                                component={BillingEdit}
                            />
                            <Route exact path="/kpis" component={Kpis} />
                            <Route exact path="/match" component={Match} />
                            <Route exact path="/explore" component={Explore} />
                            <Route exact path="/agents" component={Agents} />
                            <Route exact path="/verticals" component={Verticals} />
                            <Route exact path="/user" component={User} />
                            <Route exact path="/user/signup" component={UserSignUp} />
                            <Route exact path="/user/:userId" component={UserDetails} />
                        </Switch>
                    </div>
                    <div className={user.isB2b ? "allowedInResponsive":""} style={!user.isB2b ?{display:"none"}:{}}> 
                        El modo movil no esta disponible para tu perfil.{}
                    </div>
                </div>
            )}
        </div>
    );
}
