import React, {useState, useEffect}  from 'react';
import MapIcon from "../../assets/map-icon-yellow.png";
import {getMatchesOfJobs} from "../../utils/ParserUtilities";

import "../../styles/JobsMaps/jobs-maps-marker.css";

const JobsMapsMarker = (params) => {
    
    const {setJobCard, job, setMatches} = params;
    const [jobBorderColor, setJobBorderColor] = useState("");

    useEffect( () => {
        updateColor();
    },[])

    useEffect( () => {
        updateColor()
    },[job])

    const updateColor = () => {
        if(job.get("OpsNotification")){
            const typologies = job.get("OpsNotification").get("ExtraInfo").typologies;

            if(typologies.length > 0){
                if(typologies.includes("Cliente confirma diagnóstico")) setJobBorderColor("b-c-blue");
                else if(typologies.includes("Ilocalizable")) setJobBorderColor("b-c-red");
                else if(typologies.includes("Urgente")) setJobBorderColor("b-c-yellow");
                else if(typologies.includes("Falta información")) setJobBorderColor("b-c-green");
            } else {
                setJobBorderColor("")
            }
        }
    }

    const getNotificationsOfJob = async () => {
        let totalSlots = job.get("TotalSlots");

        let notifications = await getMatchesOfJobs(job.id);
        let newMatches = [];

        if(notifications){
            notifications.map( not => (
                newMatches.push({state: "match"})
            ))
        }

        for(let i = 0; i< totalSlots; i++){
            if(newMatches.length < totalSlots){
                newMatches.push({state: "no match"});
            }
        }
        setMatches(newMatches);
    }

    const handleOnClick = () => {
        setMatches([]);
        setJobCard(job);
        getNotificationsOfJob();
    }

    return <img onClick={handleOnClick} className={`jobs-map-icon ${jobBorderColor}`}
                src={job.get("Vertical").get("Icon") ? job.get("Vertical").get("Icon").url() : MapIcon} />
}

export default JobsMapsMarker;