export const leadTitle = [
    {
        Header: "",
        id: "level", 
    },
    {
        Header: "ID",
        id: "id", 
    },
    {
        Header: "Gremio",
        id: "vertical", 
        id: "gremio",
        Cell: ({ value, row }) => {
            return value.get("Type")
        },
    },
    {
        Header: "Estado",
        id: "state", 
    },
    {
        Header: "Título",
        id: "title", 
    },
    {
        Header: "Codigo postal",
        id: "postalCode", 
    },
    {
        Header: "Provincia",
        id: "State", 
    },
    {
        Header: "P. libres",
        id: "takenSlots", 
    },
    {
        Header: "Nombre",
        id: "name", 
    },
    {
        Header: "Notificaciones",
        id: "notif", 
    },
    {
        Header: "Agente",
        id: "fff", 
    },
    {
        Header: "Incidencia",
        id: "ggg", 
    },
    {
        Header: "Precio por oferta",
        id: "jobLeadPrice", 
    },
    {
        Header: "Negocio",
        id: "ddd", 
    },
    {
        Header: "Verificado",
        id: "verified", 
    },
   
    {
        Header: "Fecha creación",
        id: "date", 
    },
    
];
export const fixedTitle = [
    {
        Header: "",
        id: "level", 
    },
    {
        Header: "ID",
        id: "jobId", 
    },
    {
        Header: "Gremio",
        id: "guild", 
    },
    {
        Header: "Título",
        id: "title", 
    },
    {
        Header: "Estado",
        id: "state", 
    },
    {
        Header: "Codigo postal",
        id: "postalCode", 
    },
    {
        Header: "Provincia",
        id: "province", 
    },
    {
        Header: "Nombre",
        id: "name", 
    },
    {
        Header: "Notificaciones",
        id: "notify", 
    },
    {
        Header: "Incidencia",
        id: "incidence", 
    },
    {
        Header: "Precio Wolly",
        id: "price", 
    },
    {
        Header: "Precio Pro",
        id: "priceIva", 
    },
    {
        Header: "Woller",
        id: "pro", 
    },
    {
        Header: "Negocio",
        id: "business", 
    },
    {
        Header: "Verificado",
        id: "verified", 
    },
    {
        Header: "Fecha creación",
        id: "date", 
    },
    {
        Header: "Fecha cita",
        id: "date", 
    },
];
export const leadTitleGoldUsers =[
    {
        Header: "",
        id: "level", 
    },
    {
        Header: "ID",
        id: "jobId", 
    },
    {
        Header: "Gremio",
        id: "guild", 
    },
    {
        Header: "Título",
        id: "title", 
    },
    {
        Header: "Dirección",
        id: "location", 
    },
    {
        Header: "Provincia",
        id: "province", 
    },
    {
        Header: "P. libres",
        id: "freeSlots", 
    },
    {
        Header: "Verificado",
        id: "verified", 
    },
    {
        Header: "Precio por oferta",
        id: "priceOffer", 
    },
    {
        Header: "Negocio",
        id: "business", 
    },
    {
        Header: "Fecha creación",
        id: "date", 
    },
]
export const leadTitleb2b =[
    {
        Header: "",
        id: "level", 
    },
    {
        Header: "ID",
        id: "jobId", 
    },
    {
        Header: "Gremio",
        id: "guild", 
    },
    {
        Header: "Título",
        id: "title", 
    },
    {
        Header: "Dirección",
        id: "location", 
    },
    {
        Header: "Provincia",
        id: "province", 
    },
    {
        Header: "Estado",
        id: "state", 
    },
    {
        Header: "P. libres",
        id: "freeSlots", 
    },
    {
        Header: "Nombre",
        id: "name", 
    },
    {
        Header: "Agente",
        id: "CRM", 
    },
    {
        Header: "Fecha creación",
        id: "date", 
    },
]
export const mediatorTitle = [
    {
        Header: "ID",
        id: "jobId", 
    },
    {
        Header: "Gremio",
        id: "guild", 
    },
    {
        Header: "Título",
        id: "title", 
    },
    {
        Header: "Direccion",
        id: "postalCode", 
    },
    {
        Header: "Provincia",
        id: "province", 
    },
    {
        Header: "P.libres",
        id: "freeSlots", 
    },
    {
        Header: "Nombre",
        id: "name", 
    },
    {
        Header: "Notificaciones",
        id: "notifications", 
    },
    {
        Header: "Agente",
        id: "agent", 
    },

    {
        Header: "Incidencia",
        id: "incidence", 
    },
    {
        Header: "Negocio",
        id: "business", 
    },
    {
        Header: "Verificado",
        id: "verified", 
    },
    {
        Header: "Estado",
        id: "state", 
    },
    {
        Header: "Fecha creación",
        id: "date", 
    },
  
];
export const fixedTitleb2b = [
    {
        Header: "",
        id: "level", 
    },
    {
        Header: "ID",
        id: "jobId", 
    },
    {
        Header: "Gremio",
        id: "guild", 
    },
    {
        Header: "Título",
        id: "title", 
    },
    {
        Header: "Direccion",
        id: "postalCode", 
    },
    {
        Header: "Provincia",
        id: "province", 
    },
    {
        Header: "Estado",
        id: "state", 
    },
    {
        Header: "Cliente",
        id: "name", 
    },
    {
        Header: "Agente",
        id: "name", 
    },

    {
        Header: "Verificado",
        id: "verified", 
    },
    {
        Header: "Fecha creación",
        id: "date", 
    },
  
];