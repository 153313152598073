import React,  { useState, useEffect } from "react";
import Switch from "../../singleComponents/switch";
import {getNearJobsByWollerId, buyJobForWoller} from "../../../utils/ParserUtilities";
import {formatDateWhitoutHour} from "../../../utils/formatter"
import { Loading } from "jobin-ui-components";
import { useDebounce } from "../../../utils/hooks";
import { useStateValue } from "../../../state/context";


import "../../../styles/search/detail/job-detail-history.css";

export default function NearJobs(props) {

    const [distance, setDistance] = useState(50);
    const [checkNotification, setCheckNotification] = useState(false);
    const [{ user, token }] = useStateValue();

    const [nearJobs, setNearJobs] = useState([]);

    useEffect(()=> {
        getNearJobs(distance);
    },[]);

    const debouncedDistance = useDebounce(distance, 1000);

    React.useEffect(
        () => {
            setNearJobs([]);
            getNearJobs(distance);
        },
        [debouncedDistance]
      );

    useEffect(()=> {
        if(checkNotification){
            let jobsFiltered = nearJobs.filter( job => {
                if(job.get("Verified")) return true;
                else return false;
            })
            setNearJobs(jobsFiltered);
        }else {
            setNearJobs([]);
            getNearJobs(distance);
        }
    },[checkNotification]);

    const changeNotification = async (jobId, value, type) => {
        if(type == "check") {
            setCheckNotification(value);
        }
    }

    const getNearJobs = async (newDistance) => {
        let wollerId = window.location.href.split('/');
        let res;

        res = await getNearJobsByWollerId(wollerId[wollerId.length - 1], parseInt(newDistance));
        setNearJobs(res.result)
    }

    const style = {
        loadingView: {
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 24
        }
    };

    const itemDetail = id => {
        window.open(`${window.location.origin}/#/job/${id}`, "_blank")
    };

    const asignJober = async job => {

        let wollerId = window.location.href.split('/');
        setNearJobs([]);

        try{
            await buyJobForWoller(job.id, wollerId[wollerId.length - 1], token);
            alert(`Servicio comprado: ${job.get("Title")}`)
        }catch(err){
            alert("No tienes credito suficiente");
        }

        getNearJobs(distance)
    };

    return (
        <div className="nearJobsDetailColumn">
            <div className="nearJobsDetailFilters">
                <p className="near-jobs-main-title">Trabajos cercanos</p>
                <div className="match-filter-slider-wrapper">
                    <div className="match-filter-slider">
                        <p className="match-filter-title">Distancia máxima: {distance} km</p>
                        <input
                            className="slider"
                            type="range"
                            min={1}
                            max={50}
                            value={distance}
                            onChange={e => {
                                setDistance(e.target.value);
                            }}
                        />
                    </div>
                    <div>
                        <img src={require("../../../assets/notifications/check.png")} alt="check" className='near-job-check'/>
                        <Switch value={checkNotification} getChangeValue={changeNotification} jobId={""} type={'check'}/>
                    </div>
                </div>
            </div>
            <div className="near-jobs-list">
            {
                nearJobs.length > 0 && nearJobs.map( job => {
                    return (
                        <div className={job.get("Verified") ? `near-job-card border-blue` : `near-job-card`}>
                            <div className="f-row">
                                <div className="title-f-row">
                                    <p className="near-job-card-lead">LEAD</p>
                                    <p className="near-job-card-type">{job.get("Type")}</p>
                                    <p className="near-job-card-title">{job.get("Title")}</p>
                                </div>
                                <p>
                                    {job.get("Verified") ?
                                    <img src={require("../../../assets/notifications/verified.png")} alt="check" className='near-job-check-two'/>
                                    : ""}
                                 </p>
                            </div>
                            <div className="f-row">
                                <p className="near-job-card-p"><b>Dirección:</b> {job.get("Address")}</p>
                                <button className="near-job-card-buy" onClick={() => asignJober(job)}>Precio: {job.get("JobLeadPrice") ? job.get("JobLeadPrice") : "Fixed"}</button>
                            </div>
                            <p className="near-job-card-p">{job.get("Description").length > 63 ? `${job.get("Description").substring(0,65)}...` : job.get("Description")}</p>
                            <p className="near-job-card-p"><b>Fecha de creación:</b> {formatDateWhitoutHour(job.get("createdAt").toString())}</p>
                            <div className="near-job-footer">
                                <button className="near-job-card-more-info" onClick={()=>{itemDetail(job.id)}}>Más info</button>
                            </div>
                        </div>
                    )
                })
            }
            {
                nearJobs.length == 0 && (
                    <div style={style.loadingView}>
                        <Loading />
                    </div>
                )
            }
            </div>
        </div>        
    ) 
}