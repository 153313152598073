import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from "react-places-autocomplete";

import { Button, SearchBar } from "jobin-ui-components";

import mapsMarker from "../../../assets/maps-marker.svg";

export default function SignUpLocation(props) {
    const style = {
        section: {
            display: "flex",
            flex: 1
        },
        halfSection: {
            display: "flex",
            position: "relative",
            flex: 1,
            flexDirection: "column",
            height: "calc(100vh - 72px)"
        },
        leftView: {
            display: "flex",
            flexDirection: "column",
            marginLeft: "10%",
            marginRight: "10%",
            marginTop: 20
        },
        rightView: {
            display: "flex",
            flexDirection: "column"
        },
        inputSection: {
            display: "flex",
            flex: 1,
            flexDirection: "column"
        },
        inputView: {
            display: "flex",
            flex: 1,
            marginBottom: 12
        },
        radioButtonSection: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            marginTop: 24
        },
        bottomBar: {
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
            position: "absolute",
            bottom: 0,
            paddingBottom: 12,
            paddingTop: 12,
            width: "90%",
            borderTop: "1px solid var(--light-grey)",
            background: "#fff"
        },
        list: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            border: "1px solid var(--soft-grey)",
            borderRadius: 4
        },
        cell: {
            display: "flex",
            flex: 1,
            padding: 8,
            borderBottom: "1px solid var(--soft-grey)"
        }
    };

    const [center, setCenter] = useState({ lat: 40.416782, lng: -3.703391 });
    const [zoom, setZoom] = useState(12);
    const [tempAddress, setTempAddress] = useState("");
    const [defAddress, setDefAddress] = useState("");

    const Marker = () => (
        <div className="maps-marker">
            <img src={mapsMarker} alt="marker" />
        </div>
    );

    const handleSelect = async (address, placeId) => {
        try {
            const { lat, lng } = await geocodeByPlaceId(placeId).then(results => getLatLng(results[0]));

            setDefAddress(address);
            setTempAddress(address);
            setCenter({ lat, lng });
            setZoom(16);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <section style={style.section}>
            <div style={style.halfSection}>
                <div style={style.leftView}>
                    <h4>Zona de trabajo</h4>
                    <label>Indica la zona de trabajo donde el Woller desarrollará su actividad</label>
                    <div style={style.inputSection}>
                        <PlacesAutocomplete
                            value={tempAddress}
                            onChange={setTempAddress}
                            onSelect={handleSelect}
                            searchOptions={{
                                types: ["address"],
                                componentRestrictions: { country: ["es", "pt", "fr"] }
                            }}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                                return (
                                    <div>
                                        <SearchBar
                                            style={{ marginTop: 24 }}
                                            {...getInputProps({
                                                placeholder: "Escribe la dirección exacta"
                                            })}
                                        />
                                        <div style={style.list}>
                                            {loading ? (
                                                <div style={style.cell}>Cargando...</div>
                                            ) : suggestions && suggestions.length > 0 ? (
                                                suggestions.map(suggestion => {
                                                    return (
                                                        <div {...getSuggestionItemProps(suggestion, { style: style.cell })}>
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })
                                            ) : tempAddress !== defAddress ? (
                                                <div style={style.cell}>No hay resultados</div>
                                            ) : null}
                                        </div>
                                    </div>
                                );
                            }}
                        </PlacesAutocomplete>
                    </div>
                </div>
                <div style={style.bottomBar}>
                    <Button
                        buttonText="Siguiente"
                        onClick={() => props.nextView("location", { lat: center.lat, lng: center.lng, address: defAddress })}
                        disabled={props.loading || !tempAddress}
                        loading={props.loading}
                    />
                </div>
            </div>
            <div style={style.halfSection}>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: "AIzaSyDYwng9doYyVkIIyzYLX6crFuJLii07Cus"
                    }}
                    center={center}
                    zoom={zoom}
                    yesIWantToUseGoogleMapApiInternals
                >
                    {defAddress && <Marker lat={center.lat} lng={center.lng} />}
                </GoogleMapReact>
            </div>
        </section>
    );
}
