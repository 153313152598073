import React, { useState, useEffect } from "react";
import { useStateValue } from "../../state/context";
import Jobin from "jobin-client";
import Lottie from "react-lottie";

import ServiceItem from "./generic/ServiceItem";
import CustomServiceItem from "./generic/CustomServiceItem";
import ServiceSteps from "./generic/ServiceSteps";
import SearchService from "./generic/SearchService";

import "../../styles/job/service-type.css";
import * as cardSpinnerData from "../../assets/animations/card-spinner.json";

export default function ServiceType(props) {
    const [, dispatch] = useStateValue();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [childrenLoaded, setChildrenLoaded] = useState([]);
    const [isChildrenLoaded, setIsChildrenLoaded] = useState(false);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: cardSpinnerData.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const loaded = dataFromChild => {
        setChildrenLoaded(childrenLoaded =>
            childrenLoaded.concat(dataFromChild)
        );
    };

    useEffect(() => {
        dispatch({
            type: "JOB_ROUTER",
            history: props.history,
            location: props.location,
            match: props.match
        });

        let didCancel = false;

        async function getGuilds() {
            try {
                const result = await Jobin.Guild.getFathers();

                if (result.status === "ok") {
                    if (!didCancel) {
                        setItems(result.result);
                        setIsLoaded(true);
                    }
                } else {
                    if (!didCancel) {
                        setError(result.result);
                        setIsLoaded(false);
                    }
                }
            } catch (error) {
                if (!didCancel) {
                    setError(error);
                    setIsLoaded(false);
                }
            }
        }

        if (childrenLoaded.length === items.length - 1)
            setIsChildrenLoaded(true);
        else getGuilds();

        // unmount
        return () => {
            didCancel = true;
        };
    }, [childrenLoaded]);

    return error ? (
        <div>Error: {error.message}</div>
    ) : (
        <div id="service-type">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <h2 className="title">¿Qué servicio necesitas?</h2>
                        <div className="hidden-xs">
                            <SearchService />
                        </div>
                        <div
                            className={
                                isChildrenLoaded
                                    ? "service-placeholder hidden"
                                    : "service-placeholder"
                            }
                        >
                            <div className="row">
                                <div className="col-sm-6">
                                    <Lottie options={defaultOptions} />
                                </div>
                                <div className="col-sm-6">
                                    <Lottie options={defaultOptions} />
                                </div>
                                <div className="col-sm-6">
                                    <Lottie options={defaultOptions} />
                                </div>
                                <div className="col-sm-6">
                                    <Lottie options={defaultOptions} />
                                </div>
                            </div>
                        </div>
                        <div
                            id="services"
                            className={isChildrenLoaded ? "" : "hidden"}
                        >
                            <div className="column-sm-6">
                                {isLoaded && [
                                    items.map(service => {
                                        return service.id === "Z7HBY4VaNi" ? null : (
                                            <ServiceItem
                                                key={service.id}
                                                service={service}
                                                loaded={loaded}
                                            />
                                        );
                                    })
                                ]}
                            </div>
                            <div className="col-md-7">
                                <div className="row">
                                    {isLoaded && [
                                        items.map(service => {
                                            return service.id !==
                                                "8y4kFU8eiA" &&
                                                service.id !==
                                                    "Z7HBY4VaNi" ? null : (
                                                <CustomServiceItem
                                                    key={service.id}
                                                    service={service}
                                                />
                                            );
                                        })
                                    ]}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-lg-offset-1">
                        <ServiceSteps />
                    </div>
                </div>
            </div>
        </div>
    );
}
