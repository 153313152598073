import React, { useState, useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import Lottie from 'react-lottie';
import * as cardSpinnerData from "../assets/animations/button-loading.json";

const CsvExport = ({ getDataForCsv, children, disable, bipro }) => {
  const [csvData, setCsvData] = useState(false);
  const csvInstance = useRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(false);
      });
    }
  }, [csvData]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: cardSpinnerData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <>
      {
        !loading && (
          <div
            style={bipro ? {cursor:"pointer",padding: "8px 10px 8px 10px", border: "1px solid grey", width: 180, borderRadius: 12,} : {cursor:"pointer",color: '#FFE235', padding: "8px 10px 8px 10px", 
            backgroundColor: "black", fontWeight: "bold", borderRadius: 12,
            width: 90, marginBottom: 10}}
            onClick={async () => {
              if (disable) {
                return;
              }
              setLoading(true)
              const newCsvData = await getDataForCsv();
              setCsvData(newCsvData);
              setLoading(false)
            }}
          >
            {children}
          </div>
        )  
      }
      {
        loading && (
          <button style={{ backgroundColor: 'black',  border: "1px solid grey", width: 180, borderRadius: 12}}>
            <Lottie
                style={{height: 30, width: 80}}
                options={defaultOptions}
          />
          </button>
        )  
      }
      
      {csvData ?
        <CSVLink
          data={csvData.data}
          headers={csvData.headers}
          filename={csvData.filename}
          ref={csvInstance}
        />
      : undefined}
    </>

  );
};

export default CsvExport;