import React, { useState, useEffect } from "react";
import MainModal from "./MainModal";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import fetchBusinesses from "../../constants/businesses";
import { useStateValue } from "../../state/context";


import {getFatherVerticals, getAgentsForMaps} from "../../utils/ParserUtilities";
import {provinceArrEsp} from "../../constants/provinces"

import "../../styles/JobsMaps/jobs-maps-filter-modal.css";
import 'react-datepicker/dist/react-datepicker.css';

const JobsMapsFilterModal = props => {

    const [verticals, setVerticals] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [business, setBusiness] = useState([]);
    const [agents, setAgents] = useState([]);
    const [modalWidth, setModalWidth] = useState(1000);


    const [totalSlots, setTotalSlots] = useState([{value: "Todos", label: "Todos"},{value: 1, label: 1},{value: 3,label: 3}]);
    const [empty, setEmpty] = useState([{value: "All", label: "Todos"},{value: "Yes", label: "Solo Vacios"}]);
    const [showWollers, setShowWollers] = useState([{value: "Si", label: "Si"},{value: "No", label: "No"}]);
    const [{ user, currentSession }, dispatch] = useStateValue();

    const {setSelectedVerticals, getAllJobs, selectedVerticals, onClose, isVisible,
        setSelectedProvinces, selectedProvinces, dateRange, setDateRange,
        setSelectedBusiness, selectedBusiness, setSelectedTotalSlots, selectedTotalSlots,
        setSelectedEmpty, selectedEmpty, selectedAgents, setSelectedAgents, setSelectedShowWollers,
        selectedShowWollers, 
    } = props;


    const handleVerticalChange = e => setSelectedVerticals(e);
    const handleProvincesChange = e => setSelectedProvinces(e);
    const handleBusinessChange = e => setSelectedBusiness(e);
    const handleTotalSlotsChange = e => setSelectedTotalSlots(e);
    const handleEmptyChange = e => setSelectedEmpty(e);
    const handleAgentsChange = e => setSelectedAgents(e);
    const handleShowWollersChange = e => setSelectedShowWollers(e);

    const [startDate, endDate] = dateRange;

    useEffect(()=> {
        getProvinces();
        getVerticals(); 
        getBusiness();
        getAgents();

        if(window.innerWidth <= 800){
            setModalWidth("100%")
        }
    },[]);

    const getProvinces = async () => {
        const formatedProvinces = [];
        provinceArrEsp.map( province => {
            let formatedVertical = {
                value: province.id,
                label: province.name
            }
            formatedProvinces.push(formatedVertical);
        })
        setProvinces(formatedProvinces);
    }

    const getVerticals = async () => {
        const res = await getFatherVerticals();
        const formatedVerticals = [];
        res.map( vert => {
            let formatedVertical = {
                value: vert.id,
                label: vert.get("Type")
            }
            formatedVerticals.push(formatedVertical)
        })
        setVerticals(formatedVerticals);
    }

    const getBusiness = async () => {
        let res = await fetchBusinesses([],true);
        const formatedBusinesses = [];
        res.map( business => {
            let formatedBusiness = {
                value: business.id,
                label: business.name
            }
            formatedBusinesses.push(formatedBusiness)
        })
        setBusiness(formatedBusinesses);
    }

    const getAgents = async () => {
        let res = await getAgentsForMaps();
        const formatedAgents = [];
        res.map( agent => {
            let formatedAgent = {
                value: agent.id,
                label: agent.get("Name")
            }
            formatedAgents.push(formatedAgent)
        })
        setAgents(formatedAgents);
    }

    return(
        <MainModal
            style={{ height: "auto", width: modalWidth }}
            title={"Filtros"}
            subtitle={""}
            isVisible={isVisible}
            onClose={onClose}
        >
            <div className="maps-filter-modal-wrapper">
                <p className="maps-filter-modal-title">Gremios:</p>
                <Select
                    isMulti
                    options={verticals}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    defaultValue={selectedVerticals}
                    onChange={handleVerticalChange} 
                />
                {
                    !user.isGoldWoller && (
                        <>
                            <p className="maps-filter-medium-modal-title">Wollers</p>
                            <Select
                                options={showWollers}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={selectedShowWollers}
                                onChange={handleShowWollersChange} 
                            />
                        </>
                    )
                }
                
            </div>

            <div className="maps-filter-modal-wrapper">
                <p className="maps-filter-modal-title">Provincias:</p>
                <Select
                    isMulti
                    options={provinces}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    defaultValue={selectedProvinces}
                    onChange={handleProvincesChange} 
                />
            </div>
            {
                !user.isGoldWoller && (
                    <>
                        <div className="maps-filter-modal-wrapper">
                            <p className="maps-filter-modal-title">Business:</p>
                            <Select
                                isMulti
                                options={business}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={selectedBusiness}
                                onChange={handleBusinessChange} 
                            />     
                        </div>
                    
                        <div className="maps-filter-modal-wrapper">
                            <p className="maps-filter-modal-title">Agentes:</p>
                            <Select
                                options={agents}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={selectedAgents}
                                onChange={handleAgentsChange}
                            />
                        </div>

                        <div className="maps-filter-modal-wrapper">
                            <p className="maps-filter-modal-title">Totalslots:</p>
                            <Select
                                options={totalSlots}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={selectedTotalSlots}
                                onChange={handleTotalSlotsChange} 
                            />
                            <p className="maps-filter-medium-modal-title">Vacio:</p>
                            <Select
                                options={empty}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={selectedEmpty}
                                onChange={handleEmptyChange} 
                            />  
                        </div>
                    </>
                )
            }

            <div className="maps-filter-modal-wrapper">
                <p className="maps-filter-modal-title">Fecha de creación:</p>
                <DatePicker
                    className="maps-filter-datepicker"
                    dateFormat={" dd/MM/yyyy "}
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                    }}
                    withPortal
                />
            </div>
            
            <div className="maps-filter-modal-wrapper">
                <button onClick={() => {getAllJobs(); onClose();}} className="maps-filter-modal-button">Filtrar</button>
            </div>
            
        </MainModal>
    )
}

export default JobsMapsFilterModal;