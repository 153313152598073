import React, {useState, useEffect} from 'react';
import Jobin from 'jobin-client'

import {SearchBar} from 'jobin-ui-components'
import MainModal from './MainModal'
import BusinessCell from '../search/detail/detailColumn/BusinessCell'
import {useStateValue} from "../../state/context";
import {searchBusiness} from "../../utils/ParserUtilities";

const SelectBusinessModal = (props) => {
  const [{currentSession}] = useStateValue()
	const style = {
        searchContainer:{
			marginTop: 16,
			marginBottom: 16
		},
    }
    
    const [businessArray, setBusinessArray] = useState([]);

    useEffect(() => {
      async function getBusiness() {
        const result = props.businessType === "promotions" && currentSession.contractor
            ? await searchBusiness(currentSession.contractor.promotions.map(promotion => promotion.business))
            : await Jobin.Business.getMainBusinesses();

        if (result.status === 'ok') {
          // En caso de promociones, se usan los nombres de las promociones en lugar de los business
          if (props.businessType === "promotions" && currentSession.contractor) {
            const promotionsMap = currentSession.contractor.promotions.reduce((map, promotion) => {
              map[promotion.business] = promotion.name;
              return map;
            }, {});

            const updatedResult = result.result.map(business => {
              const name = promotionsMap[business.id] || business.id;
                business.set("Name", name);
                return business;
            });

            setBusinessArray(updatedResult);
          } else {
            setBusinessArray(result.result);
          }
        }
      }

    getBusiness();
    }, []);

    return (
        <MainModal
			title={props.businessType === "promotions" ? "Nueva promoción" : "Nuevo negocio"}
			subtitle={props.businessType === "promotions" ? "Selecciona la nueva promoción a la que quieres que pertenezca esta solicitud." : "Selecciona el nuevo negocio al que quieres que pertenezca esta solicitud."}
			isVisible={props.isVisible}
			onClose={props.onClose}
		>
            <section>
                <SearchBar
                    style={style.searchContainer}
                    placeholder={"Buscar por nombre"}
                    //onChange={(e) => searchJober(e.target.value)}
                />
                {(businessArray && businessArray.length > 0) &&
                    businessArray.map((item, index) => {
                        return (
                            <BusinessCell
                                key={"business"+index}
                                business={item}
                                buttonText={"Seleccionar"}
                                onClick={() => props.selectBusiness(item)}
                            />
                        )
                    })
                }
            </section>
        </MainModal>
    )
}

export default SelectBusinessModal;