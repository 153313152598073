import React, {useState, useEffect} from 'react';
import {formatDateWhitoutHour} from "../../utils/formatter";
import MatchJobWollerModal from "../modal/MatchJobWollerModal"
import {buyJobForWoller, modifyJobLeadPrice, getJobById} from "../../utils/ParserUtilities";
import { useStateValue } from "../../state/context";
import AlertModal from "../modal/Alert";
import Switch from "../singleComponents/switch";
import {editOpsNotification, getCRMUserTokenById} from "../../utils/ParserUtilities";

import "../../styles/JobsMaps/jobs-maps-card.css";

const JobsMapsCard = (params) => {

    const [joberModalVisible, setJoberModalVisible] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [rayNotification, setRayNotification] = useState(false);
    const [checkNotification, setCheckNotification] = useState(false);
    const [locationNotification, setLocationNotification] = useState(false);
    const [noDataNotification, setNoDataNotification] = useState(false);
    const [jobPrice, setJobPrice] = useState(0);
    const [disabledModifyPrice, setDisabledModifyPrice] = useState(false);

    const {job, getAllJobs, setJobCard, matches, setJobs, jobs} = params;
    const [{ user, token, agent,currentSession }] = useStateValue();


    useEffect(()=> {
        updateJobDetails();
    },[]);

    useEffect(()=> {
        updateJobDetails();
    },[job]);

    const updateJobDetails = () => {
        let check = false;
        let ray = false;
        let location = false;
        let noData = false;

        if(job.get("OpsNotification")){
            job.get("OpsNotification").get("ExtraInfo").typologies.map( not => {
                if(not == "Cliente confirma diagnóstico") check = true;
                if(not == "Urgente") ray = true;
                if(not == "Ilocalizable") location = true;
                if(not == "Falta información") noData = true;
            })
        }

        setCheckNotification(check);
        setRayNotification(ray);
        setLocationNotification(location);
        setNoDataNotification(noData);

        setJobPrice(parseInt(job.get("JobLeadPrice")));
    }

    const handleOnClick = () => {
        if (currentSession?.wollerData?.id){
            setShowConfirmModal(true);
        } else setJoberModalVisible(true);
    }

    const asignJober = async jober => {
        setJoberModalVisible(false);
        getAllJobs();
        setJobCard();

        let newToken = await getCRMUserTokenById(agent.agentId);

        if (currentSession?.wollerData?.id){
            await buyJobForWoller(job.id, currentSession?.wollerData?.id, newToken.get("sessionToken"));
            setShowConfirmModal(false);
            getAllJobs();

        } else{
            await buyJobForWoller(job.id, jober.id, newToken.get("sessionToken"));
            getAllJobs();
        }
    };

    const itemDetail = id => {
        window.open(`${window.location.origin}/#/job/${id}`, "_blank")
    };

    const onModifyJobLeadPrice = async (price) => {
        setDisabledModifyPrice(true)
        if((jobPrice + price) >= 3 || agent.dept == "CEO" || ["gdMnCpCtux", "HhjJLJTauL"].includes(agent.agentId)){
            let res = await modifyJobLeadPrice(job.id, price);
            setJobPrice(jobPrice + price);
        } else {
            alert("No se puede cambiar el precio de un trabajo menor a 3 euros");
        }

        setDisabledModifyPrice(false)
    }

    const changeNotification = async (jobId, value, type) => {
        let newType = "";
        if(type == "check") {
            newType = "Cliente confirma diagnóstico";
            setCheckNotification(value);
            if(value){
                setJobPrice(jobPrice + 2)
            } else {
                setJobPrice(jobPrice - 2)
            }
        } else if(type == "ray") {
            newType = "Urgente";
            setRayNotification(value)
        } else if(type == "location") {
            newType = "Ilocalizable";
            setLocationNotification(value)
        } else if(type == "no_data") {
            newType = "Falta información";
            setNoDataNotification(value)
        }

        await editOpsNotification(jobId, value, newType);

        let newJob = await getJobById(job.id);
        replaceJob(newJob);
    }

    const replaceJob = (newJob) => {
        let newJobs = jobs.filter( oldJob => {
            if(oldJob.id == job.id) return false;
            else return true;
        })

        newJobs.push(newJob);
        setJobs(newJobs);
    }

    return(
        <div className='jobs-maps-job-card'>
            <div className='job-card-info'>
                <div className='flex-row-space-beetween'>
                    <p className='job-card-title'>{job.get("Title")}</p>
                    <button className='job-card-cancel-button' onClick={() => setJobCard()}>X</button>
                </div>

                <div className='flex-row-space-beetween'>
                    <p className='job-card-p'> <b>Gremio:</b> {job.get("Vertical").get("Type")} </p>
                    <p className='job-card-p'> <b>Id: </b> {job.id} </p>
                </div>

                <p className='job-card-p'> <b>Localización:</b> {job.get("Address")} </p>
                <p className='job-card-p'> <b>Fecha de creación:</b> {formatDateWhitoutHour(job.get("createdAt").toString())} </p>
                {!currentSession?.wollerData?.id && (
                    <div className='job-card-notifications'>
                        <div className='job-card-notifications-wrapper'>
                            <img src={require("../../assets/notifications/check.png")} alt="check" className='job-card-notification'/>
                            <Switch value={checkNotification} getChangeValue={changeNotification} jobId={job.id} type={'check'}/>
                        </div>
                        <div className='job-card-notifications-wrapper'>
                            <img src={require("../../assets/notifications/location.png")} alt="location" className='job-card-notification margin-left-40'/>
                            <Switch color="-red" value={locationNotification} getChangeValue={changeNotification} jobId={job.id} type={'location'}/>
                        </div>
                    </div>
                )}
                {!currentSession?.wollerData?.id && (
                    <div className='job-card-notifications'>
                        <div className='job-card-notifications-wrapper'>
                            <img src={require("../../assets/notifications/ray.png")} alt="ray" className='job-card-notification'/>
                            <Switch color="-yellow" value={rayNotification} getChangeValue={changeNotification} jobId={job.id} type={'ray'}/>
                        </div>
                        <div className='job-card-notifications-wrapper'>
                            <img src={require("../../assets/notifications/no_data.png")} alt="no_data" className='job-card-notification margin-left-40'/>
                            <Switch color="-green" value={noDataNotification} getChangeValue={changeNotification} jobId={job.id} type={'no_data'}/>
                        </div>
                    </div>
                )}
                <p className='job-card-p'> 
                    <b>Descripción:</b> { job.get("Description").length < 180 ? job.get("Description") : `${job.get("Description").substring(0,180)}...`} 
                </p>
                {!currentSession?.wollerData?.id && (
                    <>
                        <p className='job-card-p'> <b>Nombre de cliente:</b> {job.get("CreatedBy") ? job.get("CreatedBy").get("Name") : "No"} </p>
                        <p className='job-card-p'> <b>Teléfono:</b> {job.get("CreatedBy") ? job.get("CreatedBy").get("Phone") : "No"} </p>
                    </>
                )}
            </div>
            <div className='job-card-match-wrapper'>
                {
                    matches.map( match => (
                        <div style={{marginRight: 10}}>
                            {match.state == "no match" && (
                                <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                                    <defs>
                                        <rect id="path-1" x="0" y="0" width="40" height="40" rx="20"></rect>
                                        <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="40" height="40" fill="white">
                                            <rect id="path-1" x="0" y="0" width="40" height="40" rx="20"></rect>
                                        </mask>
                                    </defs>
                                    <g id="Icono-/-Background-/-Pro-/-Empty" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="40" height="40" fill="white">
                                            <rect id="path-1" x="0" y="0" width="40" height="40" rx="20"></rect>
                                        </mask>
                                        <g id="Icono-/-Pro-/-Empty-/-Outline" transform="translate(10.000000, 10.000000)">
                                            <mask id="mask-4" fill="white">
                                                <path d="M4.16666667,11.9189167 L4.16666667,11.6664167 C4.16666667,9.94558333 4.7825,8.01891667 6.36833333,7.14641667 L5.9975,11.4089167 L7.67083333,11.4089167 L8.16333333,5.74141667 C8.21083333,5.31891667 8.56666667,4.99975 8.99166667,4.99975 L11.0083333,4.99975 C11.4333333,4.99975 11.7891667,5.31891667 11.835,5.72141667 L12.3291667,11.4089167 L14.0025,11.4089167 L13.6308333,7.14641667 C15.2175,8.01891667 15.8333333,9.94558333 15.8333333,11.6664167 L15.8333333,11.9189167 L16.7758333,13.3330833 L3.22416667,13.3330833 L4.16666667,11.9189167 Z M18.3333333,14.99975 L19.0266667,13.70475 L17.4966667,11.4089167 C17.4133333,8.33058333 15.8283333,6.03058333 13.435,5.27558333 C13.1775,4.15308333 12.1858333,3.33308333 11.0083333,3.33308333 L8.99166667,3.33308333 C7.82,3.33308333 6.83166667,4.14475 6.56666667,5.27475 C4.1725,6.02975 2.58666667,8.32975 2.50333333,11.4089167 L0.973333333,13.70475 L1.66666667,14.99975 L18.3333333,14.99975 Z" id="path-3"></path>
                                            </mask>
                                            <path d="M4.16666667,11.9189167 L4.16666667,11.6664167 C4.16666667,9.94558333 4.7825,8.01891667 6.36833333,7.14641667 L5.9975,11.4089167 L7.67083333,11.4089167 L8.16333333,5.74141667 C8.21083333,5.31891667 8.56666667,4.99975 8.99166667,4.99975 L11.0083333,4.99975 C11.4333333,4.99975 11.7891667,5.31891667 11.835,5.72141667 L12.3291667,11.4089167 L14.0025,11.4089167 L13.6308333,7.14641667 C15.2175,8.01891667 15.8333333,9.94558333 15.8333333,11.6664167 L15.8333333,11.9189167 L16.7758333,13.3330833 L3.22416667,13.3330833 L4.16666667,11.9189167 Z M18.3333333,14.99975 L19.0266667,13.70475 L17.4966667,11.4089167 C17.4133333,8.33058333 15.8283333,6.03058333 13.435,5.27558333 C13.1775,4.15308333 12.1858333,3.33308333 11.0083333,3.33308333 L8.99166667,3.33308333 C7.82,3.33308333 6.83166667,4.14475 6.56666667,5.27475 C4.1725,6.02975 2.58666667,8.32975 2.50333333,11.4089167 L0.973333333,13.70475 L1.66666667,14.99975 L18.3333333,14.99975 Z" id="path-3"></path>
                                            <g id="✱-/-Color-/-Black-/-400" mask="url(#mask-4)" fill="#B0B0B0" fillRule="evenodd">
                                                <rect id="Rectangle-13" x="0" y="0" width="20" height="20"></rect>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            )}
                            {match.state == "match" && (
                                <svg width="38" height="38" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                    <title>Layer 1</title>
                                    <g id="Mask">
                                        <path stroke="#000000" fill={"#d2d2d2"} opacity="undefined" d="m19,37.83183c-10.40777,0 -18.83806,-8.4275 -18.83806,-18.83183c0,-10.40433 8.43029,-18.83183 18.83806,-18.83183c10.40777,0 18.83806,8.4275 18.83806,18.83183c0,10.40433 -8.43029,18.83183 -18.83806,18.83183z" id="svg_3" stroke-opacity="0"/>
                                        <path strokeWidth="0" stroke="null" fill="#444444" d="m19.96074,9.56174c1.12193,0 2.06679,0.77316 2.31214,1.83338c2.28038,0.71259 3.79057,2.88339 3.86997,5.7888l0,0l1.45779,2.16608l-0.66061,1.22305l-0.72096,0l0,0.78652c0,3.90271 -3.20618,7.07869 -7.14602,7.07869c-3.93985,0 -7.14603,-3.17598 -7.14603,-7.07869l0,0l0,-0.78652l-0.86705,0l-0.66061,-1.22305l1.45858,-2.16608c0.07939,-2.9062 1.58959,-5.07779 3.87156,-5.78958c0.2517,-1.06653 1.19338,-1.8326 2.30976,-1.8326l0,0l1.92149,0l-0.00001,0zm4.67032,11.01131l-11.11604,0l0,0.78652c0,3.03597 2.49316,5.50564 5.55802,5.50564c3.06486,0 5.55802,-2.46967 5.55802,-5.50564l0,0l0,-0.78652zm-8.40993,0.78652c0.65744,0 1.19101,0.52854 1.19101,1.17978c0,0.65124 -0.53357,1.17979 -1.19101,1.17979c-0.65743,0 -1.191,-0.52855 -1.191,-1.17979c0,-0.65124 0.53357,-1.17978 1.191,-1.17978zm5.55803,0c0.65743,0 1.19101,0.52854 1.19101,1.17978c0,0.65124 -0.53358,1.17979 -1.19101,1.17979c-0.65743,0 -1.191,-0.52855 -1.191,-1.17979c0,-0.65124 0.53357,-1.17978 1.191,-1.17978zm-1.81842,-10.22478l-1.9215,0c-0.40494,0 -0.74398,0.30123 -0.78923,0.7l0,0l-0.46926,5.34913l-1.59436,0l0.35334,-4.02305c-1.51099,0.82348 -2.09776,2.64193 -2.09776,4.26609l0,0l0,0.23832l-0.89802,1.33473l12.91288,0l-0.8988,-1.33473l0,-0.23832c0,-1.62416 -0.58678,-3.4426 -2.09776,-4.26609l0,0l0.35332,4.02305l-1.59356,0l-0.47164,-5.36801c-0.04288,-0.37989 -0.38271,-0.68111 -0.78764,-0.68111l0,0l-0.00001,-0.00001z" id="svg_1"/>
                                    </g>
                                </svg>
                            )}
                        </div>
                    ))
                }
            </div>
            
            <div className='job-card-asign-button-wrapper'>
                {!currentSession?.wollerData?.id && (

                    <button className='job-card-asign-button-modify' 
                            disabled={disabledModifyPrice} 
                            onClick={() => {onModifyJobLeadPrice(-1)}}
                    > 
                        <p className='job-card-asign-button-modify-p'>-</p> 
                    </button>
                )}
                <button className='job-card-asign-button' 
                        onClick={() => handleOnClick()}
                >
                    {currentSession?.wollerData?.id ? `Comprar ${jobPrice}€` : `Asignar ${jobPrice}€`}
                </button>
                {!currentSession?.wollerData?.id && (
                    <button className='job-card-asign-button-modify' 
                            disabled={disabledModifyPrice} 
                            onClick={() => {onModifyJobLeadPrice(+1)}}
                    > 
                        <p className='job-card-asign-button-modify-p'>+</p>  
                    </button>
                )}
            </div>
            {
                !currentSession?.wollerData?.id && (
                    <button className='job-card-details-button' onClick={()=>{itemDetail(job.id)}}>
                        Mas Información
                    </button>
                )
            }

            {joberModalVisible && (
                <MatchJobWollerModal
                    isVisible={joberModalVisible}
                    onClose={() => setJoberModalVisible(false)}
                    asignJober={asignJober}
                    job={job}
                    leadPrice={job.get("JobLeadPrice")}
                />
            )}

            { showConfirmModal && (
                <AlertModal
                    isVisible={showConfirmModal}
                    onClose={() => {
                        setShowConfirmModal(false);
                    }}
                    title={job.get("Title")}
                    subtitle="¿Seguro que deseas comprar este trabajo?"
                    buttonText="Comprar"
                    onClick = {() => asignJober() }
                />
            )}

        </div>
    )
}

export default JobsMapsCard;