import React, { useState, useEffect } from "react";
import { useStateValue } from "../../../state/context";
import Button from "../../button/Button";

import back from "../../../assets/back-step.svg";

import "../../../styles/job/step-controlls.css";

export default function StepControlls(props) {
    const [{ job }] = useStateValue();

    const [disabled, setDisabled] = useState(
        props.disabled ? props.disabled : false
    );
    const [loading, setLoading] = useState(
        props.loading ? props.loading : false
    );
    const [nextTrigger, setNextTrigger] = useState(
        props.nextTrigger ? props.nextTrigger : null
    );
    const [nextTitle, setNextTitle] = useState(
        props.nextTitle ? props.nextTitle : false
    );

    const enableButton = () => {
        setDisabled(false);
    };

    const disableButton = () => {
        setDisabled(true);
    };

    const updateText = (button, text) => {
        if (button === "next") setNextTitle(text);
    };

    const next = () => {
        if (props.next === "root") {
            job.history.push({
                pathname: "/job/new",
                state: {
                    inner: true
                }
            });
        } else if (props.next) {
            job.history.push({
                pathname: `/${props.next}`,
                search: job.location.search,
                state: {
                    inner: true
                }
            });
        } 

        // save content and push it to parent
        if (props.update){
            props.update();  
        } 
    };

    const previous = () => {
        if (props.prev === "root") {
            job.history.replace({
                pathname: "/job/new",
                search: job.location.search,
                state: {
                    inner: true
                }
            });
            return;
        } else if (props.prev) {
            job.history.push({
                pathname: `/${props.prev}`,
                search: job.location.search,
                state: {
                    inner: true
                }
            });
        } else job.history.goBack();
    };

    const submit = () => {
        if (props.update) props.update();
    };

    useEffect(() => {
        if (props.nextTrigger !== nextTrigger) {
            setNextTrigger(props.nextTrigger);
            if (props.nextTrigger) disableButton();
            else enableButton();
        }

        if (props.nextTitle !== nextTitle)
            updateText("next", props.nextTitle ? props.nextTitle : "Siguiente");

        if (props.loading !== loading) setLoading(props.loading);
        if (props.disabled !== disabled) setDisabled(props.disabled);
    }, [
        props.nextTrigger,
        props.loadTrigger,
        props.nextTitle,
        props.loading,
        props.disabled
    ]);

    return (
        <footer id="footer">
            <div className="bottom-controls">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="controls-content">
                                <div className="row">
                                    <div className="col-xs-3">
                                        <div className="btn-back">
                                            <button
                                                type="button"
                                                className="secondary"
                                                onClick={previous}
                                            >
                                                <img src={back} alt="Volver" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-xs-9">
                                        {!props.nextHidden &&
                                            (!props.isLastPage ? (
                                                <div className="btn-next">
                                                    <Button
                                                        buttonText={nextTitle}
                                                        onClick={next}
                                                        disabled={disabled}
                                                        loading={loading}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="btn-submit">
                                                    <Button
                                                        buttonText={nextTitle}
                                                        onClick={submit}
                                                        disabled={disabled}
                                                        loading={loading}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
